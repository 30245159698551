export async function uploadWithSignURL(url, blobData ) {
  return new Promise( (resolve, reject) => {  
    fetch( url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      body: blobData
    })
      .then(response => {
        if (response.status / 100 === 2) resolve({ message: 'Success' });
        reject(response);
      });
  });
}

export async function uploadJsonWithSignURL(url, json ) {
  return new Promise( (resolve, reject) => {  
    fetch( url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: json
    })
      .then(response => {
        if (response.status / 100 === 2) resolve({ message: 'Success' });
        reject(response);
      });
  });
}