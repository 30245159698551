import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { handleDeleteSelectedGlobalHotspotFromAGlobalHotspotGroup } from 'ogmo_core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import GlobalHotspotIconOrColor from '../GlobalHotspotComponent/GlobalHotspotIconOrColor'

const useStyles = makeStyles({
  componentBody: {
    Height: 'auto',
    width: 326
  },
  Typography: {
    color: '#575757'
  },
  name: {
    marginLeft: '30px'
  },
  avatar: {
    marginLeft: '-20px',
    width: 10,
  },
  listItem: {
    height: 65
  }
});

const SelectedGlobalHotspotsList = ({ componentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cmpts = useSelector(state => state.scene.componentByIds);
  const globalHotspotGroupComponent = useSelector(state => state.scene.componentByIds[componentId]);
  const globalHotspotGroupComponentData = globalHotspotGroupComponent.data
  const selectedGlobalHotspotComponents = globalHotspotGroupComponentData.selectedGlobalHotspots

  const handleDeleteSelectedGlobalHotspot = globalHotspot => {
    dispatch(
      handleDeleteSelectedGlobalHotspotFromAGlobalHotspotGroup({
        selectedGlobalHotspot: globalHotspot,
        globalHotspotGroupComponentId: componentId
      })
    );
  };

  return (
    <Box
      className={classes.componentBody}
      display="flex"
      flex="1"
      flexDirection="column"
      position="relative"
    >
      <List>
        {selectedGlobalHotspotComponents.map((globalHotspot, index) => (
          <ListItem
            className={classes.listItem}
            key={index}
          >
            <ListItemAvatar className={classes.avatar}>
              <GlobalHotspotIconOrColor globalHotspot={cmpts[globalHotspot].data} />
            </ListItemAvatar>
            <ListItemText
              className={classes.name}
              primary={cmpts[globalHotspot].data.displayName}
            />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="comments"
                edge="end"
              >
                <DeleteIcon onClick={() => handleDeleteSelectedGlobalHotspot(globalHotspot)} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
export default SelectedGlobalHotspotsList;