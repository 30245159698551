import React from 'react';
import { Box, Switch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { updateCameraComponentData } from 'ogmo_core';

export default function EnableInteraction({ cameraComponent }) {
  const dispatch = useDispatch();

  const handleEnableInteraction = event => {
    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: event.target.checked,
      updateField: 'enableInteraction'
    }
    ));
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
    >
      <Box width="45%">
        <Typography variant="caption">Enable Interaction</Typography>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        ml={2}
        width="55%"
      >
        <Switch
          checked={cameraComponent.data.enableInteraction}
          color="primary"
          onChange={handleEnableInteraction}
          size="small"
        />
      </Box>
    </Box>
  );
}
