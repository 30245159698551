import React from 'react';
import AssetPicker from './AssetPicker';
import { useDispatch } from 'react-redux';
import { OgmoConsts,updateARComponentData } from 'ogmo_core';

const GlbAssetPicker = ({
  openAssetPicker,
  handleCloseAssetPicker,
  glbComponent,
}) => {
  const dispatch = useDispatch();

  const handleChoosedGlbAssetId = choosedGlbAssetId => {
    dispatch(updateARComponentData({
      componentId: glbComponent.id,
      updateField:'glbAssetId',
      value: choosedGlbAssetId
    })
    );
    handleCloseAssetPicker();
  };

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedGlbAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.GLB]}
    />
  );
};
export default GlbAssetPicker;
