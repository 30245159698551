import React, { useState } from 'react';
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import UsdzAssetPicker from '../AssetPickers/UsdzAssetPicker';
import { getAssetById, updateUsdzComponentData } from 'ogmo_core';
import { useDispatch, useSelector } from 'react-redux';
import { showInfoSnackbar} from 'reducers/snackbarSlice';

const useStyles = makeStyles({
  image: {
    width: 50,
    height: 50,
    border: '1px solid #d6d6d6',
    backgroundColor: 'white',
    cursor: 'pointer'
  },
  deleteIcon: {
    marginLeft: 'auto'
  },
  usdzTypography: {
    marginTop: 20,
    minHeight: 30,
    background: '#e0e0e0',
    display: 'flex',
    paddingLeft: '10px',
    alignItems: 'center'
  },
  usdzContent: {
    height: '270px',
    background: '#f7f4f4',
    padding: '15px'
  },
  usdzNameTypography: {
    marginLeft: '10px'
  }
});

export default function USDZComponent({ componentId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const usdzComponent = useSelector(state => state.scene.componentByIds[componentId]);

  const [openAssetPicker, setOpenAssetPicker] = useState(false);

  const deleteUsdz = () => {
    dispatch(showInfoSnackbar('Clicked Delete USDZ'));
  };

  const handleOpenAssetPicker = () => {
    setOpenAssetPicker(true);
  };

  const handleCloseAssetPicker = () => {
    setOpenAssetPicker(false);
  };

  const getAssetName = asset => {
    return asset ? asset.name : '';
  };

  const getAssetURL = asset => {
    return asset ? asset.data.usdzPath : '';
  };

  const handleDeleteUsdz = () => {
    dispatch(updateUsdzComponentData({
      componentId: usdzComponent.id,
      updateField: 'usdzAssetId',
      value: ''
    })
    );
  };

  const usdzAsset = getAssetById(usdzComponent.data.usdzAssetId);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexWrap="nowrap"
      mt={1}
    >
      <Box className={classes.usdzTypography}>
        <Typography variant="body1">USDZ Component</Typography>
        <Button
          className={classes.deleteIcon}
          onClick={deleteUsdz}
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </Button>
      </Box>
      <Box className={classes.usdzContent}>
        <Typography>USDZ Asset</Typography>

        <Box display="flex">
          <img
            alt=""
            className={classes.image}
            onClick={handleOpenAssetPicker}
            src={getAssetURL(usdzAsset)}
          />
          <Typography className={classes.usdzNameTypography}>
            {getAssetName(usdzAsset)}
          </Typography>
          <IconButton
            aria-label="delete"
            className={classes.deleteIcon}
            onClick={handleDeleteUsdz}
          >
            <DeleteIcon
              color="action"
              fontSize="small"
            />
          </IconButton>
        </Box>
      </Box>

      {openAssetPicker && (
        <UsdzAssetPicker
          handleCloseAssetPicker={handleCloseAssetPicker}
          openAssetPicker={openAssetPicker}
          usdzComponent={usdzComponent}
        />
      )}
    </Box>
  );
}
