import React from 'react';
import AssetPicker from './AssetPicker';
import { useDispatch } from 'react-redux';
import { OgmoConsts, updateUsdzComponentData } from 'ogmo_core';

const UsdzAssetPicker = ({
  openAssetPicker,
  handleCloseAssetPicker,
  usdzComponent
}) => {
  const dispatch = useDispatch();

  const handleChoosedUsdzAssetId = choosedUsdzAssetId => {
    dispatch(updateUsdzComponentData({
      componentId: usdzComponent.id,
      updateField: 'usdzAssetId',
      value: choosedUsdzAssetId
    })
    );
    handleCloseAssetPicker();
  };

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedUsdzAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.USDZ]}
    />
  );
};
export default UsdzAssetPicker;
