import dashboardReducer from '../reducers/dashboardSlice'
import ogmoDesignsReducer from '../reducers/ogmoDesignsSlice'
import ecommerceProductsReducer from '../reducers/ecommerceProductsSlice'
import { assetBrowserReducer } from '../reducers/assetBrowserReducer'
import snackbarDashboardReducer from '../reducers/snackbarSlice'
import organizationAndUserReducer from '../reducers/organizationAndUserSlice'
import refImageReducer from '../reducers/refImageSlice'
import { createStore } from 'ogmo_core'

const store = createStore( null, 
  null,
  dashboardReducer, 
  ogmoDesignsReducer, 
  ecommerceProductsReducer, 
  assetBrowserReducer,
  snackbarDashboardReducer,
  refImageReducer,
  organizationAndUserReducer );

export default store;