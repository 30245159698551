import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import * as api from '../../services/api';
import { deleteDesign } from '../../reducers/dashboardSlice';
import { showErrorSnackbar,showSuccessSnackbar} from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    width: '400px',
    margin: theme.spacing(1)
  },
  okButton: {
    backgroundColor: '#3e8eb8',
    color: '#ffffff',
    '&:hover': { backgroundColor: '#24739C' }
  },
  cancelButton: {
    color: '#3e8eb8',
  },
  loader: {
    color: '#3183ae'
  }
}));

const ForceDeleteDialog = ({
  design,
  loadDesigns,
  openDeleteConfirmationDialog,
  setOpenDeleteConfirmationDialog,
  handleCloseDeleteConfirmationDialog
}) => {

  const [groupDesigns, setGroupDesigns] = useState([]);
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [deletingLoader, setDeletingLoader] = useState(false);
    
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDeleteDesign = (isForceDelete) => {
    setDeletingLoader(true);
    let flag = '';
    if(isForceDelete == true){
      flag = `?isForceDelete=${isForceDelete}`
    }
    //Todo - wrong. We need to unlink from all the multiple eCommerce sites.
    api.remove(`designs/${design.id}${flag}`) 
      .then(data => {
        setDeletingLoader(false);
        if (data.GroupDesigns.length > 0 || data.LinkProducts.length > 0) {
          setGroupDesigns(data.GroupDesigns);
          setLinkedProducts(data.LinkProducts);
        } else {
          dispatch(deleteDesign(design.id));
          dispatch(showSuccessSnackbar('Deleted Successfully'));
          setOpenDeleteConfirmationDialog(false);
          loadDesigns();
        }
      })
      .catch(error => {
        dispatch(showErrorSnackbar('Delete Failed ', error.message));
        setOpenDeleteConfirmationDialog(false);
        setDeletingLoader(false);
        setGroupDesigns([]);
        setLinkedProducts([]);
        console.log(error);
      });
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={handleCloseDeleteConfirmationDialog}
      open={openDeleteConfirmationDialog}
    >
      <DialogTitle className={classes.title}>
                Delete "{design.name}" from the list?
      </DialogTitle>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {groupDesigns.length > 0 || linkedProducts.length > 0 ?
          <DialogContent>
            <Typography>
              {groupDesigns.length > 0 ? 'This design is a part of following Group Designs.' : ''}
            </Typography>
            <DialogContent>
              {groupDesigns.map((groupDesign, id) => (
                <DialogContentText key={id}>&emsp;{groupDesign}</DialogContentText>
              ))}
            </DialogContent>
            <Typography>
              {linkedProducts.length > 0 ? 'This design is a part of following Link designs.' : ''}
            </Typography>
            <DialogContent>
              {linkedProducts.map((linkedProduct, id) => (
                <div key={id}>
                  <DialogContentText key={id}>&emsp;{linkedProduct}</DialogContentText>
                </div>
              ))}
            </DialogContent>
            <Typography>Do you want to delete this?</Typography>
            <DialogContent>
              <Box
                display="flex"
                justifyContent="center"
              >
                {deletingLoader == true ? <CircularProgress
                  className={classes.loader}
                  disableShrink
                /> : ''}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                className={classes.cancelButton}
                onClick={handleCloseDeleteConfirmationDialog}
              >
                                Cancel
              </Button>
              <Button
                className={classes.okButton}
                onClick={() => handleDeleteDesign(true)}
              >
                                Ok
              </Button>
            </DialogActions>
          </DialogContent>
          :
          <DialogContent>
            <Box
              display="flex"
              justifyContent="center"
            >
              {deletingLoader == true ? <CircularProgress
                className={classes.loader}
                disableShrink
              /> : ''}
            </Box>
            <DialogActions>
              <Button
                autoFocus
                className={classes.cancelButton}
                onClick={handleCloseDeleteConfirmationDialog}
              >
                                Cancel
              </Button>
              <Button
                className={classes.okButton}
                onClick={() => handleDeleteDesign(false)}
              >
                                Ok
              </Button>
            </DialogActions>
          </DialogContent>
        }
      </Box>
    </Dialog>
  );
}

export default ForceDeleteDialog;