import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, IconButton, Avatar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { updateMaterialAssetData } from 'ogmo_core'

const useStyles = makeStyles({
  image: {
    width: 65,
    height: 65,
    border: '1px solid #d6d6d6',
    backgroundColor: 'white',
    cursor: 'pointer',
    borderRadius: 5
  },
  nameField: {
    width: '60%',
    marginLeft: 16,
    height: 25,
    backgroundColor:'#e6e6e6'
  },
  input: {
    fontSize: 12,
    paddingLeft: 8,
    paddingTop: 3
  },
  deleteIcon: {
    padding: 0,
    marginLeft: 'auto'
  }
});

export default function InspectorPropertyPreview({
  handleOpenTextureAssetPicker,
  assetMap,
  mapType
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedAsset = useSelector(
    state => state.scene.assetByIds[state.scene.selectedAssetId]
  );

  const getAssetURL = asset => {
    return asset ? asset.data.originalTextures[0] : '';
  };

  const getAssetName = asset => {
    return asset ? asset.name : '';
  };

  const handleDeleteTextureAssetProperty = () => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      value: '',
      updateField: mapType
    }
    ));
    // dispatch(deleteMaeterialAssetAssignedTexture({
    //   assetID: selectedAsset.id,
    //   mapType: mapType
    // }))
  };

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      flexWrap="nowrap"
    >
      <Avatar
        className={classes.image}
        onClick={handleOpenTextureAssetPicker(mapType)}
        src={getAssetURL(assetMap)}
      />
      <Box className={classes.nameField}>
        {getAssetName(assetMap) === ''? 
          <Typography className={classes.input}>[empty]</Typography>
          :<Typography className={classes.input}>
            {getAssetName(assetMap)}
          </Typography>}
      </Box>
      <IconButton
        aria-label="delete"
        className={classes.deleteIcon}
        onClick={handleDeleteTextureAssetProperty}
      >
        <DeleteIcon
          color="action"
          fontSize="small"
        />
      </IconButton>
    </Box>
  );
}
