import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Input,
  Divider,
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import {v4 as UUID} from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as api from 'services/api';
import { Alert, AlertTitle } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import { useSelector, useDispatch } from 'react-redux';
import querystring from 'querystring';
import { showErrorSnackbar, showSuccessSnackbar } from 'reducers/snackbarSlice';
import DokanMultiVenderPlatform from './DokanMultiVenderPlatform';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: 'bold',
  },
  margin: {
    marginBottom: theme.spacing(3),
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 250,
    border: 'solid 1px #C4C4C4',
  },
  baseURLInputField: {
    fontSize: 12,
    width: 320,
    border: 'solid 1px #C4C4C4',
    alignItems: 'center',
    padding: '6px',
    borderRadius: '4px',
  },
  hostUrl: {
    width: 320,
    fontWeight: 'bold',
  },
  versionInputField: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 150,
    border: 'solid 1px #C4C4C4'
  },
  button: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '10px',
    width: 100,
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' },
  },
  buttonGetVender: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '10px',
    width: 100,
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' },
  },
  loader: {
    color: '#3183ae',
  },
  chipLabel: {
    marginLeft: theme.spacing(3),
    fontWeight: 'bold',
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  unlinkCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#3E8EB8',
      color: 'white'
    }
  }
}));

export default function ECommercePlatform() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [version, setVersion] = useState();
  const [platform, setPlatform] = useState('');
  const [consumerKey, setConsumerKey] = useState();
  const [consumerSecret, setConsumerSecret] = useState();
  const [tempHost, setTempHost] = useState('');
  const [isCustomShopifyAppUsed,setCustomShopifyAppUsed] = useState(false)
  const [APIKeyOfCustomShopifyApp,setAPIKeyOfCustomShopifyApp] = useState(false)
  const [APISecretOfCustomShopifyApp,setAPISecretOfCustomShopifyApp] = useState(false)
  const [isECommPlatformLoading, setECommPlatformLoading] = useState(false);
  const [isVisibleLinkedAccounts, setIsVisibleLinkedAccounts] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [isMagento2xKeySubmitSuccess,setMagento2xKeySubmitSuccess] = useState(false)
  const ecommerceHosts = useSelector((state) => state.ecommerceProducts.ecommerceHosts);
  const organizationId = useSelector(
    (state) => state.dashboard.userOrganizationId
  );

  useEffect(() => {
    if (Object.keys(ecommerceHosts).length > 0) {
      setIsVisibleLinkedAccounts(true);
    }
    setInitialize(true);
  }, []);

  if (!initialize) {
    return null;
  }

  const handleChangeVersion = (event) => {
    setVersion(event.target.value);
  };

  const handleChangePlatform = (event) => {
    setPlatform(event.target.value);
  };

  const handleAPIKeyOfCustomShopifyApp = (event) => {
    setAPIKeyOfCustomShopifyApp(event.target.value)
  }

  const handleAPISecretOfCustomShopifyApp = (event) => {
    setAPISecretOfCustomShopifyApp(event.target.value)
  }

  const handleSetCustomShopifyAppUsed = (event) => {
    setCustomShopifyAppUsed(event.target.checked);
  }

  const handleConsumerKey = (event) => {
    setConsumerKey(event.target.value);
  };

  const handleConsumerSecret = (event) => {
    setConsumerSecret(event.target.value);
  };

  const handleHost = (event) => {
    setTempHost(event.target.value);
  };

  const linkPlatform = () => {
    if (platform === 'magento'){
      linkMagentoPlatformToTheOrganization()
    }else if (platform === 'shopify' && isCustomShopifyAppUsed){
      storeCustomShopifyAppDetails()
    }
    else if(platform === 'shopify'){
      getShopifyFrontendAPI()
    }else{
      linkWooCommercePlatformToTheOrganization()
    }
  }

  const linkMagentoPlatformToTheOrganization = (event) => { // eslint-disable-line no-unused-vars
    setECommPlatformLoading(true);
    if(tempHost.includes('https://')){
      const newHost = tempHost.split('://')[1];
      const data = {
        host: newHost,
        consumer_key: consumerKey,
        consumer_secret: consumerSecret
      };
      const endpoint = version === '1.x' ? 'magento1x/auth' :  'magento2x/auth'
      api
        .post(endpoint, data)
        .then((res) => {
          if(version === '1.x') {
            window.location.assign(res.data.url);
          } else if (version === '2.x') {
            setMagento2xKeySubmitSuccess(true)
          }
          setECommPlatformLoading(false);
        })
        .catch((error) => {
          setECommPlatformLoading(false);
          dispatch(showErrorSnackbar(error.message));
          console.log(error);
        });
    } else {
      dispatch(showErrorSnackbar('Please enter a valid url.'));
      setECommPlatformLoading(false);
    }
  };

  // eslint-disable-next-line
  const storeCustomShopifyAppDetails = (event) => {
    api.post('shopify/custom-auth',{
      custom_app_api_key: APIKeyOfCustomShopifyApp,
      custom_app_secret: APISecretOfCustomShopifyApp,
      host: tempHost
    }).then(res => {
      if(res.status === 200) {
        dispatch(showSuccessSnackbar('Shopify store details with custom app credentials were stored.'))
      } else {
        dispatch(showErrorSnackbar('An error occured when storing Shopify data'))
      } 
    }).catch(err => {
      console.error(err)
      dispatch(showErrorSnackbar('An error occured when storing Shopify data'))
    })
  }

  // eslint-disable-next-line
  const getShopifyFrontendAPI = (event) => {
    setECommPlatformLoading(true);
    if(tempHost.includes('https://')){
      const storeUrlRegex = /[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com[/]?/;
      if (storeUrlRegex.test(tempHost)) {
        const shopifyStoreUrl = tempHost.split('://')[1];
        const redirectUrl = process.env.REACT_APP_SHOPIFY_REDIRECT_URI;
        const oAuthUrl = `https://${shopifyStoreUrl}/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_API_KEY}&scope=write_products,read_products,write_script_tags,read_themes,write_themes&redirect_uri=${redirectUrl}&state={nonce}&grant_options[]=per_user`;
        window.location.replace(oAuthUrl);
      } else {
        dispatch(showErrorSnackbar('Invalid Shopify store URL'));
      }
    } else {
      dispatch(showErrorSnackbar('Please enter a valid url.'));
      setECommPlatformLoading(false);
    }
  };

  const linkWooCommercePlatformToTheOrganization = () => {
    setECommPlatformLoading(true);
    if (tempHost.includes('https://')) {
      const windowUrl = window.location.href.split('/settings');
      const newHost = tempHost.split('://')[1];
      const params = {
        app_name: 'OgmoViewer',
        scope: 'read',
        user_id: UUID(),
        return_url: `${windowUrl[0]}/designs`,
        callback_url: `${process.env.REACT_APP_API_ENDPOINT}woocommerce/authCallBack`
      };
      const queryStringParms = querystring.stringify(params).replace(/%20/g, '+');
      const organizationData = `${organizationId}//${newHost}`;
      const finalUrl = `https://${newHost}/wc-auth/v1/authorize?${queryStringParms}?orgData=${organizationData}`
      setECommPlatformLoading(false);
      window.location.replace(finalUrl);
    } else {
      dispatch(showErrorSnackbar('Please enter a valid url.'));
      setECommPlatformLoading(false);
    }
  };

  const unlinkEcomercePlatform = (element) => {
    if (ecommerceHosts[element].platform === 'shopify') {
      api
        .post('shopify/uninstall', { organizationId })
        .then((res) => {
          if (res.status === 200) {
            dispatch(showSuccessSnackbar('Platform Unassigned Success'));
            localStorage.setItem('selectedHost', '');
            window.location.reload(false);
            setECommPlatformLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(showErrorSnackbar('Invalid request. Please try again'));
        });
    } else {
      api
        .remove(
          `magento/unassignplatform?organization_id=${organizationId}&platform=${ecommerceHosts[element].platform}&platform_version=${ecommerceHosts[element].version}&host=${element}`
        )
        .then((response) => { // eslint-disable-line no-unused-vars
          dispatch(showSuccessSnackbar('Platform Unassigned Success'));
          localStorage.setItem('selectedHost', '');
          window.location.reload(false);
          setECommPlatformLoading(false);
        })
        .catch((error) => {
          /* When user click link button with incorrect url then it shows linked successfull message. But actually it's not linked properly. 
        To fix this need to refresh */
          dispatch(
            showErrorSnackbar(error.message + ' or Please try refresh!')
          );
          console.log(error);
        });
    }
  };

  if (isVisibleLinkedAccounts) {
    return (
      <Card className={classes.margin}>
        <CardContent>
          <LinkedSuccesMessage
            ecommerceHosts={ecommerceHosts}
            setIsVisibleLinkedAccounts={setIsVisibleLinkedAccounts}
            unlinkEcomercePlatform={unlinkEcomercePlatform}
          />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.margin}>
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
          mx={4}
        >
          <Typography variant="subtitle1">Linked Platforms</Typography>
          <Typography
            className={classes.title}
            variant="subtitle2"
          >
            Select an ECommerce platform to link
          </Typography>
          <Divider />
          {isECommPlatformLoading && (
            <Box
              display="flex"
              justifyContent="center"
              pt={2}
            >
              <CircularProgress className={classes.loader} />
            </Box>
          )}
          {isMagento2xKeySubmitSuccess && (
            <Alert severity="success">
              Magento 2.x Store details updated successfully. Now, Activate the
              integration from Magento Admin to complete linking.
            </Alert>
          )}
          {!isECommPlatformLoading && !isMagento2xKeySubmitSuccess && (
            <>
              <Box
                display="flex"
                mb={2}
                mt={4}
              >
                <Box mr={13}>
                  <Typography required>ECommerce Platform</Typography>
                  <FormControl>
                    <Select
                      className={classes.input}
                      disableUnderline
                      onChange={handleChangePlatform}
                      value={platform}
                    >
                      <MenuItem value="magento">Magento</MenuItem>
                      <MenuItem value="woocommerce">WooCommerce</MenuItem>
                      <MenuItem value="shopify">Shopify</MenuItem>
                      <MenuItem value="wordpress">WordPress</MenuItem>
                      <MenuItem value="dokan">Dokan Multi-Vender</MenuItem>
                    </Select>
                  </FormControl>
                  {platform === 'dokan' && (
                    <DokanMultiVenderPlatform
                      tempHost={tempHost}
                    />
                  )}
                  {platform === 'wordpress' && (
                    <Box>
                      <Typography>Version</Typography>
                      <FormControl>
                        <Select
                          className={classes.versionInputField}
                          disableUnderline
                          onChange={handleChangeVersion}
                          value={version}
                        >
                          <MenuItem value="wc/v5.3">5.3 +</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Box>
                <Box display="flex">
                  <Box
                    ml={3}
                    mr={3}
                  >
                    <Typography required>
                      Base URL
                    </Typography>
                    <Input
                      autoFocus
                      className={classes.baseURLInputField}
                      disableUnderline
                      onChange={handleHost}
                      placeholder={
                        platform === 'shopify'
                          ? 'https://example.myshopify.com'
                          : 'https://example.com'
                      }
                      required
                      value={tempHost}
                    />
                  </Box>
                </Box>
              </Box>
              {platform === 'shopify' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCustomShopifyAppUsed}
                      name="isCustomShopifyAppUsed"
                      onChange={handleSetCustomShopifyAppUsed}
                    />
                  }
                  label="Link using a custom Shopify app"
                />
              )}
              { (platform === 'shopify' && isCustomShopifyAppUsed)  && (
                <Box
                  display="flex"
                  flexDirection="row"
                  mb={2}
                  mt={2}
                >
                  <Box mr={13}>
                    <Typography required>Custom App API key</Typography>
                    <Input
                      autoFocus
                      className={classes.input}
                      disableUnderline
                      onChange={handleAPIKeyOfCustomShopifyApp}
                      placeholder="Add the Custom App API keys"
                      required
                    />
                  </Box>
                  <Box ml={3}>
                    <Typography required>Custom App API Secret key</Typography>
                    <Input
                      autoFocus
                      className={classes.input}
                      disableUnderline
                      onChange={handleAPISecretOfCustomShopifyApp}
                      placeholder="Enter the secret API key"
                      required
                    />
                  </Box>
                </Box>
              )}
              <Box
                display="flex"
                mb={2}
              >
                {platform === 'magento' && (
                  <Box>
                    <Typography>Version</Typography>
                    <FormControl>
                      <Select
                        className={classes.versionInputField}
                        disableUnderline
                        onChange={handleChangeVersion}
                        value={version}
                      >
                        <MenuItem value="1.x">1.x</MenuItem>
                        <MenuItem value="2.x">2.x</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {version && platform === 'magento' && (
                  <>
                    <Box
                      ml={3}
                      mr={3}
                    >
                      <Typography required>Consumer Key</Typography>
                      <Input
                        autoFocus
                        className={classes.input}
                        disableUnderline
                        onChange={handleConsumerKey}
                        placeholder="Add the Consumer Key"
                        required
                      />
                    </Box>
                    <Box>
                      <Typography required>Consumer Secret</Typography>
                      <Input
                        autoFocus
                        className={classes.input}
                        disableUnderline
                        onChange={handleConsumerSecret}
                        placeholder="Add the Consumer Secret"
                        required
                      />
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
          { platform !== 'dokan' && !isMagento2xKeySubmitSuccess &&
          (
            <Button
              className={classes.buttonGetVender}
              onClick={linkPlatform}
              variant="contained"
            >
              Link
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

function LinkedSuccesMessage({
  ecommerceHosts,
  unlinkEcomercePlatform,
  setIsVisibleLinkedAccounts,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Box>
        {Object.keys(ecommerceHosts).map((element, index) => (
          <Alert
            key={index}
            severity="success"
          >
            <AlertTitle>
              <Typography className={classes.chipLabel}>
                Your Account has already linked
              </Typography>
            </AlertTitle>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              <Typography className={classes.chipLabel}>
                ECommerce Platform
              </Typography>
              <Chip
                className={classes.chip}
                color="primary"
                label={ecommerceHosts[element].platform}
                variant="outlined"
              />
              {ecommerceHosts[element].version && (
                <>
                  <Typography className={classes.chipLabel}>Version</Typography>
                  <Chip
                    className={classes.chip}
                    color="primary"
                    label={ecommerceHosts[element].version}
                    variant="outlined"
                  />
                </>
              )}
              <Typography className={classes.chipLabel}>Base URL</Typography>
              <Chip
                className={classes.chip}
                color="primary"
                label={ecommerceHosts[element].host}
                variant="outlined"
              />
              <Button
                className={classes.button}
                onClick={() => {
                  unlinkEcomercePlatform(element);
                }}
                variant="contained"
              >
                Unlink
              </Button>
            </Box>
          </Alert>
        ))}
      </Box>

      <Box>
        <Card className={classes.unlinkCard}>
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
            >
              <Box>
                <Typography
                  className={classes.chipLabel}
                  variant="subtitle2"
                />{' '}
              </Box>
              <Box>
                <Button
                  className={classes.button}
                  onClick={() => setIsVisibleLinkedAccounts(false)}
                  style={{ width: '100%' }}
                  variant="contained"
                >
                  Link another platform
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
