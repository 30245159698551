/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:76380fb2-b559-4fb7-b2b6-57ebfe7cc26b",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_xLeAu6L7n",
    "aws_user_pools_web_client_id": "tlbpm62glacsicdcge9r3aeos",
    "oauth": {
        "domain": "ogmo-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app2.ogmo.xyz/",
        "redirectSignOut": "https://app2.ogmo.xyz/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ogmo-prod",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "ogmo-content-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_content_delivery_bucket": "ogmo-hosting-prod",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d2gd8o4z9mwscs.cloudfront.net"
};


export default awsmobile;
