import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
  showWarningSnackbar
} from 'reducers/snackbarSlice';
import {
  Card,
  CardContent,
  FormControl,
  Typography,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress
} from '@material-ui/core';
import * as api from '../../services/api';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RefImageUploadDialog from './RefImageUploadDialog';
import UpdateRefImageName from './UpdateRefImageName';
import ImageAdditionalDetails from './ImageAdditionalDetails';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';


const useStyles = makeStyles(theme => ({
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 600
  },
  title2: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontWeight: 550
  },
  subText: {
    marginBottom: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: '#c7c9c9',
    textTransform: 'capitalize'
  },
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  dimentionBox: {
    display: 'flex',
  },
  boxColor: {
    background: '#f7f7f7',
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(2)
  },
  image: {
    minWidth: '100px',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  uploadBox: {
    minHeight: '400px',
    minWidth: '500px',
    border: 'dotted 2px'
  },
  displayImage: {
    position: 'relative'
  },
  cancelIcon: {
    position: 'absolute',
    right: '0px'
  },
  loader: {
    color: '#3183ae'
  },
  skipButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  skipButton: {
    width: '10%'
  }
}));

const ReferenceImages = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [dimention, setDimention] = useState({ 'width': '', 'height': '', 'breadth': '', 'comments': '' });
  const [uploadedImageList, setUploadedImageList] = useState({});
  const [openImageUploadDialog, setOpenImageUploadDialog] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [imageIdToDelete, setImageIdToDelete] = useState();
  const [apiLoader, setApiLoader] = useState(false);

  const { designId, designName, from } = props.location.state || {};
  if (!designId) history.push('/designs');

  useEffect(() => {
    loadUploadedImages();
  },[])

  const loadUploadedImages = async () => {
    if (apiLoader !== true) {
      setApiLoader(true);
      try {
        const response = await api.get(`designs/${designId}`)
        if (response.ref_image_dimentions) {
          let refImageBreadth = JSON.parse(response.ref_image_dimentions[0]).breadth;
          let refImageNotes = JSON.parse(response.ref_image_dimentions[1]).comments;
          let refImageHeight = JSON.parse(response.ref_image_dimentions[2]).height;
          let refImageWidth = JSON.parse(response.ref_image_dimentions[3]).width;
          setDimention({
            width: refImageWidth,
            height: refImageHeight,
            breadth: refImageBreadth,
            comments: refImageNotes
          });
        }
        if (response.ref_images) {
          let refImageList = JSON.parse(response.ref_images);
          setUploadedImageList(refImageList);
          setApiLoader(false);
        } else {
          setApiLoader(false);
        }
      } catch (error) {
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message);
        setApiLoader(false);
      }
    } else {
      dispatch(showWarningSnackbar('wait!!!'));
    }
  }

  const handleOpenImageUploadDialog = (event) => {
    event.preventDefault();
    setOpenImageUploadDialog(true);
  }

  const handleCloseImageUploadDialog = () => {
    loadUploadedImages();
    setOpenImageUploadDialog(false);
  }

  const deleteFromUploadedList = (imageId) => {
    setImageIdToDelete(imageId);
    handleOpenDeleteConfirmationDialog();
  }

  const confirmDelete = () => {
    if (apiLoader !== true) {
      let newImageList = { ...uploadedImageList };
      delete newImageList[imageIdToDelete];
      setApiLoader(true);
      api
        .put(`addReferenceImageDetailsToDB/${designId}`, { refImageDetails: [newImageList] })
        .then(data => { // eslint-disable-line no-unused-vars
          dispatch(showSuccessSnackbar('Successfully deleted the image'));
          handleCloseDeleteConfirmationDialog();
          setUploadedImageList(newImageList);
          setApiLoader(false);
        })
        .catch(error => {
          dispatch(showErrorSnackbar(error.message));
          console.log(error.message);
          setApiLoader(false);
        });
    } else {
      dispatch(showWarningSnackbar('wait!!!'));
    }
  }

  const handleOpenDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(true);
  }

  const handleCloseDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(false);
  }

  const handleConfirmRefImageNameChange = (imageName, imageId) => {
    if (apiLoader !== true) {
      let newImageList = { ...uploadedImageList };
      newImageList[imageId].name = imageName;
      setApiLoader(true);
      api
        .put(`addReferenceImageDetailsToDB/${designId}`, { refImageDetails: [newImageList] })
        .then(data => { // eslint-disable-line no-unused-vars
          dispatch(showSuccessSnackbar('Successfully updated the Image name'));
          setUploadedImageList(newImageList);
          setApiLoader(false);
        })
        .catch(error => {
          dispatch(showErrorSnackbar(error.message));
          console.log(error.message);
          setApiLoader(false);
        });
    } else {
      dispatch(showWarningSnackbar('wait!!!'));
    }
  };

  const saveDimentionDetailsToDB = (detailListToSave) => {
    if (apiLoader !== true) {
      setApiLoader(true);
      api
        .put(`addDimentionToTheDB/${designId}`, { 'refImageDimentionDetails': detailListToSave })
        .then(data => { // eslint-disable-line no-unused-vars
          dispatch(showSuccessSnackbar('Successfully added the details.'));
          setDimention({
            width: detailListToSave.width,
            height: detailListToSave.height,
            breadth: detailListToSave.breadth,
            comments: detailListToSave.comments
          });
          loadUploadedImages();
          setApiLoader(false);
          history.push('/designs')
        })
        .catch(error => {
          dispatch(showErrorSnackbar('Failed to added details. Please try again.'));
          console.log(error);
          setApiLoader(false);
        })
    } else {
      dispatch(showWarningSnackbar('wait!!!'));
    }
  }

  const handleSkip = () => {
    history.push('/designs')
  }

  const handleEditBreadcrumbClick = event => {
    event.preventDefault();
    history.push('/designs')
  };

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleEditBreadcrumbClick}
        >
                    Designs
        </Link>
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
                    Reference Images
        </Typography>
      </Breadcrumbs>
      { from === 'newDesign' && (
        <Box
          className={classes.skipButtonBox}
          ml={6}
          mt={6}
        >
          <Button
            className={classes.skipButton}
            color="primary"
            onClick={handleSkip}
            padding="10px"
            variant="outlined"
          >
                        Skip
          </Button>
        </Box>
      )}
      <Card className={classes.card}>
        <CardContent>
          <Box
            mb={4}
            ml={3}
            mt={2}
          >
            <Typography variant="h5">{designName}</Typography>
          </Box>
          <Box
            display="flex"
            mb={2}
            ml={6}
          >
            <FormControl>
              <Typography
                className={classes.title2}
                variant="h6"
              >
                                Reference Images
              </Typography>
              <Typography className={classes.subText}>
                                Add reference images required for 3D designer.
              </Typography>
            </FormControl>
            <FormControl>
              <Box
                display="flex"
                justifyContent="center"
                ml={1}
              >
                {apiLoader && <CircularProgress
                  className={classes.loader}
                  disableShrink
                />}
              </Box>
            </FormControl>
          </Box>
          <div className={classes.boxColor}>
            <Box
              display="flex"
              mb={2}
              ml={6}
            >
              <FormControl>
                <Typography
                  className={classes.card}
                >
                                    Add Images
                </Typography>
              </FormControl>
              <FormControl >
                <Box
                  display="flex"
                  justifyContent="center"
                  mb={2}
                  ml={1}
                  mt={4}
                >
                  <AddCircleIcon
                    color="primary"
                    fontSize="small"
                    onClick={(event) => handleOpenImageUploadDialog(event)}
                  />
                </Box>
              </FormControl>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              mb={2}
              ml={6}
            >
              {Object.keys(uploadedImageList).map((id) => (
                <FormControl
                  className={classes.image}
                  key={id}
                >
                  <Box
                    display="flex"
                    position="relative"
                  >
                    <img
                      className={classes.displayImage}
                      height="150px"
                      src={uploadedImageList[id].url}
                    />
                    <IconButton
                      className={classes.cancelIcon}
                      onClick={() => deleteFromUploadedList(id)}
                    >
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <UpdateRefImageName
                    handleConfirmRefImageNameChange={handleConfirmRefImageNameChange}
                    id={id}
                    referenceImageDefaultName={uploadedImageList[id].name}
                  />
                </FormControl>
              ))}
            </Box>
            <RefImageUploadDialog
              designId={designId}
              onClose={handleCloseImageUploadDialog}
              open={openImageUploadDialog}
              uploadedImageList={uploadedImageList}
            />
            <Dialog
              onClose={handleCloseDeleteConfirmationDialog}
              open={openDeleteConfirmationDialog}
            >
              <DialogTitle>Are you sure do you want to delete this image?</DialogTitle>
              <DialogActions>
                <Button
                  className={classes.button}
                  onClick={confirmDelete}
                >
                                    ok
                </Button>
                <Button
                  className={classes.button}
                  onClick={handleCloseDeleteConfirmationDialog}
                >
                                    Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <ImageAdditionalDetails
              dimention={dimention}
              saveDimentionDetailsToDB={saveDimentionDetailsToDB}
            />
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ReferenceImages;
