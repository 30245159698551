import React from 'react';
import { Grid, Button, Box, Checkbox } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import OnBlurOnEnterInput from '../ComponetInspector/OnBlurOnEnterInput';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { deleteComponent, OgmoConsts } from 'ogmo_core';
import ColorPickerInputBase from '../ColorPickers/ColorPickerInputBase';
import OnCommitedSliderInput from '../ComponetInspector/OnCommitedSliderInput';
import { updateLightComponentData } from 'ogmo_core';

const useStyles = makeStyles({
  light: {
    marginTop: 20,
    minHeight: 30,
    background: '#e0e0e0',
    paddingLeft: '10px'
  },
  head: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#575757'
  },
  title: {
    height: '15px',
    fontSize: '12px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#575757',
  },
  lightElements: {
    height: '200px',
    background: '#f7f4f4',
    padding: '15px'
  },
  Model: {
    alignItems: 'center',
    margin: '20px 0 10px 0'
  },
  input: {
    borderRadius: 2,
    position: 'relative',
    padding: '3px 8px',
    border: '1px solid #eeeeee',
    marginRight: '10px',
    width: '165px',
    '&:hover': { backgroundColor: '#d6d6d6', borderColor: 'white' }
  },
  deleteIcon: {
    marginLeft: 'auto'
  },
  lightInputField: {
    marginLeft: '20px',
    backgroundColor: '#ffffff',
    width: 50
  },
  directionField: {
    backgroundColor: '#ffffff',
  }
});

const BootstrapInput = withStyles(theme => ({ // eslint-disable-line no-unused-vars
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: '#c1c1c1',
    fontSize: 10,
    padding: '3px 8px',
    width: '150px',
    height: '14px'
  }
}))(InputBase);

export default function LightComponent({componentId}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lightComponent = useSelector(state => state.scene.componentByIds[componentId]);

  const resolutions = [];

  for (var key in OgmoConsts.Resolutions) {
    resolutions.push({
      value: OgmoConsts.Resolutions[key],
    });
  }

  const handleIntensityChange = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 1) {
      value = 1;
    }
    dispatch(updateLightComponentData({
      componentID: lightComponent.id,
      value: value === '' ? '' : Number(value),
      updateField: 'intensity'
    }
    ));
  };

  const handlChangeLightColor = event => {
    const Hex2RGB = hex => {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      const R = r / 255;
      const G = g / 255;
      const B = b / 255;
      return [R, G, B];
    };

    dispatch(updateLightComponentData({
      componentID: lightComponent.id,
      value: Hex2RGB(event.target.value),
      updateField: 'color'
    }
    ));
  };

  const handleDragStop = (event, newValue) => {
    dispatch(updateLightComponentData({
      componentID: lightComponent.id,
      value: Number(newValue),
      updateField: 'intensity'
    }
    ));
  };
  const handleClickCastShadow = event => {
    dispatch(updateLightComponentData({
      componentID: lightComponent.id,
      value: event.target.checked,
      updateField: 'castShadows'      }
    ));
  };

  const handlChangeShadowCameraDistance = value => {
    if (value < 0) {
      value = 0;
    }

    dispatch(updateLightComponentData({
      componentID: lightComponent.id,
      value:  value === '' ? '' : Number(value),
      updateField: 'shadowCameraDistance'
    }))
  }

  const handlChangeShadowMapResolution = event => {
    dispatch(updateLightComponentData({
      componentID: lightComponent.id,
      value: event.target.value,
      updateField: 'shadowMapResolution'
    }))
  }

  const deleteLight = () => {
    deleteComponent(lightComponent);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      wrap="nowrap"
    >
      <Box
        alignItems="center"
        className={classes.light}
        display="flex"
      >
        <Typography className={classes.head}>Directional Light</Typography>
        <Button
          className={classes.deleteIcon}
          onClick={deleteLight}
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </Button>
      </Box>
      <Box
        mb={3}
        ml={2}
        mt={3}
      >
        <Box
          alignItems="center"
          display="flex"
          flexWrap="nowrap"
          height="40px"
        >
          <Box width="30%">
            <Typography className={classes.title}>Color</Typography>
          </Box>
          <Box width="70%">
            <ColorPickerInputBase
              colorValue={lightComponent.data.color}
              handleChangeColorPicker={handlChangeLightColor}
            />
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexWrap="nowrap"
          height="40px"
        >
          <Box width="30%">
            <Typography className={classes.title}>Intensity</Typography>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            flexGrow={1}
            flexWrap="nowrap"
            width="50px"
          >
            <Box width={100}>
              <OnCommitedSliderInput
                handleDragStop={handleDragStop}
                max={1}
                min={0}
                sliderInputValue={lightComponent.data.intensity}
                step={0.1}
              />
            </Box>
            <Box className={classes.lightInputField}>
              <OnBlurOnEnterInput
                inputValue={lightComponent.data.intensity}
                max={1}
                min={0}
                onPress={handleIntensityChange}
                step={0.1}
              />
            </Box>
          </Box>
        </Box>

        <Box
          alignItems="center"
          display="flex"
          flexWrap="nowrap"
          height="40px"
        >
          <Box width="30%">
            <Typography className={classes.title}>Cast Shadow</Typography>
          </Box>
          <Box width="70%">
            <Checkbox
              checked={lightComponent.data.castShadows}
              className={classes.checkbox}
              onClick={handleClickCastShadow}
            />
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexWrap="nowrap"
          height="40px"
        >
          <Box width="50%">
            <Typography className={classes.title}>Shadow Camera Distance</Typography>
          </Box>
          <Box
            display="flex"
            flexGrow={1}
            width="50%"
          >
            <Box className={classes.lightInputField}>
              <OnBlurOnEnterInput
                inputValue={lightComponent.data.shadowCameraDistance ? lightComponent.data.shadowCameraDistance: 10}
                min={0}
                onPress={handlChangeShadowCameraDistance}
                step={1}
              />
            </Box>
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexWrap="nowrap"
          height="40px"
        >
          <Box width="50%">
            <Typography className={classes.title}>Shadow Map Resolution</Typography>
          </Box>
          <Box
            display="flex"
            flexGrow={1}
            width="50%"
          >
            <Grid
              className={classes.lightInputField}
              item
              xs={9}
            >
              <FormControl>
                <Select
                  input={<BootstrapInput />}
                  onChange={handlChangeShadowMapResolution}
                  value={lightComponent.data.shadowMapResolution ? lightComponent.data.shadowMapResolution : 1024}
                >
                  {resolutions.map((resolution, index) => {
                    return <MenuItem
                      key={index}
                      value={resolution.value}
                    >{resolution.value}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
