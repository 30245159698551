import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Input } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showErrorSnackbar, showSuccessSnackbar} from 'reducers/snackbarSlice';
import { setCognitoUsername } from 'reducers/dashboardSlice';
import { useDispatch } from 'react-redux';

const AWS = require('aws-sdk');
const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
  region: 'eu-central-1'
});

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '12px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 250,
    border: 'solid 1px #C4C4C4',
    marginTop: theme.spacing(2)
  },
  emailLabel: {
    marginTop: theme.spacing(2)
  },
  loader: {
    color: '#3183ae'
  }
}));

export default function PersonalInfoNameEmail() {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [userEmail, setUserEmail] = useState();
  const dispatch = useDispatch();

  const handleChangeUserName = async event => {
    setUsername(event.target.value);
  };

  useEffect(() => {
    Auth.currentSession()
      .then(user => {
        const payload = user.idToken.payload;
        setUsername(payload.name);
        setUserEmail(payload.email);
      })
      .catch(err => {
        dispatch(showErrorSnackbar(err));
        console.log(err)
      });
  }, []);

  return (
    <Formik
      initialValues={{
        name: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        Auth.currentSession()
          .then((session) => {
            const accessToken = session.getAccessToken().getJwtToken()
            var params = {
              AccessToken: accessToken,
              UserAttributes: [
                {
                  Name: 'name',
                  Value: username
                }
              ]
            }

            cognitoidentityserviceprovider.updateUserAttributes(params, ((err, data) => { // eslint-disable-line no-unused-vars
              if (err){
                console.log(err, err.stack)
                throw err.stack;
              }       

              let cognitoUser;
              Auth.currentAuthenticatedUser()
                .then( cognito_user => {
                  cognitoUser = cognito_user;
                  return Auth.currentSession()            
                })
                .then((currentSession) => {
                  cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => { // eslint-disable-line no-unused-vars
                    if(err){
                      console.log(err)
                      throw err;
                    }
                    dispatch(setCognitoUsername(username));
                    dispatch(showSuccessSnackbar('User Details Updated Successfully'));
                    setSubmitting(false);
                  })
                })
                .catch(err => {
                  console.log(err)
                  dispatch(showErrorSnackbar(err));
                  setSubmitting(false);
                })
            })
            )
          })
          .catch(err => {
            setSubmitting(false)
            console.log(err)
            dispatch(showErrorSnackbar(err));
          })
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          {isSubmitting && (
            <Box
              display="flex"
              justifyContent="center"
              pt={2}
            >
              <CircularProgress className={classes.loader}/>
            </Box>
          )}
          <Box
            display="flex"
            mb={4}
          >
            {!isSubmitting && (
              <>
                <Box mr={15}>
                  <Typography>Name</Typography>
                  <Input
                    className={classes.input}
                    disableUnderline
                    inputProps={{'data-test': 'personal-info-name'}}
                    onChange={handleChangeUserName}
                    required
                    value={username}
                  />
                </Box>
                <Box>
                  <Typography>Email</Typography>
                  <Typography className={classes.emailLabel}>
                    {userEmail}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Button
            className={classes.button}
            data-test="save-personal-info-name"
            disabled={isSubmitting}
            type="submit"
            variant="contained"
          >
            Save Changes
          </Button>
        </Form>
      )}
    </Formik>
  );
}
