import React from 'react';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { updateMeshComponentData } from 'ogmo_core';

const useStyles = makeStyles({
  shadowsTypography: {
    color: '#575757'
  },
  checkbox: {
    padding: 0,
    margin: '0 8px 8px 8px',
    borderRadius: 2,
    color: '#e1e1e1',
  },
  typography: {
    color: '#575757',
    fontSize: 10
  }
});

export default function Shadows({ meshComponent }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClickCastShadow = event => {

    dispatch(updateMeshComponentData({
      componentId: meshComponent.id,
      updateField: 'castShadows',
      value: event.target.checked
    }));
  };

  const handleClickReceiveShadow = event => {

    dispatch(updateMeshComponentData({
      componentId: meshComponent.id,
      updateField: 'receiveShadows',
      value: event.target.checked
    }));
  };

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      mt={2}
      width="25%"
    >
      <Typography
        className={classes.shadowsTypography}
        variant="body1"
      >
        Shadows
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        width="75%"
      >
        <Box display="flex">
          <Checkbox
            checked={meshComponent.data.castShadows}
            className={classes.checkbox}
            onClick={handleClickCastShadow}
          />
          <Typography className={classes.typography}> Cast </Typography>
        </Box>
        <Box display="flex">
          <Checkbox
            checked={meshComponent.data.receiveShadows}
            className={classes.checkbox}
            onClick={handleClickReceiveShadow}
          />
          <Typography className={classes.typography}>Recieve</Typography>
        </Box>
      </Box>
    </Box>
  );
}
