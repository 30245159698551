import React, { useState } from 'react';
import SaveButton from '../components/CreateDesign/SaveButton';
import DesignDetails from '../components/CreateDesign/DesignDetails';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography } from '@material-ui/core';
import * as api from 'services/api';
import {useSelector} from 'react-redux' ;
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useDispatch } from 'react-redux';
import { showErrorSnackbar, showSuccessSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  loader: {
    color: '#3183ae',
  },
}));

const CreateSingleDesign = () => {

  const dispatch = useDispatch();
  const [designName, setDesignName] = useState('');
  const [isCreateDesignInProgress, setIsCreateDesignInProgress] = useState(false)

  const classes = useStyles();
  const history = useHistory();
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);

  const handleEditBreadcrumbClick = event => {
    event.preventDefault();
    history.push('/designs')
  };

  const handleDesignNameChange = event => {
    setDesignName(event.target.value);
  };

  const newDesign = {
    name :designName,
    type: 'Single',
    organization_id:organizationId
  };

  const goToReferenceImageScreen = ( name, designId) => {
    history.push({
      pathname: '/referenceImages',
      state: { designId: designId, designName: name, from: 'newDesign' }
    });
  }

  const handleSubmit = event => {
    event.preventDefault();
    if (designName == ''){
      dispatch(showErrorSnackbar('Please add a design name'));
    }
    else{
      setIsCreateDesignInProgress(true);
      api.post('designs',newDesign)
        .then(data => {
          setIsCreateDesignInProgress(false);
          dispatch(showSuccessSnackbar(newDesign.name + ' successfully created'));
          goToReferenceImageScreen(data.data.name, data.data.id);
        })
        .catch(error => {
          setIsCreateDesignInProgress(false);
          dispatch(showErrorSnackbar(error.message));
          console.log('Faild to create design',error.message);
        });
    }
  };

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleEditBreadcrumbClick}
        >
          Designs
        </Link>
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
          Create Single Design
        </Typography>
      </Breadcrumbs>
      <Box p={8}>
        {isCreateDesignInProgress && (
          <Box
            display="flex"
            justifyContent="center"
          >
            <CircularProgress
              className={classes.loader}
              disableShrink
            />
          </Box>
        )}
        <SaveButton handleSubmit={handleSubmit} />
        <DesignDetails handleNameChange={handleDesignNameChange} />
      </Box>
    </Box>
  );
};
export default CreateSingleDesign;
