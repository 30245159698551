import { OgmoConsts } from "../constants/consts";
import { createUUID } from '../core/Utils.js'

export const ARComponent = (glbAsset = { id: "" },usdzAsset = { id: "" }) => ({
    id: createUUID(),
    needsUpdate :true,
    type:OgmoConsts.ComponentType.AR,
    data: {
      glbAssetId: glbAsset.id,
      usdzAssetId :usdzAsset.id
    }
  });