import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  openImageBox: false
};

const refImageSlice = createSlice({
  name: 'refImage',
  initialState,
  reducers:{
    setOpenImageBox(state, action) {
      state.openImageBox = action.payload;
    }
  }
});

export const {
  setOpenImageBox
} = refImageSlice.actions

export default refImageSlice.reducer