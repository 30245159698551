import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import SearchInput from 'components/Design/SearchInput';
import { Divider } from '@material-ui/core';
import DesignsTable from 'components/Design/DesignsTable';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    margin: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(5)
  },
  search: {
    width: 200
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: 5
  },
  button: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '8px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  }
}));

const DesignDialog = props => {
  const {
    handleClose,
    open,
    passSelectedDesignIdsToParent,
    designsAddedToTheGroup,
    rowsPerPage,
    setRowsPerPage,
    handlePageChange,
    isDesignsAreLoading
  } = props;

  const classes = useStyles();

  const designsByIdsInTheStore = useSelector(
    state => state.dashboard.designs
  );
  const designIds = Object.keys(designsByIdsInTheStore);
  const designsInTheStore = designIds.map(designId => {
    return designsByIdsInTheStore[designId];
  });
  const [temporarySelectedDesignsForTheGroup,setTemporarySelectedDesignsForTheGroup] = useState(designsAddedToTheGroup);

  const handlePassSelectedDesignIdsToParent = () => {
    handleClose();
    passSelectedDesignIdsToParent(temporarySelectedDesignsForTheGroup);
  };

  const handleCheckBoxClick = (event, design) => {
    const selectedIndex = temporarySelectedDesignsForTheGroup.findIndex(eachDesign => design.id === eachDesign.id);

    let newSelectedDesignIds = [];

    if (selectedIndex === -1) {
      newSelectedDesignIds = newSelectedDesignIds.concat(
        temporarySelectedDesignsForTheGroup,
        {...design}
      );
    } else if (selectedIndex === 0) {
      newSelectedDesignIds = newSelectedDesignIds.concat(
        temporarySelectedDesignsForTheGroup.slice(1)
      );
    } else if (
      selectedIndex ===
      temporarySelectedDesignsForTheGroup.length - 1
    ) {
      newSelectedDesignIds = newSelectedDesignIds.concat(
        temporarySelectedDesignsForTheGroup.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedDesignIds = newSelectedDesignIds.concat(
        temporarySelectedDesignsForTheGroup.slice(0, selectedIndex),
        temporarySelectedDesignsForTheGroup.slice(selectedIndex + 1)
      );
    }
    setTemporarySelectedDesignsForTheGroup(newSelectedDesignIds);
  };

  return (
    <Box width={800}>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <Box className={classes.dialogTitle}>
          <Typography
            className={classes.title}
            variant="h5"
          >
            Add Designs to the Group
          </Typography>
          <SearchInput
            placeholder="Search by name"
            rowsPerPage={rowsPerPage}
          />
        </Box>
        <Divider />
        <DialogContent>
          <Box
            p={0}
            width={750}
          >
            <DesignsTable
              designs={designsInTheStore}
              handleCheckBoxClick={handleCheckBoxClick}
              handlePageChange={handlePageChange}
              isDesignsAreLoading={isDesignsAreLoading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              showCheckBox
              showDate
              showPreviewIcon
              showType
              showactions={false}
              temporarySelectedDesignsForTheGroup={
                temporarySelectedDesignsForTheGroup
              }
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            autoFocus
            className={classes.button}  
            onClick={handlePassSelectedDesignIdsToParent}
            variant="contained"
          >
            Add Designs
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DesignDialog;
