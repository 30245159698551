import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Input,
  IconButton,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import * as api from '../../services/api';
import * as img from '../../services/image-resize';
import tepmImg from '../../images/organization.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { setLogo as setLogoAction, setOrgName as setOrgNameAction, setOrgBlockedByAdmin } from '../../reducers/organizationAndUserSlice';
import { showErrorSnackbar, showSuccessSnackbar} from 'reducers/snackbarSlice';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold'
  },
  margin: {
    marginBottom: theme.spacing(3)
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 250,
    border: 'solid 1px #C4C4C4',
    marginTop: theme.spacing(2)
  },
  image: {
    height: 90,
    width: 90,
    border: 'solid 1px #C4C4C4',
    borderRadius: '4px'
  },
  uploadButton: {
    backgroundColor: '#ececec',
    textTransform: 'capitalize',
    marginTop: 15
  },
  imageUploadFile: {
    display: 'none'
  },
  deleteIcon: {
    marginTop: 10
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '12px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  regionLabel: {
    marginLeft: theme.spacing(2),
    fontWeight: 'bold'
  },
  avatar: {
    borderRadius: 0,
    height: 88,
    width: 88
  }
}));

export default function OrganizationSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [orgStatistics, setOrgStatistics] = useState(0);
  const [isOrgBlocked, setOrgBlocked] = useState();
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [blockConfirm, setBlockConfirm] = useState('');
  const userOrganizationId = useSelector(
    state => state.dashboard.userOrganizationId
  );
  
  const [isSubmitting, setIsSubmitting] = useState(false)

  const userOrganizationName = useSelector(
    state => state.organizationAndUser.orgName
  );
  const userOrganizationLogo = useSelector(
    state => state.organizationAndUser.logo
  );
  const orgDesignLimit = useSelector(
    state => state.organizationAndUser.designLimit
  );
  const s3DetailsOfTheOrganizaion = useSelector(state => state.organizationAndUser.s3Details)
  const [orgName, setOrgName] = useState(userOrganizationName);
  const [logo, setLogo] = useState(userOrganizationLogo);
  const handleChangeOrgName = event => {
    setOrgName(event.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    api.post('analytics/report',{})
      .then(res => {
        const orgIndex = res.findIndex(org => org.organization_id == `ORG${userOrganizationId.replace(/-/gi, '_')}`);
        if (orgIndex>=0) {
          setOrgStatistics(res.find(org => org.organization_id == `ORG${userOrganizationId.replace(/-/gi, '_')}`).count);
        }
        else {
          setOrgStatistics(0);
        }
      })
        
      .catch(error => {
        dispatch(showErrorSnackbar('Organizations were failed to load'));
        console.log(error);
      });
  }, [])

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`organization/${userOrganizationId}`)
      .then(data => {
        setOrgName(data.name);
        setLogo(data.logo);
        setIsLoading(false);
        setOrgBlocked(data.org_blocked_by_superadmin || data.org_blocked_by_admin)
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const handleImageUpload = event => {
    const file = event.target.files[0];
    uploadImage(file);
  };

  const uploadImage = file => {
    img
      .imgResize(file, 'base64')
      .then(resizedImgResponse => {
        setLogo(resizedImgResponse);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
      });
  };

  const deleteUploadedImg = () => {
    setLogo('');
  };

  const handleBlockOrg = () => {
    if (orgName == blockConfirm) {
      setIsLoading(true);
      api.put(`organization/block?organization_id=${userOrganizationId}&isToBlock=${!isOrgBlocked}`)
        .then(() => {
          setOrgBlocked(!isOrgBlocked);
          dispatch(setOrgBlockedByAdmin(!isOrgBlocked));
          setIsLoading(false);
        })
        .catch(error => {
          dispatch(showErrorSnackbar(error.message));
          console.log(error);
          setIsLoading(false);
        });
      setBlockDialogOpen(false);
    }
    else dispatch(showErrorSnackbar('Invalid Organization Name'));
  };

  // eslint-disable-next-line
  const handleClickOpen = (design) => {
    setBlockDialogOpen(true);
  };

  const handleClose = () => {
    setBlockDialogOpen(false);
  };
  
  const handleSubmit = () => {
    setIsSubmitting(true)
    return api
      .put('organization', {
        name: orgName,
        logo: logo
      })
      .then(res => {
        setIsSubmitting(false);
        dispatch(setLogoAction(res.data.logo));
        dispatch(setOrgNameAction(res.data.name));
        dispatch(showSuccessSnackbar(res.message));
      })
      .catch(error => {
        setIsSubmitting(false);
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message);
      });
  }

  return (
    <div>
      <Card className={classes.margin}>
        <CardContent>
          <Box mx={4}>
            <Typography
              className={classes.title}
              variant="subtitle2"
            >
                  Organization Info
            </Typography>
            {(isSubmitting || isLoading) && (
              <Box
                display="flex"
                justifyContent="center"
                pt={2}
              >
                <CircularProgress className={classes.loader}/>
              </Box>
            )}
            <>
              {!isSubmitting && (
                <>
                  <Box
                    display="flex"
                    mb={2}
                  >
                    <Box mr={13}>
                      <Typography required>
                              Organizational Name
                      </Typography>
                      <Input
                        className={classes.input}
                        disableUnderline
                        inputProps={{'data-test': 'organization-name'}}
                        onChange={handleChangeOrgName}
                        required
                        value={orgName}
                      />
                    </Box>
                    <Box
                      alignItems="center"
                      className={classes.image}
                      display="flex"
                      justifyContent="center"
                      mr={3}
                    >
                      <Avatar
                        className={classes.avatar}
                        src={logo ? logo : tepmImg}
                      />
                    </Box>
                    <Box
                      mr={11}
                      mt={4}
                    >
                      <Input
                        accept="image/*"
                        className={classes.imageUploadFile}
                        id="contained-button-file"
                        inputProps = {{'data-test':'organization-profile-picture'}}
                        onChange={handleImageUpload}
                        type="file" 
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          className={classes.uploadButton}
                          component="span"
                        >
                                Upload Picture
                        </Button>
                      </label>

                      <IconButton
                        className={classes.deleteIcon}
                        onClick={deleteUploadedImg}
                      >
                        <DeleteIcon
                          color="action"
                          fontSize="small"
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    mb={3}
                    mt={5}
                  >
                    <Typography>Data Location :</Typography>
                    <Typography className={classes.regionLabel}>
                      {s3DetailsOfTheOrganizaion.region}
                    </Typography>
                  </Box>
                </>
              )}
              <Button
                className={classes.button}
                data-test="save-organization-info"
                disabled={isSubmitting}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
              >
                      Save Changes
              </Button>
            </>
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.margin}>
        <CardContent>
          <Box
            ml={4}
            mt={2}
          >
            <Typography
              className={classes.title}
              variant="subtitle2"
            >Amazon S3 Bucket Details</Typography>
          </Box>
          <Box
            mb={2}
            ml={4}
          >
            <Typography>Organization Bucket Name : {s3DetailsOfTheOrganizaion.bucket}</Typography>
          </Box>
          <Box ml={4}>
            <Typography>Organization Bucket URL : {s3DetailsOfTheOrganizaion.cloudfront_url}</Typography>
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.margin}>
        <CardContent>
          <Box
            ml={4}
            mt={2}
          >
            <Typography
              className={classes.title}
              variant="subtitle2"
            >QUOTA</Typography>
          </Box>
          <Box
            mb={2}
            ml={4}
          >
            <Typography>Organization Design view Limit: {orgDesignLimit}</Typography>
          </Box>
          <Box ml={4}>
            <Typography>Current view count : {orgStatistics}</Typography>
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.margin}>
        <CardContent>
          <Box
            ml={4}
            mt={2}
          >
            <Typography
              className={classes.title}
              variant="subtitle2"
            >{isOrgBlocked ? 'UNBLOCK' : 'BLOCK'} THE ORGANIZATION</Typography>
          </Box>
          {!isLoading && <Box
            mb={2}
            ml={4}
          >
            <Typography>Your Organization is currently: {isOrgBlocked ? 'BLOCKED' : 'UNBLOCKED'}</Typography>
          </Box>
          }
          {!isLoading && <Box ml={4}>
            <Button
              className={classes.button}
              data-test="block-button"
              onClick={handleClickOpen}
            >
              {isOrgBlocked ? 'UNBLOCK' : 'BLOCK'}
            </Button>
            <Dialog
              aria-labelledby="form-dialog-title"
              onClose={handleClose}
              open={blockDialogOpen}
            >
              <DialogTitle id="form-dialog-title">{isOrgBlocked ? 'UnBlock' : 'Block'}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {`To ${isOrgBlocked ? 'UnBlock' : 'Block'} the Organization, please enter the Organization name`}
                </DialogContentText>
                <TextField
                  autoFocus
                  data-test="dialog-box-confirm-org-name"
                  fullWidth
                  id="name"
                  label="Organization Name"
                  onChange={(e) => setBlockConfirm(e.target.value)}
                  type="text"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  data-test="dialog-box-confirm-blocking"
                  onClick={handleBlockOrg}>
                  {isOrgBlocked ? 'UnBlock' : 'Block'}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          }
        </CardContent>
      </Card>
    </div>
  );
}
