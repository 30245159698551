import React from 'react';
import { Box, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionSection from '../ExpansionSection';
import OnBlurOnEnterInput from '../ComponetInspector/OnBlurOnEnterInput';
import OnCommitedSliderInput from '../ComponetInspector/OnCommitedSliderInput';
import { useSelector, useDispatch } from 'react-redux';
import { updateMaterialAssetData } from 'ogmo_core';

const useStyles = makeStyles({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '0 0 3px 10px',
    height: '25px',
    width: '50px',
    marginLeft: '20px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function MaterialAssetOtherProperties() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedAsset = useSelector(
    state => state.scene.assetByIds[state.scene.selectedAssetId]
  );

  const handleDisableSliderChange = event => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'clearCoat',
      value: event.target.checked ? 1 : 0
    }
    ));
  };

  const handleDisableTransparentSliderChange = event => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'transparent',
      value: event.target.checked ? true : false
    }
    ));
  };

  const handleDragStopOpacityValue = (event, opacity) => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'opacity',
      value: Number(opacity)
    }
    ));
  };

  const handleDragStopRoughnessValue = (event, newRoughnessValue) => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'clearCoatRoughness',
      value: Number(newRoughnessValue)
    }
    ));
  };

  const handleDragStopReflectionValue = (event, newReflectionValue) => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'reflectance',
      value: Number(newReflectionValue)
    }
    ));
  };

  const handleOpacityInputChange = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 1) {
      value = 1;
    }
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'opacity',
      value: value === '' ? '' : Number(value)
    }
    ));
  };

  const handleRoughnessInputChange = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 1) {
      value = 1;
    }
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'clearCoatRoughness',
      value: value === '' ? '' : Number(value)
    }
    ));
  };

  const handleReflectionInputChange = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 1) {
      value = 1;
    }
    dispatch(updateMaterialAssetData({
      assetID: selectedAsset.id,
      updateField: 'reflectance',
      value: value === '' ? '' : Number(value)
    }
    ));
  };

  return (
    <ExpansionSection sectionName="Other Properties">
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        pt={2}
        width="100%"
      >
        <Box display="flex">
          <Box width="30%">
            <Typography variant="caption">Clear Coat</Typography>
          </Box>
          <Box width="70%">
            <Switch
              checked={Boolean(selectedAsset.data.clearCoat)}
              color="default"
              onChange={handleDisableSliderChange}
              size="small"
            />
          </Box>
        </Box>
        {selectedAsset.data.clearCoat === 1 && (
          <Box
            display="flex"
            mt={3}
          >
            <Box width="30%">
              <Typography variant="caption">Clear Coat Roughness</Typography>
            </Box>
            <Box
              ml={1}
              width="40%"
            >
              <OnCommitedSliderInput
                handleDragStop={handleDragStopRoughnessValue}
                max={1}
                min={0}
                sliderInputValue={selectedAsset.data.clearCoatRoughness}
                step={0.1}
              />
            </Box>
            <Box
              className={classes.input}
              width="30%"
            >
              <OnBlurOnEnterInput
                inputValue={selectedAsset.data.clearCoatRoughness}
                max={1}
                min={0}
                onPress={handleRoughnessInputChange}
                step={0.05}
              />
            </Box>
          </Box>
        )}
        <Box
          display="flex"
          mt={3}
        >
          <Box width="30%">
            <Typography variant="caption">Reflection</Typography>
          </Box>
          <Box
            ml={1}
            width="40%"
          >
            <OnCommitedSliderInput
              handleDragStop={handleDragStopReflectionValue}
              max={1}
              min={0}
              sliderInputValue={selectedAsset.data.reflectance}
              step={0.1}
            />
          </Box>
          <Box
            className={classes.input}
            width="30%"
          >
            <OnBlurOnEnterInput
              inputValue={selectedAsset.data.reflectance}
              max={1}
              min={0}
              onPress={handleReflectionInputChange}
              step={0.05}
            />
          </Box>
        </Box>
        {/*this check is required to support old materials without transparetn property*/
          selectedAsset.data.hasOwnProperty('transparent') && ( // eslint-disable-line no-prototype-builtins
            <Box
              display="flex"
              mt={3}
            >
              <Box width="30%">
                <Typography variant="caption">Transparent</Typography>
              </Box>
              <Box width="70%">
                <Switch
                  checked={Boolean(selectedAsset.data.transparent)}
                  color="default"
                  onChange={handleDisableTransparentSliderChange}
                  size="small"
                />
              </Box>
            </Box>
          )}
        {selectedAsset.data.transparent && (
          <Box
            display="flex"
            mt={3}
          >
            <Box width="30%">
              <Typography variant="caption">Opacity</Typography>
            </Box>
            <Box
              ml={1}
              width="40%"
            >
              <OnCommitedSliderInput
                handleDragStop={handleDragStopOpacityValue}
                max={1}
                min={0}
                sliderInputValue={selectedAsset.data.opacity}
                step={0.1}
              />
            </Box>
            <Box
              className={classes.input}
              width="30%"
            >
              <OnBlurOnEnterInput
                inputValue={selectedAsset.data.opacity}
                max={1}
                min={0}
                onPress={handleOpacityInputChange}
                step={0.05}
              />
            </Box>
          </Box>
        )}
      </Box>
    </ExpansionSection>
  );
}
