import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorSnackbar} from 'reducers/snackbarSlice';
import { setOpenImageBox } from 'reducers/refImageSlice';
import * as api from '../services/api';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  initializeScene,
  uninitializeScene
} from 'ogmo_core';
import { 
  Typography,
  FormControl,
  Box,
  TextField,
  Tooltip
} from '@material-ui/core';
import OverlayOpacity from './OverlayOpacity';

const useStyles = makeStyles(theme => ({
  image:{
    position: 'relative'
  },
  imageBox:{
    position: 'absolute',
    right:'0px',
    maxHeight: '500px',
    minWidth: '340px',
    background: '#c7c9c9',
    padding: '10px',
    borderRadius: '10px',
    overflowY: 'auto',
    zIndex: 100
  },
  imagePicker: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  thumbnail: {
    overflowY: 'hidden'
  },
  cancelIcon: {
    position: 'absolute',
    right: '0px',
    background: '#ffffff'
  },
  hover: {
    cursor: 'pointer' 
  },
  dimentions: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  imageBackground: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '340px',
    maxHeight: '170px',
    minHeight: '30px',
    background: '#ffffff',
    padding: '10px',
    overflowY: 'scroll',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  textarea: {
    font: 8,
    maxWidth: '320px',
    textAlign: 'justify',
    marginTop: theme.spacing(1),
    wordWrap: 'break-word',
    display: 'inline-block'
  },
  whiteScreen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '340px',
    maxHeight: '200px',
    minHeight: '80px',
    background: '#ffffff',
    padding: '20px',
    borderRadius: '5px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  dimensionText: {
    maxWidth: '150px',
    wordWrap: 'break-word',
    display: 'flex',
    flex: '1',
    flexWrap: 'wrap'
  },
  additionalDetails: {
    maxHeight: '270px',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1), 
    marginBottom: theme.spacing(1)
  }
}));

export default function EditorCanvas(props) {

  const ref = React.useRef();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [height, setHeight] = useState(0);
  const [refImages, setRefImages] = useState({});
  const [selectedImage, setSelectedImage] = useState('');
  const [openOverlay, setOpenOverlay] = useState(false);
  const [opacityOfOverlay, setOpacityOfOverlay] = useState(1);
  const [dimention, setDimention] = useState({ 'width': '', 'height':'', 'breadth': '', 'comments': '' });
  const [showDimentions, setShowDimensions] = useState(false);
  const openImageBox = useSelector(state => state.refImage.openImageBox);
    
  useEffect(() => {
    const canvas = ref.current;
    initializeScene(canvas, null, () => {
      console.log('Scene Initialized');
    }, false);

    return () => {
      uninitializeScene();
    };
  }, []);

  //debugger;
  //const xrSupported = await isARAvailable();

  useEffect(() => {
    api
      .get(`designs/${props.designId}`)
      .then(data => {
        if(data.ref_images){
          let refImageList = JSON.parse(data.ref_images);
          setRefImages(refImageList);
        }
        if(data.ref_image_dimentions){
          let refImageBreadth = JSON.parse(data.ref_image_dimentions[0]).breadth;
          let refImageNotes = JSON.parse(data.ref_image_dimentions[1]).comments;
          let refImageHeight = JSON.parse(data.ref_image_dimentions[2]).height;
          let refImageWidth = JSON.parse(data.ref_image_dimentions[3]).width;
          setDimention({ 
            width: refImageWidth, 
            height: refImageHeight, 
            breadth: refImageBreadth, 
            comments: refImageNotes
          });
        }
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(ref.current.offsetHeight)
    }
    handleResize();
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref]);

  useEffect(() => {
    const showDimensions = () => {
      if (dimention.width !== '') {
        setShowDimensions(true)
      } else if (dimention.height !== '') {
        setShowDimensions(true)
      } else if (dimention.breadth !== '') {
        setShowDimensions(true)
      } else if (dimention.comments !== '') {
        setShowDimensions(true)
      }
    }
    showDimensions();
  }, [dimention]);

  const closeImageBox = () => {
    dispatch(setOpenImageBox(false));
    setShowDimensions(false);
  }

  const handleChange = e => {
    const file = e.target.src;
    setSelectedImage(file);
    setOpenOverlay(true);
  }

  const removeOverlay = () => {
    if(openOverlay){
      setOpenOverlay(false);
    }
  }
    
  return (
    <>
      {/* <Button onClick={startAR} style={buttonStyle}> Enter AR </Button> */}
      <Box
        className={classes.thumbnail}
        display="flex"
        flexWrap="wrap"
        height="100%"
        justifyContent="center"
        position="relative"
      >
        <canvas ref={ref} />
        {openImageBox &&
                    <div className={classes.imageBox}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography>
                          <b>Reference Images</b>
                        </Typography>
                        <CancelIcon
                          className={classes.hover}
                          fontSize="small"
                          onClick={closeImageBox}
                        />
                      </Box>
                      { Object.keys(refImages).length === 0 && !showDimentions && (
                        <Box>
                          <Box
                            className={classes.whiteScreen}
                          >
                            <Typography>No Reference images to display</Typography>
                          </Box>
                          <Box
                            ml={2}
                            mt={4}
                          >
                            <Typography>To add reference images</Typography>
                          </Box>
                          <Box
                            mb={1}
                            ml={2}
                            mr={2}
                            mt={2}
                          >
                            <Typography>1. Go to <b>Designs</b> in web app.</Typography>
                            <Typography>2. Click menu icon of the preferred design.</Typography>
                            <Typography>3. Select <b>Ref images.</b></Typography>
                          </Box>
                        </Box>
                      )}
                      {(Object.keys(refImages).length > 0 || showDimentions) && (
                        <Box
                          className={classes.imageBackground}
                        >
                          {Object.keys(refImages).map((id) => (
                            <FormControl
                              className={classes.imagePicker}
                              key={id}
                            >
                              <Box
                                display="flex"
                                position="relative"
                              >
                                <img
                                  className={classes.hover}
                                  height="70px"
                                  onClick={handleChange}
                                  src={refImages[id].url}
                                />
                              </Box>
                              <Tooltip
                                placement="bottom-start"
                                title={refImages[id].name}
                              >
                                <TextField
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                  fontSize={8}
                                  value={refImages[id].name}
                                />
                              </Tooltip>
                            </FormControl>
                          ))}
                        </Box>
                      )}
                      <Box className={classes.additionalDetails}>
                        <Box
                          display="flex"
                          flex="1"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                        >
                          {dimention.width && (<Typography className={classes.dimensionText}><b>Width:&nbsp;</b>{dimention.width}cm</Typography>)}
                          {dimention.height && (<Typography className={classes.dimensionText}><b>Height:&nbsp;</b>{dimention.height}cm</Typography>)}
                        </Box>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                        >
                          {dimention.breadth && (<Typography className={classes.dimensionText}><b>Breadth:&nbsp;</b>{dimention.breadth}cm</Typography>)}
                        </Box>
                        <Typography className={classes.textarea}>{dimention.comments}</Typography>
                        {Object.keys(refImages).length > 0 && (
                          <OverlayOpacity
                            opacityValue={opacityOfOverlay}
                            setOpacityOfOverlay={setOpacityOfOverlay}
                          />
                        )}
                      </Box>
                    </div>
        }
        {openOverlay &&
                    <Box
                      display="flex"
                      height="100%"
                      position="absolute"
                    >
                      <img
                        className={classes.image}
                        height={height}
                        src={selectedImage}
                        style={{ opacity: opacityOfOverlay }}
                      />
                      <CancelIcon
                        className={classes.cancelIcon}
                        fontSize="small"
                        onClick={removeOverlay}
                      />
                    </Box>
        }
      </Box>
    </>
  );
}
