import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import OnBlurOnEnterInput from '../OnBlurOnEnterInput.js';
import { setEntityName } from 'ogmo_core';

const useStyles = makeStyles({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '6px 0 3px 10px',
    height: '25px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  },
  label: {
    fontWeight: 550,
    color: '#575757'
  }
});

export default function Name() {
  const classes = useStyles();
  const selectedEntitiyId = useSelector(state => state.scene.selectedEntitiyId);
  const entity = useSelector(
    state => state.scene.entityByIds[selectedEntitiyId]
  );

  const handleNameChange = value => {
    setEntityName(entity.id, value);
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
    >
      <Box width="25%">
        <Typography
          className={classes.label}
          variant="body1"
        >
          Name
        </Typography>
      </Box>
      <Box
        className={classes.input}
        width="75%"
      >
        <OnBlurOnEnterInput
          inputValue={entity.name}
          inputValueFontSize="12px"
          onPress={handleNameChange}
          type="text"
        />
      </Box>
    </Box>
  );
}
