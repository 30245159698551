import React from "react";
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

const CreateOrgConfirmationDialog = ({
  checked,
  handleClose,
  open,
  createOrganization,
  orgCreationInProgress,
}) => {
  let dialogTitleAndContent;
  if (checked) {
    dialogTitleAndContent = (
      <DialogContent>
        <Typography>
          <b>Note : </b> Your organization's assets, (i.e images, 3D objects)
          will be stored and served from the S3 bucket you mentioned. OGMO will
          not keep any backups of your assets.
        </Typography>
      </DialogContent>
    );
  } else {
    dialogTitleAndContent = (
      <DialogContent>
        <Typography>
          <b>Note : </b> Your organization's assets, (i.e images, 3D objects)
          will be stored and served from the OGMO's data storage infrustructure.
        </Typography>
      </DialogContent>
    );
  }
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      disableBackdropClick={orgCreationInProgress}
      disableEscapeKeyDown={orgCreationInProgress}
    >
      <DialogTitle>
        <b>Please give consent to create the organization.</b>
      </DialogTitle>
      {dialogTitleAndContent}
      <DialogActions>
        <Box display="flex" mt={2}>
          <Button
            color="primary"
            onClick={handleClose}
            variant="outlined"
            disabled={orgCreationInProgress}
          >
            Cancel
          </Button>
          <Button
            style={{marginLeft:"16px"}}
            color="primary"
            variant={orgCreationInProgress ? "text" : "contained"}
            onClick={createOrganization}
            disabled={orgCreationInProgress}
          >
            {orgCreationInProgress ? (
              <CircularProgress size={14} />
            ) : (
              "Create organization"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrgConfirmationDialog;
