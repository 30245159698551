import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Box,
  Typography,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem
} from '@material-ui/core';
import SearchInput from './SearchInput';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Link } from 'react-router-dom';
import {setOpenCreateGroupDesignDialog} from 'reducers/dashboardSlice';
import { useDispatch } from 'react-redux';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  button: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '6px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  designCount: {
    marginLeft: 25,
    color: '#576077'
  }
}));

export default function DesignsToolBar({ rowsPerPage, isDesignsAreLoading }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = React.useRef(null);
  const count = useSelector((state) => state.dashboard.designResponseTotal);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };
  const handleOpenDialog = () => {
    dispatch(setOpenCreateGroupDesignDialog(true));
    setOpenMenu((prevOpen) => !prevOpen);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={7}>
      <Box
        alignItems="flex-end"
        display="flex"
        flexDirection="column">
        <ButtonGroup
          aria-label="split button"
          ref={anchorRef}
          variant="contained"
        >
          <Button
            className={classes.button}
            component={Link}
            to="/createSingleDesign"
          >
            Add design
          </Button>
          <Button
            className={classes.button}
            data-test="arrow-drop-down-icon"
            onClick={handleToggle}
            size="small"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          anchorEl={anchorRef.current}
          disablePortal
          open={openMenu}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper id="menu-list-grow">
                <MenuList>
                  <MenuItem
                    component={Link}
                    to="/createSingleDesign">
                    Single Design
                  </MenuItem>
                  <MenuItem onClick={handleOpenDialog}>Group Design</MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      <Box
        alignItems="center"
        display="flex">
        <SearchInput
          placeholder="Search by name"
          rowsPerPage={rowsPerPage} />
        {!isDesignsAreLoading && (
          <Typography
            className={classes.designCount}
            variant="subtitle2">
            {count} designs available
          </Typography>
        )}
      </Box>
    </Box>
  );
}

DesignsToolBar.propTypes = {
  className: PropTypes.string
};
