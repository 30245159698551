import React, { useState, useEffect } from 'react';
import {
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Avatar,
  TableCell,
  TableRow,
  Box,
  IconButton,
  DialogContent,
  Dialog,
  Input
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TvIcon from '@material-ui/icons/Tv';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import LinkIcon from '@material-ui/icons/Link';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import * as api from '../../services/api';
import Popover from '@material-ui/core/Popover';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import designImg from '../../images/designs-icon.png';
import { setDesignPublishedState } from '../../reducers/dashboardSlice';
import { Auth } from 'aws-amplify';
import { showErrorSnackbar,showSuccessSnackbar} from 'reducers/snackbarSlice';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

const useStyles = makeStyles(theme => ({
  inner: {
    minWidth: 1050
  },
  avatar: {
    height: '44px',
    width: '44px',
    borderRadius: 0
  },
  image: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    height: '45px',
    width: '45px',
    borderRadius: '50%'
  },
  designType: {
    height: 23,
    width: 70,
    borderRadius: 4,
    color: 'white',
    backgroundColor: designUseStyleValue =>
      designUseStyleValue.designType === 'Single' ? '#8ED49B' : '#68BCE8'
  },
  menuIcon: {
    marginRight: theme.spacing(1),
    color: '#C4C4C4'
  },
  designPublish: {
    fontWeight: designUseStyleValue =>
      designUseStyleValue.designPublished ? 'bold' : 'normal'
  },
  designBlockStatus: {
    fontWeight: designUseStyleValue =>
      designUseStyleValue.designBlockedBySuperAdmin ? 'bold' : 'normal',
    height: 23,
    width: 100,
    borderRadius: 4,
    color: 'white',
    backgroundColor: designUseStyleValue =>
      (designUseStyleValue.designSuperAdminBlockStatus||designUseStyleValue.designAdminBlockStatus)? '#ff4d4d' : '#8ED49B'
  },
  designName: {
    width: '30%'
  },
  okButton: {
    backgroundColor: '#3e8eb8',
    color: '#ffffff',
    '&:hover': { backgroundColor: '#24739C' }
  },
  cancelButton: {
    color: '#3e8eb8',
  },
  loader: {
    color: '#3183ae'
  },
  linkTitle: {
    fontWeight: 600,
    fontSize: 13,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  popupText: {
    fontSize: 12,
    marginLeft: theme.spacing(1),
    width: 250,
    backgroundColor: '#d9ebf9'
  }
}));

const CheckBoxCell = ({
  handleCheckBoxClick,
  temporarySelectedDesignsForTheGroup,
  index,
  design
}) => {

  const isSelected = selectedDesign => {
    const selectedDesignIDs = temporarySelectedDesignsForTheGroup.map(
      design => design.id
    );
    return selectedDesignIDs.some(designID => designID === selectedDesign.id);
  };

  const isItemSelected = isSelected(design);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <TableCell padding="checkbox">
      <Checkbox
        checked={isItemSelected}
        inputProps={{ 'aria-labelledby': labelId }}
        onClick={event => handleCheckBoxClick(event, design)}
      />
    </TableCell>
  );
};

export default function DesignsTableRow({
  design,
  showactions,
  index,
  handleCheckBoxClick,
  temporarySelectedDesignsForTheGroup,
  showCheckBox,
  showPreviewIcon,
  showType,
  showDate,
  loadDesigns
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const updatedOn = new Date(design.updated_at);
  const updatedDate = updatedOn.toLocaleDateString();
  const updatedTime = updatedOn.toLocaleTimeString();

  const date = new Date(design.created_at);
  const createdDate = date.toLocaleDateString();
  const createdTime = date.toLocaleTimeString();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openGetLinkPopover, setOpenGetLinkPopover] = useState(false);


  const [publishDialog, setPublishDialog] = useState({
    openPublishLoadingDialog: false,
    dialogMessage: ''
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);

  const designUseStyleValue = { designType: design.type, designPublished: design.published, designSuperAdminBlockStatus: (design.design_blocked_by_superadmin || design.org_blocked_by_superadmin), designAdminBlockStatus: design.org_blocked_by_admin, };
  const classes = useStyles(designUseStyleValue);

  useEffect(() => {
    Auth.currentSession()
      .then(data => {
        const payload = data.idToken.payload;
        const cognito_groups = payload['cognito:groups'];
        setIsAdmin(cognito_groups.includes('Admin'));
      })
      .catch(err => {
        dispatch(showErrorSnackbar(err));
        console.log(err)
      })
  },[]);

  const handleMenuIconClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(true);
    handleMenuClose();
  };

  const handleCloseDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(false);
  };

  const handlePublish = () => {
    handleMenuClose();
    const newPublishedStatus = !design.published;
    setPublishDialog({
      openPublishLoadingDialog: true,
      dialogMessage: newPublishedStatus ? 'Publishing' : 'Unpublishing'
    });

    api.put(`designs/${design.id}`, { published: newPublishedStatus })
      .then(res => { // eslint-disable-line no-unused-vars
        dispatch(setDesignPublishedState({id: design.id, publish: newPublishedStatus}));
        setPublishDialog({
          openPublishLoadingDialog: false,
          dialogMessage: ''
        });
        dispatch(showSuccessSnackbar(newPublishedStatus ? 'Successfully published' : 'Successfully unpublished'));
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
        setPublishDialog({
          openPublishLoadingDialog: false,
          dialogMessage: ''
        });
      });
  };


  const handleEdit = design => {
    if (design.type === 'Single') {
      history.push({
        pathname: '/editDesign',
        state: { design: design }
      });
    }
    else {
      history.push({
        pathname: '/createGroupDesign',
        state: { designId: design.id }
      });
    }
  };

  const handleEditor = design => {
    history.push({
      pathname: '/editor',
      state: { designId: design.id, designName: design.name, IsdesignPublished:design.published }
    });
  };

  const addReferenceImages = design => {
    history.push({
      pathname: '/referenceImages',
      state: { designId: design.id, designName: design.name, from: 'designTable' }
    });
  };

  var url = `${api.ViewerHost}/?id=${design.short_url}`;

  const handlePreview = () => {
    handleMenuClose();
    var win = window.open(url, '_blank');
    win.focus();
  };

  const handleOpenGetLinkPopover = () => {
    handleMenuClose();
    setOpenGetLinkPopover(true);
  };

  const handleCloseGetLinkPopover = () => {
    setOpenGetLinkPopover(false);
  };

  const handleCopyLink = () => {
    setOpenGetLinkPopover(false);
    var text = document.getElementById('url');
    text.select();
    document.execCommand('copy');
  };

  return (
    <TableRow
      data-test={`design-table-row-${index}`}
      hover
      key={design.id}
      role="checkbox"
      selected={false}>
      {showCheckBox && (
        <CheckBoxCell
          design={design}
          handleCheckBoxClick={handleCheckBoxClick}
          index={index}
          temporarySelectedDesignsForTheGroup={
            temporarySelectedDesignsForTheGroup
          }
        />
      )}
      <TableCell className={classes.designName}>
        <Box
          alignItems="center"
          display="flex"
        >
          <Box className={classes.image}>
            <Avatar
              className={classes.avatar}
              src={designImg}
            />
          </Box>
          <Typography>{design.name}</Typography>
        </Box>
      </TableCell>
      {showactions && (
        <TableCell>
          <Typography
            className={classes.designPublish}
            data-test={'publishedStatusCypressHelper'}>{design.published ? 'Yes' : 'No'}</Typography>
        </TableCell>
      )}
      {showactions && (
        <TableCell>
          <Box
            alignItems="center"
            className={classes.designBlockStatus}
            data-test="block-status"
            display="flex"
            justifyContent="center"
          >
            {(design.design_blocked_by_superadmin || design.org_blocked_by_superadmin) ? 'Super Admin' : (design.org_blocked_by_admin) ? 'Admin' : 'Not Blocked'}
          </Box>
        </TableCell>
      )}
      {showType && (
        <TableCell>
          <Box
            alignItems="center"
            className={classes.designType}
            data-test="design-type"
            display="flex"
            justifyContent="center"
          >
            {design.type}
          </Box>
        </TableCell>
      )}
      {showDate && (
        <TableCell display="flex">
          <Typography>{updatedDate === 'Invalid Date' ? createdDate : updatedDate}</Typography>
          <Typography>{updatedTime === 'Invalid Date' ? createdTime : updatedTime}</Typography>
        </TableCell>
      )}
      {showactions && (
        <TableCell>
          <Box
            data-test="design-icon"
            display="flex"
            justifyContent="flex-end"
          >
            {design.type === 'Single' && (
              <IconButton
                data-test="tv-icon"
                onClick={() => handleEditor(design)}
              >
                <TvIcon fontSize="small" />
              </IconButton>
            )}
            {design.type === 'Group' && (
              <IconButton
                data-test="visibility-icon"
                onClick={handlePreview}
              >
                <VisibilityIcon color="action" />
              </IconButton>
            )}
            <IconButton
              aria-controls="long-menu"
              aria-haspopup="true"
              aria-label="more"
              data-test="long-menu"
              onClick={handleMenuIconClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              PaperProps={{
                style: {
                  width: 150
                }
              }}
              anchorEl={anchorEl}
              id="long-menu"
              keepMounted
              onClose={handleMenuClose}
              open={open}
            >
              <MenuItem onClick={() => handleEdit(design)}>
                <EditIcon
                  className={classes.menuIcon}
                  fontSize="small"
                />
                Edit Info
              </MenuItem>
              <MenuItem onClick={handleClickOpenDeleteConfirmationDialog}>
                <DeleteIcon
                  className={classes.menuIcon}
                  fontSize="small"
                />
                Delete
              </MenuItem>
              {isAdmin && (<MenuItem onClick={handlePublish}>
                <PublishIcon
                  className={classes.menuIcon}
                  data-test="publish-design"
                  fontSize="small"
                />
                {design.published ? 'Unpublish' : 'Publish'}
              </MenuItem>)}
              <MenuItem onClick={handlePreview}>
                <VisibilityIcon
                  className={classes.menuIcon}
                  data-test="preview-design"
                  fontSize="small"/>
                Preview
              </MenuItem>
              <MenuItem onClick={handleOpenGetLinkPopover}>
                <LinkIcon
                  className={classes.menuIcon}
                  fontSize="small"
                />
                Get Link
              </MenuItem>
              <MenuItem onClick={() => addReferenceImages(design)}>
                <PermMediaIcon
                  className={classes.menuIcon}
                  fontSize="small"
                />
                Ref Images
              </MenuItem>
              <DeleteConfirmationDialog
                design = {design}
                handleCloseDeleteConfirmationDialog={handleCloseDeleteConfirmationDialog}
                loadDesigns = {loadDesigns}
                openDeleteConfirmationDialog = {openDeleteConfirmationDialog}
                setOpenDeleteConfirmationDialog = {setOpenDeleteConfirmationDialog}
              />
              <Dialog
                className={classes.publishLoadingDialog}
                maxWidth="md"
                open={publishDialog.openPublishLoadingDialog}
              >
                <DialogContent className={classes.title}>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    mb={2}
                    mt={2}
                  >
                    <CircularProgress
                      className={classes.loader}
                      disableShrink
                    />
                    <Typography variant="h3">
                      {publishDialog.dialogMessage}...
                    </Typography>
                  </Box>
                </DialogContent>
              </Dialog>
              <Popover
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                id={url}
                onClose={handleCloseGetLinkPopover}
                open={openGetLinkPopover}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Typography className={classes.linkTitle}>
                  Copy Link..
                </Typography>
                <Input
                  className={classes.popupText}
                  disableUnderline
                  id="url"
                  type="text"
                  value={url}
                />
                <IconButton onClick={handleCopyLink}>
                  <FilterNoneIcon fontSize="small" />
                </IconButton>
              </Popover>
            </Menu>
          </Box>
        </TableCell>
      )}
      {showPreviewIcon && (
        <TableCell>
          <IconButton>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}
