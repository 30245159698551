import React from 'react';
import TextureAssetPicker from '../AssetPickers/TextureAssetPicker';
import ExpansionSection from '../ExpansionSection';
import InspectorPropertyPreviewHook from '../InspectorPropertyPreviewHook';
import InspectorPropertyPreview from '../InspectorPropertyPreview';
import { Box } from '@material-ui/core';

export default function NormalMap() {
  const mapType = 'normalMap';
  const [
    textureAssetPicker,
    handleOpenTextureAssetPicker,
    handleCloseTextureAssetPicker,
    assetMap
  ] = InspectorPropertyPreviewHook(mapType);

  return (
    <ExpansionSection sectionName="Normal">
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        flexWrap="nowrap"
        pt={2}
      >
        <InspectorPropertyPreview
          assetMap={assetMap}
          handleOpenTextureAssetPicker={handleOpenTextureAssetPicker}
          mapType={mapType}
        />
        {textureAssetPicker.openTextureAssetPicker && (
          <TextureAssetPicker
            handleCloseAssetPicker={handleCloseTextureAssetPicker}
            openAssetPicker={textureAssetPicker.openTextureAssetPicker}
            type={textureAssetPicker.type}
          />
        )}
      </Box>
    </ExpansionSection>
  );
}
