import React from 'react';
import { Select, FormControl, MenuItem, IconButton, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { createMaterialAsset,
  createTextureAsset,
  addAsset,
  deleteAsset,
  createIconAsset, OgmoConsts, loadObj, generatePlaneMeshAsset, USDZAsset, GLBAsset } from 'ogmo_core';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import * as api from '../../services/api';
import * as s3 from '../../services/s3';
import * as img from '../../services/image-resize';
import { useSelector,useDispatch } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import { isAnimageFile, isOBJFile, isUSDZFile, isGLBFile } from '../../utils/extensionHelpers';
import { createARAssetFromScene } from 'ogmo_core';
import { showErrorSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles({
  icon: {
    margin: '0 5px 0 5px'
  },
  assetTypography: {
    fontFamily: 'Montserrat',
    fontSize: 12
  },
  buttonAsset: {
    borderRadius: 0,
    boxShadow: 'none',
    padding: 5,
    fontSize: 20,
    fontFamily: 'Montserrat',
    '&:hover': { boxShadow: 'none', backgroundColor: '#b5b5b5' }
  },
  selectEmpty: {
    fontSize: 12,
    width: 150,
    marginLeft: 15
  },
  assetStatus:{
    marginLeft:5
  },
  progressBar:{
    width:'100px'
  },
  imageUploadFile: {
    display: 'none'
  },
  uploadButton: {
    padding: 0,
    backgroundColor: 'none',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: 'none' }
  },
  hovermsg: {
    backgroundColor: '#ff80ab',
    padding: '5px 10px',
    marginLeft: 16,
    borderRadius: 3,
    fontWeight: 'bold'
  },

});

export default function AssetBrowserToolbar({
  filteredAssetType,
  setfilteredAssetType,
  designId
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isUploadIconHovering, setIsUploadIconHovering] = React.useState(false); 
  const isAssetUploading = useSelector(state => state.assetBrowser.isUploading);
  
  const dispatch = useDispatch();

  const handleUploadProgress = (isAssetUploading,uploadingAssetId) =>{
    dispatch({
      type: 'SET_UPLOAD_PROGRESS',
      payload: {
        uploading: isAssetUploading,
        assetId: uploadingAssetId
      }
    });
  }
  const selectedAssetId = useSelector(state => state.scene.selectedAssetId)

  const handleUploadIconMouseHover = () => {
    setIsUploadIconHovering(prevState=> !prevState);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  const handleCreateNewPlaneAsset = () => {
    const plane = generatePlaneMeshAsset(1, 1, 1, 1);
    plane.name = 'Plane';
    addAsset(plane);
  }

  const changeAssetType = event => {
    setfilteredAssetType(event.target.value);
  };

  const createNewARAssetFromScene = () =>{
    createARAssetFromScene(uploadGLBFile);
    handleClose();
  }

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file) {
      const filename = file.name;
      if (isAnimageFile(filename)){
        uploadImageFile(filename,file,OgmoConsts.AssetType.TEXTURE);
      }else if(isOBJFile(filename)){
        uploadOBJFile(filename,file);
      }else if(isUSDZFile(filename,file)){
        uploadUSDZFile(filename,file);
      }else if(isGLBFile(filename,file)){
        uploadGLBFile(filename,file);
      }else{
        handleUploadProgress(false,'');
        dispatch(showErrorSnackbar('Please upload a file of supported formats'));
      }
    }
  };

  const handleIconUpload = event => {
    const file = event.target.files[0];
    if (file) {
      const filename = file.name;
      if (isAnimageFile(filename)){
        uploadImageFile(filename,file,OgmoConsts.AssetType.ICON);
      }else{
        dispatch(showErrorSnackbar('Please upload a file of supported formats'));
      }
    }
  };

  const uploadImageFile = (filename, file,imageType) => {
    handleUploadProgress(true,'');
    let resizedImg;
    let getResourceResponse;
    img.imgResize(file)
      .then( (resizedImgResponse) => {
        resizedImg = resizedImgResponse
        return api.post('editor/asset', { id: designId, name: filename })
      })
      .then(response => { 
        getResourceResponse = response;    
        s3.uploadWithSignURL(getResourceResponse.signed_url, new Blob([file]));  
        return s3.uploadWithSignURL(getResourceResponse.thumb_signed_url, resizedImg)
      })
      .then( () => {
        const name = filename;
        const originalTextures = [getResourceResponse.thumb_url, getResourceResponse.url];
        if(imageType === OgmoConsts.AssetType.ICON){
          createIconAsset(name, getResourceResponse.thumb_url);
        }else if(imageType === OgmoConsts.AssetType.TEXTURE){
          createTextureAsset(name, originalTextures);
        }
        handleUploadProgress(false,'');
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
        handleUploadProgress(false,'');
      });
  }

  const uploadOBJFile = (filename, file) => {
    handleUploadProgress(true,'');
    img.fileToOBJ(file)
      .then((obj_data) => {
        let asset = loadObj(obj_data, filename);
        handleUploadProgress(true,asset.id);
        if(asset) {
          api.post('editor/asset', { id: designId, name: asset.id + '.json', isJson : true })
            .then(response => {  
              s3.uploadJsonWithSignURL(response.signed_url, JSON.stringify(asset))
                .then(()=>{
                  handleUploadProgress(false,'');
                  asset.data.meshPath = response.url;
                  addAsset(asset);
                })
            })
            .catch(error => {
              dispatch(showErrorSnackbar(error.message));
              handleUploadProgress(false,'');
            });
        }
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
      });
  }

  const uploadUSDZFile = (filename, file) =>{
    handleUploadProgress(true,'');
    let getResourceResponse;
    api.post('editor/asset', { id: designId, name: filename })
      .then(response => {
        getResourceResponse = response;
        return s3.uploadWithSignURL(response.signed_url, new Blob([file]))
      })
      .then(() => {
        let usdzAsset = USDZAsset();
        usdzAsset.name = filename;
        usdzAsset.data.usdzPath = getResourceResponse.url;
        addAsset(usdzAsset);
        handleUploadProgress(false,'');
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error)
        handleUploadProgress(false,'');
      });
  }

  const uploadGLBFile = (filename, file) =>{
    handleUploadProgress(true,'');
    let getResourceResponse;
    api.post('editor/asset', { id: designId, name: filename })
      .then(response => {
        getResourceResponse = response;
        return s3.uploadWithSignURL(response.signed_url, new Blob([file]))
      })
      .then(() => {
        let glbAsset = GLBAsset();
        glbAsset.name = filename;
        glbAsset.data.glbPath = getResourceResponse.url;
        addAsset(glbAsset);
        handleUploadProgress(false,'');
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error)
        handleUploadProgress(false,'');
      });
  }

  const deleteAssetAction = selectedAssetId => {
    deleteAsset(selectedAssetId);
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      height="50px"
    >
      <Box
        alignItems="center"
        direction="row"
        display="flex"
        justifyContent="center"
      >
        <PlayCircleOutlineIcon
          className={classes.icon}
          color="action"
          fontSize="small"
        />
        <Typography
          className={classes.assetTypography}
          variant="subtitle2"
        >
          Assets
        </Typography>
      </Box>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        disabled={isAssetUploading}
        onClick={handleClick}
      >
        <AddIcon fontSize="small"/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={handleClose}>
          <input
            className={classes.imageUploadFile}
            id="texture"
            onChange={handleFileUpload}
            type="file"
          />
          <label htmlFor="texture">
            <Button
              className={classes.uploadButton}
              component="span"
            >
              Upload Asset
            </Button>
          </label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <input
            className={classes.imageUploadFile}
            id="icon"
            onChange={handleIconUpload}
            type="file"
          />
          <label htmlFor="icon">
            <Button
              className={classes.uploadButton}
              component="span"
              onMouseEnter={handleUploadIconMouseHover}
              onMouseLeave={handleUploadIconMouseHover}
            >
              Upload Icon
            </Button>
          </label>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => createMaterialAsset(OgmoConsts.MaterialType.LIT)}>New Lit Material</MenuItem>
        <MenuItem onClick={() => createMaterialAsset(OgmoConsts.MaterialType.INVISIBLE_SHADOW)}>New Shadow Material</MenuItem>
        <MenuItem onClick={handleCreateNewPlaneAsset}>New Plane</MenuItem>
        <Divider/>
        <MenuItem onClick={createNewARAssetFromScene}>New AR Asset</MenuItem>
      </Menu>
      <Button
        disabled={!selectedAssetId}
        onClick={() => deleteAssetAction(selectedAssetId)}
      >
        <DeleteIcon
          color="action"
          fontSize="small"
        />
      </Button>
      <FormControl>
        <Select
          className={classes.selectEmpty}
          displayEmpty
          onChange={changeAssetType}
          value={filteredAssetType}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value={OgmoConsts.AssetType.MATERIAL}>Material</MenuItem>
          <MenuItem value={OgmoConsts.AssetType.TEXTURE}>Texture</MenuItem>
          <MenuItem value={OgmoConsts.AssetType.MODEL}>Model</MenuItem>
          <MenuItem value={OgmoConsts.AssetType.MESH}>Mesh</MenuItem>
          <MenuItem value={OgmoConsts.AssetType.ICON}>Icon</MenuItem>
        </Select>
      </FormControl>
      <LinearProgress />
      {isAssetUploading && <LinearProgress className={classes.progressBar}/>}
      {isUploadIconHovering && (
        <Typography
          className={classes.hovermsg}
          variant="body1"
        >Aspect ratio of the Icon should be 1:1</Typography>
      )}
    </Box>
  );
}
