import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ogmoLogo from '../../../../images/ogmoLogo.png';
import Profile from '../Topbar/Profile';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  flexGrow: {
    flexGrow: 1
  },
  ogmoLogo: {
    marginLeft: 35
  },
  root: {
    backgroundColor:'white'
  }
}));

const Topbar = ({ className, onSidebarOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <img
          alt=""
          className={classes.ogmoLogo}
          src={ogmoLogo}
        />
        <div className={classes.flexGrow} />
        <Profile />
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
