import React from 'react';
import AssetPicker from './AssetPicker';
import { useDispatch } from 'react-redux';
import { OgmoConsts, setMaterialAssetIdForAnOptionInGlobalHotspot } from 'ogmo_core';

const GlobalHotspotTargetMaterialAssetPicker = props => {
  const { openAssetPicker, handleCloseAssetPicker, hotspotComponentId, optionId } = props;
  const dispatch = useDispatch();

  const handleChoosedMaterialAssetId = choosedMaterialAssetId => {
    dispatch(setMaterialAssetIdForAnOptionInGlobalHotspot(
      {
        hotspotComponentId: hotspotComponentId,
        choosedMaterialAssetId: choosedMaterialAssetId,
        optionId: optionId
      }))
    handleCloseAssetPicker();
  }

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedMaterialAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.MATERIAL]}
    />
  );
};
export default GlobalHotspotTargetMaterialAssetPicker;
