import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableCell,
  Paper,
  Avatar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import * as api from 'services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import CollaboratersTableRow from '../Collaborators/CollaboratersTableRow';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import userImg from '../../images/user-icon.png';
import { showErrorSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  button: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '8px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    border: 'solid 1px #c7c7c7',
    alignItems: 'center',
    padding: '6px',
    display: 'flex',
    width: 200
  },
  collaboratorsCount: {
    color: '#576077'
  },
  avatar: {
    height: '44px',
    width: '44px'
  },
  loader: {
    color: '#3183ae',
    margin: theme.spacing(5)
  },
  image: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    height: '45px',
    width: '45px',
    borderRadius: '50%'
  }
}));

const Collaborators = () => {
  const classes = useStyles();
  const [usersList, setUsersList] = useState([]);
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsUserListLoading(true);
    api
      .get('users/all')
      .then(res => {
        setUsersList(res.data);
        setIsUserListLoading(false);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message)
      });
  }, []);

  const handleClick = () => {
    history.push('/collaboraters/add');
  };

  const removeUserFromList = ( removedEmail ) => {
    let ExistingUsers = usersList.filter((item, index, List) => List[index].email !== removedEmail);
    setUsersList(ExistingUsers);
  }

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
          Collaborators
        </Typography>
      </Breadcrumbs>
      <Box p={4}>
        <Box
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
        >
          <Button
            className={classes.button}
            onClick={handleClick}
            variant="contained"
          >
            Add Collaborators
          </Button>
        </Box>
        {!isUserListLoading&&<Box
          alignItems="center"
          display="flex"
          mb={5}
        >
          <Typography
            className={classes.collaboratorsCount}
            variant="subtitle2"
          >
            {usersList.length} collaborators available
          </Typography>
        </Box>}

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Added On</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              {(!isUserListLoading || usersList.length > 0) && (
                <>
                  {usersList.map((element, index) => {
                    return (
                      <TableBody key={index}>
                        <TableRow
                          data-test={element.email.split('@')[0]}
                          hover
                        >
                          <TableCell>
                            <Box
                              alignItems="center"
                              display="flex"
                            >
                              <Box className={classes.image}>
                                <Avatar
                                  className={classes.avatar}
                                  src={element.img ? element.img : userImg}
                                />
                              </Box>
                              <Typography>xyz</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{element.role}</TableCell>
                          <TableCell>{element.email}</TableCell>
                          <TableCell>{element.created_at}</TableCell>
                          <TableCell>
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <CollaboratersTableRow
                                email={element.email}
                                removeUserFromList={removeUserFromList}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </>
              )}
            </Table>

            {isUserListLoading && (
              <Box
                display="flex"
                justifyContent="center"
                py={2}
              >
                <CircularProgress className={classes.loader}/>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};
export default Collaborators;
