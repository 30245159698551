import { OgmoConsts } from '../constants/consts';
import { createUUID } from "../core/Utils";

export const IconAsset = () => ({
	type: OgmoConsts.AssetType.ICON,
	name: "Default Icon",
	id: createUUID(),
	data: {
		iconImage: ""
	}
});
