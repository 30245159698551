export const isAnimageFile = (fileName) => {
  const extension = fileName.split('.').pop();
  return ['png', 'jpg', 'jpeg'].includes(extension);
};

export const isOBJFile = (fileName) => {
  const extension = fileName.split('.').pop();
  return ['obj'].includes(extension);
};

export const isUSDZFile = (fileName) => {
  const extension = fileName.split('.').pop();
  return ['usdz','reality'].includes(extension);
};

export const isGLBFile = (fileName) => {
  const extension = fileName.split('.').pop();
  return ['glb'].includes(extension);
};

