import React, { useState, useEffect } from 'react';
import {
  Box,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { useSelector } from 'react-redux';
import { setSelectedGlobalHotspotComponents } from 'ogmo_core';
import GlobalHotspotIconOrColor from '../GlobalHotspotComponent/GlobalHotspotIconOrColor'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles({
  addHotspotsButton: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 10,
    marginRight: 30,
    alignItems: 'center',
    marginLeft: '200px'
  },
  button: {
    width: 170,
    alignSelf: 'center',
    borderRadius: 0,
    boxShadow: 'none',
    fontSize: 12,
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
    backgroundColor: '#e0e0e0',
    '&:hover': { boxShadow: 'none', backgroundColor: '#b5b5b5' }
  },
  buttonIcon: {
    backgroundColor: '#576077',
    color: 'white',
    borderRadius: 8
  },
  list: {
    padding: '1px',
    margin: '10px',
    backgroundColor: '#f7f4f4',
    alignSelf: 'center',
    width: '350px'
  },
  name: {
    marginLeft: '30px'
  },
  checkbox: {
    marginLeft: '80px'
  },

});

const GlobalHotspotSelectionPopUp = ({ openModel, handleCloseModel, globalHotspotGroupComponentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cmpts = useSelector(state => state.scene.componentByIds);
  const globalHotspotGroupComponent = useSelector(state => state.scene.componentByIds[globalHotspotGroupComponentId]);
  const selectedGlobalHotspotComponentsList = globalHotspotGroupComponent.data.selectedGlobalHotspots
  const ents = useSelector(state => state.scene.entityByIds);
  const allGlobalHotspotComponents = Object.keys(cmpts).filter(key => cmpts[key].type === 'globalHotspotComponent')
  const [selectedGlobalHotspotsInThePoUp, setSelectedGlobalHotspotsInThePopUp] = useState([]); //This is only for the Popup not to save

  useEffect(() => {
    setSelectedGlobalHotspotsInThePopUp(selectedGlobalHotspotComponentsList)
  }, [selectedGlobalHotspotComponentsList]);

  let selectedGlobalHotspotComponents = []
  Object.keys(ents).forEach(entity => {
    ents[entity].components.map((component) => {
      if (component.type === 'globalHotspotGroupComponent') {
        let selectedGlobalHotspots = cmpts[component.id].data.selectedGlobalHotspots
        selectedGlobalHotspots.map((globalHotspot) =>
          selectedGlobalHotspotComponents = [...selectedGlobalHotspotComponents, globalHotspot]
        )

      }
    })
  })

  let globalHotspotsForTheList = allGlobalHotspotComponents.filter((component) => !selectedGlobalHotspotComponents.includes(component))

  const handleCheckedGlobalHotspot = (globalHotspot) => {
    let newSelectedGlobalHotspotsInThePopUp = [];
    if (!selectedGlobalHotspotsInThePoUp.includes(globalHotspot)) {
      newSelectedGlobalHotspotsInThePopUp = [...selectedGlobalHotspotsInThePoUp, globalHotspot];
    }
    else {
      newSelectedGlobalHotspotsInThePopUp = selectedGlobalHotspotsInThePoUp.filter(component => component !== globalHotspot);
    }
    setSelectedGlobalHotspotsInThePopUp(newSelectedGlobalHotspotsInThePopUp)
  };

  const updateSelectedGlobalHotspots = () => {
    dispatch(setSelectedGlobalHotspotComponents({
      componentId: globalHotspotGroupComponentId,
      updateField: 'selectedGlobalHotspots',
      value: selectedGlobalHotspotsInThePoUp
    }));
    handleCloseModel()
  };

  return (
    <Box className={classes.content}>
      <Dialog
        aria-labelledby="form-dialog-title"
        minWidth="md"
        open={openModel}
      >
        <DialogTitle id="form-dialog-title">Select Hotspots</DialogTitle>
        <Box className={globalHotspotsForTheList.length ? classes.list : ''}>
          {selectedGlobalHotspotComponentsList &&
                        <List>
                          {globalHotspotsForTheList.map((globalHotspot) => (
                            <ListItem key={globalHotspot} >
                              <ListItemAvatar>
                                <GlobalHotspotIconOrColor globalHotspot={cmpts[globalHotspot].data} />
                              </ListItemAvatar>
                              <ListItemText
                                className={classes.name}
                                primary={cmpts[globalHotspot].data.displayName}
                              />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  checked={selectedGlobalHotspotsInThePoUp.includes(globalHotspot)}
                                  checkedIcon={<CircleCheckedFilled />}
                                  className={classes.checkBox}
                                  color="default"
                                  edge="end"
                                  icon={<CircleUnchecked />}
                                  onClick={() => handleCheckedGlobalHotspot(globalHotspot)}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
          }
        </Box>
        <Box className={classes.addHotspotsButton}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.button}
            onClick={updateSelectedGlobalHotspots}
            size="small"
            type="button"
            variant="contained"
          >
                        Add Hotspots To Group
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
}
export default GlobalHotspotSelectionPopUp;





