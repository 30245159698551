import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  TableRow,
  Typography,
  TableCell,
} from '@material-ui/core';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as api from 'services/api';
import Tooltip from '@material-ui/core/Tooltip';
import { showErrorSnackbar, showSuccessSnackbar } from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';
import Preview from '@material-ui/icons/RemoveRedEye';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  linkButton: {
    padding: 0,
    color: '#24739C',
    fontSize: '12px',
    borderRadius: '3px',
    textTransform: 'capitalize',
    width: '130px',
    height: '35px',
    border: 'solid 1px #3e8eb8',
    backgroundColor: '#ffffff',
    '&:hover': { backgroundColor: '#ececec' },
  },
  UnlinkButton: {
    color: '#ffffff',
    backgroundColor: '#3e8eb8',
    fontSize: '12px',
    borderRadius: '3px',
    textTransform: 'capitalize',
    width: '130px',
    height: '35px',
    '&:hover': { backgroundColor: '#24739C' },
  },
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: 5,
  },
  name: {
    cursor: 'pointer',
    width: '30%',
  },
  loader: {
    color: '#3183ae',
  },
  linked: {
    color: '#3183ae',
    marginRight: 5,
  },
  unlinked: {
    color: '#b2b2b2',
    marginRight: 5,
  },
  linkIcon: {
    marginRight: 5,
  },
  okButton: {
    backgroundColor: '#3e8eb8',
    color: '#ffffff',
    '&:hover': { backgroundColor: '#24739C' },
  },
  cancelButton: {
    color: '#3e8eb8',
  },
  linkedProductName: {
    color: '#2F4474',
    marginRight: 5,
  },
  previewBtn: {
    cursor: 'pointer'
  }
}));

export default function ProductsTableRow(props) {
  const {
    element,
    showStatus,
    showLinkedDesign,
    organizationId,
    searchByNameAndSKU,
    showPreviewButton,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [openUnlinkDialog, setOpenUnlinkDialog] = useState(false);
  const dispatch = useDispatch();
  const ecommerceHosts = useSelector(state => state.ecommerceProducts.ecommerceHosts);
  const selectedEcommerce = useSelector(state => state.ecommerceProducts.selectedEcommerce);

  const handleClickProductSKU = (element) => {
    history.push({
      pathname: 'chooseDesigns',
      state: {
        productName: element.name,
        productId: element.id,
        productSKU: element.sku,
        productOriginalId: element.original_product_id,
        productUrlKey: getProductUrlKeyForLinking(element)
      },
    });
  };

  const handleClickOpenUnlinkDialog = () => {
    setOpenUnlinkDialog(true);
  };

  const handleCloseUnlinkDialog = () => {
    setOpenUnlinkDialog(false);
  };

  const handleUnlinkProduct = (sku) => {
    handleCloseUnlinkDialog();
    api.remove(`products/assign/${encodeURIComponent(sku)}?organization_id=${organizationId}&host=${ecommerceHosts[selectedEcommerce].host}`)
      .then((res) => {
        dispatch(showSuccessSnackbar(res.message));
        searchByNameAndSKU();
      })
      .catch((error) => {
        dispatch(showErrorSnackbar(error.message));
        searchByNameAndSKU();
        console.log(error);
      });
  };

  function getUrlKeyForMagento2x(productRecord) {
    if(ecommerceHosts[selectedEcommerce].version === '2.x' && ecommerceHosts[selectedEcommerce].platform === 'magento'){
      const productLink = productRecord &&
      productRecord.custom_attributes.filter(
        (item) => item.attribute_code == 'url_key'
      )[0].value;
      return productLink;
    }
  }

  function getProductUrlKeyForLinking(element){
    const urlKeys = {
      'woocommerce': element.permalink,
      'magento 1.x': `index.php/catalogsearch/result/?q=${element.name}`,
      'magento 2.x': element && getUrlKeyForMagento2x(element),
      'shopify': `${element.sku}`,
      'dokan': `${element.permalink}`,
    }
    const platform = ecommerceHosts[selectedEcommerce].platform
    const version = platform === 'magento' ? ' ' + ecommerceHosts[selectedEcommerce].version : ''
    return urlKeys[`${platform}${version}`]
  }

  const goToProductPageInEcommerceSite = (element) => {
    const selectedHost = ecommerceHosts[selectedEcommerce].host
    const selectedPlatform = ecommerceHosts[selectedEcommerce].platform
    const selectedVersion = ecommerceHosts[selectedEcommerce].version

    function getMagento2xUrlWithoutUrlKey(element) {
      if(selectedVersion === '2.x' && selectedPlatform === 'magento'){
        const productLink = element &&
        element.custom_attributes.filter(
          (item) => item.attribute_code == 'url_key'
        )[0].value;
        return `https://${selectedHost}/${productLink}.html`;
      }
    }
    const urls = !element.url_key
      ? {
        'woocommerce': element.permalink,
        'magento 1.x': `https://${selectedHost}/index.php/catalogsearch/result/?q=${element.name}`,
        'magento 2.x': element && getMagento2xUrlWithoutUrlKey(element),
        'shopify': `https://${selectedHost}/products/${element.sku}`,
        'dokan': `${element.permalink}`,
      }
      : {
        'woocommerce': element.url_key,
        'magento 1.x': `https://${selectedHost}/${element.url_key}`,
        'magento 2.x': `https://${selectedHost}/${element.url_key}.html`,
        'shopify': `https://${selectedHost}/products/${element.url_key}`,
        'dokan': `${element.url_key}`,
      }
    const version = selectedPlatform === 'magento' ? ' ' + selectedVersion : ''
    window.open(urls[`${selectedPlatform}${version}`]);
  };
  
  const handlePreview = (element) => {
    var url = `${api.ViewerHost}/?id=${element.short_url}`;
    var win = window.open(url, '_blank');
    win.focus();
  };

  return (
    <TableRow hover>
      <TableCell />
      <TableCell className={classes.name}>
        <Tooltip
          placement="top"
          title="open this in e-commerce site"
        >
          <Typography
            className={classes.linkedProductName}
            onClick={() => goToProductPageInEcommerceSite(element)}
          >
            <u>{element.name}</u>
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>{element.sku}</TableCell>
      {showStatus && (
        <TableCell
          align="center"
          width="12%"
        >
          {element.assigned && (
            <Box
              alignItems="center"
              display="flex"
            >
              <LinkIcon
                className={classes.linked}
                fontSize="small"
              />
              <Typography className={classes.linked}>Linked</Typography>
            </Box>
          )}
          {!element.assigned && (
            <Box
              alignItems="center"
              display="flex"
            >
              <LinkOffIcon
                className={classes.unlinked}
                fontSize="small"
              />
              <Typography className={classes.unlinked}>Unlinked</Typography>
            </Box>
          )}
        </TableCell>
      )}
      {showLinkedDesign && (
        <TableCell>
          <Box
            alignItems="center"
            display="flex"
          >
            <Typography>{element.design_name}</Typography>
          </Box>
        </TableCell>
      )}
      <TableCell align="center">
        {element.assigned && (
          <Button
            className={classes.UnlinkButton}
            data-test="unlink-product-button"
            onClick={handleClickOpenUnlinkDialog}
            variant="contained"
          >
              Unlink Product
          </Button>
        )}
        {!element.assigned && (
          <Button
            className={classes.linkButton}
            onClick={() => handleClickProductSKU(element)}
            variant="outlined"
          >
            <LinkIcon
              className={classes.linkIcon}
              fontSize="small"
            />
              Link Product
          </Button>
        )}
      </TableCell>
      {showPreviewButton && (
        <TableCell align="center">
          <Preview
            className={classes.previewBtn}
            onClick={() => handlePreview(element)}
          />
        </TableCell>
      )}
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        data-test="unlink-confirmation-dialog"
        onClose={handleCloseUnlinkDialog}
        open={openUnlinkDialog}
      >
        <DialogTitle>Unlink the Product "{element.name}"?</DialogTitle>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleCloseUnlinkDialog}
          >
              Cancel
          </Button>
          <Button
            autoFocus
            className={classes.okButton}
            onClick={() => handleUnlinkProduct(element.sku)}
          >
              Ok
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}
