import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
    width: '1000px'
  },
  paper: {
    padding: theme.spacing(3),
  }
}));

const S3DetailInfoIconWithPopover = () => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
      
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
      <>
        <InfoIcon
          aria-haspopup="true"
          aria-owns={open ? "mouse-over-popover" : undefined}
          color="action"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          disableRestoreFocus
          id="mouse-over-popover"
          onClose={handlePopoverClose}
          open={open}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Typography>
            Amazon S3 bucket is a public cloud storage resource available in AWS.
            You can add an external S3 Bucket to your OGMO organization account to
            save your media content. Else, all of your media content will be saved
            in OGMO S3 Buckets.
          </Typography>
        </Popover>
      </>
    );
  };

  export default S3DetailInfoIconWithPopover