import React from 'react';
import AssetPicker from './AssetPicker';
import { useDispatch } from 'react-redux';
import { OgmoConsts, setMeshAssetIdForAnOptionInGlobalHotspot } from 'ogmo_core';

const GlobalHotspotTargetMeshAssetPicker = props => {
  const { openAssetPicker, handleCloseAssetPicker, hotspotComponentId, optionId } = props;
  const dispatch = useDispatch();

  const handleChoosedMeshAssetId = choosedMeshAssetId => {
    dispatch(setMeshAssetIdForAnOptionInGlobalHotspot({
      hotspotComponentId: hotspotComponentId,
      choosedMeshAssetId: choosedMeshAssetId,
      optionId: optionId

    }));
    handleCloseAssetPicker();
  };

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedMeshAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.MESH]}
    />
  );
};
export default GlobalHotspotTargetMeshAssetPicker;
