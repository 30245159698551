import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenGlobalHotspotGroupDetails, deleteComponent } from 'ogmo_core';
import AddIcon from '@material-ui/icons/Add';
import GlobalHotspotSelectionPopUp from './GlobalHotspotsSelectionPopUp';
import SelectedGlobalHotspotsList from './SelectedGlobalHotspotsList'
import GlobalHotspotGroupIconOrColor from './GlobalHotspotGroupIconOrColor'

const useStyles = makeStyles({
  componentBody: {
    Height: 'auto',
    width: 326
  },
  globalHotspotTypography: {
    marginTop: 40,
    minHeight: 35,
    background: '#e0e0e0',
    paddingLeft: '10px',
  },
  globalHotspotContent: {
    height: 'auto',
    background: '#f7f4f4',
    padding: '15px'
  },
  Typography: {
    color: '#575757'
  },
  deleteIcon: {
    marginLeft: 'auto',
    marginRight: '20px',
  },
  createIcon: {
    marginRight: 1,
    marginLeft: '75px',
  },
  nameInput: {
    backgroundColor: '#eeeeee',
    height: 25,
    paddingTop: 3
  },
  addHotspotsButton: {
    marginTop: 20,
    display: 'flex',
    paddingLeft: '30px',
    alignItems: 'center'
  },
  button: {
    width: 250,
    alignSelf: 'center',
    borderRadius: 0,
    boxShadow: 'none',
    fontSize: 12,
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
    backgroundColor: '#e0e0e0',
    '&:hover': { boxShadow: 'none', backgroundColor: '#b5b5b5' }
  },
  buttonIcon: {
    backgroundColor: '#576077',
    color: 'white',
    borderRadius: 8
  },
  globalHotspotsList: {
    display: 'flex',
    marginTop: '20px',
    marginLeft: '10px',
    marginBottom: '10px',
    backgroundColor: 'white',
    width: 290
  }
});

const GlobalHotspotGroupComponent = ({ componentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalHotspotGroupComponent = useSelector(state => state.scene.componentByIds[componentId]);
  const globalHotspotGroupComponentData = globalHotspotGroupComponent.data
  const selectedGlobalHotspotComponents = globalHotspotGroupComponentData.selectedGlobalHotspots
  const [openModel, setOpenModel] = useState(false);

  const deleteGlobalHotspotGroupComponent = () => {
    deleteComponent(globalHotspotGroupComponent)
  };

  const handleOpenGlobalHotspotGroupDetails = (globalHotspotComponentId) => {
    dispatch(setOpenGlobalHotspotGroupDetails(globalHotspotComponentId));
  };

  const handleOpenModel = () => {
    setOpenModel(true);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
  };

  return (
    <Box
      className={classes.componentBody}
      display="flex"
      flex="1"
      flexDirection="column"
      position="relative"
    >
      <Box
        alignItems="center"
        className={classes.globalHotspotTypography}
        display="flex"
      >
        <Typography variant="subtitle2" >
          Global Hotspot Group
        </Typography>
        <IconButton
          className={classes.createIcon}
          onClick={() => handleOpenGlobalHotspotGroupDetails(globalHotspotGroupComponent.id)}
          size="small"
        >
          <CreateIcon
            color="action"
            fontSize="small"
          />
        </IconButton>
        <IconButton
          className={classes.deleteIcon}
          onClick={deleteGlobalHotspotGroupComponent}
          size="small"
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </IconButton>
      </Box>
      <Box className={classes.globalHotspotContent}>
        <Box
          display="flex"
          mb={2}
          mt={2}
        >
          <Box width="30%">
            <Typography
              className={classes.Typography}
              variant="body1"
            >
              Group Name
            </Typography>
          </Box>
          <Box
            className={classes.nameInput}
            pl={1}
            width="70%"
          >
            <Typography
              className={classes.Typography}
              variant="body1"
            >
              {globalHotspotGroupComponentData.displayName}
            </Typography>
          </Box>
        </Box>
        <GlobalHotspotGroupIconOrColor globalHotspotGroupData={globalHotspotGroupComponentData} />
        <Box className={classes.addHotspotsButton}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.button}
            onClick={handleOpenModel}
            size="small"
            startIcon={<AddIcon className={classes.buttonIcon} />}
            type="button"
            variant="contained"
          >
            Add Hotspots To Group
          </Button>
        </Box>
        <GlobalHotspotSelectionPopUp
          globalHotspotGroupComponentData={globalHotspotGroupComponentData}
          globalHotspotGroupComponentId={componentId}
          handleCloseModel={handleCloseModel}
          openModel={openModel}
          selectedGlobalHotspotsList={globalHotspotGroupComponentData.selectedGlobalHotspots}
        />
        <Box className={selectedGlobalHotspotComponents.length ? classes.globalHotspotsList : ''} >
          {selectedGlobalHotspotComponents &&
            <SelectedGlobalHotspotsList
              componentId={componentId}
              selectedGlobalHotspotComponents={globalHotspotGroupComponentData.selectedGlobalHotspots}
            />
          }
        </Box>
      </Box>
    </Box>
  );
}
export default GlobalHotspotGroupComponent;