import { createSlice } from '@reduxjs/toolkit'
import {Severity} from '../constants/consts.js'

const initialState = {
  snackPack: [],
  open: false,
  messageInfo: undefined,
};

const snackbarSlice = createSlice({
  name: 'snackbarDashboard',
  initialState,
  reducers: {

    setOpen(state, action) {
      state.open = action.payload;
    },
    setMessageInfo(state, action) {
      state.messageInfo = action.payload;
    },
    
    showErrorSnackbar(state, action) {
      state.snackPack.push({  message: action.payload, key:new Date().getTime(),severity:Severity.error});
    },

    showSuccessSnackbar(state, action) {
      state.snackPack.push({ message: action.payload, key:new Date().getTime(),severity:Severity.success});
    },

    showInfoSnackbar(state, action) {
      state.snackPack.push({ message: action.payload, key:new Date().getTime(),severity:Severity.info});
    },

    showWarningSnackbar(state, action) {
      state.snackPack.push({ message: action.payload, key:new Date().getTime(),severity:Severity.warning});
    },

    shiftSnackPack(state, action) { // eslint-disable-line no-unused-vars
      state.snackPack.shift();
    }
  }
});
export const {
  setOpen,
  setMessageInfo,
  shiftSnackPack,
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
  showWarningSnackbar
} = snackbarSlice.actions

export default snackbarSlice.reducer