import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import useSnackbar from '../SnackBar/useSnackbar';

function CustomSnackbar(){

  const { open, handleSnackbarClose, handleExited, messageInfo } = useSnackbar()

  return (

    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={4000}
      key={messageInfo ? messageInfo.key : undefined}
      onClose={handleSnackbarClose}
      onExited={handleExited}
      open={open}
    >
      <Alert
        data-test={`${messageInfo ? messageInfo.severity : ''}-snackbar`}
        severity={messageInfo ? messageInfo.severity : undefined}
        variant="filled"
      >
        {messageInfo ? messageInfo.message : undefined}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;

