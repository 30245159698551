import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import AssetThumbnail from '../AssetThumbnail';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  assetPickerBody: {
    width: '500px',
    backgroundColor: '#c9c9c9'
  },
  assetPickerButton: {
    width: 100,
    alignSelf: 'flex-end'
  }
});

const AssetPicker = props => {
  const classes = useStyles();
  const {
    openAssetPicker,
    handleCloseAssetPicker,
    handleChoosedAssetId,
    types
  } = props;

  const [choosedAssetId, setChoosedAssetId] = React.useState();

  const assetByIds = useSelector(state => state.scene.assetByIds);
  const assetKeys = Object.keys(assetByIds);

  const assetTypeKeys = assetKeys.filter(
    assetKey => types.includes(assetByIds[assetKey].type)
  );

  const setSelectedAssetId = assetKey => {
    setChoosedAssetId(assetKey);
  };

  return (
    <div>
      <Dialog
        onClose={handleCloseAssetPicker}
        open={openAssetPicker}
      >
        <DialogContent className={classes.assetPickerBody}>
          <Grid
            container
            direction="column"
            wrap="nowrap"
          >
            <Grid container>
              {assetTypeKeys.map((assetKey, index) => (
                <AssetThumbnail
                  asset={assetByIds[assetKey]}
                  key={index}
                  setSelectedAssetId={() => setSelectedAssetId(assetKey)}
                />
              ))}
            </Grid>
            <Button
              className={classes.assetPickerButton}
              disabled={!choosedAssetId}
              onClick={() => handleChoosedAssetId(choosedAssetId)}
              size="small"
              type="button"
              variant="contained"
            >
              Change
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AssetPicker;
