import React, {useState} from 'react';
import {
  Box, 
  TextField, 
  IconButton,
  Tooltip
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { green, red } from '@material-ui/core/colors';

const UpdateRefImageName = ({
  id,
  referenceImageDefaultName,
  handleConfirmRefImageNameChange
}) => {

  const [isImageNameEditClicked,setIsImageNameEditClicked] = useState(false);
  const [imageName, setImageName] = useState(referenceImageDefaultName);

  const handleOpenImageNameEdit = () => {
    setIsImageNameEditClicked(true);
  }

  const handleImageNameChange = event => {
    const updatedName = event.target.value;
    setImageName(updatedName);
  };

  const saveImageNameChange = () => {
    handleConfirmRefImageNameChange(imageName, id); 
    setIsImageNameEditClicked(false);
  }

  const discardImageNameChange = () => {
    setIsImageNameEditClicked(false);
    setImageName(referenceImageDefaultName)
  }

  return (
    <Box
      alignItems="center"
      display="flex"
    >
      {!isImageNameEditClicked && (
        <IconButton onClick={handleOpenImageNameEdit}>
          <CreateIcon
            color="primary"
            fontSize="small"
          />
        </IconButton>
      )}

      {!isImageNameEditClicked && (
        <Tooltip
          placement="bottom-start"
          title={referenceImageDefaultName}
        >
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            defaultValue={referenceImageDefaultName}
          />
        </Tooltip>
      )}

      {isImageNameEditClicked && (
        <TextField
          autoFocus
          onChange={handleImageNameChange}
          value={imageName}
        />
      )}
      {isImageNameEditClicked && (
        <IconButton
          disabled={!imageName}
          onClick={saveImageNameChange}
        >
          <CheckIcon style={{ color: green[500] }} />
        </IconButton>
      )}
      {isImageNameEditClicked && (
        <IconButton onClick={discardImageNameChange}>
          <CloseIcon style={{ color: red[500] }} />
        </IconButton>
      )}
    </Box>
  );
}

export default UpdateRefImageName;
