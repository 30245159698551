import { OgmoConsts } from '../constants/consts';
import { createUUID } from '../core/Utils.js';

export const LightComponent = () => ({
    type: OgmoConsts.ComponentType.LIGHT,
    needsUpdate: true,
    id: createUUID(),
    name: "Light Component",
    data: {
        kind: OgmoConsts.LightType.DIRECTIONAL,
        castShadows: false,
        color: [0.98, 0.92, 0.89],
        intensity: 1.0,
        intensityLumen: 110000.0,
        shadowCameraDistance: 10,
        shadowMapResolution: OgmoConsts.Resolutions.RESOLUTION2
    }
});