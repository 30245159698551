import React from 'react';
import { Grid, Box, Button, IconButton } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getSkyData, OgmoConsts } from 'ogmo_core';
import { useDispatch, useSelector } from 'react-redux';
import { updateSkyboxComponentData } from 'ogmo_core';
import { showInfoSnackbar} from 'reducers/snackbarSlice';

const useStyles = makeStyles({
  componentBody: {
    height: '100%'
  },
  skyTypography: {
    marginTop: 20,
    minHeight: 30,
    background: '#e0e0e0',
    display: 'flex',
    paddingLeft: '10px',
    alignItems: 'center'
  },
  deleteIcon: {
    marginLeft: 'auto'
  },
  skyElements: {
    height: '200px',
    background: '#f7f4f4',
    padding: '15px'
  },
  Model: {
    alignItems: 'center',
    margin: '20px 0 10px 0'
  },
  Typography: {
    fontFamily: 'Montserrat',
    fontSize: 10
  },
  skyContent: {
    height: '270px',
    background: '#f7f4f4',
    padding: '15px'
  },
  image: {
    width: 50,
    height: 50,
    border: '1px solid #d6d6d6',
    backgroundColor: 'white',
    cursor: 'pointer'
  }
});
const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 10,
    fontFamily: 'Montserrat',
    padding: '3px 8px',
    width: '150px',
    height: '14px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
}))(InputBase);

export default function SkyComponent({ componentId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const skyComponent = useSelector(state => state.scene.componentByIds[componentId]);

  const skies = [];

  for (var key in OgmoConsts.SkyType) {
    let sky_data = getSkyData(OgmoConsts.SkyType[key]);
    skies.push({
      key: OgmoConsts.SkyType[key],
      text: OgmoConsts.SkyType[key],
      imageThumb: sky_data.icon
    });
  }

  const deleteSky = () => {
    dispatch(showInfoSnackbar('Clicked Delete Sky'));
  };

  const handleChange = event => {
    dispatch(updateSkyboxComponentData({
      componentID: skyComponent.id,
      value: event.target.value,
      updateField: 'type'
    }
    ));
  };

  return (
    <Grid
      className={classes.componentBody}
      container
      direction="column"
      wrap="nowrap"
    >
      <Box className={classes.skyTypography}>
        <Typography variant="body1">Sky Component</Typography>
        <Button
          className={classes.deleteIcon}
          onClick={deleteSky}
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </Button>
      </Box>

      <div className={classes.skyElements}>
        <Grid
          className={classes.Model}
          container
        >
          <Grid
            item
            xs={3}
          >
            <Typography
              className={classes.Typography}
              variant="body1"
            >
              Sky Type
            </Typography>
          </Grid>

          <Grid
            className={classes.select}
            item
            xs={9}
          >
            <FormControl>
              <Select
                input={<BootstrapInput />}
                onChange={handleChange}
                value={skyComponent.data.type}
              >
                {skies.map((sky, index) => {
                  return <MenuItem
                    key={index}
                    value={sky.key}
                  >{sky.text}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          className={classes.Model}
          container
        >
          <Box display="flex">
            <img
              alt=""
              className={classes.image}
              src={getSkyData(skyComponent.data.type).icon}
            />
            <IconButton className={classes.deleteIcon}>
              <DeleteIcon
                color="action"
                fontSize="small"
              />
            </IconButton>
          </Box>
        </Grid>
      </div>
      <Divider />
    </Grid>
  );
}
