import Resizer from 'react-image-file-resizer';

export async function imgResize(file, returnType = 'blob', width = 256, height = 256) {
  return new Promise(function(resolve, reject) { // eslint-disable-line no-unused-vars
    Resizer.imageFileResizer(file, width, height, 'JPEG', 100, 0, uri => {
      resolve(uri);
    }, returnType);
  });
}

export async function fileToB64(file) {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export async function fileToJSON(file) {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(JSON.parse(reader.result));
    reader.onerror = error => reject(error);
  });
}

export async function fileToOBJ(file) {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}