import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import OnBlurOnEnterInput from '../OnBlurOnEnterInput';
import { makeStyles } from '@material-ui/core/styles';
import { setPositionOnAxis, setRotationOnAxis, setScaleOnAxis } from 'ogmo_core';

const useStyles = makeStyles({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    marginLeft: '10px',
    paddingLeft: '3px',
    border: '1px solid #eeeeee',
    width: '60px',
    height: '20px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function TransformInput({ transformName }) {
  const classes = useStyles();

  const selectedEntitiyId = useSelector(state => state.scene.selectedEntitiyId);
  const entity = useSelector(
    state => state.scene.entityByIds[selectedEntitiyId]
  );

  const handleValueChange = (arrayIndex, value) => {

    if (transformName === 'position') {
      setPositionOnAxis(entity.id, value, Number(arrayIndex));
    } else if (transformName === 'rotation') {
      setRotationOnAxis(entity.id, value, Number(arrayIndex));
    } else if (transformName === 'scale') {
      setScaleOnAxis(entity.id, value, Number(arrayIndex));
    }
  };
  
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      width="75%"
    >
      <Box className={classes.input}>
        <OnBlurOnEnterInput
          inputValue={entity[transformName][0]}
          onPress={e => handleValueChange('0', e)}
          placeholder="x"
          step={1}
        />
      </Box>

      <Box className={classes.input}>
        <OnBlurOnEnterInput
          inputValue={entity[transformName][1]}
          onPress={e => handleValueChange('1', e)}
          placeholder="y"
          step={1}
        />
      </Box>

      <Box className={classes.input}>
        <OnBlurOnEnterInput
          inputValue={entity[transformName][2]}
          onPress={e => handleValueChange('2', e)}
          placeholder="z"
          step={1}
        />
      </Box>
    </Box>
  );
}
