import React, { useEffect } from 'react';
import {
  Box, Typography
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import { makeStyles } from '@material-ui/core/styles';
import { showErrorSnackbar } from '../../reducers/snackbarSlice';
import { useParams } from 'react-router-dom'
import { useSelector , useDispatch } from 'react-redux';
import * as api from '../../services/api';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  loaderContainer: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  loader: {
    color: '#3183ae'
  }
}));

const ShopifyAuthCallback = ({history}) => {
  const classes = useStyles();
  const {app_type} = useParams()
  const dispatch = useDispatch();
  const organizationId = useSelector(state => state.dashboard.userOrganizationId)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shopOrigin = params.get('shop');
    const authCode = params.get('code');
    
    api.post('shopify/access-token',{
      authCode: authCode,
      shopOrigin: shopOrigin,
      organizationId: organizationId,
      withCustomApp: app_type === 'custom'
    })
    // eslint-disable-next-line
      .then(data => {
        history.push('/')
      })
      .catch(error => {
        console.error(error)
        dispatch(showErrorSnackbar('Invalid request. Please try reinstalling'));
      });
  })

  return (
    <div>
      <Box
        className={classes.loaderContainer}
        display="flex"
        justifyContent="center"
        pt={2}
      >
        <CircularProgress className={classes.loader}/>
        <Typography variant="subtitle1">Redirecting...</Typography>
      </Box>
    </div>
  );
};

export default ShopifyAuthCallback;