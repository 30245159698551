import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialAssetPicker from '../../AssetPickers/MaterialAssetPicker';
import { getAssetById, updateMeshComponentData } from 'ogmo_core';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  image: {
    width: 50,
    height: 50,
    backgroundColor: '#e6e6e6',
    cursor: 'pointer'
  },
  materialAssetTypography: {
    backgroundColor: '#eeeeee',
    height: 25,
    paddingTop: 3
  },
  typography: {
    color: '#575757'
  },
  deleteIcon: {
    width: '10%',
    marginLeft: 'auto'
  }
});

export default function MeshComponentMaterialProperty({ materialProperty }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openAssetPicker, setOpenAssetPicker] = useState(false);

  const handleOpenAssetPicker = () => {
    setOpenAssetPicker(true);
  };

  const handleCloseAssetPicker = () => {
    setOpenAssetPicker(false);
  };

  const getAssetName = asset => {
    return asset ? asset.name : '';
  };

  const handleDeleteMaterialProperty = () => {

    dispatch(updateMeshComponentData({
      componentId: materialProperty.id,
      updateField: 'materialAssetId',
      value: ''
    }))
  };

  const materialAssetProperty = getAssetById(
    materialProperty.data.materialAssetId
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexWrap="nowrap"
      mt={2}
    >
      <Typography
        className={classes.typography}
        variant="body1"
      >
        Material
      </Typography>

      <Box
        alignItems="flex-start"
        display="flex"
      >
        <Box width="25%">
          <img
            alt=""
            className={classes.image}
            onClick={handleOpenAssetPicker}
          />
        </Box>
        <Box
          className={classes.materialAssetTypography}
          mt={1}
          pl={1}
          width="60%"
        >
          <Typography
            className={classes.typography}
            variant="body1"
          >
            {materialAssetProperty ? getAssetName(materialAssetProperty) : '[empty]'}
          </Typography>
        </Box>
        <IconButton
          aria-label="delete"
          className={classes.deleteIcon}
          onClick={handleDeleteMaterialProperty}
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </IconButton>
      </Box>

      {openAssetPicker && (
        <MaterialAssetPicker
          handleCloseAssetPicker={handleCloseAssetPicker}
          materialProperty={materialProperty}
          openAssetPicker={openAssetPicker}
        />
      )}
    </Box>
  );
}
