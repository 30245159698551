import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Name from './Name';
import Position from './Position';
import Rotation from './Rotation';
import Scale from './Scale';
import Hide from './Hide'

const useStyles = makeStyles({
  content: {
    padding: '15px'
  }
});

export default function DefaultEntityProperties() {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Name />
      <Position />
      <Rotation />
      <Scale />
      <Hide />
    </div>
  );
}
