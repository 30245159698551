import React from 'react';
import {
  Typography,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  image: {
    width: 50,
    height: 50,
    backgroundColor: '#e6e6e6',
    cursor: 'pointer'
  },
  materialTargetTypography: {
    backgroundColor: 'white',
    height: 25,
    paddingTop: 3,
    marginLeft: 20
  },
  typography: {
    color: '#575757'
  },
  nameTypography: {
    marginBottom: 10,
    color: '#575757'
  },
  deleteIcon: {
    width: '10%',
    marginLeft: 'auto'
  }
});

export default function GlobalHotspotMaterialTarget({ materialAssetName, handleOpenMaterialAssetPicker, handleDeleteTargetMaterialAsset }) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexWrap="nowrap"
      mt={1}
    >
      <Typography
        className={classes.nameTypography}
        variant="body1"
      >
                Material Target
      </Typography>
      <Box
        alignItems="flex-start"
        display="flex"
      >
        <Box width="20%">
          <img
            alt=""
            className={classes.image}
            onClick={handleOpenMaterialAssetPicker}
          />
        </Box>
        <Box
          className={classes.materialTargetTypography}
          mt={1}
          pl={1}
          width="100%"
        >
          <Typography
            className={classes.targetAssetId}
            variant="body1"
          >
            {materialAssetName}
          </Typography>
        </Box>
        <Box className={classes.deleteIcon}>
          <IconButton onClick={handleDeleteTargetMaterialAsset} >
            <DeleteIcon
              color="action"
              fontSize="small"
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}




