import React from 'react';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  hideTypography: {
    fontFamily: 'Montserrat',
    fontWeight: 550,
    color: '#575757'
  },
  checkbox: {
    padding: 0,
    margin: 8,
    borderRadius: 0,
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function Hide() {
  const classes = useStyles();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
    >
      <Box width="25%">
        <Typography
          className={classes.hideTypography}
          variant="body1"
        >
          Hide
        </Typography>
      </Box>
      <Checkbox className={classes.checkbox} />
    </Box>
  );
}
