import React,{useState} from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';
import {setOpenCreateGroupDesignDialog} from 'reducers/dashboardSlice';
import { useDispatch } from 'react-redux';
import * as api from '../services/api';
import { useHistory } from 'react-router-dom';
import { showErrorSnackbar, showSuccessSnackbar} from 'reducers/snackbarSlice';

const CreateGroupDesignDialog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);
  const [groupDesignName, setGroupDesignName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const isCreateGroupDesignDialogOpen = useSelector(
    state => state.dashboard.isCreateGroupDesignDialogOpen
  );

  const data = {
    name: groupDesignName,
    type: 'Group',
    list: [],
    organization_id: organizationId
  }
  
  const handleCloseDialog = () => {
    dispatch(setOpenCreateGroupDesignDialog(false));
    setGroupDesignName('');
  }

  const handleGroupNameChange = (e) => {
    setGroupDesignName(e.target.value);
  }
  
  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
    api
      .post('designs', data)
      .then(res => {
        handleCloseDialog();
        setIsSubmitting(false);
        dispatch(showSuccessSnackbar(groupDesignName + ' successfully created'));
        history.push({
          pathname: '/createGroupDesign',
          state: { designId: res.data.id }
        })
      })
      .catch(error => {
        dispatch(showErrorSnackbar('Failed to create Group', error.message));
        handleCloseDialog();
        setIsSubmitting(false);
        console.log(error.message)
      });
  };

  return (
    <Box>
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleCloseDialog}
        open={isCreateGroupDesignDialogOpen}
      >
        <DialogTitle id="form-dialog-title">Create Group Design</DialogTitle>
        <DialogContent>
          <DialogContentText>
              To create a group design, please enter the group name here.
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            id="name"
            label="Group Design Name"
            margin="dense"
            onChange={handleGroupNameChange}
            type="text"
            value={groupDesignName}
          />
        </DialogContent>
        {isSubmitting && (
          <Box
            display="flex"
            justifyContent="center"
          >
            <CircularProgress disableShrink />
          </Box>
        )}
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseDialog}
          >
              Cancel
          </Button>
          <Button
            color="primary"
            disabled={!groupDesignName}
            onClick={handleSubmit}
          >
              Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateGroupDesignDialog;
