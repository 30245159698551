import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardContent,
  Input,
  Button,
  Typography,
  Box
} from '@material-ui/core';
import * as api from 'services/api';
import {useSelector} from 'react-redux' ;
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { showErrorSnackbar, showSuccessSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 600
  },
  text: {
    marginRight: 50
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 300,
    border: 'solid 1px #C4C4C4',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  count: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  tableContent: {
    marginTop: theme.spacing(8),
    width: '75%'
  },
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: 5
  },
  noProductLabel: {
    backgroundColor: '#f8f8f8'
  },
  button: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '10px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  }
}));

const EditDesign = props => {
  const { design } = props.location.state || {};
  const [editName, setEditName] = useState(design.name);
  const classes = useStyles();
  const history = useHistory();
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);
  const dispatch = useDispatch();

  const handleDesignNameEdit = event => {
    setEditName(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    api.put(`designs/${design.id}`,{ 
      name: editName,
      organization_id:organizationId 
    })
    // eslint-disable-next-line
      .then(data => {
        history.push('/designs');
        dispatch(showSuccessSnackbar('Design '+editName+' edited'));
       
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message))
        console.log(error.message)
      });
  };

  const handleEditBreadcrumbClick = event => {
    event.preventDefault();
    history.push('/designs')
  };

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleEditBreadcrumbClick}
        >
          Designs
        </Link>
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
          Edit Design
        </Typography>
      </Breadcrumbs>
      <Box p={8}>
        <Box
          display="flex"
          justifyContent="flex-end"
          mb={4}
          mt={0}
        >
          <Button
            className={classes.button}
            disabled={!editName}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </Box>
        <Card>
          <CardContent>
            <Typography
              className={classes.title}
              variant="h5"
            >
              {design.name}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              mb={2}
              ml={6}
            >
              <FormControl>
                <Typography> Design Name</Typography>
                <Input
                  autoFocus
                  className={classes.input}
                  defaultValue={editName}
                  disableUnderline
                  onChange={handleDesignNameEdit}
                  required
                />
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default EditDesign;

