import * as api from './../services/api';
import GitInformation from './../GitInformation.json';
import awsexports from './../aws-exports.js';
import { version } from './../version.json';

const getVersion = () => {
    return api
        .get('getversion')
        .then(data => {
          const env = awsexports.aws_dynamodb_table_schemas[0].tableName.split('-');
          const BRANCH = GitInformation.branch;
          const SHA = GitInformation.short_sha;
  
          var git_ver = GitInformation.version.split('-');
          var num_commits = `-${git_ver[1]}`;
          var short_sha = `-${SHA}`
          var git_branch = '';
  
          if(env[1] === 'prod'){
            git_branch = '';
            if(git_ver.length === 1){
              num_commits = '';
            }
          }
          else if(env[1] === 'dev'){
            git_branch = '-alpha';
          }
          else if(env[1] === 'uat'){
            git_branch = '-beta';
          }
          else if(env[1] === 'topic'){
            git_branch = `-${BRANCH}`;
          }
          
          GitInformation.version = `v${version}${num_commits}${git_branch}${short_sha}`
          GitInformation.stage = env[1]
  
          return {GitInformation, data}
        })
  }

  export default getVersion