import React from 'react';
import { Box, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {  useSelector } from 'react-redux';

const useStyles = makeStyles({
  Typography: {
    color: '#575757'
  },
  image: {
    marginTop: 10,
    marginLeft: 20,
    width: 50,
    height: 50,
    backgroundColor: '#e6e6e6',
    cursor: 'pointer',
    border: '1px solid #d6d6d6',
    borderRadius: 5
  }
});

function GlobalHotspotIconOrColor({ globalHotspot }){
  const classes = useStyles();
  const assetByIds = useSelector(state => state.scene.assetByIds);
  const iconAssetId = globalHotspot.iconAssetId
  const iconImage = iconAssetId && assetByIds[iconAssetId].data.iconImage
  const color = globalHotspot.color
  const hasIconAssetUrl = iconAssetId && iconImage

  const convertToRgb = (color) => {
    return (
      'rgb(' +
      color[0] * 255 +
      ',' +
      color[1] * 255 +
      ',' +
      color[2] * 255 +
      ')'
    );
  };

  if (hasIconAssetUrl) {
    return (
      <Box width="25%" >
        <Avatar
          className={classes.image}
          src={iconImage}
        />
      </Box>
    )
  } 

  if (color) {
    return (
      <Box width="25%">
        <Box
          className={classes.image}
          style={{ backgroundColor: convertToRgb(color) }}
        />
      </Box>
    )
  }

  return (
    <Box width="25%">
      <img
        alt=""
        className={classes.image}
      />
    </Box>
  )
}
export default GlobalHotspotIconOrColor