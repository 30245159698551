import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box
} from '@material-ui/core';
import OnBlurOnEnterInput from './ComponetInspector/OnBlurOnEnterInput';
import OnCommitedSliderInput from './ComponetInspector/OnCommitedSliderInput';

const useStyles = makeStyles({
  input: {
    position: 'relative',
    margin: '0 0 3px 10px',
    height: '30px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

const OverlayOpacity = ({
  opacityValue,
  setOpacityOfOverlay
}) => {
  const classes = useStyles();

  const handleDragStop = (event, newValue) => {
    setOpacityOfOverlay(newValue);
  };
    
  const handleOpacityChange = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 1) {
      value = 1;
    }
    setOpacityOfOverlay(value);
  };

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      mt={1}
    >
      <Box
        mt={0.5}
        width="35%"
      >
        <Typography>Overlay Opacity</Typography>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexGrow={1}
        flexWrap="nowrap"
        width="50%"
      >
        <Box width={100}>
          <OnCommitedSliderInput
            handleDragStop={handleDragStop}
            max={1}
            min={0}
            sliderInputValue={opacityValue ? opacityValue : 0.2}
            step={0.1}
          />
        </Box>
        <Box
          className={classes.input}
          width="40%"
        >
          <OnBlurOnEnterInput
            inputValue={opacityValue ? opacityValue : 0.2}
            max={1}
            min={0}
            onPress={handleOpacityChange}
            step={0.05}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default OverlayOpacity;