import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Typography,Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '600px',
    padding: '15px'
  },
  typography: {
    fontWeight: 800,
  }
}));

const RootEntitySelectedMessage = () => {
    
  const classes = useStyles();

  return (
    <Box>
      <Box
        className={classes.content}
        display="flex"
      >
        <Typography
          className={classes.typography}
          variant="subtitle2"
        >
                   You have selected the root 
        </Typography>
      </Box>
      <Divider className={classes.divider}/>
    </Box>
  )
}
export default RootEntitySelectedMessage;