import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  FormControl,
  Typography,
  Input,
  Button,
  Box,
  TextareaAutosize,
  Tooltip
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  boxColor: {
    background: '#f7f7f7',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5)
  },
  infoIcon: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 150,
    border: 'solid 1px #C4C4C4',
    background: '#ffffff',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  textArea: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 500,
    border: 'solid 1px #C4C4C4',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'capitalize',
    background: '#c7c9c9'
  },
  saveButton: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' },
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));


const ImageAdditionalDetails = ({
  dimention,
  saveDimentionDetailsToDB
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [breadth, setBreadth] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    setWidth(dimention.width);
    setHeight(dimention.height);
    setBreadth(dimention.breadth);
    setNotes(dimention.comments);
  }, [dimention]);

  const handleClose = event => {
    event.preventDefault();
    history.push('/designs')
  }

  const changeWidth = event => {
    const value = event.target.value;
    setWidth(value);
  }

  const changeHeight = event => {
    const value = event.target.value;
    setHeight(value);
  }

  const changeBreadth = event => {
    const value = event.target.value;
    setBreadth(value);
  }

  const changeNotes = event => {
    setNotes(event.target.value);
  }

  const saveAdditionalDetails = () => {
    let detailListToSave = [
      { width: width },
      { height: height },
      { breadth: breadth },
      { comments: notes }
    ];
    saveDimentionDetailsToDB(detailListToSave);
  }

  return (
    <form onSubmit={saveAdditionalDetails}>
      <div className={classes.boxColor}>
        <Box
          display="flex"
          mb={2}
          ml={6}
        >
          <FormControl>
            <Typography
              className={classes.infoIcon}
            >
                            Add Additional Details
            </Typography>
          </FormControl>
          <FormControl>
            <Box
              display="flex"
              justifyContent="center"
              mb={2}
              ml={1}
              mt={3}
            >
              <Tooltip
                placement="bottom-start"
                title="Add additional details required for the design below. These 
                                    details will be displayed with the reference images in the editor."
              >
                <InfoIcon
                  color="primary"
                  fontSize="small"
                />
              </Tooltip>
            </Box>
          </FormControl>
        </Box>
        <Box
          display="flex"
          mb={2}
          ml={6}
        >
          <FormControl>
            <Typography>Width (cm)</Typography>
            <Input
              autoFocus
              className={classes.input}
              disableUnderline
              onChange={changeWidth}
              required
              type="number"
              value={width}
            />
          </FormControl>
          <FormControl>
            <Typography>Height (cm)</Typography>
            <Input
              autoFocus
              className={classes.input}
              disableUnderline
              onChange={changeHeight}
              required
              type="number"
              value={height}
            />
          </FormControl>
          <FormControl>
            <Typography>Breadth (cm)</Typography>
            <Input
              autoFocus
              className={classes.input}
              disableUnderline
              onChange={changeBreadth}
              required
              type="number"
              value={breadth}
            />
          </FormControl>
        </Box>
        <Box
          display="flex"
          mb={2}
          ml={6}
        >
          <FormControl>
            <Typography>Notes</Typography>
            <TextareaAutosize
              className={classes.textArea}
              maxLength="300"
              onChange={changeNotes}
              rowsMin="5"
              value={notes}
            />
          </FormControl>
        </Box>
        <Box
          display="flex"
          mb={2}
          ml={6}
        >
          <FormControl>
            <Button
              className={classes.saveButton}
              onClick={saveAdditionalDetails}
            >
                            Save
            </Button>
          </FormControl>
          <FormControl>
            <Button
              className={classes.button}
              onClick={handleClose}
            >
                            Cancel
            </Button>
          </FormControl>
        </Box>
      </div>
    </form>
  );
}

export default ImageAdditionalDetails;