import React, { useState } from 'react';
import {
  Typography,
  Box,
  Select
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { setGlobalHotspotSelectedMeshComponentForAnOption } from 'ogmo_core';

const useStyles = makeStyles({
  menu: {
    width: 140,
    backgroundColor: '#D3D3D3',
    height: 25,
    paddingTop: 5,
    marginRight: 20,
    marginBottom: 20
  },
  nameTypography: {
    width: 80,
    alignItems: 'center',
    color: '#575757',
    marginTop: 10
  },
  Typography: {
    color: '#575757'
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4
  }
});

export default function GlobalHotspotMeshComponentSelection({ componentId, optionId, selectedMeshComponent }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cmpts = useSelector(state => state.scene.componentByIds);
  const targetMeshMaterialOptions = cmpts[componentId].data.targetMeshMaterialOptions
  const [openMeshComponentSelectionMenu, setOpenMeshComponentSelectionMenu] = useState(false);

  let allMeshComponents = Object.keys(cmpts).filter(key => cmpts[key].type === 'meshComponent')
  let meshComponentsUsedInOptions = []
  Object.keys(targetMeshMaterialOptions).map((option) => {
    let targetMeshComponentId = targetMeshMaterialOptions[option].targetMeshComponentId
    meshComponentsUsedInOptions = [...meshComponentsUsedInOptions, targetMeshComponentId]
  })
    
  let meshComponentsForTheDropDown = []
  allMeshComponents.forEach(meshComponent => {
    let meshComponentForTheDropDown = {}
    let isFound = meshComponentsUsedInOptions.find(meshComponentUsedInOptions => meshComponentUsedInOptions === meshComponent)
    meshComponentForTheDropDown = {
      id: meshComponent,
      isDisabled: isFound
    }
    meshComponentsForTheDropDown = [...meshComponentsForTheDropDown,  meshComponentForTheDropDown]
  })

  const handleCloseMenu = () => {
    setOpenMeshComponentSelectionMenu(false);
  };

  const handleOpenMenu = () => {
    setOpenMeshComponentSelectionMenu(true);
  };

  const handleMeshComponentSelection = (event) => {
    let selectedMeshComponentId = event.target.value
    dispatch(setGlobalHotspotSelectedMeshComponentForAnOption({
      componentId: componentId,
      optionId: optionId,
      value: selectedMeshComponentId
    }))
    handleCloseMenu();
  };

  return (
    <Box
      alignItems="space-between"
      display="flex"
    >
      <Box width="40%">
        <Typography
          className={classes.nameTypography}
          variant="body1"
        >Assign Mesh</Typography>
      </Box>
      <Box
        mt={1}
        pl={1}
        width="70%"
      >
        <Select
          className={classes.menu}
          name="meshComponentSelection"
          onChange={handleMeshComponentSelection}
          onClose={handleCloseMenu}
          onOpen={handleOpenMenu}
          open={openMeshComponentSelectionMenu}
          value={selectedMeshComponent}
        >
          {meshComponentsForTheDropDown.map((meshComponent, index) =>
            <MenuItem
              className={meshComponent.isDisabled ? classes.disabled : ''}
              key={index}
              value={meshComponent.id}
            >{cmpts[meshComponent.id].data.displayName}</MenuItem>
          )}
        </Select>
      </Box>
    </Box>
  );
}

