import React from 'react';
import { Button, Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';


import CameraColor from './CameraColor';
import CameraFieldOfView from './CameraFieldOfView';
import CameraClip from './CameraClip';
import DeleteIcon from '@material-ui/icons/Delete';
import RotateSpeed from './RotateSpeed';
import EnableInteraction from './EnableInteraction';
import CameraZoomProperties from './CameraZoomProperties';
import CameraDampingProperties from './CameraDampingProperties';
import CameraAngleProperties from './CameraAngleProperties';
import { showInfoSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  camera: {
    marginTop: 20,
    minHeight: 30,
    background: '#e0e0e0',
    display: 'flex',
    paddingLeft: '10px',
    alignItems: 'center',
    fontSize: 12
  },
  cameraElements: {
    height: '210px',
    padding: '16px'
  },
  deleteIcon: {
    marginLeft: 'auto'
  },
  cameraProperties: {
    height: 220
  },
  tabButton: {
    fontSize: 12,
    textTransform: 'capitalize',
    minWidth: 0,
    backgroundColor: '#e2e2e2',
    opacity: 0.4,
    minHeight: 0
  },
  bigIndicator: {
    height: 0
  }
});

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Box hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
export default function CameraComponent({ componentId }) {
  const classes = useStyles();
  const [option, setOption] = React.useState(0);
  const cameraComponent = useSelector(state => state.scene.componentByIds[componentId]);
  const dispatch = useDispatch();

  const deleteCamera = () => {
    dispatch(showInfoSnackbar('Clicked Delete Camera'));
  };

  const handleChangeOptions = (event, newValue) => {
    setOption(newValue);
  };

  return (
    <Box className={classes.cameraEntity}>
      <Box className={classes.camera}>
        <Typography variant="body1">Camera</Typography>
        <Button
          className={classes.deleteIcon}
          onClick={deleteCamera}
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </Button>
      </Box>
      <div className={classes.cameraElements}>
        <CameraColor cameraComponent={cameraComponent} />
        <CameraFieldOfView cameraComponent={cameraComponent} />
        <CameraClip cameraComponent={cameraComponent} />
        <RotateSpeed cameraComponent={cameraComponent} />
        <EnableInteraction cameraComponent={cameraComponent} />
      </div>
      <Divider />

      <Box
        className={classes.cameraProperties}
        display="flex"
        flexDirection="column"
        p={2}
        width="100%"
      >
        <Tabs
          classes={{ indicator: classes.bigIndicator }}
          onChange={handleChangeOptions}
          value={option}
          variant="fullWidth"
        >
          <Tab
            className={classes.tabButton}
            label="Zoom"
          />
          <Tab
            className={classes.tabButton}
            label="Damping"
          />
          <Tab
            className={classes.tabButton}
            label="Angle"
          />
        </Tabs>
        <TabPanel
          index={0}
          value={option}
        >
          <CameraZoomProperties cameraComponent={cameraComponent} />
        </TabPanel>
        <TabPanel
          index={1}
          value={option}
        >
          <CameraDampingProperties cameraComponent={cameraComponent} />
        </TabPanel>
        <TabPanel
          index={2}
          value={option}
        >
          <CameraAngleProperties cameraComponent={cameraComponent} />
        </TabPanel>
      </Box>
    </Box>
  );
}
