import React from 'react';
import AssetPicker from '../AssetPicker';
import { useDispatch } from 'react-redux';
import { OgmoConsts } from 'ogmo_core';
import { setIconToGlobalHotspotComponent } from 'ogmo_core';

const GlobalHotspotIconPicker = props => {
  const { openAssetPicker, handleCloseAssetPicker, globalHotspotComponentId } = props;
  const dispatch = useDispatch();

  const handleChoosedIconAssetId = choosedIconAssetId => {
    dispatch(setIconToGlobalHotspotComponent({
      globalHotspotComponentId: globalHotspotComponentId,
      iconAssetId: choosedIconAssetId
    }
    ));
    handleCloseAssetPicker();
  };

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedIconAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.ICON]}
    />
  );
};
export default GlobalHotspotIconPicker;
