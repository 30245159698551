import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import OnBlurOnEnterInput from '../OnBlurOnEnterInput.js';
import { updateCameraComponentData } from 'ogmo_core';

const useStyles = makeStyles({
  cameraClipInputField: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '6px 5px 3px 8px',
    height: '25px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function CameraClip({ cameraComponent }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handlChangeCameraClipNear = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 180) {
      value = 180;
    } else if (value > cameraComponent.data.far) {
      dispatch(updateCameraComponentData({
        componentID: cameraComponent.id,
        value: value === '' ? '' : Number(value),
        updateField: 'far'
      }
      ));

    }

    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: value === '' ? '' : Number(value),
      updateField: 'near'
    }
    ));
  };

  const handlChangeCameraClipFar = value => {
    if (value < 0) {
      value = 0;
    } else if (value < cameraComponent.data.near) {
      dispatch(updateCameraComponentData({
        componentID: cameraComponent.id,
        value: value === '' ? '' : Number(value),
        updateField: 'near'
      }
      ));
    }

    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: value === '' ? '' : Number(value),
      updateField: 'far'
    }
    ));
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
    >
      <Box width="45%">
        <Typography variant="caption">Clip</Typography>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexGrow={1}
        flexWrap="nowrap"
        width="55%"
      >
        <Box className={classes.cameraClipInputField}>
          <OnBlurOnEnterInput
            inputValue={cameraComponent.data.near}
            max={180}
            min={0}
            onPress={handlChangeCameraClipNear}
            placeholder="near"
            step={0.1}
          />
        </Box>

        <Box className={classes.cameraClipInputField}>
          <OnBlurOnEnterInput
            inputValue={cameraComponent.data.far}
            min={0}
            onPress={handlChangeCameraClipFar}
            placeholder="far"
            step={0.1}
          />
        </Box>
      </Box>
    </Box>
  );
}
