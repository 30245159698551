import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table, TableBody, TableContainer, TableCell, TableRow,
  TableHead, Paper, TablePagination, Box, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoader from 'super_admin/components/CircularLoader';
import * as api from '../../../src/services/api';
import { showErrorSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 600,
    color: '#1F3974'
  },
  blueTypography: {
    fontWeight: 600,
    color: '#1F3974'
  }
}));

const LinkedSites = ({ organization_id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [siteCount, setSiteCount] = useState(0);
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, sites.length - page * rowsPerPage);

  useEffect(() => {
    setIsLoading(true);
    api.get(`superadmin/site/${organization_id}`)
      .then(res => {
        setIsLoading(false);
        setSites(res.data);
        setSiteCount(res.data.length);
      })
      .catch(error => {
        dispatch(showErrorSnackbar('Linked Sites were failed to load'));
        console.log(error);
      });
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {!isLoading && <Box
        display="flex"
        flexDirection="column"
        p={4}
      >
        <Typography
          className={classes.title}
          component={'span'}
        >{siteCount} Sites are linked </Typography>
      </Box>
      }
      {isLoading && <CircularLoader />}
      {!isLoading && <Box p={4} >
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell className={classes.blueTypography}>Linked sites</TableCell>
                  <TableCell
                    align="right"
                    className={classes.blueTypography}
                  >Version</TableCell>
                  <TableCell
                    align="right"
                    className={classes.blueTypography}
                  >Status</TableCell>
                  <TableCell
                    align="right"
                    className={classes.blueTypography}
                  >No Linked Products</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((linked_site, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.blueTypography}>{linked_site.name}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.blueTypography}
                    >{linked_site.version}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.blueTypography}
                    >{linked_site.status}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.blueTypography}
                    >{linked_site.no_of_designs}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={sites.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
          /></>
      </Box>
      }
    </>);
};
export default LinkedSites;
