import { OgmoConsts } from "../constants/consts";
import { createUUID } from "../core/Utils";

export const USDZAsset = () => ({
	type: OgmoConsts.AssetType.USDZ,
	name: "",
	id: createUUID(),
	data: {
		usdzPath: ""
	}
});
