import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {setMessageInfo,setOpen, shiftSnackPack} from '../../reducers/snackbarSlice';
import {useSelector} from 'react-redux' ;

export function useSnackbar() {

  const dispatch = useDispatch();
  const snackPack = useSelector(state => state.snackbarDashboard.snackPack);
  const open = useSelector(state => state.snackbarDashboard.open);
  const messageInfo = useSelector(state => state.snackbarDashboard.messageInfo);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      dispatch(setMessageInfo({ ...snackPack[0] }));
      dispatch(shiftSnackPack());
      dispatch(setOpen(true));
    } else if (snackPack.length && messageInfo && open) {
      dispatch(setOpen(false));
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setOpen(false));
  }

  const handleExited = () => {
    dispatch(setMessageInfo(undefined));
  }

  return (
    { open, messageInfo, handleSnackbarClose, handleExited }
  );
}

export default useSnackbar;