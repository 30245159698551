import React, { useState } from 'react';
import {
  Typography,
  Box,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector} from 'react-redux'
import { getAssetById } from 'ogmo_core';
import GlobalHotspotIconPicker from '../../AssetPickers/IconAssetPickers/GlobalHotspotIconPicker';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
  Typography: {
    color: '#575757'
  },
  image: {
    width: 50,
    height: 50,
    border: '1px solid #d6d6d6',
    backgroundColor: '#e6e6e6',
    borderRadius: 5
  },
  iconNameField: {
    backgroundColor: '#d9d9d9',
    height: 25,
    padding: 5
  },
});

const GlobalHotspotIconPickerMain = ({globalHotspotComponent}) => {
  const classes = useStyles();
  const globalHotspot = globalHotspotComponent.data;
  const assetByIds = useSelector(state => state.scene.assetByIds);
  const iconImage = globalHotspot.iconAssetId && assetByIds[globalHotspot.iconAssetId].data.iconImage;
  const [openIconAssetPicker, setOpenIconAssetPicker] = useState(false);

  const handleOpenIconAssetPicker = () => {
    setOpenIconAssetPicker(true);
  };

  const handleIconCloseAssetPicker = () => {
    setOpenIconAssetPicker(false);
  };

  return (
    <>
      <Typography variant="body2">Icon</Typography>
      <Box
        alignItems="center"
        display="flex"
        mb={2}
        mt={2}
      >
        <Box width="30%">
          <Avatar
            className={classes.image}
            src={iconImage}
          />
        </Box>
        <Box
          alignItems="center"
          className={classes.iconNameField}
          display="flex"
          width="60%"
        >
          <Typography
            className={classes.Typography}
            variant="body1"
          >
            {globalHotspot.iconAssetId ? getAssetById(globalHotspot.iconAssetId).name : '[empty]'}
          </Typography>
          <IconButton
            onClick={handleOpenIconAssetPicker}
            style={{ display: 'inline', marginLeft: 'auto' }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {openIconAssetPicker == true && (
        <GlobalHotspotIconPicker
          globalHotspotComponentId={globalHotspotComponent.id}
          handleCloseAssetPicker={handleIconCloseAssetPicker}
          openAssetPicker={openIconAssetPicker}
        />
      )}
    </>
  );
}

export default GlobalHotspotIconPickerMain;