import { OgmoConsts } from '../constants/consts';
import { createUUID } from '../core/Utils';

export const GlobalHotspotComponent = () => ({
    type: OgmoConsts.ComponentType.GLOBAL_HOTSPOT,
    needsUpdate: true,
    id: createUUID(),
    name: "Global Hotspot",
    data: {
        displayName : "Default Global Hotspot",
        identifierType: "",
        iconAssetId:"",
        color:[],
        targetMeshMaterialOptions : {}
    }
})

export function createMeshMaterialOption(targetMeshAssetId = "", targetMaterialAssetId="" , targetMeshComponentId="") {
    return {
        displayName: "Default Option",
        optionId : createUUID(),
        targetMeshAssetId : targetMeshAssetId,
        targetMaterialAssetId: targetMaterialAssetId,
        targetMeshComponentId: targetMeshComponentId
    }
}