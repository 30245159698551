import React from 'react';
import DesignsTable from '../../components/Design/DesignsTable';
import DesignsToolBar from '../../components/Design/DesignsToolBar';
import { useSelector } from 'react-redux';
import UseFetchDesigns from '../../components/Design/UseFetchDesigns';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CreateGroupDesignDialog from '../CreateGroupDesignDialog';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
}))

const Designs = () => {
  const classes = useStyles();

  const designsByIdsInTheStore = useSelector(
    state => state.dashboard.designs
  );
 
  const designIds = Object.keys(designsByIdsInTheStore);
  const designsInTheStore = designIds.map(designId => {
    return designsByIdsInTheStore[designId];
  });

  const [
    rowsPerPage,
    setRowsPerPage,
    handlePageChange,
    isDesignsAreLoading,
    loadDesigns,
    setIsDesignsAreLoading
  ] = UseFetchDesigns();
  
  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >Designs</Typography>
      </Breadcrumbs>
      <Box p={4}>
        <DesignsToolBar
          isDesignsAreLoading={isDesignsAreLoading}
          rowsPerPage={rowsPerPage}
          setIsDesignsAreLoading={setIsDesignsAreLoading}
        />
        <CreateGroupDesignDialog />
        <Box mt={2}>
          <DesignsTable
            designs={designsInTheStore}
            handlePageChange={handlePageChange}
            isDesignsAreLoading={isDesignsAreLoading}
            loadDesigns={loadDesigns}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            showCheckBox={false}
            showDate
            showPreview={false}
            showTableHeads
            showType
            showactions
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Designs;
