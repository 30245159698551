import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import save from '../images/save.png';
import update from '../images/update.png';
import publish from '../images/publish.png';
import logo from '../images/logo.png';
import preview from '../images/preview.png';
import * as api from '../services/api';
import refImage from '../images/refImage.png';
import { updateEntities, OgmoConsts, MeshAsset, emptyDeletedAssets, getAssetById } from 'ogmo_core';
import { showErrorSnackbar, showSuccessSnackbar, showInfoSnackbar } from 'reducers/snackbarSlice';
import { setOpenImageBox } from 'reducers/refImageSlice';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  toolBar: {
    background: '#5b7073'
  },
  tools: {
    padding: 1,
    width: '3rem',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor:'pointer'
  },
  errorTools: {
    padding: 1,
    width: '3rem',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor:'pointer',
    background: 'red'
  }
}));

function ToolBar(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { designId,IsdesignPublished } = props || {};
  const root = useSelector(state => state.scene.entityByIds[state.scene.rootId]);
  const cmpts = useSelector(state => state.scene.componentByIds)
  const ents = useSelector(state => state.scene.entityByIds)
  const assetIds = useSelector(state => state.scene.assetIds)
  const isUploading = useSelector(state => state.assetBrowser.isUploading);
  const deletedAssets = useSelector(state => state.scene.deletedAssets);
  const [Ispublish,setPublish] = useState(IsdesignPublished);
  const [isPublishFail, setIsPublishFail] = useState(false);

  const [saveState, setSaveState] = useState({
    shouldUpdatePublicFolderAfterUpdatingPrivateFolder: false,
    shouldSave: false,
  })

  const handleManualUpdateEntities = () => {
    updateEntities();
  }

  const removeAssets = () => {
    deletedAssets.forEach( assetFileName => {
      api.remove(`editor/asset/${designId}/${assetFileName}`)
        .then ( () => {
          dispatch(emptyDeletedAssets({asset_filename: assetFileName}));
        })
        .catch( error => {
          dispatch(showErrorSnackbar(error));
          console.log(error);
        })
    });
  }
    
  useEffect(() => {
    if(saveState.shouldSave){
      if (!isUploading) {
        removeAssets(); //Remove Deleted Assets from S3
        const assetsToSave = {};
        assetIds.forEach(assetId => {
          const asset = getAssetById(assetId);
          if (asset.type === OgmoConsts.AssetType.MESH) {
            const meshAsset = MeshAsset();
            meshAsset.id = asset.id;
            meshAsset.data.thumbnail = asset.data.thumbnail;
            meshAsset.data.type = asset.data.type;
            meshAsset.data.count = asset.data.count;
            meshAsset.data.min = asset.data.min;
            meshAsset.data.max = asset.data.max;
            meshAsset.data.meshPath = asset.data.meshPath;
            meshAsset.data.primitiveType = (asset.data.primitiveType) ? asset.data.primitiveType : '';
    
            assetsToSave[assetId] = meshAsset;
          } else {
            assetsToSave[assetId] = asset;
          }
        });
        const sceneJson = {
          root: root,
          entities: ents,
          components: cmpts,
          assets: assetsToSave
        }
        const doSaveScene = () => {
          api.post('editor/scene', { id: designId, scene: sceneJson, public: saveState.shouldUpdatePublicFolderAfterUpdatingPrivateFolder })
            .catch(error => {
              setIsPublishFail(true);
              dispatch(showErrorSnackbar('Scene Not Saved or Published! Try Again.'));
              console.log(error);
            });
        }
        const doPublish = () => {
          const newPublishedStatus = !Ispublish;
          api.put(`designs/${designId}`,{ published: newPublishedStatus })
            .then(response => dispatch(showSuccessSnackbar('Published and Scene Saved Success')),setPublish(true)) // eslint-disable-line no-unused-vars
            .catch(error => {
              setIsPublishFail(true);
              dispatch(showErrorSnackbar('Error while moving the design to public state.'));
              console.log(error);
            });
        }
                
        setIsPublishFail(false);
        if(saveState.shouldUpdatePublicFolderAfterUpdatingPrivateFolder && !Ispublish){
          doSaveScene();
          doPublish();
        }
        else{
          api.post('editor/scene', { id: designId, scene: sceneJson, public: saveState.shouldUpdatePublicFolderAfterUpdatingPrivateFolder })
            .then(response => dispatch(showSuccessSnackbar('Scene Saved Success'))) // eslint-disable-line no-unused-vars
            .catch(error => {
              setIsPublishFail(true);
              dispatch(showErrorSnackbar('Scene Not Saved! Try Again.'));
              console.log(error);
            });
        }
             
      }
      else {
        dispatch(showSuccessSnackbar('Assets are being uploaded'));
      }
      setSaveState({
        shouldUpdatePublicFolderAfterUpdatingPrivateFolder: false,
        shouldSave: false
      })
    }
  },[saveState.shouldSave])

  const handleSave = () => {
    setSaveState({
      shouldUpdatePublicFolderAfterUpdatingPrivateFolder: false,
      shouldSave: true
    })
  }

  const handleSaveAndPublish = () => {
    dispatch(showInfoSnackbar('This operation will take time. Please wait.'));
    setSaveState({
      shouldUpdatePublicFolderAfterUpdatingPrivateFolder: true,
      shouldSave: true
    })
  }

  const handlePreview = () => {
    var url = `${api.ViewerHost}/?design_id=${designId}`;
    var win = window.open(url, '_blank');
    win.focus();
  }

  const handleOpenRefImages = () => {
    dispatch(setOpenImageBox(true));
  }
  
  return (
    <Grid
      className={classes.toolBar}
      item
    >
      <Box className={classes.tools}>
        <img
          alt=""
          src={logo}
        />
      </Box>
      <Divider />
      <Box
        className={classes.tools}
        onClick={handleSave}
      >
        <img
          alt=""
          src={save}
        />
      </Box>
      <Divider />
      <Box
        className={classes.tools}
        onClick={handleManualUpdateEntities}
      >
        <img
          alt=""
          src={update}
        />
      </Box>
      <Divider />
      <Box
        className={isPublishFail ? classes.errorTools : classes.tools}
        onClick={handleSaveAndPublish}
      >
        <img
          alt=""
          src={publish}
        />
      </Box>
      <Divider />
      <Box
        className={classes.tools}
        onClick={handlePreview}
      >
        <img
          alt=""
          src={preview}
        />
      </Box>
      <Divider />
      <Box
        className={classes.tools}
        onClick={handleOpenRefImages}
      >
        <img
          alt=""
          src={refImage}
        />
      </Box>
      <Divider />
    </Grid>
  );
}

export default ToolBar;
