import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, Drawer } from '@material-ui/core';
import { SidebarNav } from './components';
import Dashboard from '../../../../../images/SupeAdminDashboard.png';
import Organizations from '../../../../../images/SuperAdminOrganization.png';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 220,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    background: 'linear-gradient(to right bottom, #33456E, #1F3974 )',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  nav: {
    marginBottom: theme.spacing(2),
    color: 'white'
  },
  iconImg: {
    width: 25,
    height: 25
  },
  ogmoLogo: {
    width: 60,
    height: 15,
    marginBottom: 30
  },
  orgName: {
    marginTop: 'auto',
    minWidth: 200
  }
}));

const Sidebar = ({ open, variant, onClose, className, ...rest }) => {
  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <img
        alt=""
        className={classes.iconImg}
        src={Dashboard} />
    },
    {
      title: 'Organizations',
      href: '/organizations',
      icon: <img
        alt=""
        className={classes.iconImg}
        src={Organizations} />
    }
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <Box
        alignItems="center"
        display="flex"
        {...rest}
        className={clsx(classes.root, className)}
      >
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />

        <Box className={classes.orgName}>
          {/* <OrganizationName /> */}
        </Box>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
