import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import OnBlurOnEnterInput from '../OnBlurOnEnterInput.js';
import OnCommitedSliderInput from '../OnCommitedSliderInput';
import { updateCameraComponentData } from 'ogmo_core';

const useStyles = makeStyles({
  cameraFieldInputField: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '6px 5px 3px 8px',
    height: '25px',
    width: '60px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function CameraFieldOfView({ cameraComponent }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handlChangeCameraFOV = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 180) {
      value = 180;
    }
    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: value === '' ? '' : Number(value),
      updateField: 'fov'
    }
    ));
  };

  const handleDragStop = (event, newValue) => {
    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: Number(newValue),
      updateField: 'fov'
    }
    ));
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
    >
      <Box width="45%">
        <Typography variant="caption">Field of View</Typography>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexGrow={1}
        flexWrap="nowrap"
        width="55%"
      >
        <Box className={classes.cameraFieldInputField}>
          <OnBlurOnEnterInput
            inputValue={cameraComponent.data.fov}
            onPress={handlChangeCameraFOV}
          />
        </Box>
        <OnCommitedSliderInput
          handleDragStop={handleDragStop}
          max={180}
          min={0}
          sliderInputValue={cameraComponent.data.fov}
        />
      </Box>
    </Box>
  );
}
