import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import ColorPickerInputBase from '../../ColorPickers/ColorPickerInputBase';
import { updateCameraComponentData } from 'ogmo_core';

export default function CameraColor({ cameraComponent }) {
  const dispatch = useDispatch();

  const handlChangeCameraColor = event => {
    const Hex2RGBA = (hex, alpha = 1) => {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      const R = r / 255;
      const G = g / 255;
      const B = b / 255;
      return [R, G, B, alpha];
    };

    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: Hex2RGBA(event.target.value),
      updateField: 'clearColor'
    }
    ));
  };
  const cameraColorValue = cameraComponent.data.clearColor;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
    >
      <Box width="45%">
        <Typography variant="caption">Color</Typography>
      </Box>
      <Box
        ml={1}
        width="55%"
      >
        <ColorPickerInputBase
          colorValue={cameraColorValue}
          handleChangeColorPicker={handlChangeCameraColor}
        />
      </Box>
    </Box>
  );
}
