import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import * as api from "../services/api";
import { useDispatch } from "react-redux";
import { setUserOrganizationId } from "reducers/dashboardSlice";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import FaceIcon from "@material-ui/icons/Face";
import EmailIcon from "@material-ui/icons/Email";
import { showErrorSnackbar } from "reducers/snackbarSlice";
import Checkbox from "@material-ui/core/Checkbox";
import S3DetailInfoIconWithPopover from "./../components/S3DetailInfoIconWithPopover";
import CreateOrgConfirmationDialog from "./../components/CreateOrgConfirmationDialog";
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomSnackbar from 'components/SnackBar/CustomSnackbar';

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
  },
  text2: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  margin: {
    margin:theme.spacing(2)
  },
  userLabel: {
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
  },
  userValue: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(5),
    fontWeight: "bold",
  },
  button: {
    marginLeft: theme.spacing(4),
  },
}));

const refreshLocalUserSesssion = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) {
        reject("Refreshing user session failed.");
      }
      const {
        idToken: { payload },
      } = session;
      const orgId = payload["custom:organization"];
      resolve(orgId);
    });
  });
};

const CustomTextField = ({
  id, name, style, formik
}) => {
  return (
    <TextField
      style={style}
      id={id}
      label={id}
      name={name}
      fullWidth
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
    />
  );
};

const ERROR_VALID_ORG_NAME = "Enter a valid organization name"
const ERROR_VALID_BUCKET_NAME = "Enter a valid S3 bucket name"
const ERROR_VALID_CDN = "Enter a valid cdn url"
const ERROR_HTTPS_CDN = "CDN url should contain https://"
const ERROR_ARN = "Enter a valid ARN"
const ERROR_REGION = "Enter a valid region"

const validate = ({
  organizationName,
  s3BucketName,
  cdnUrl,
  roleArn,
  region,
  useOwnBucket,
}) => {
  let errors = {};
  if (!organizationName) {
    errors.organizationName = ERROR_VALID_ORG_NAME;
  }
  if (useOwnBucket) {
    if (!s3BucketName) {
      errors.s3BucketName = ERROR_VALID_BUCKET_NAME;
    }
    if (!cdnUrl) {
      errors.cdnUrl = ERROR_VALID_CDN;
    } else if (!cdnUrl.includes("https://")) {
      errors.cdnUrl = ERROR_HTTPS_CDN;
    }
    if (!roleArn) {
      errors.roleArn = ERROR_ARN;
    }
    if (!region) {
      errors.region = ERROR_REGION;
    }
  }
  return errors;
};

const CreateOrganization = (props) => {
  const dispatch = useDispatch();
  const { setOrganization } = props;
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [confirmationOpened, setConfirmationOpened] = useState(false);
  const [orgCreationInProgress, setOrgCreationInProgress] = useState(false);

  useEffect(() => {
    Auth.currentSession().then((data) => {
      const payload = data.idToken.payload;
      const given_name = payload.name;
      const given_email = payload.email;
      setUsername(given_name);
      setEmail(given_email);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      organizationName:"",
      s3BucketName: '',
      cdnUrl:"",
      roleArn:"",
      region:"",
      useOwnBucket:false,
    },
    validate: validate,
    onSubmit: (values) => {
      setConfirmationOpened(c => !c)
    },
  });

  const createNewOrganization = () => {
    const {
      organizationName,
      s3BucketName,
      cdnUrl,
      roleArn,
      region,
      useOwnBucket,
    } = formik.values;

    let params = { organization_name: organizationName };
    if (useOwnBucket) {
      params = {
        organization_name: organizationName,
        cloudfront_url: cdnUrl,
        assumerole_arn: roleArn,
        bucket_name: s3BucketName,
        region: region,
      };
    }
    return api.post("organization", params);
  };

  const continueWithNewOrganization = (newOrgId) => {
    setOrganization([newOrgId]);
    dispatch(setUserOrganizationId(newOrgId));
  };

  const createOrganization = async () => {
    setOrgCreationInProgress(c => !c)
    try {
      await createNewOrganization();
      const newOrgId = await refreshLocalUserSesssion();
      continueWithNewOrganization(newOrgId);
    } catch (error) {
      dispatch(showErrorSnackbar(error.message || error));
      setOrgCreationInProgress(c => !c)
      console.log(error.message || error);
    }
  };

  const signOut = () => {
    Auth.signOut();
  };

  return (
    <Box>
      <CustomSnackbar />
      <CreateOrgConfirmationDialog
        createOrganization={createOrganization}
        checked={formik.values.useOwnBucket}
        open={confirmationOpened}
        handleClose={() => setConfirmationOpened((c) => !c)}
        orgCreationInProgress={orgCreationInProgress}
      />
      <Alert className={classes.margin} icon={false} severity="info">
        <Box alignItems="center" display="flex" m={1}>
          <FaceIcon />
          <Typography className={classes.userLabel}>UserName:</Typography>
          <Typography className={classes.userValue}>{username}</Typography>
          <EmailIcon />
          <Typography className={classes.userLabel}>E-mail:</Typography>
          <Typography className={classes.userValue}>{email}</Typography>
        </Box>
      </Alert>

      <Card className={classes.margin}>
        <CardContent>
          <Typography variant="h5">
            I want to work as a Designer for an existing organization
          </Typography>
          <Divider />
          <Typography className={classes.text2}>
            please send your user name , e-mail address to the admin of the
            organization you want to join. Ask the admin to add you as a
            collaborator. You must logout and login to the OGMO to takes place
            this change.
          </Typography>
          <Box mt={2}>
            <Button
              className={classes.button}
              color="primary"
              onClick={signOut}
              variant="contained"
            >
              sign out
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Card className={classes.margin}>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="h5">
              I want to create an Organization and get onboard
            </Typography>
            <CustomTextField
              id="Organization name"
              name="organizationName"
              formik={formik}
            />
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.useOwnBucket}
                    onChange={formik.handleChange}
                    name="useOwnBucket"
                    color="primary"
                  />
                }
                label="Add Amazon S3 Bucket details(Optional)"
              />
              <Box display="flex">
                <S3DetailInfoIconWithPopover />
              </Box>
            </Box>
            {formik.values.useOwnBucket && (
              <>
                <Typography variant="h6">
                  Add your own Amazon S3 Bucket details below.
                </Typography>
                <Box display="flex">
                  <CustomTextField
                    id="S3 Bucket name"
                    name="s3BucketName"
                    formik={formik}
                  />
                  <CustomTextField
                    id="Bucket(CloudFront) Url"
                    name="cdnUrl"
                    formik={formik}
                    style={{ marginLeft: 8 }}
                  />
                </Box>
                <Box display="flex">
                  <CustomTextField
                    id="AssumeRole ARN for Client S3 Bucket"
                    name="roleArn"
                    formik={formik}
                  />
                  <CustomTextField
                    id="Region"
                    name="region"
                    formik={formik}
                    style={{ marginLeft: 8 }}
                  />
                </Box>
              </>
            )}
            <Box mt={2}>
              <Button color="primary" type="submit" variant="contained">
                Create Organization
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreateOrganization;
export {
  validate,
  ERROR_VALID_ORG_NAME,
  ERROR_VALID_BUCKET_NAME,
  ERROR_VALID_CDN,
  ERROR_HTTPS_CDN,
  ERROR_ARN,
  ERROR_REGION,
};
