import React, { useReducer } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Input,
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableCell,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as api from 'services/api';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames'
import { showErrorSnackbar, showSuccessSnackbar } from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 550
  },
  titleOfAssign: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 550,
    marginLeft:'30px'
  },
  titleOfNoResult: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 550,
    marginLeft: '32px'
  },
  button: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '10px',
    textTransform: 'capitalize',
    marginLeft: theme.spacing(1),
    '&:hover': { backgroundColor: '#24739C' }
  },
  buttonForAdd: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '10px',
    textTransform: 'capitalize',
    marginLeft:'30px',
    '&:hover': { backgroundColor: '#24739C' }
  },
  selectedItemFromList: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    '&:hover': { backgroundColor: '#24739C' }
  },
  inputField: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    display: 'flex',
    width: 200
  },
  input: {
    fontSize: 12
  },
  divider: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: 5
  },
  loader: {
    color: '#3183ae',
  },
}));

const initialState = {
  keyword: '',
  usersList: [],
  isUserListLoading: false,
  isUserAdding: false,
  selectedUserIndex: null
};

const ACTIONS = {
  SET_KEYWORD: 'setKeyword',
  SET_USERS_LIST: 'setUsersList',
  SET_IS_USER_LIST_LOADING: 'setIsUserListLoading',
  SET_IS_USER_ADDING: 'setIsUserAdding',
  SET_SELECTED_USER_INDEX: 'setSelecetedUserIndex'
};

const {
  SET_KEYWORD,
  SET_USERS_LIST,
  SET_IS_USER_LIST_LOADING,
  SET_IS_USER_ADDING,
  SET_SELECTED_USER_INDEX
} = ACTIONS;

function reducer(state, action) {
  switch (action.type) {
    case SET_KEYWORD:
      return { ...state, keyword: action.value };
    case SET_USERS_LIST:
      return { ...state, usersList: action.value };
    case SET_IS_USER_LIST_LOADING:
      return { ...state, isUserListLoading: action.value };
    case SET_IS_USER_ADDING:
      return { ...state, isUserAdding: action.value };
    case SET_SELECTED_USER_INDEX:
      return { ...state, selectedUserIndex: action.value};
    default:
      throw new Error();
  }
}

const AddCollaborators = () => {
  const classes = useStyles();
  const history = useHistory();
  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(reducer, initialState);

  const SearchUser = () => {
    dispatch({ type: SET_USERS_LIST, value: [] });
    dispatch({ type: SET_SELECTED_USER_INDEX, value: null});
    dispatch({ type: SET_IS_USER_LIST_LOADING, value: true });
    api
      .get(`users?keyword=${state.keyword}`)
      .then(res => {
        if (res.data.length === 0) {
          dispatch({ type: SET_IS_USER_LIST_LOADING, value: false });
        }
        dispatch({ type: SET_USERS_LIST, value: res.data });
        dispatch({ type: SET_IS_USER_LIST_LOADING, value: false });
      })
      .catch(error => { // eslint-disable-line no-unused-vars
        dispatch({ type: SET_IS_USER_LIST_LOADING, value: false });
      });
  };

  const handleEnterKeyPress = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      SearchUser();
    }
  };

  const AddUserToOrganization = event => {
    dispatch({ type: SET_IS_USER_ADDING, value: true });
    api
      .post('users', { user_email: event })
      .then(res => {
        reduxDispatch(showSuccessSnackbar(res.message));
        dispatch({ type: SET_IS_USER_ADDING, value: false });
      })
      .catch(error => {
        dispatch({ type: SET_IS_USER_LIST_LOADING, value: false });
        dispatch({ type: SET_IS_USER_ADDING, value: false });
        reduxDispatch(showErrorSnackbar(error.message));
        console.log(error);
      });
  };

  const handleKeyword = event => {
    dispatch({ type: SET_KEYWORD, value: event.target.value });
  };

  const handleSelectedUser = (element,index) => {
    dispatch({ type: SET_SELECTED_USER_INDEX, value : index})
  };

  const handleCollaboratorBreadcrumbClick = event => {
    event.preventDefault();
    history.push('/collaborators')
  };

  const isAUserSelected = !(state.selectedUserIndex === null);

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleCollaboratorBreadcrumbClick}
        >
          Collaborators
        </Link>
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
          Add Collaborators
        </Typography>
      </Breadcrumbs>
      <Box p={4}>
        <Card>
          <CardContent className={classes.card}>
            <Box
              disabled
              display="flex"
              flexDirection="column"
              mx={4}
            >
              <Typography
                className={classes.title}
                variant="subtitle1"
              >
                Add Collaborators
              </Typography>
              <Box
                alignItems="center"
                display="flex"
                mb={5}
              >
                <Paper className={classes.inputField}>
                  <SearchIcon
                    color="disabled"
                    fontSize="small"
                  />
                  <Input
                    autoFocus
                    className={classes.input}
                    disableUnderline
                    disabled={state.isUserAdding}
                    onChange={handleKeyword}
                    onKeyPress={handleEnterKeyPress}
                    placeholder="Search by Email"
                  />
                </Paper>
                <Button
                  className={classes.button}
                  disabled={!state.keyword.trim() || state.isUserAdding}
                  onClick={SearchUser}
                  variant="contained"
                >
                  Search
                </Button>
              </Box>
            </Box>

            
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mb={5}
              mx={4}
              width="50%"
            >
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>

                  {(!state.isUserListLoading || state.usersList.length > 0) && (
                    <>
                      {state.usersList.map((element,index) => {
                        const indexOfSelectedUser = state.selectedUserIndex === index;
                        return (
                          <TableBody>
                            <TableRow
                              hover
                              onClick={() => handleSelectedUser(element,index)}
                            >
                              <TableCell className={classNames({[`${ classes.selectedItemFromList}`]: indexOfSelectedUser})}>{element}</TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </>
                  )}
                </Table>
              </Paper>
              {state.isUserListLoading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  pt={2}
                >
                  <CircularProgress className={classes.loader}/>
                </Box>
              )}
            </Box>

            {(state.usersList.length === 0 && !state.isUserListLoading) && (
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
              >
                <Typography
                  className={classes.titleOfNoResult}
                  variant="h6"
                >
                  No Result Found.
                </Typography>
              </Box>
            )}

            {(isAUserSelected) && (
              <Button
                className={classes.buttonForAdd}
                disabled={state.isUserAdding}
                onClick={() => AddUserToOrganization(state.usersList[state.selectedUserIndex])}
                size="small"
                variant="contained"
              >
                Add Collaborators
              </Button>
            )}
            
            {state.isUserAdding && (
              <Typography
                className={classes.titleOfAssign}
                variant="h6"
              >
                User is being assigned
              </Typography>
            )}

          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default AddCollaborators;
