import { OgmoConsts } from '../constants/consts';
import { createUUID } from '../core/Utils';

export const MaterialAsset = () => ({
	type: OgmoConsts.AssetType.MATERIAL,
	name: "Default Material",
	id: createUUID(),
	data: {
		kind: OgmoConsts.MaterialType.LIT,
		clearCoat: 1.0, // 0 or 1
		clearCoatRoughness: 0.0, // [0 ... 1]
		reflectance: 0.5, // Prefer values > 0.35
		albedoMap: "",
		albedoColor: [1, 1, 1], // Pre-multiplied linear RGB
		normalMap: "",
		aormMap: "",
		aoMap: "",
		roughnessMap: "",
		metallicMap: "",
		roughnessValue: 1.0, // [0 ... 1]
		metallicValue: 1.0, // 0 or 1
		transparent: false,
		opacity: 0.2
	}
});