import { Mesh } from 'webgl-obj-loader';
import { MeshAsset } from '../assets/MeshAsset';
import { calculateBoundingBox } from '../core/Utils';

const loadObj = (data, name) => {
	const mesh = new Mesh(data);
	var asset = null;
	if (mesh) {
		asset = MeshAsset();
		asset.name = name;

		asset.data.index = [];

		for (var key in mesh.indicesPerMaterial) {
			asset.data.index = asset.data.index.concat(mesh.indicesPerMaterial[key]);
		}
		asset.data.count = asset.data.index.length;

		asset.data.normals = mesh.vertexNormals;
		asset.data.vertices = mesh.vertices;
		asset.data.texcoord0 = mesh.textures;

		const bbox = calculateBoundingBox(asset.data.vertices);
		asset.data.min = bbox.min;
		asset.data.max = bbox.max;

	} else {
		alert("Error Loading Obj");
	}
	return asset;
}

export { loadObj }
