import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoader from 'super_admin/components/CircularLoader';
import * as api from '../../../src/services/api';
import { showErrorSnackbar, showSuccessSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 600,
    color: '#1F3974'
  },
  blueTypography: {
    fontWeight: 600,
    color: '#1F3974'
  }
}));

const BlockUnBlock = ({ organization_id, setIsBlocked }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isBlockBySuperAdmin, setBlockBySuperAdmin] = useState(false);
  const [isBlockByAdmin, setBlockByAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [blockConfirm, setBlockConfirm] = useState('');
  const [orgName, setOrgName] = useState({});

  useEffect(() => {
    setIsLoading(true);
    api.get(`superadmin/organization/${organization_id}`)
      .then(res => {
        setBlockBySuperAdmin(res.data.org_blocked_by_superadmin);
        setBlockByAdmin(res.data.org_blocked_by_admin);
        setOrgName(res.data.name);
        setIsLoading(false);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error));
        console.log(error);
      });
  }, [])

  const handleBlock = () => {
    if (orgName == blockConfirm) {
      setIsLoading(true);
      api.put(`superadmin/block?organization_id=${organization_id}&isToBlock=${!isBlockBySuperAdmin}`)
        .then(() => {
          setBlockBySuperAdmin(!isBlockBySuperAdmin);
          setIsBlocked(!isBlockBySuperAdmin, isBlockByAdmin);
          setIsLoading(false);
          dispatch(showSuccessSnackbar(!isBlockBySuperAdmin ? 'Successfully Blocked the Organization' : 'Successfully UnBlocked the Organization'));
        })
        .catch(error => {
          dispatch(showErrorSnackbar(error));
          console.log(error);
        });
      setBlockDialogOpen(false);
    }
    else {
      setBlockDialogOpen(false);
      dispatch(showErrorSnackbar('Invalid Organization Name'));
    }
  };

  const handleClickOpen = () => {
    setBlockDialogOpen(true);
  };

  const handleClose = () => {
    setBlockDialogOpen(false);
  };

  return (
    <>
      {!isLoading && <Card className={classes.margin}>
        <CardContent>
          {!isLoading && <Box
            ml={4}
            mt={2}
          >
            <Typography
              className={classes.title}
              variant="subtitle2"
            >{isBlockBySuperAdmin ? 'UNBLOCK' : 'BLOCK'} THE ORGANIZATION</Typography>
          </Box>
          }
          <Box
            mb={2}
            ml={4}
          >
            <Typography>The Organization is currently: {isBlockBySuperAdmin ? 'BLOCKED' : 'UNBLOCKED'}</Typography>
          </Box>
          <Box ml={4}>
            <Button
              className={classes.button}
              onClick={handleClickOpen}
              variant="contained"
            >
              {isBlockBySuperAdmin ? 'UNBLOCK' : 'BLOCK'}
            </Button>
            <Dialog
              aria-labelledby="form-dialog-title"
              onClose={handleClose}
              open={blockDialogOpen}
            >
              <DialogTitle id="form-dialog-title">{isBlockBySuperAdmin ? 'UnBlock' : 'Block'}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {`To ${isBlockBySuperAdmin ? 'UnBlock' : 'Block'} the Organization, please enter the Organization name`}
                </DialogContentText>
                <TextField
                  autoFocus
                  fullWidth
                  id="name"
                  label="Organization Name"
                  margin="dense"
                  onChange={(e) => setBlockConfirm(e.target.value)}
                  type="text"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={handleBlock}
                >
                  {isBlockBySuperAdmin ? 'UnBlock' : 'Block'}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </CardContent>
      </Card>
      }
      {isLoading && <CircularLoader />}
    </>);
};
export default BlockUnBlock;
