import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, Button, CardContent, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as api from 'services/api';
import {useSelector} from 'react-redux' ;
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { showErrorSnackbar , showSuccessSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  card: {
    margin: 100,
    width: '75%'
  },
  input: {
    marginBottom: 10,
    flex: '1 1 100%'
  },
  button: {
    marginTop: 20,
    width: '140px',
    height: '45px',
    color: 'white',
    fontSize: 14,
    borderRadius: 3,
    backgroundColor: '#3e8eb8',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  loader: {
    color: '#3183ae'
  }
});

export default function AssignDesignToProduct(props) {
  const classes = useStyles();
  const history = useHistory();
  const [isAssignLoading, setIsAssignLoading] = useState(false);
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);
  const ecommerceHosts = useSelector(state => state.ecommerceProducts.ecommerceHosts);
  const selectedEcommerce = useSelector(state => state.ecommerceProducts.selectedEcommerce);
  const dispatch = useDispatch();

  const {
    OGMODesignId,
    productSKU,
    productName,
    productOriginalId,
    productUrlKey,
    OGMODesignName
  } = props.location.state;

  const assignDesignToProducts = () => {
    setIsAssignLoading(true);
    const data = {
      design_id: OGMODesignId,
      design_name:OGMODesignName,
      sku: String(productSKU),
      product_name: productName,
      organization_id: organizationId,
      original_product_id: String(productOriginalId),
      url_key: productUrlKey,
      host: ecommerceHosts[selectedEcommerce].host,
    };

    api.post('products/assign',data)
      .then(res => { // eslint-disable-line no-unused-vars
        setIsAssignLoading(false); 
        dispatch(showSuccessSnackbar('Successfully Assigned'));
        history.push('/assign/chooseProducts');
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        history.push('/assign/chooseProducts')
        console.log(error);
      });
  };

  const handleProductsBreadcrumbClick = event => {
    event.preventDefault();
    history.push('/assign/chooseProducts')
  };

  const handleDesignBreadcrumbClick = event => {
    event.preventDefault();
    history.push({
      pathname: 'chooseDesigns',
      state: {
        productName: productName,
        OGMODesignId: OGMODesignId,
        productSKU: productSKU
      }
    });
  }

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small"/>}
      >
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleProductsBreadcrumbClick}
        >
          Products
        </Link>
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleDesignBreadcrumbClick}
        >
          Link Product
        </Link>
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
          Assign Design
        </Typography>
      </Breadcrumbs>
      <Card className={classes.card}>
        <CardContent>
          <Box my={2}>
            <Typography variant="subtitle1">
              <b>Product Name:</b> {productName}
            </Typography>
            <Typography
              className={classes.input}
              variant="subtitle1"
            >
              <b> Product SKU: </b> {productSKU}
            </Typography>
            <Typography variant="subtitle1">
              <b>Design Name:</b>
              {OGMODesignName}
            </Typography>
            <Typography variant="subtitle1">
              <b>OGMO Design Id:</b>
              {OGMODesignId}
            </Typography>
          </Box>
          {!isAssignLoading && (
            <Button
              className={classes.button}
              color="primary"
              onClick={assignDesignToProducts}
              variant="contained"
            >
              Assign
            </Button>
          )}
          {isAssignLoading && (
            <Box
              display="flex"
              justifyContent="center"
              pt={2}
            >
              <CircularProgress className={classes.loader} />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
