import React from "react";
import PropTypes from "prop-types";
import { Switch, Redirect, Route } from "react-router-dom";
import RouteWithLayout from "./components/RouteWithLayout/RouteWithLayout";
import { Main as MainLayout } from "./layouts";
import Designs from "./views/Designs/Designs";
import Editor from "./views/Editor/Editor";
import CreateSingleDesign from "./views/CreateSingleDesign";
import CreateGroupDesign from "./views/CreateGroupDesign";
import EditDesign from "./views/EditDesign";
import Settings from "./views/Settings/Settings";
import ShopifyAuthCallback from "./views/Settings/ShopifyAuthCallback";
import AddCollaborators from "views/Collaborators/AddCollaborators";
import Collaborators from "views/Collaborators/Collaborators";
import AuthorizeMagento1 from "views/Collaborators/AuthorizeMagento1";
import Assign from "views/Assign";
import ReferenceImages from "components/CreateDesign/ReferenceImages";

const designer = "designer";
const orgAdmin = "admin";

const allRoutes = [
  {
    allow: [designer, orgAdmin],
    component: Designs,
    exact: true,
    layout: MainLayout,
    path: "/designs",
  },
  {
    allow: [designer, orgAdmin],
    component: Editor,
    exact: true,
    path: "/editor",
  },
  {
    allow: [designer, orgAdmin],
    component: CreateSingleDesign,
    exact: true,
    layout: MainLayout,
    path: "/createSingleDesign",
  },
  {
    allow: [designer, orgAdmin],
    component: EditDesign,
    exact: true,
    layout: MainLayout,
    path: "/editDesign",
  },
  {
    allow: [designer, orgAdmin],
    component: CreateGroupDesign,
    exact: true,
    layout: MainLayout,
    path: "/createGroupDesign",
  },
  {
    allow: [designer, orgAdmin],
    component: ReferenceImages,
    exact: true,
    layout: MainLayout,
    path: "/referenceImages",
  },
  {
    allow: [designer, orgAdmin],
    component: Settings,
    exact: true,
    layout: MainLayout,
    path: "/settings",
  },
  {
    allow: [orgAdmin],
    component: Collaborators,
    exact: true,
    layout: MainLayout,
    path: "/collaborators",
  },
  {
    allow: [orgAdmin],
    component: AddCollaborators,
    exact: true,
    layout: MainLayout,
    path: "/collaboraters/add",
  },
  {
    allow: [orgAdmin],
    component: Assign,
    path: "/assign",
  },
  {
    allow: [orgAdmin],
    component: AuthorizeMagento1,
    exact: true,
    path: "/authorizeMagento1",
  },
  {
    allow: [orgAdmin],
    component: ShopifyAuthCallback,
    exact: true,
    layout: MainLayout,
    path: "/shopify/:app_type/auth/callback",
  },
];

const filterByPermissionLevel = (permissionsLevel) => {
  return allRoutes.filter((item) => item.allow.includes(permissionsLevel));
};

const Routes = ({ permissionsLevel }) => {
  const filteredRoutes = filterByPermissionLevel(permissionsLevel).map(
    ({ component, exact, layout, path }, index) => {
      if (layout) {
        return (
          <RouteWithLayout
            component={component}
            exact={exact}
            layout={layout}
            path={path}
            key={index}
          />
        );
      } else {
        return (
          <Route component={component} exact={exact} path={path} key={index} />
        );
      }
    }
  );

  return (
    <Switch>
      {filteredRoutes}
      <Redirect from="/" to="/designs" key="redirect" />
    </Switch>
  );
};

Routes.propTypes = {
  permissionsLevel: PropTypes.oneOf(["admin", "designer"]),
};

export default Routes;
export { filterByPermissionLevel };
