import React from 'react';
import {
  Box,
  TableRow,
  Table,
  TableHead,
  TableCell, 
  TableBody
} from '@material-ui/core';
import ProductsTableRow from './ProductsTableRow';

export default function ProductsTable(props) {
  const {
    ecommerceProductsInTheStore,
    showId,
    showLinkedDesign,
    organizationId,
    searchByNameAndSKU,
    showPreviewButton
  } = props; 
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="5%" />
            <TableCell width="30%">Product Name</TableCell>
            <TableCell width="20%">SKU</TableCell>
            {showLinkedDesign && <TableCell width="20%">Linked Design</TableCell>}
            <TableCell />
            {showPreviewButton && <TableCell width="15%" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {ecommerceProductsInTheStore.length > 0 ?
            <>
              {ecommerceProductsInTheStore.map((element, index) => {
                return (
                  <ProductsTableRow
                    element={element}
                    key={index}
                    organizationId={organizationId}
                    searchByNameAndSKU={searchByNameAndSKU}
                    showId={showId}
                    showLinkedDesign={showLinkedDesign}
                    showPreviewButton={showPreviewButton}
                  />
                );
              })}
            </> : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{ textAlign: 'center' }}
                >{
                    showLinkedDesign ? 'No linked products' : 'No products'
                  }</TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </Box>
  );
}
