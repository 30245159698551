import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import EnhancedTableHead from './EnhancedTableHead';
import DesignTableRow from './DesignsTableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from 'react-redux' ;
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TablePagination,
  Box
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  loader: {
    color: '#3183ae',
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tablePagination: {
    fontSize: 12
  }
}));

const getAValidPageNumber = (pages) => {
  return (pages-1) >= 0 ? (pages-1) : 0
}

const DesignsTable = ({
  designs,
  showactions,
  showCheckBox,
  temporarySelectedDesignsForTheGroup,
  handleCheckBoxClick,
  showPreviewIcon,
  showType,
  showDate,
  rowsPerPage,
  isDesignsAreLoading,
  setRowsPerPage,
  handlePageChange,
  loadDesigns,
}) => {
  const classes = useStyles();

  const count = useSelector(state => state.dashboard.designResponseTotal);
  const pages = useSelector(state => state.dashboard.designResponseCurrentPage);
  const page = getAValidPageNumber(pages); // to prevent MUI warning 

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const published = showactions ? 'Published' : null;
  const blockedStatus = showactions ? 'Blocked Status' : null;

  if (isDesignsAreLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center">
        <CircularProgress
          className={classes.loader}
          data-test="design-loader"
          disableShrink/>
      </Box>
    );
  } else {
    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <Box>
              <Table data-test="design-table">
                <EnhancedTableHead
                  blockedStatus={blockedStatus}
                  published={published}
                  showCheckBox={showCheckBox}
                  showPreviewIcon={showPreviewIcon}
                  showactions={showactions}
                />
                {!isDesignsAreLoading && (
                  <TableBody>
                    {designs.map((design, index) => {
                      return (
                        <DesignTableRow
                          design={design}
                          handleCheckBoxClick={handleCheckBoxClick}
                          index={index}
                          key={design.id}
                          loadDesigns={loadDesigns}
                          showCheckBox={showCheckBox}
                          showDate={showDate}
                          showPreviewIcon={showPreviewIcon}
                          showType={showType}
                          showactions={showactions}
                          temporarySelectedDesignsForTheGroup={
                            temporarySelectedDesignsForTheGroup
                          }
                        />
                      );
                    })}
                  </TableBody>
                )}
              </Table>
              {isDesignsAreLoading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  m={4}
                >
                  <CircularProgress
                    className={classes.loader}
                    disableShrink
                  />
                </Box>
              )}
            </Box>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            backIconButtonProps={{
              'aria-label': 'previous page',
              'data-test' : 'previous-page'
            }}
            className={classes.tablePagination}
            component="div"
            count={count ? count : 0}
            nextIconButtonProps={{
              'aria-label': 'next page',
              'data-test' : 'next-page'
            }}
            onChangePage={(event, page) => handlePageChange(page)}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    );
  }
};

export default DesignsTable;
