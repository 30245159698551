import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Typography,
  Box,
  MenuItem,
  IconButton,
  Menu
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import tempImg from '../../../../../images/user-icon.png'
import { useSelector } from 'react-redux';
import { showErrorSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'fit-content'
  },
  avatar: {
    width: 40,
    height: 40
  },
  Typography: {
    marginLeft: theme.spacing(1)
  },
  button: {
    width: 20,
    marginLeft: 10,
    '&:hover': { backgroundColor: 'white' }
  }
}));

export default function Profile({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userImageURL = useSelector(state => state.dashboard.userImageURL);
  const [cognitoUsername, setCognitoUsername] = useState('');
  const [position, setPosition] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userProfileImg = useSelector(state => state.organizationAndUser.userProfileImg);

  useEffect(() => {
    Auth.currentSession()
      .then(data => {
        const payload = data.idToken.payload;
        const given_name = payload.email;
        const cognito_groups = payload['cognito:groups'];
        const is_superadmin = cognito_groups.includes('SuperUser');
        const is_admin = cognito_groups.includes('Admin');
        const is_designer = cognito_groups.includes('Designer');
        const Role = is_superadmin ? 'SuperAdmin' : is_admin ? 'Admin' : is_designer ? 'Designer' : 'Not Defined';
        (setCognitoUsername(given_name));
        setPosition(Role);
      })
  }, [userImageURL]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    Auth.signOut()
      .then(() => {
        // setSignOut({authState: 'signIn'});
        // dispatch(ResetDashboardSlice());
        // dispatch(ResetOgmoDesignSlice());
      })
    // eslint-disable-next-line
      .catch(e => {
        dispatch(showErrorSnackbar('SignOut Failed'));
      });
    handleClose();
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={userProfileImg ? userProfileImg : tempImg}
        to="/settings"
      />
      <Box
        className={classes.Typography}
        display="flex"
        flexDirection="column"
      >
        <Typography
          data-test="header-profile-name"
          variant="subtitle2"
        >{cognitoUsername}</Typography>
        <Typography variant="caption">{position}</Typography>
      </Box>
      <IconButton
        className={classes.button}
        onClick={handleClick}
      >
        <ArrowDropDownIcon
          color="action"
          fontSize="small"
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="long-menu"
        onClose={handleClose}
        open={open}
      >
        <MenuItem onClick={signOut}>Sign Out</MenuItem>
      </Menu>
    </Box>
  );
}