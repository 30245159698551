import React from 'react';
import { Box,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextureAssetPicker from '../AssetPickers/TextureAssetPicker';
import ExpansionSection from '../ExpansionSection';
import InspectorPropertyPreviewHook from '../InspectorPropertyPreviewHook';
import InspectorPropertyPreview from '../InspectorPropertyPreview';
import OnBlurOnEnterInput from '../ComponetInspector/OnBlurOnEnterInput';
import { useSelector, useDispatch } from 'react-redux';
import OnCommitedSliderInput from 'components/ComponetInspector/OnCommitedSliderInput';
import { updateMaterialAssetData } from 'ogmo_core';

const useStyles = makeStyles({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '0 0 3px 10px',
    height: '25px',
    width: '50px',
    marginLeft: '20px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  },
  channel: {
    fontWeight: 600
  }
});

export default function Roughness() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mapType = 'roughnessMap';

  const selectedAsset = useSelector(
    state => state.scene.assetByIds[state.scene.selectedAssetId]
  );

  const handleDragStop = (event, newValue) => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'roughnessValue',
      value: Number(newValue)
    }
    ));
  };

  const handleInputChange = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 1) {
      value = 1;
    }
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      updateField: 'roughnessValue',
      value: value === '' ? '' : Number(value)
    }
    ));
  };

  const [
    textureAssetPicker,
    handleOpenTextureAssetPicker,
    handleCloseTextureAssetPicker,
    assetMap
  ] = InspectorPropertyPreviewHook(mapType);

  return (
    <ExpansionSection sectionName="Roughness">
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        pt={2}
        width="100%"
      >
        <InspectorPropertyPreview
          assetMap={assetMap}
          handleOpenTextureAssetPicker={handleOpenTextureAssetPicker}
          mapType={mapType}
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          mb={1}
        >
          <Typography
            className={classes.channel}
            variant="caption"
          >Channel: G</Typography>
        </Box>
        {(
          <Box
            display="flex"
            mt={3}
          >
            <Box width="30%">
              <Typography variant="caption">Roughness</Typography>
            </Box>
            <Box
              ml={1}
              width="40%"
            >
              <OnCommitedSliderInput
                handleDragStop={handleDragStop}
                max={1}
                min={0}
                sliderInputValue={selectedAsset.data.roughnessValue}
                step={0.1}
              />
            </Box>
            <Box
              className={classes.input}
              width="30%"
            >
              <OnBlurOnEnterInput
                inputValue={selectedAsset.data.roughnessValue}
                max={1}
                min={0}
                onPress={handleInputChange}
                step={0.05}
              />
            </Box>
          </Box>
        )}
      </Box>
      {textureAssetPicker.openTextureAssetPicker && (
        <TextureAssetPicker
          handleCloseAssetPicker={handleCloseTextureAssetPicker}
          openAssetPicker={textureAssetPicker.openTextureAssetPicker}
          type={textureAssetPicker.type}
        />
      )}
    </ExpansionSection>
  );
}
