import React from 'react';
import { Button, Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  button: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '10px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  }
}));

const SaveButton = ({ handleSubmit }) => {
  const classes = useStyles();
  let location = useLocation();

  const buttonLabel =
    location.pathname === '/createGroupDesign'
      ? 'Create Group'
      : 'Create Design';

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      mb={4}
      mt={0}
    >
      <Button
        className={classes.button}
        color="primary"
        onClick={handleSubmit}
        type="submit"
        variant="contained"
      >
        {buttonLabel}
      </Button>
    </Box>
  );
};
export default SaveButton;
