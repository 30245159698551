import React from 'react';
import AssetPicker from './AssetPicker';
import { useDispatch } from 'react-redux';
import { OgmoConsts } from 'ogmo_core';
import { updateMeshComponentData } from 'ogmo_core';

const MaterialAssetPicker = props => {
  const { openAssetPicker, handleCloseAssetPicker, materialProperty } = props;
  const dispatch = useDispatch();

  const handleChoosedMaterialAssetId = choosedMaterialAssetId => {

    dispatch(updateMeshComponentData({
      componentId: materialProperty.id,
      updateField: 'materialAssetId',
      value: choosedMaterialAssetId
    }))
    handleCloseAssetPicker();
  };

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedMaterialAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.MATERIAL]}
    />
  );
};
export default MaterialAssetPicker;
