import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import OnBlurOnEnterInput from '../ComponetInspector/OnBlurOnEnterInput';
import OnCommitedSliderInput from 'components/ComponetInspector/OnCommitedSliderInput';
import { updateMaterialAssetData } from 'ogmo_core';

const useStyles = makeStyles({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '0 0 3px 10px',
    height: '25px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  },
  title: {
    height: '15px',
    fontSize: '12px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#575757',
  }
});

export default function AormMap({selectedAsset}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const material_data = selectedAsset.data;
  
  const handleDragStop = (event, newValue) => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      value: Number(newValue),
      updateField: 'opacity'
    }
    ));
  };

  const handleOpacityChange = value => {
    if (value < 0) {
      value = 0;
    } else if (value > 1) {
      value = 1;
    }
    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      value: value === '' ? '' : Number(value),
      updateField: 'opacity'
    }
    ));
  };

  return (
    <Box ml={2}>
      <Box
        alignItems="flex-start"
        display="flex"
        mb={2}
        mt={2}
      >
        <Box width="30%">
          <Typography className={classes.title}>Opacity</Typography>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          flexGrow={1}
          flexWrap="nowrap"
          width="50px"
        >
          <Box width={100}>
            <OnCommitedSliderInput
              handleDragStop={handleDragStop}
              max={1}
              min={0}
              sliderInputValue={selectedAsset.data.opacity ? selectedAsset.data.opacity : 0.2}
              step={0.1}
            />
          </Box>
          <Box
            className={classes.input}
            width="30%"
          >
            <OnBlurOnEnterInput
              inputValue={material_data.opacity ? selectedAsset.data.opacity : 0.2}
              max={1}
              min={0}
              onPress={handleOpacityChange}
              step={0.05}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
