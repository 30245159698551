import React, { useEffect, useState, useRef } from 'react';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';

import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/index.css';
import Routes from './Routes';
import AdminRoutes from './super_admin/AdminRoutes';

import { withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';
import { BrowserRouter } from 'react-router-dom';
import CreateOrganization from './views/CreateOrganization';
import ChooseOrganization from './views/ChooseOrganization';
import {useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ResetDashboardSlice, setUserImage, setUserOrganizationId, setUserPhoneNumber } from './reducers/dashboardSlice';
import { ResetEcommerceProducts, setEcommerceHosts, setSelectedEcommerce} from './reducers/ecommerceProductsSlice';
import * as api from 'services/api';
import CustomSnackbar from './components/SnackBar/CustomSnackbar';
import { setOrgName, setLogo, setDesignLimit, setS3Details, setOrgBlockedBySuperAdmin, setOrgBlockedByAdmin } from './reducers/organizationAndUserSlice';
import { showErrorSnackbar} from 'reducers/snackbarSlice';
import '@aws-amplify/ui/dist/style.css';

import { ResetOgmoDesignSlice } from 'reducers/ogmoDesignsSlice';

Amplify.configure(aws_exports);
Auth.configure();

const App = () => {
  const dispatch = useDispatch();
  const [displayInitializingMessage, setDisplayInitializingMessage] = useState(false);
  const [organizationInitialized, setOrganizationInitialized] = useState(false);
  const [userInitialized, setUserInitialized] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [organization, setOrganization] = useState([]);
  const permissionsLevelRef = useRef()
  const userOrganizationId = useSelector(state => state.dashboard.userOrganizationId);
  const localyStoredData = localStorage.getItem('selectedHost');
  const isAValueAlreadySetInLocalStorageUponInitialization = (localyStoredData != '');

  useEffect(() => {
    //Nothing
    return () => { // onSignout App component get unmounted
      dispatch(ResetEcommerceProducts());
      dispatch(ResetDashboardSlice());
      dispatch(ResetOgmoDesignSlice());    }
  }, [])

  useEffect(() => {
    const fetchEcommerceHosts = () => {
      api.get('platforms')
        .then((res) => {
          let linkedPlatforms = {}
          let selectedHostInitializeFromLocalStorage = ''
          res.data.forEach((ecommerceSite) => {
            let host = ecommerceSite.host;
            let platform = ecommerceSite.platform;
            let version = ecommerceSite.version;
            linkedPlatforms[host] = { host, platform, version }

            if (isAValueAlreadySetInLocalStorageUponInitialization) {
              if (ecommerceSite.host == localyStoredData) {
                selectedHostInitializeFromLocalStorage = localyStoredData
              }
            }
          })
          dispatch(setSelectedEcommerce(selectedHostInitializeFromLocalStorage));
          dispatch(setEcommerceHosts(linkedPlatforms));
          setDisplayInitializingMessage(true);
        })
        .catch(error => {
          dispatch(showErrorSnackbar('Failed to load linked eCommerce platforms'))
          console.log(error.message || error);
        });
    }

    Auth.currentSession()
      .then(cognitoUser => {
        const { idToken: { payload } } = cognitoUser
        if(payload['cognito:groups']){      
          const is_superuser = payload['cognito:groups'].includes('SuperUser');
          if (is_superuser){
            setIsSuperUser(true)
            return false
          }
        }   
        if (payload['custom:organization']) {
          let str = payload['custom:organization']
          let organizationList = str.split(',')
          let userRole = payload['cognito:groups']
          setOrganization(organizationList);
          if (organizationList.length === 1) {
            dispatch(setUserOrganizationId(organizationList[0]));
          }
          if (userRole.includes('Admin')){
            permissionsLevelRef.current = "admin"
            fetchEcommerceHosts();
          } else {
            permissionsLevelRef.current = "designer"
            setDisplayInitializingMessage(true);
          }
        }
        else {
          setDisplayInitializingMessage(true);
        }
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message || error));
        console.log(error.message || error);
      })
  }, []);

  useEffect(() => {
    let cancelled = false;
    api
      .get("user")
      .then((user) => {
        if (!cancelled) {
          dispatch(setUserImage(user.img));
          dispatch(setUserPhoneNumber(user.telephone));
          setUserInitialized(true);
        }
      })
      .catch((err) => {
        if (!cancelled) {
          dispatch(showErrorSnackbar(err.message || err));
        }
        console.log(err.message || err);
      });
    return () => {
      cancelled = true
    }
  }, []);

  useEffect(() => {
    if (userOrganizationId) {
      api
        .get(`organization/${userOrganizationId}`)
        .then(data => {
          const s3Details = {
            cloudfront_url: data.cloudfront_url,
            roleArn: data.assumerole_arn,
            bucket: data.bucket_name,
            region: data.region  
          }
          dispatch(setS3Details(s3Details));
          dispatch(setOrgName(data.name));
          dispatch(setDesignLimit(data.designLimit));
          dispatch(setOrgBlockedBySuperAdmin(data.org_blocked_by_superadmin));
          dispatch(setOrgBlockedByAdmin(data.org_blocked_by_admin));
          if (!(data.logo == '' || data.logo.includes('default-organization-logo.png'))) {
            dispatch(setLogo(data.logo));
          }
          setOrganizationInitialized(true);
        })
        .catch(error => {
          dispatch(showErrorSnackbar(error.message || error));
          setOrganizationInitialized(false);
          console.log(error.message || error);
        });
    }
  }, [userOrganizationId]);

  const handleOrganizationCreation = (userOrganizations) => {
    permissionsLevelRef.current = "admin"
    setOrganization(userOrganizations)
  }

  if (isSuperUser){
    return(<ThemeProvider theme={theme}>
      <BrowserRouter>
        <AdminRoutes />
      </BrowserRouter>
      <CustomSnackbar/>
    </ThemeProvider>)
  }
  
  if (!displayInitializingMessage) {
    return(
      <div>
        <CustomSnackbar />
        App is still initializing. If you are continuously seeing this screen, it could be due to slow network
      </div>
    ) 
  } else if (organization.length === 0) {
    return <CreateOrganization setOrganization={handleOrganizationCreation} />;
  } else if (organization.length > 1 && !userOrganizationId) {
    return (<ChooseOrganization />);
  } else if (!organizationInitialized || !userInitialized) {
    return <CustomSnackbar />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes permissionsLevel={permissionsLevelRef.current}/>
        </BrowserRouter>
        <CustomSnackbar />
      </ThemeProvider>
    );
  }
}

const signUpConfig = {
  header: 'Create a new account',
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'Name',
      key: 'name',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password'
    },
    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 4,
      type: 'string'
    }
  ]
};
export default withAuthenticator(App, {
  // Render a sign out button once logged in
  // includeGreetings: true,
  // display federation/social provider buttons
  signUpConfig
});

// export default withAuthenticator(App, true);
//  export default App;