import React from 'react';
import AssetPicker from './AssetPicker';
import { useSelector, useDispatch } from 'react-redux';
import { OgmoConsts } from 'ogmo_core';
import { updateMeshComponentData } from 'ogmo_core';

const MeshAssetPicker = props => {
  const { openAssetPicker, handleCloseAssetPicker, meshProperty } = props;
  const assetByIds = useSelector(state => state.scene.assetByIds);
  const dispatch = useDispatch();

  const handleChoosedMeshAssetId = choosedMeshAssetId => {
    const hasChosenGlbAssetAsMesh = assetByIds[choosedMeshAssetId].type === OgmoConsts.AssetType.GLB
    dispatch(updateMeshComponentData({
      componentId: meshProperty.id,
      updateField: 'meshAssetId',
      value: choosedMeshAssetId
    }))
    dispatch(updateMeshComponentData({
      componentId: meshProperty.id,
      updateField: 'meshAssetType',
      value: hasChosenGlbAssetAsMesh ? OgmoConsts.AssetType.GLB : OgmoConsts.AssetType.MESH
    }))
    handleCloseAssetPicker();
  };

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedMeshAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.MESH,OgmoConsts.AssetType.GLB]}
    />
  );
};
export default MeshAssetPicker;
