import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, Drawer } from '@material-ui/core';
import { SidebarNav } from './components';
import { OrganizationName } from './components';
import Collaborators from '../../../../images/Collaborators.png';
import Products from '../../../../images/Products.png';
import Settings from '../../../../images/Settings.png';
import Designs from '../../../../images/Designs.png';
import { Auth } from 'aws-amplify';
import { showErrorSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
// import * as api from './../../../../services/api';
// import GitInformation from './../../../../GitInformation.json';
// import awsexports from './../../../../aws-exports.js';
// import { version } from './../../../../version.json';

import getVersion from './../../../../services/getVersion'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 220,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    background: 'linear-gradient(to right bottom, #33456E, #1F3974 )',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  nav: {
    marginBottom: theme.spacing(2),
    color: 'white'
  },
  iconImg: {
    width: 18,
    height: 18
  },
  ogmoLogo: {
    width: 60,
    height: 15,
    marginBottom: 30
  },
  orgName: {
    marginTop: 'auto',
    minWidth: 200
  }
}));

const Sidebar = ({ open, variant, onClose, className, ...rest }) => {
  const classes = useStyles();
  let newPages = [];
  const dispatch = useDispatch();
  const [appVersion, setAppVersion] = useState()

  const elementCollaborators = {
    title: 'Collaborators',
    href: '/collaborators',
    icon: <img
      alt=""
      className={classes.iconImg}
      src={Collaborators}
    />
  }

  const elementProducts ={
    title: 'Products',
    href: '/assign',
    icon: <img
      alt=""
      className={classes.iconImg}
      src={Products}
    />
  }

  const [pages, setPages] = useState([
    {
      title: 'Designs',
      href: '/designs',
      icon: <img
        alt=""
        className={classes.iconImg}
        src={Designs}
      />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <img
        alt=""
        className={classes.iconImg}
        src={Settings}
      />
    }
  ]
  );

  useEffect(() => {
    Auth.currentSession()
      .then(data => {
        const payload = data.idToken.payload;
        const cognito_groups = payload['cognito:groups'];
        const is_admin = cognito_groups.includes('Admin');
        if (is_admin){
          insertCollaboratorsAndProductsTabToTheSidebar();
        }
      })
      .catch(err => {
        dispatch(showErrorSnackbar(err))
        console.log(err)
      })
  }, [])

  useEffect(() => {
    getVersion()
      .then(({ GitInformation, data }) => {
        console.log("ogmo backend : ", data);
        console.log("3d editor : ", GitInformation);
        setAppVersion(`api version - ${data.branch}-${data.short_sha}`);
      })
      .catch((error) => {
        dispatch(showErrorSnackbar(error.message || error));
        console.log(error.message || error);
      });
  }, []);

  const insertCollaboratorsAndProductsTabToTheSidebar = () => {
    newPages = pages.slice()
    newPages.splice(2, 0, elementCollaborators,elementProducts);
    setPages([...newPages]);
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <Box
        alignItems="center"
        display="flex"
        {...rest}
        className={clsx(classes.root, className)}
      >
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />

        <Box className={classes.orgName}>
          <OrganizationName />
        </Box>
        <Typography
          color="secondary"
          variant="body2">
          {appVersion}
        </Typography>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
