import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OgmoConsts } from '../constants/consts';
import { useEffect } from "react";
import { getAssetById } from '../ecs/useAssets';
import { OgmoCache } from './Cache';

export const useAssetRenderer = () => {

    var renderer, camera, controls, scene, requestLoop, context;

    useEffect(() => {

        return () => {
            // Discard stuff here
            window.cancelAnimationFrame(requestLoop);
            renderer.dispose();
        }
    }, []);

    const renderAsset = async (canvas, assetId) => {

        const asset = getAssetById(assetId);

        scene = new THREE.Scene();

        const width = canvas.width = canvas.clientWidth;
	    const height = canvas.height = canvas.clientWidth;

        context = canvas.getContext('2d');

        renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setClearColor(0xFFFFFF, 1); // the default

        camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 10);
        controls = new OrbitControls(camera, canvas);
        controls.enableDamping = true;
        controls.autoRotate = true;
        controls.enableZoom = false;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        // set false here to prevent resize making the canvas big bug
        renderer.setSize(width, height, false);

        camera.updateProjectionMatrix();

        var light = new THREE.AmbientLight(0xFFFFFF, 1.0);
        scene.add(light);

        var topLight = new THREE.DirectionalLight(0xFFFFFF, 0.5);
        scene.add(topLight);

        // set false here to prevent resize making the canvas big bug
        renderer.setSize(width, height, false);

        // Call render before loading assets
        render();

        if (asset.type === OgmoConsts.AssetType.MATERIAL) {
            var geometry = new THREE.SphereGeometry(1, 32, 32);
            var material = await OgmoCache.getMaterial(asset.id);
            var sphere = new THREE.Mesh(geometry, material);
            scene.add(sphere);
        } else if (asset.type === OgmoConsts.AssetType.MESH) {
            createMeshAsset(asset);
            var bottomLight = new THREE.DirectionalLight(0xFFFFFF, 0.5);
            bottomLight.position.set(0, -1, 0);
            scene.add(bottomLight);
            // Dim the ambient light for mesh assets
            light.intensity = 0.3;
        }
        camera.position.z = 3;

    }

    const render = () => {
        controls.update();
        renderer.render(scene, camera);
        context.drawImage(renderer.domElement, 0, 0);
        requestLoop = requestAnimationFrame(render);
    }

    const createMeshAsset = (mesh_asset) => {
        const model_data = mesh_asset.data;

        const asset = new THREE.Mesh();
        const vertices = new Float32Array(model_data.vertices);
        const indices = new Uint32Array(model_data.index);
        const normals = new Float32Array(model_data.normals);
        const texcoord0 = new Float32Array(model_data.texcoord0);

        const geometry = new THREE.BufferGeometry();

        const positionNumComponents = 3;
        const normalNumComponents = 3;
        const uvNumComponents = 2;

        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, positionNumComponents));
        geometry.setAttribute('normal', new THREE.BufferAttribute(normals, normalNumComponents));
        geometry.setAttribute('uv', new THREE.BufferAttribute(texcoord0, uvNumComponents));
        geometry.setIndex(new THREE.BufferAttribute(indices, 1));
        asset.geometry = geometry;

        asset.material = new THREE.MeshPhongMaterial({
            color: 0xFFFFFF
        });

        // Scale asset to fit 1x1x1 box
        var x_diff = model_data.max[0] - model_data.min[0];
        var y_diff = model_data.max[1] - model_data.min[1];
        var z_diff = model_data.max[2] - model_data.min[2];

        var max_diff = Math.max(Math.max(x_diff, y_diff), z_diff) / 2;

        asset.scale.x /= max_diff;
        asset.scale.y /= max_diff;
        asset.scale.z /= max_diff;

        // Set center of orbit controls to center of gravity of object
        var x_center = ((model_data.max[0] + model_data.min[0]) / 2) / max_diff;
        var y_center = ((model_data.max[1] + model_data.min[1]) / 2) / max_diff;
        var z_center = ((model_data.max[2] + model_data.min[2]) / 2) / max_diff;

        controls.target = new THREE.Vector3(x_center, y_center, z_center);
        scene.add(asset);
    }

    return renderAsset;
}