import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isAnimageFile } from '../../utils/extensionHelpers';
import { useDispatch } from 'react-redux';
import { green, red } from '@material-ui/core/colors';
import { showErrorSnackbar, showSuccessSnackbar, showWarningSnackbar } from 'reducers/snackbarSlice';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  FormControl,
  Box,
  IconButton,
  TextField,
  Button,
  Tooltip
} from '@material-ui/core';
import * as api from '../../services/api';
import * as s3 from '../../services/s3';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 150,
    border: 'solid 1px #C4C4C4',
    background: '#ffffff',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  uploadBox: {
    minHeight: '400px',
    minWidth: '500px',
    border: 'dotted 2px'
  },
  image: {
    minWidth: '100px',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  displayImage: {
    position: 'relative'
  },
  cancelIcon: {
    position: 'absolute',
    right: '0px'
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: '#c7c9c9',
    textTransform: 'capitalize'
  },
  progressBar: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    color: '#3E8EB8',
    width: '25%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  doneIcon: {
    position: 'absolute',
    bottom: '0px',
    right: '0px'
  },
  errorText: {
    color: 'red'
  },
  clearIcon: {
    position: 'absolute',
    bottom: '0px',
    right: '0px'
  },
  imageSelectionButton: {
    background: '#c7c9c9',
    padding: '10px',
    width: '100px',
    marginBottom: theme.spacing(1),
    borderRadius: '5px'
  },
  uploadButton : {
    backgroundColor: '#3E8EB8',
    color: 'white',
    textTransform: 'initial',
    '&:hover': { backgroundColor: '#24739C' },
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const RefImageUploadDialog = ({
  open,
  onClose,
  designId,
  uploadedImageList
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [imageFiles, setImageFiles] = useState([]);
  const [refImageNames, setRefImageNames] = useState([]);
  const [failedImageNames, setfailedImageNames] = useState([]);
  const [uploadedImageCount, setUploadedImageCount] = useState(0);
  const [uploading, setUploading] = useState(false);
  let count = 0;

  const displayFile = event => {
    const files = event.target.files;
    if (files) {
      let imageFile = [...imageFiles];
      let ref_image_name = [...refImageNames];
      let index = 0;
      for (index = 0; index < files.length; index++) {
        if (isAnimageFile) {
          let name = files[index].name;
          let id = Date.now()+index;
          let file = files[index];
          let image = {
            file: file, 
            id: id, 
            uploadingState: 'initial'
          }
          imageFile = [...imageFile, image];
          ref_image_name = [...ref_image_name, { name: name }];
        }
      }
      setImageFiles(imageFile);
      setRefImageNames(ref_image_name);
    }
  }

  const uploadImages = () => {
    if (imageFiles.length >= 1) {
      setUploading(true);
      api
        .post(`refImagesUploadURLs/${designId}`, { 'referenceImageNames': refImageNames }) 
        .then(async data => {
          let imagesToSaveInDB = { };
          for (let i = 0; i < imageFiles.length; i++) {
            const image = imageFiles[i].file;
            const signUrl = await s3.uploadWithSignURL(
              data.signed_url[i],
              new Blob([image])
            );
            if (signUrl.message === 'Success') {
              let url = data.url[i];
              let name = refImageNames[i].name;
              imagesToSaveInDB[Date.now() + i] = { url, name };
              await addReferenceImageDetailsToDB(imagesToSaveInDB, imageFiles[i].id);
            } else {
              updateImageState(imageFiles[i].id, 'failed');
              dispatch(showErrorSnackbar('Failed to upload images. Please try again'));
              setfailedImageNames(oldList => {
                oldList.push(refImageNames[i]);
                return [...oldList];
              });
            }
          }
          setUploading(false);
        })
        .catch(error => {
          dispatch(showErrorSnackbar(error.message));
          console.log(error.message);
          setUploading(false);
          handleClose();
        });
    } else {
      dispatch(showWarningSnackbar('Please select images to upload'));
      setUploading(false);
    }
  }

  const addReferenceImageDetailsToDB = async (imagesToSaveInDB, imageId) => {
    //ToDo - Need to pass new image list instead of pass entire list
    let fullImagesListToUpload = { ...imagesToSaveInDB, ...uploadedImageList };
    try {
      await api.put(`addReferenceImageDetailsToDB/${designId}`, { refImageDetails: [fullImagesListToUpload] })
      count++;
      setUploadedImageCount(count);
      updateImageState(imageId, 'uploaded');
      if (Object.keys(imagesToSaveInDB).length == imageFiles.length) {
        dispatch(showSuccessSnackbar('Successfully uploaded the images'));
        handleClose();
        setUploading(false);
      }
    } catch (error) {
      dispatch(showErrorSnackbar(error.message));
      console.log(error.message);
      updateImageState(imageId, 'failed');
      let index = imageFiles.findIndex(item => item.id === imageId);
      setfailedImageNames(oldList => {
        oldList.push(refImageNames[index]);
        return [...oldList];
      });
      setUploading(false);
    }
  }

  const deleteFromSelectedList = (image) => {
    setRefImageNames(items => {
      items = items.filter(item => item.name !== image.file.name);
      return [...items];
    });
    setImageFiles(items => {
      items = items.filter(item => item.id !== image.id);
      return [...items];
    });
  }

  const editImageName = (imageId, event) => {
    let newImageName = event.target.value;
    setRefImageNames(items => {
      items[imageId].name = newImageName;
      return [...items];
    });
  }

  const updateImageState = (imageId, state) => {
    setImageFiles(items => {
      let index = items.findIndex(item => item.id === imageId);
      items[index].uploadingState = state;
      return [...items];
    })
  }

  const handleClose = () => {
    onClose();
    setImageFiles([]);
    setRefImageNames([]);
    setfailedImageNames([]);
    setUploadedImageCount(0);
  };
  return (
    <Dialog
      maxWidth="md"
      open={open}
    >
      <DialogTitle>Add Reference Images</DialogTitle>
      <DialogContent>
        {uploading && (
          <Box mb={1} >
            <Typography variant="caption">
              {uploadedImageCount + '/' + imageFiles.length} images
                                    successfully attached.
            </Typography>
            <LinearProgress
              className={classes.progressBar}
              value={(uploadedImageCount / imageFiles.length) * 100}
              variant="determinate"
            />{' '}
          </Box>
        )}
        <input
          className={classes.input}
          id="button-front-view"
          multiple
          onChange={displayFile}
          style={{ display: 'none' }}
          type="file"
        />
        <label htmlFor="button-front-view">
          {!uploading &&
                        <Typography className={classes.imageSelectionButton}>
                            Add Images
                        </Typography>
          }
        </label>
        <DialogContent className={classes.uploadBox}>
          {imageFiles.map((item, i) => (
            <FormControl
              className={classes.image}
              key={i}
            >
              <Box
                display="flex"
                position="relative"
              >
                <img
                  className={classes.displayImage}
                  height="150px"
                  src={URL.createObjectURL(item.file)}
                />
                <IconButton
                  className={classes.cancelIcon}
                  onClick={() => deleteFromSelectedList(item)}
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
                {item.uploadingState == 'uploaded' &&
                                    <DoneIcon
                                      className={classes.doneIcon}
                                      style={{ color: green[500] }}
                                    />
                }
                {item.uploadingState == 'failed' &&
                                    <ClearIcon
                                      className={classes.clearIcon}
                                      style={{ color: red[500] }}
                                    />
                }
              </Box>
              <Tooltip
                placement="bottom-start"
                title={item.file.name}
              >
                <TextField
                  defaultValue={item.file.name}
                  onChange={(event) => editImageName(i, event)}
                />
              </Tooltip>
            </FormControl>
          ))}
        </DialogContent>
        {failedImageNames.length >= 1 &&
                    <DialogContent>
                      <Typography
                        className={classes.errorText}
                      >
                            These images was not uploaded. Please try again.
                      </Typography>
                      <Box >
                        {failedImageNames.map((id, i) => (
                          <Typography
                            className={classes.errorText}
                            key={i}
                          >
                            {failedImageNames[i].name}
                          </Typography>
                        ))}
                      </Box>
                    </DialogContent>
        }
        <Typography>
          <Button
            className={classes.uploadButton}
            disabled={uploading}
            onClick={uploadImages}
          >
                        Upload and Save
          </Button>
          <Button
            className={classes.button}
            disabled={uploading}
            onClick={handleClose}
          >
                        Cancel
          </Button>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default RefImageUploadDialog;