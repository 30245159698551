import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ExpansionPanels from './ExpansionPanels';
import { useSelector,useDispatch } from 'react-redux';
import OnBlurOnEnterInput from '../../components/ComponetInspector/OnBlurOnEnterInput';
import MaterialAssetType from './MaterialAssetType';
import { OgmoConsts, updateAssetName } from 'ogmo_core';
import AssetRenderer from '../AssetInspector/AssetRenderer';

const useStyles = makeStyles({
  iconMaterial: {
    minHeight: '30px',
    background: '#e0e0e0',
    alignItems: ' center'
  },
  icon: {
    margin: '0 5px 0 10px'
  },
  material: {
    fontSize: 12
  },
  img: {
    width: '100%',
    height: '100%',
    backgroundColor: '#d6d6d6'
  },
  textureThumbnail: {
    height: '100%',
    width: '100%',
    backgroundSize: 'auto'
  },
  input: {
    paddingLeft: '5px',
    marginRight: '10px',
    maxWidth: '235px',
    height: '25px',
    backgroundColor: 'white',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function AssetInspector() {
  const classes = useStyles();

  const selectedAsset = useSelector(state => state.scene.assetByIds[state.scene.selectedAssetId]);
  const dispatch = useDispatch();

  const updateName = (value) =>{
    dispatch(updateAssetName({assetId:selectedAsset.id,name:value}))
  }
  return (
    <Box
      display="flex"  
      flexDirection="column"
      wrap="nowrap"
    >
      <Box
        className={classes.iconMaterial}
        display="flex"
      >
        <PlayCircleOutlineIcon
          className={classes.icon}
          color="action"
          fontSize="small"
        />
        <Typography
          className={classes.material}
          variant="subtitle2"
        >
          {selectedAsset.type}
        </Typography>
      </Box>
      <Box className={classes.img}>
        {(selectedAsset.type === 'materialAsset' || selectedAsset.type === 'meshAsset') && <AssetRenderer key={selectedAsset.id}/>}
        {selectedAsset.type === 'textureAsset' && (
          <img
            alt=""
            className={classes.textureThumbnail}
            src={selectedAsset.data.originalTextures[1]}
          />
        )}
        {selectedAsset.type === OgmoConsts.AssetType.ICON && (
          <img
            alt=""
            className={classes.textureThumbnail}
            src={selectedAsset.data.iconImage}
          />
        )}
      </Box>
      <Box ml={2}>
        <Box
          alignItems= "flex-start"
          display="flex"
          mb={2}
          mt={2}
        >
          <Box width="30%">
            <Typography variant="body1">
              ID
            </Typography>
          </Box>
          <Box width="70%">
            <Typography  variant="body1">
              {selectedAsset.id}
            </Typography>
          </Box>
        </Box>
        <Box
          alignItems= "flex-start"
          display="flex"
        >
          <Box width="30%">
            <Typography variant="body1">
              Name
            </Typography>
          </Box>
          <Box
            className={classes.input}
            width="70%"
          >
            <OnBlurOnEnterInput
              inputValue={selectedAsset.name}
              inputValueFontSize="12px"
              onPress={(e)=>updateName(e)}
              type="text"
            />
          </Box>
        </Box>
        {selectedAsset.type === OgmoConsts.AssetType.MATERIAL && <MaterialAssetType />}
      </Box>
      {selectedAsset.type === OgmoConsts.AssetType.MATERIAL && <ExpansionPanels />}
    </Box>
  );
}
