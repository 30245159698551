import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  orgName: '',
  logo: '/images/logos/organization.png',
  s3Details: {},
  orgBlockedBySuperAdmin: false,
  orgBlockedByAdmin: false
};

const organizationAndUserSlice = createSlice({
  name: 'organizationAndUser',
  initialState,
  reducers: {
    setOrgName(state, action) {
      state.orgName = action.payload;
    },
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setS3Details(state, action) {
      state.s3Details = action.payload;
    },
    setOrgBlockedBySuperAdmin(state, action) {
      state.orgBlockedBySuperAdmin = action.payload;
    },
    setOrgBlockedByAdmin(state, action) {
      state.orgBlockedByAdmin = action.payload;
    },
    setDesignLimit(state, action) {
      state.designLimit = action.payload;
    }
  }
});

export const {
  setOrgName,
  setLogo,
  setS3Details,
  setOrgBlockedBySuperAdmin,
  setOrgBlockedByAdmin,
  setDesignLimit
} = organizationAndUserSlice.actions

export default organizationAndUserSlice.reducer