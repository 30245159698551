import React, { useState } from 'react'
import {
  Typography,
  Button,
  Box,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as api from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { showErrorSnackbar, showSuccessSnackbar } from 'reducers/snackbarSlice';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(3),
  },
  buttonGetVender: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '10px',
    width: 100,
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' },
  },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#3E8EB8',
      color: 'white'
    }
  },
}))

function DokanMultiVenderPlatform(tempHost) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isGetVendersInProgress, setIsGetVendersInProgress] = useState(false);
  const [dokanStoreList, setDokanStoreList] = useState({})
  const [selectedVendorIndex, setSelectedVendorIndex] = useState(null);
  const [isLinkButtonClicked, setIsLinkButtonClicked] = useState(false);

  const organizationId = useSelector(
    (state) => state.dashboard.userOrganizationId
  );

  const getVenders = () => {
    setIsGetVendersInProgress(true)
    setDokanStoreList({})
    if (tempHost.tempHost.includes('https://')) {
      api.get(`${tempHost.tempHost}/wp-json/dokan/v1/stores/`)
        .then((data) => {
          let storesList = {}
          data.forEach(store => {
            let storeID = store.id
            let storeName = store.store_name
            storesList[storeID] = { storeID, storeName }
          });
          setDokanStoreList(storesList)
          setIsGetVendersInProgress(false)
        })
        .catch((error) => {
          console.error(error);
          dispatch(showErrorSnackbar('Unable to get Venders. Please try again'));
          setIsGetVendersInProgress(false)
        });
    } else {
      dispatch(showErrorSnackbar('Please enter a valid url.'));
      setIsGetVendersInProgress(false)
    }
        
  }

  const linkDokanPlatformToTheOrganization = () => {
    setIsLinkButtonClicked(true);
    if (tempHost.tempHost.includes('https://')){
      const hostdetails = tempHost.tempHost.split('//')
      const organizationData = `${organizationId}//${hostdetails[1]}//${selectedVendorIndex}`;
      const data = {
        consumer_key: '',
        consumer_secret: '',
        platform: 'dokan'
      }
      api.post(`woocommerce/authCallBack?orgData=${organizationData}`, data)
        .then((res) => {
          if (res.status === 200) {
            setIsLinkButtonClicked(false)
            dispatch(showSuccessSnackbar('E-Commerece Platform Linked Successfully'));
            window.location.reload(false);
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(showErrorSnackbar('Invalid request. Please try again'));
          setIsLinkButtonClicked(false)
        });
    }else {
      dispatch(showErrorSnackbar('Please enter a valid url.'));
      setIsLinkButtonClicked(false);
    }
  }

  const isTempHostNotEmpty = (tempHost.tempHost != '')

  return (
    <Box>
      {isTempHostNotEmpty && (
        <Button
          className={classes.buttonGetVender}
          disabled={isGetVendersInProgress}
          onClick={getVenders}
        >Get Venders</Button>
      )}
      { isGetVendersInProgress && (
        <Box
          display="flex"
          justifyContent="center"
          pt={2}
        >
          <CircularProgress className={classes.loader} />
        </Box>
      )}
      { (Object.keys(dokanStoreList).length != 0) && (
        <Typography required>
                    Sellect Vender Store To Link
        </Typography>
      )}
      {Object.keys(dokanStoreList).map((host, index) => (
        <MenuItem
          className={classes.tableRow}
          key={index}
          onClick={() => { setSelectedVendorIndex(dokanStoreList[host].storeID) }}
          selected={selectedVendorIndex === dokanStoreList[host].storeID}
          value={host}
        >{dokanStoreList[host].storeName}</MenuItem>
      ))}
      <Box mt={1} />
      <Button
        className={classes.buttonGetVender}
        disabled={isLinkButtonClicked}
        onClick={linkDokanPlatformToTheOrganization}
        variant="contained"
      >
                Link
      </Button>
      {isLinkButtonClicked && (
        <Box
          display="flex"
          justifyContent="center"
          pt={2}
        >
          <CircularProgress className={classes.loader} />
        </Box>
      )}
    </Box>
  )
}

export default DokanMultiVenderPlatform
