import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import OnBlurOnEnterInput from '../OnBlurOnEnterInput.js';
import { updateCameraComponentData } from 'ogmo_core';

const useStyles = makeStyles({
  InputField: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '6px 0 3px 3px',
    height: '25px',
    width: 50,
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function CameraAngleProperties({ cameraComponent }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const radians_to_degrees = radians => {
    var pi = Math.PI;
    return radians * (180 / pi);
  };

  const degrees_to_radians = degrees => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  const handlChangeMinPolarAngle = value => {
    if (value < 5) {
      value = 5;
    } else if (value > 90) {
      value = 90;
    }
      
    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: degrees_to_radians(value),
      updateField: 'minPolarAngle'
    }
    ));
  };

  const handlChangeMaxPolarAngle = value => {
    if (value < 90) {
      value = 90;
    } else if (value > 175) {
      value = 175;
    }

    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: degrees_to_radians(value),
      updateField: 'maxPolarAngle'
    }
    ));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Box
        alignItems="center"
        display="flex"
        flexWrap="nowrap"
      >
        <Box width="50%">
          <Typography variant="caption">Min Polar Angle</Typography>
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          width="50%"
        >
          <Box className={classes.InputField}>
            <OnBlurOnEnterInput
              inputValue={radians_to_degrees(
                cameraComponent.data.minPolarAngle
              )}
              max={90}
              min={5}
              onPress={handlChangeMinPolarAngle}
              step={1}
            />
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexWrap="nowrap"
      >
        <Box width="50%">
          <Typography variant="caption">Max Polar Angle</Typography>
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          width="50%"
        >
          <Box className={classes.InputField}>
            <OnBlurOnEnterInput
              inputValue={radians_to_degrees(
                cameraComponent.data.maxPolarAngle
              )}
              max={175}
              min={90}
              onPress={handlChangeMaxPolarAngle}
              step={1}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
