import React from 'react';
import { Box, Typography } from '@material-ui/core';
import TransformInput from './TransformInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    fontWeight: 550,
    color: '#575757'
  }
});

export default function Rotation() {
  const classes = useStyles();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
      mt={1}
    >
      <Box width="25%">
        <Typography
          className={classes.label}
          variant="body1"
        >
          Rotation
        </Typography>
      </Box>
      <TransformInput transformName={'rotation'} />
    </Box>
  );
}
