import React, { useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import * as api from 'services/api';
import { showErrorSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 550
  },
  button: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
  }
}));

const getUrlParams = (url) => {
  if (!url) url = window.location.search;
  const query = url.substr(1);
  const result = {};
  query.split('&').forEach(function (part) {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

const AuthorizeMagento1 = () => {
  const history = useHistory();
  const classes = useStyles();
  const params = getUrlParams();
  const dispatch = useDispatch();

  useEffect(() => {
    Auth.currentSession()
      .then(res => { // eslint-disable-line no-unused-vars
        const data = {
          'oauth_token': params.oauth_token,
          'oauth_verifier': params.oauth_verifier,
          'platform': 'magento',
          'platform_version': '1.x'

        }
        api.post('magento1x/callback', data)
      })
      .then(res => { // eslint-disable-line no-unused-vars
        history.push('/products')
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message))
        console.log(error)
      });
  }, [])

  return (
    <Card>
      <CardContent>
        <Typography
          className={classes.title}
          variant="h5"
        >
          callback api
        </Typography>
      </CardContent>
    </Card>
  );
};
export default AuthorizeMagento1;
