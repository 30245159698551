import React from 'react';
import { Box,Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import TextureAssetPicker from '../AssetPickers/TextureAssetPicker';
import ExpansionSection from 'components/ExpansionSection';
import InspectorPropertyPreviewHook from '../InspectorPropertyPreviewHook';
import InspectorPropertyPreview from '../InspectorPropertyPreview';
import ColorPickerInputBase from '../ColorPickers/ColorPickerInputBase';
import { updateMaterialAssetData } from 'ogmo_core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    marginTop: 16
  }
});

export default function AlbedoMap() {
  const dispatch = useDispatch();
  const mapType = 'albedoMap';
  const classes = useStyles();

  const selectedAsset = useSelector(
    state => state.scene.assetByIds[state.scene.selectedAssetId]
  );

  const handlChangeAlbedoMapColor = event => {
    const Hex2RGB = hex => {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      const R = r / 255;
      const G = g / 255;
      const B = b / 255;
      return [R, G, B];
    };

    dispatch(updateMaterialAssetData({
      assetId: selectedAsset.id,
      value: Hex2RGB(event.target.value),
      updateField: 'albedoColor'
    }
    ));
  };

  const [
    textureAssetPicker,
    handleOpenTextureAssetPicker,
    handleCloseTextureAssetPicker,
    assetMap
  ] = InspectorPropertyPreviewHook(mapType);

  const albedoColorValue = selectedAsset.data.albedoColor;
  return (
    <ExpansionSection sectionName="Albedo">
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        flexWrap="nowrap"
        pt={2}
      >
        <InspectorPropertyPreview
          assetMap={assetMap}
          handleOpenTextureAssetPicker={handleOpenTextureAssetPicker}
          mapType={mapType}
        />
        {!assetMap && (
          <ColorPickerInputBase
            colorValue={albedoColorValue}
            handleChangeColorPicker={handlChangeAlbedoMapColor}
          />
        )} 
        {assetMap && (
          <Typography
            className={classes.label}
            variant="caption"
          >Color is disabled</Typography>
        )} 
      </Box>
      {textureAssetPicker.openTextureAssetPicker && (
        <TextureAssetPicker
          handleCloseAssetPicker={handleCloseTextureAssetPicker}
          openAssetPicker={textureAssetPicker.openTextureAssetPicker}
          type={textureAssetPicker.type}
        />
      )}
    </ExpansionSection>
  );
}
