import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  Select
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { useSelector, useDispatch } from 'react-redux'
import * as api from '../../../services/api'
import {
  editGlobalHotspotName,
  setGlobalHotspotColor,
  setIconToGlobalHotspotComponent,
  closeGlobalHotspotEditPanel,
  setProductVariantToGlobalHotspotComponent,
  setComponentIdentifierType
} from 'ogmo_core';
import OnBlurOnEnterInput from './../../ComponetInspector/OnBlurOnEnterInput';
import MenuItem from '@material-ui/core/MenuItem';
import { showErrorSnackbar } from '../../../reducers/snackbarSlice';
import ColorPickerInputBase from '../.././ColorPickers/ColorPickerInputBase';
import GlobalHotspotIconPickerMain from './GlobalHotspotIconPickerMain';

const useStyles = makeStyles({
  body: {
    height: '100%',
  },
  titleBox: {
    minHeight: '30px',
    background: '#e0e0e0',
    alignItems: 'center'
  },
  title: {
    marginLeft: '10px'
  },
  icon: {
    margin: '0 5px 0 10px'
  },
  deleteIcon: {
    marginLeft: 'auto'
  },
  nameField: {
    backgroundColor: 'white',
    height: 25,
    paddingTop: 2,
    paddingLeft: 2
  },
  menu: {
    width: 180,
    backgroundColor: '#e6e6e6',
    height: 25,
    paddingTop: 2,
    paddingLeft: 2
  },
});

const EditGlobalHotspot = ({componentId,linkedProductIds}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalHotspotComponent = useSelector(state => state.scene.componentByIds[componentId]);
  const globalHotspotData = globalHotspotComponent.data;
  const globalHotspotColor = globalHotspotData.color;
  const globalHotspotIcon = globalHotspotData.iconAssetId;
  const selectedIdentifierType = globalHotspotData.identifierType
  const assignedProductVariants = globalHotspotData.assignedProductVariants
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);
  const [shopifyProductVariants,setShopifyProductVariants] = useState([])

  const assignedShopifyVariantValue = React.useMemo(
    () => {
      if(assignedProductVariants){
        const assignedShopifyVariantData = assignedProductVariants.find(variant => variant.platform === 'shopify')
        return 'shopify '+assignedShopifyVariantData.variantId 
      }
    },[assignedProductVariants],
  )

  useEffect(() => {
    linkedProductIds.map(linkedProduct => {
      switch(linkedProduct.platform){
        case 'shopify': 
          getProductVariantsByShopifyProdId(linkedProduct.productId)   
      }
    }) 
  },[])

  const getProductVariantsByShopifyProdId = (shopifyProductId) => {
    api.get(`shopify/products/${shopifyProductId}/variants?organization_id=${organizationId}`).then(
      res => {
        const variantData = res.data.variants.map(variant => (
          {
            id: variant.id,
            name: `${variant.option1 ? variant.option1 : ''} ${variant.option2 ? variant.option2 : ''} ${variant.option3 ? variant.option3 : ''}`
          }
        ))
        setShopifyProductVariants(variantData)
      }
    ).catch(err => {
      console.log(err)
      dispatch(showErrorSnackbar('Getting variants for the linked Shopify product failed'))
    })
  }

  const handleProductVariantSelect = (event) => {
    let [platform, variantId] = event.target.value.split(' ');
    
    dispatch(setProductVariantToGlobalHotspotComponent({
      platform: platform, 
      globalHotspotComponentId: componentId,
      variantId: variantId
    }))
  }

  const handleGlobalHotspotDisplayNameChange = (value) => {
    dispatch(editGlobalHotspotName({
      componentId: componentId,
      globalHotspotDisplayName: value
    }));
  }

  const closeEditGlobalHotspot = () => {
    dispatch(closeGlobalHotspotEditPanel());
  }

  const handleChange = (event) => {
    let selectedIdentifierType= event.target.value;
    if (selectedIdentifierType == 'No Identifier') {
      dispatch(setIconToGlobalHotspotComponent({
        globalHotspotComponentId: componentId,
        iconAssetId: ''
      }));
      dispatch(setGlobalHotspotColor({
        componentId: componentId,
        updateField: 'color',
        value: []
      }));
      dispatch(setComponentIdentifierType({
        componentId: componentId,
        updateField: 'identifierType',
        value: selectedIdentifierType
      }));
    }
    if (selectedIdentifierType == 'Icon') {
      dispatch(setGlobalHotspotColor({
        componentId: componentId,
        updateField: 'color',
        value: []
      }));
      dispatch(setComponentIdentifierType({
        componentId: componentId,
        updateField: 'identifierType',
        value: selectedIdentifierType
      }));
    }
    if (selectedIdentifierType == 'Color') {
      dispatch(setIconToGlobalHotspotComponent({
        globalHotspotComponentId: componentId,
        iconAssetId: ''
      }))
      dispatch(setComponentIdentifierType({
        componentId: componentId,
        updateField: 'identifierType',
        value: selectedIdentifierType
      }));
    }
  };

  const handlChangeGlobalHotspotColor = event => {
    const Hex2RGB = hex => {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      const R = r / 255;
      const G = g / 255;
      const B = b / 255;
      return [R, G, B];
    };

    dispatch(setGlobalHotspotColor({
      componentId: componentId,
      updateField: 'color',
      value: Hex2RGB(event.target.value)
    }));
  };

  return (
    <Box
      className={classes.body}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Box
        alignItems="center"
        className={classes.titleBox}
        display="flex"
      >
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          Edit Global Hotspot Details
        </Typography>
        <Button className={classes.deleteIcon}>
          <ArrowLeftIcon
            className={classes.icon}
            color="action"
            fontSize="small"
            onClick = {closeEditGlobalHotspot}
          />
        </Button>
      </Box>
      <Box ml={3}>
        <Box
          alignItems="center"
          display="flex"
          mb={2}
          mt={3}
        >
          <Box width="30%">
            <Typography variant="body2">
              Display Name
            </Typography>
          </Box>
          <Box
            className={classes.nameField}
            width="60%"
          >
            <OnBlurOnEnterInput
              inputValue={globalHotspotData.displayName}
              inputValueFontSize="12px"
              onPress={handleGlobalHotspotDisplayNameChange}
              type="text"
            />
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          mb={3}
          mt={3}
        >
          <Box width="30%">
            <Typography variant="body2">
              Set Identifier
            </Typography>
          </Box>
          <Box width="60%" >
            <Select
              className={classes.menu}
              onChange={handleChange}
              value={selectedIdentifierType}
            >
              <MenuItem value="No Identifier">No identifier</MenuItem>
              <MenuItem value="Icon">Icon</MenuItem>
              <MenuItem value="Color">Color</MenuItem>
            </Select>
          </Box>
        </Box>
       
        <Box>
          {(globalHotspotColor.length || selectedIdentifierType == 'Color') &&
            (<ColorPickerInputBase
              colorValue={globalHotspotData.color}
              handleChangeColorPicker={handlChangeGlobalHotspotColor}
            />)
          }
        </Box>
        <Box>
          {(globalHotspotIcon || selectedIdentifierType == 'Icon') &&
            <GlobalHotspotIconPickerMain globalHotspotComponent={globalHotspotComponent}/>
          }
        </Box>
      
        {/* Shopify product variant */}
        {shopifyProductVariants.length > 0 ?  (
          <Box
            mb={3}
            mt={3}
          >
            <Box>
              <Typography variant="body2">
                Shopify product variant
              </Typography>
            </Box>
            <Box>
              <Select
                className={classes.menu}
                onChange={handleProductVariantSelect}
                value={assignedShopifyVariantValue}
              >
                {shopifyProductVariants.map(variant => 
                  <MenuItem value={`shopify ${variant.id}`}>{variant.name}</MenuItem>
                )}
              </Select>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            height={50}
            justifyContent="center"
            pt={3}
          >
            <CircularProgress size={20}/>
          </Box> 
        )}
      </Box>
    </Box>
  );
}

export default EditGlobalHotspot;