import React, { useState } from 'react';
import {
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import {
  deleteTargetMeshMaterialOptionFromGlobalHotspot,
  deleteTargetMaterialAssetFromAnGlobalHotspotOption,
  deleteTargetMeshAssetFromAnGlobalHotspotOption,
  getAssetById
} from 'ogmo_core';
import GlobalHotspotMeshComponentSelection from './GlobalHotspotMeshComponentSelection';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import GlobalHotspotTargetMeshAssetPicker from 'components/AssetPickers/GlobalHotspotTargetMeshAssetPicker';
import GlobalHotspotTargetMaterialAssetPicker from 'components/AssetPickers/GlobalHotspotTargetMaterialAssetPicker';
import GlobalHotspotMeshTarget from './GlobalHotspotMeshTarget'
import GlobalHotspotMaterialTarget from './GlobalHotspotMaterialTarget'

const useStyles = makeStyles({
  menu: {
    width: 140,
    backgroundColor: '#D3D3D3',
    height: 25,
    paddingTop: 5,
    marginRight: 5,
    marginBottom: 20
  },
  nameTypography: {
    alignItems: 'center',
    color: '#575757',
    marginTop: 10
  },
  addTargetOptionButton: {
    marginTop: 20,
    display: 'flex',
    paddingLeft: '25px',
    alignItems: 'center'
  },
  button: {
    width: 250,
    alignSelf: 'center',
    borderRadius: 0,
    boxShadow: 'none',
    fontSize: 12,
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
    backgroundColor: '#e0e0e0',
    '&:hover': { boxShadow: 'none', backgroundColor: '#b5b5b5' }
  },
  buttonIcon: {
    backgroundColor: '#576077',
    color: 'white',
    borderRadius: 8
  },
  componentBody: {
    height: '100%',
    width: '100%',
  },
  Typography: {
    color: '#575757'
  },
  list: {
    backgroundColor: '#eeeeee',
    marginBottom: 8
  },
  addTargetOption: {
    height:'auto'
  },
  deleteIcon: {
    width: '10%',
    marginLeft: '94%'
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4
  }
});

const GlobalHotspotOptionList = ({ componentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalHotspotComponent = useSelector(state => state.scene.componentByIds[componentId]);
  const targetOptions = globalHotspotComponent.data.targetMeshMaterialOptions;
  
  const [openMeshAssetPicker, setOpenMeshAssetPicker] = useState({
    isVisible: false,
    optionId: ''
  });
  const [openMaterialAssetPicker, setOpenMaterialAssetPicker] = useState({
    isVisible: false,
    optionId: ''
  });

  const handleOpenMeshAssetPicker = optionId => {
    setOpenMeshAssetPicker({ isVisible: true, optionId: optionId });
  };

  const handleOpenMaterialAssetPicker = optionId => {
    setOpenMaterialAssetPicker({ isVisible: true, optionId: optionId });
  };

  const handleMeshCloseAssetPicker = () => {
    setOpenMeshAssetPicker({ isVisible: false, optionId: '' });
  };

  const handleMaterialCloseAssetPicker = () => {
    setOpenMaterialAssetPicker({ isVisible: false, optionId: '' });
  };

  const handleDeleteTargetMeshMaterialOption = optionId => {
    dispatch(
      deleteTargetMeshMaterialOptionFromGlobalHotspot({
        meshMaterialOptionId: optionId,
        globalHotspotComponentId: globalHotspotComponent.id
      })
    );
  };

  const handleDeleteTargetMaterialAsset = optionId => {
    dispatch(
      deleteTargetMaterialAssetFromAnGlobalHotspotOption({
        meshMaterialOptionId: optionId,
        globalHotspotComponentId: globalHotspotComponent.id
      })
    );
  };

  const handleDeleteTargetMeshAsset = optionId => {
    dispatch(
      deleteTargetMeshAssetFromAnGlobalHotspotOption({
        meshMaterialOptionId: optionId,
        globalHotspotComponentId: globalHotspotComponent.id
      })
    );
  };

  const getAssetName = asset => {
    return asset ? asset.name : '';
  };

  if (!targetOptions){
    return null
  }

  return (
    <Box className={classes.addTargetOption}>
      <List style={{ width: '100%' }}>
        {Object.keys(targetOptions).map((targetOption, index) => (
          <ListItem
            className={classes.list}
            key={index}
          >
            <Box className={classes.componentBody}>
              <IconButton
                className={classes.deleteIcon}
                onClick={() => handleDeleteTargetMeshMaterialOption(targetOptions[targetOption].optionId)}
              >
                <DeleteIcon
                  color="action"
                  fontSize="small"
                />
              </IconButton>
              <Box>
                <GlobalHotspotMeshComponentSelection
                  componentId={globalHotspotComponent.id}
                  optionId= {targetOptions[targetOption].optionId}
                  selectedMeshComponent = {(targetOptions[targetOption].targetMeshComponentId)}
                />
              </Box>
              <Box
                className={((targetOptions[targetOption].targetMeshComponentId)) ? ' ' : classes.disabled}
                display="flex"
              >
                <GlobalHotspotMeshTarget
                  handleDeleteTargetMeshAsset={() => handleDeleteTargetMeshAsset(targetOptions[targetOption].optionId)}
                  handleOpenMeshAssetPicker={() => handleOpenMeshAssetPicker(targetOptions[targetOption].optionId)}
                  meshAssetName={targetOptions[targetOption].targetMeshAssetId ? getAssetName(getAssetById(targetOptions[targetOption].targetMeshAssetId)) : '[empty]'}
                />
              </Box>
              <Box
                className={((targetOptions[targetOption].targetMeshComponentId)) ? ' ' : classes.disabled}
                display="flex"
              >
                <GlobalHotspotMaterialTarget
                  handleDeleteTargetMaterialAsset={() => handleDeleteTargetMaterialAsset(targetOptions[targetOption].optionId)}
                  handleOpenMaterialAssetPicker={() => handleOpenMaterialAssetPicker(targetOptions[targetOption].optionId)}
                  materialAssetName={targetOptions[targetOption].targetMaterialAssetId ? getAssetName(getAssetById(targetOptions[targetOption].targetMaterialAssetId)) : '[empty]'}
                />
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
      {openMeshAssetPicker.isVisible && (
        <GlobalHotspotTargetMeshAssetPicker
          handleCloseAssetPicker={handleMeshCloseAssetPicker}
          hotspotComponentId={globalHotspotComponent.id}
          openAssetPicker={openMeshAssetPicker.isVisible}
          optionId={openMeshAssetPicker.optionId}
        />
      )}
      {openMaterialAssetPicker.isVisible && (
        <GlobalHotspotTargetMaterialAssetPicker
          handleCloseAssetPicker={handleMaterialCloseAssetPicker}
          hotspotComponentId={globalHotspotComponent.id}
          openAssetPicker={openMaterialAssetPicker}
          optionId={openMaterialAssetPicker.optionId}
        />
      )}
    </Box>
  );
}
export default GlobalHotspotOptionList;





