import React from 'react';
import AlbedoMap from './AlbedoMap';
import NormalMap from './NormalMap';
import AmbientOcclusion from './AmbientOcclusion';
import Opacity from './Opacity';
import Roughness from './Roughness';
import Metallic from './Metallic';
import MaterialAssetOtherProperties from './MaterialAssetOtherProperties';
import { useSelector} from 'react-redux';
import { OgmoConsts } from 'ogmo_core';

export default function ExpansionPanels() {
  const selectedAsset = useSelector(
    state => state.scene.assetByIds[state.scene.selectedAssetId]
  );

  if(selectedAsset.data.kind === OgmoConsts.MaterialType.LIT){
    return(
      <>
        <AlbedoMap />
        <NormalMap />
        <AmbientOcclusion />
        <Roughness />
        <Metallic />
        <MaterialAssetOtherProperties />
      </>
    );
  }
  
  if(selectedAsset.data.kind === OgmoConsts.MaterialType.INVISIBLE_SHADOW){
    return (<Opacity
      selectedAsset={selectedAsset}
    />);
  }
}
