import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  designs: {},
  designRowsPerPage:'5'
};

const ogmoDesignsSlice = createSlice({
  name: 'ogmoDesigns',
  initialState,
  reducers: {
    setOGMODesigns(state, action) {
      state.designs = action.payload;
    },
    setDesignRowsPerPage(state, action) {
      state.designs = {};
      state.designRowsPerPage = action.payload
    },
    ResetOgmoDesignSlice(state, action) { // eslint-disable-line no-unused-vars
      Object.keys(state).forEach(key => {
        state[key] = initialState [key]
      })
    }
  }
});

export const { setOGMODesigns, setDesignRowsPerPage, ResetOgmoDesignSlice} = ogmoDesignsSlice.actions

export default ogmoDesignsSlice.reducer