import { OgmoConsts } from "../constants/consts";
import { createUUID } from '../core/Utils.js'

export const USDZComponent = (usdzAsset = { id: "" }) => ({
  id: createUUID(),
  needsUpdate :true,
  type:OgmoConsts.ComponentType.USDZ,
  name: "USDZ Component",
  data: {
    usdzAssetId: usdzAsset.id
  }
});