import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableContainer, TableCell, TableRow,
  TableHead, Paper, Typography, TablePagination, Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import CircularLoader from 'super_admin/components/CircularLoader';
import * as api from 'services/api';
import { showErrorSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 600,
    color: '#1F3974'
  },
  button: {
    display: 'block',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [orgStatistics, setOrgStatistics] = useState([]);
  const [orgDesignViews, setOrgDesignViews] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const classes = useStyles();
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, orgStatistics.length - page * rowsPerPage);

  useEffect(() => {
    setIsLoading(true);
    api.get('superadmin/organizations/all')
      .then(res => {
        const org_data = res.data.map((org) => {
          return { 'id': org.organization_id, 'name': org.name };
        })
        api.post('analytics/report',{})
        .then((res) => {
          const data = res.map(organization => {
            return { ...organization, organization_id: organization.organization_id.replace(/ORG#|ORG_|ORG/gi, '').replace(/_/gi, '-'), organization_name: org_data.find(org => org.id == organization.organization_id.replace(/ORG/gi, 'ORG#').replace(/_/gi, '-')).name };
          })
            setIsLoading(false);
            setOrgStatistics(data);
            setOrgDesignViews(data.map(organization => parseInt(organization.count)).reduce((a, b) => a + b, 0));
        })
          .catch(error => {
            dispatch(showErrorSnackbar('Statistics were failed to load'));
            console.log(error);
          });
      }).catch(error => {
        dispatch(showErrorSnackbar('Organizations were failed to load'));
        console.log(error);
      });
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Box p={4}>
        <Typography className={classes.title} > Super Admin Dashboard</Typography>
      </Box>
      {!isLoading && <Box
        display="flex"
        flexDirection="column"
        p={4}
      >
        <Typography
          className={classes.title}
          component={'span'}
        >Total Design Views : {orgDesignViews}</Typography>
      </Box>
      }
      {!isLoading && <Box p={4} />
      }
      {isLoading && <CircularLoader />}
      {!isLoading && <Box p={4}>
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell className={classes.blueTypography}>Organization Name</TableCell>
                  <TableCell
                    align="right"
                    className={classes.blueTypography}
                  >Total View Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orgStatistics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.blueTypography}>{data.organization_name}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.blueTypography}
                    >{data.count}</TableCell>

                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={orgStatistics.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
          /></>
      </Box>
      }
    </>);
};
export default AdminDashboard;
