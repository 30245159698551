import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Avatar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import tempOrgImg from '../../../../../images/organization.png';

const useStyles = makeStyles(theme => ({
  media: {
    paddingTop: theme.spacing(2),
    height: 80,
    textAlign: 'center',
    paddingBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(1, 1)
  },
  image: {
    borderRadius: 0,
    height: 50,
    width: 50
  },
  name: {
    fontSize: 18,
    fontWeight: 500,
    color: 'white',
  },
  org: {
    color: '#ececec',
    fontSize: 10
  }
}));

const OrganizationName = () => {
  const classes = useStyles();
  const logo = useSelector(state => state.organizationAndUser.logo);
  const orgName = useSelector(state => state.organizationAndUser.orgName);

  return (
    <Box
      alignItems="center"
      className={classes.media}
      display="flex"
      justifyContent="center"
    >
      <Avatar
        alt="org"
        className={classes.image}
        src={(logo) ? logo : tempOrgImg}
      />
      <Box className={classes.content}>
        <Typography
          align="left"
          className={classes.org}
          variant="body2"
        >
          Organization :
        </Typography>
        <Typography
          align="left"
          className={classes.name}
          data-test="sidebar-organization-name"
          variant="h6"
        >
          {orgName}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrganizationName;
