import React from 'react';
import { List, IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as api from 'services/api'
import { showErrorSnackbar, showSuccessSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const CollaboratersTableRow = ({ email,removeUserFromList }) =>{
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const options = ['Delete'];
  const dispatch = useDispatch();
    
  const handleTarget = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteUsers = (event) => {
    api.remove(`users/${event}`)
      .then(res => {
        dispatch(showSuccessSnackbar(res.message));
        handleClose();
        removeUserFromList(event);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message)
      });
  }

  return(
    <List>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        aria-label="more"
        data-test="more-vert-icon"
        onClick={handleTarget}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        data-test="long-menu"
        id="long-menu"
        keepMounted
        onClose={handleClose}
        open={open}
      >
        {options.map(option => (
          <MenuItem
            data-test={`${email.split('@')[0]}-delete-button`}
            key={option}
            onClick={() => deleteUsers(email)}
            selected={option === 'Delete'}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </List>
  )
}
export default CollaboratersTableRow;
