import React from 'react';
import { Box,Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Shadows from './Shadows';
import MeshComponentMeshProperty from './MeshComponentMeshProperty';
import MeshComponentMaterialProperty from './MeshComponentMaterialProperty';
import OnBlurOnEnterInput from '../OnBlurOnEnterInput';
import { useDispatch, useSelector } from 'react-redux';
import { updateComponentName, deleteComponent, OgmoConsts } from 'ogmo_core';

const useStyles = makeStyles({
  componentBody: {
    height: '100%'
  },
  meshTypography: {
    marginTop: 20,
    minHeight: 30,
    background: '#e0e0e0',
    display: 'flex',
    paddingLeft: '10px',
    alignItems: 'center'
  },
  mesh: {
    width: 42,
    height: 18
  },
  meshContent: {
    height: '270px',
    background: '#f7f4f4',
    padding: '15px'
  },
  nameHead: {
    width: '25%',
    color: '#575757'
  },
  groupLayers: {
    height: '100px',
    background: '#f7f4f4'
  },
  deleteIcon:{
    marginLeft:'auto'
  },
  input: {
    position: 'relative',
    backgroundColor: '#eeeeee',
    paddingLeft: '8px',
    height: '25px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function MeshComponent({componentId}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const meshComponent = useSelector(state => state.scene.componentByIds[componentId]);
  const meshAssetType = meshComponent && meshComponent.data['meshAssetType']

  const deleteMesh = () => {
    deleteComponent(meshComponent)
  }
  
  const handleMeshComponentNameChange = (meshDisplayName) =>{
    dispatch(updateComponentName({
      componentId: meshComponent.id,
      updateField: 'displayName',
      value: meshDisplayName
    }));
  }

  return (
    <Box
      className={classes.componentBody}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      mb={3}
    > 
      <Box className={classes.meshTypography}>
        <Typography
          className={classes.mesh}
          variant="subtitle2"
        >
        Mesh
        </Typography>
        <Button
          className={classes.deleteIcon}
          onClick={deleteMesh}
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </Button>
      </Box>
      <Box className={classes.meshContent}>
        <Box display="flex">
          <Typography
            className={classes.nameHead}
            variant="body1"
          >
        Name
          </Typography>
          <Box
            className={classes.input}
            width="60%"
          >
            <OnBlurOnEnterInput
              inputValue={meshComponent.data.displayName}
              inputValueFontSize="12px"
              onPress={handleMeshComponentNameChange}
              type= "text"
            />
          </Box>
        </Box>
        <MeshComponentMeshProperty meshProperty = {meshComponent} />
        {meshAssetType !== OgmoConsts.AssetType.GLB && <MeshComponentMaterialProperty materialProperty = {meshComponent} />}
        <Shadows meshComponent={meshComponent}/>
      </Box>
    </Box>
  );
}
