import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InspectorHeader from '../ComponetInspector/InspectorHeader';
import DefaultEntityProperties from './DefaultEntityProperties/DefaultEntityProperties';
import AddComponent from './AddComponent/AddComponet';
import MeshComponent from './MeshComponent/MeshComponent';
import CameraComponent from './CameraComponent/CameraComponent';
import LightComponent from '../ComponetInspector/LightComponent';
import SkyComponent from '../ComponetInspector/SkyComponent';
import { hasComponent, getComponent, OgmoConsts, getComponents } from 'ogmo_core';
import { useSelector } from 'react-redux';
import USDZComponent from '../ComponetInspector/USDZComponent';
import ARComponent from '../ComponetInspector/ARComponent';
import GlobalHotspotComponent from './GlobalHotspotComponent/GlobalHotspotComponent';
import GlobalHotspotGroupComponent from './GlobalHotspotGroupComponent/GlobalHotspotGroupComponent';

const useStyles = makeStyles({
  inspectorBody: {
    height: '100%'
  }
});

export default function ComponetInspector() {
  const classes = useStyles();
  const selectedEntitiyId = useSelector(state => state.scene.selectedEntitiyId);
  const entity = useSelector(state => state.scene.entityByIds[selectedEntitiyId]);

  var components = [];
  
  if (hasComponent(OgmoConsts.ComponentType.CAMERA, entity)) {
    const cameraComponent = getComponent(OgmoConsts.ComponentType.CAMERA, entity);
    components.push(<CameraComponent
      componentId={cameraComponent.id}
      key={OgmoConsts.ComponentType.CAMERA}
    />);
  }
  if (hasComponent(OgmoConsts.ComponentType.MESH, entity)) {
    const meshComponent = getComponent(OgmoConsts.ComponentType.MESH, entity);
    components.push(<MeshComponent
      componentId={meshComponent.id}
      key={OgmoConsts.ComponentType.MESH}
    />);
  }
  if (hasComponent(OgmoConsts.ComponentType.LIGHT, entity)) {
    const lightComponent = getComponent(OgmoConsts.ComponentType.LIGHT, entity);
    components.push(<LightComponent
      componentId={lightComponent.id}
      entity={entity}
      key={OgmoConsts.ComponentType.LIGHT}
    />);
  }
  if (hasComponent(OgmoConsts.ComponentType.SKYBOX, entity)) {
    const skyComponent = getComponent(OgmoConsts.ComponentType.SKYBOX, entity);
    components.push(<SkyComponent
      componentId={skyComponent.id}
      key={OgmoConsts.ComponentType.SKYBOX}
    />);
  }
  if (hasComponent(OgmoConsts.ComponentType.USDZ, entity)) {
    const usdzComponent = getComponent(OgmoConsts.ComponentType.USDZ, entity);
    components.push(<USDZComponent
      componentId={usdzComponent.id}
      key={OgmoConsts.ComponentType.USDZ}
    />);
  }
  if (hasComponent(OgmoConsts.ComponentType.AR, entity)) {
    const arComponent = getComponent(OgmoConsts.ComponentType.AR, entity);
    components.push(<ARComponent
      componentId={arComponent.id}
      key={OgmoConsts.ComponentType.AR}
    />);
  }
  if (hasComponent(OgmoConsts.ComponentType.GLOBAL_HOTSPOT, entity)) {
    const globalHotspotComponents = getComponents(OgmoConsts.ComponentType.GLOBAL_HOTSPOT, entity);
    globalHotspotComponents.forEach(globalHotspotComponent => {
      components.push(<GlobalHotspotComponent
        componentId={globalHotspotComponent.id}
        key={globalHotspotComponent.id}
      />)
    })
  }
  if (hasComponent(OgmoConsts.ComponentType.GLOBAL_HOTSPOT_GROUP, entity)) {
    const globalHotspotGroupComponents = getComponents(OgmoConsts.ComponentType.GLOBAL_HOTSPOT_GROUP, entity);
    globalHotspotGroupComponents.forEach(globalHotspotGroupComponent => {
      components.push(<GlobalHotspotGroupComponent
        componentId={globalHotspotGroupComponent.id}
        key={globalHotspotGroupComponent.id}
      />)
    })
  }

  return (
    <Box
      className={classes.inspectorBody}
      display="flex"
      flexDirection="column"
    >
      <InspectorHeader selectedEntitiyId={selectedEntitiyId} />
      {entity && <DefaultEntityProperties />}
      <AddComponent />
      {components}
    </Box>
  );
}
