import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { purple } from '@material-ui/core/colors';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MeshComponent,
  getEntityById,
  setComponent,
  USDZComponent,
  ARComponent,
  GlobalHotspotComponent,
  GlobalHotspotGroupComponent } from 'ogmo_core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  button: {
    width: 250,
    alignSelf: 'center',
    borderRadius: 0,
    boxShadow: 'none',
    fontSize: 12,
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
    '&:hover': { boxShadow: 'none', backgroundColor: '#b5b5b5' }
  },

  buttonIcon: {
    backgroundColor: '#576077',
    color: 'white',
    borderRadius: 8
  }
});

const theme = createMuiTheme({
  palette: {
    default: { main: '#41b327' } ,
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: '#11cb5f' } // This is just green.A700 as hex.
  },
});

export default function AddComponent() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedEntityId = useSelector(state => state.scene.selectedEntitiyId)

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createMesh = event => { // eslint-disable-line no-unused-vars
    const entity = getEntityById(selectedEntityId);
    const mesh = MeshComponent();
    setComponent(mesh, entity);
    handleClose();
  };

  const createUsdz = () => {
    const entity = getEntityById(selectedEntityId);
    const usdzComponent = USDZComponent();
    setComponent(usdzComponent, entity);
    handleClose();
  };

  const createArComponent = () => {
    const entity = getEntityById(selectedEntityId);
    const arComponent = ARComponent();
    setComponent(arComponent, entity);
    handleClose();
  };

  const createGlobalHotspotComponent = () => {
    const entity = getEntityById(selectedEntityId);
    const globalHotspotComponent = GlobalHotspotComponent();
    setComponent(globalHotspotComponent, entity);
    handleClose();
  };

  const createGlobalHotspotGroupComponent = () => {
    const entity = getEntityById(selectedEntityId);
    const globalHotspotGroupComponent = GlobalHotspotGroupComponent();
    setComponent(globalHotspotGroupComponent, entity);
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classes.button}
        onClick={handleClick}
        size="small"
        startIcon={<AddIcon className={classes.buttonIcon}/>} 
        type="button" 
        variant="contained"
      >
        Add Component
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={createMesh}>Mesh Component</MenuItem>
        <MenuItem onClick={createUsdz}>USDZ Component</MenuItem>
        <MenuItem onClick={createArComponent}>AR Component</MenuItem>
        <MenuItem onClick={createGlobalHotspotComponent}>Global Hotspot</MenuItem>
        <MenuItem onClick={createGlobalHotspotGroupComponent}>Global Hotspot Group</MenuItem>
      </Menu>
    </ThemeProvider>
  );
}
