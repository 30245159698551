import React from 'react';
import { Box, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import OnBlurOnEnterInput from '../OnBlurOnEnterInput.js';
import { updateCameraComponentData } from 'ogmo_core';

const useStyles = makeStyles({
  InputField: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '6px 0 3px 3px',
    height: '25px',
    width: 50,
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function CameraZoomProperties({  cameraComponent }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleEnableZoom = event => {
    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: event.target.checked,
      updateField: 'enableZoom'
    }
    ));
  };

  const handlChangeMinZoom = value => {
    if (value < 0) {
      value = 0;
    }

    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: value === '' ? '' : Number(value),
      updateField: 'minZoomDistance'
    }
    ));
  };

  const handlChangeMaxZoom = value => {
    if (value < 0) {
      value = 0;
    }

    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: value === '' ? '' : Number(value),
      updateField: 'maxZoomDistance'
    }
    ));
  };

  const handlChangeZoomSpeed = value => {
    if (value < 0) {
      value = 0;
    }
    
    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: value === '' ? '' : Number(value),
      updateField: 'zoomSpeed'
    }
    ));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Box
        alignItems="center"
        display="flex"
        flexWrap="nowrap"
      >
        <Box width="45%">
          <Typography variant="caption">Enable Zoom</Typography>
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          ml={2}
          width="55%"
        >
          <Switch
            checked={cameraComponent.data.enableZoom}
            color="primary"
            onChange={handleEnableZoom}
            size="small"
          />
        </Box>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexWrap="nowrap"
      >
        <Box width="50%">
          <Typography variant="caption">Min Zoom</Typography>
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          width="50%"
        >
          <Box className={classes.InputField}>
            <OnBlurOnEnterInput
              inputValue={cameraComponent.data.minZoomDistance}
              min={0}
              onPress={handlChangeMinZoom}
              step={1}
            />
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexWrap="nowrap"
      >
        <Box width="50%">
          <Typography variant="caption">Max Zoom</Typography>
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          width="50%"
        >
          <Box className={classes.InputField}>
            <OnBlurOnEnterInput
              inputValue={cameraComponent.data.maxZoomDistance}
              min={0}
              onPress={handlChangeMaxZoom}
              step={1}
            />
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexWrap="nowrap"
      >
        <Box width="50%">
          <Typography variant="caption">Zoom Speed</Typography>
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          width="50%"
        >
          <Box className={classes.InputField}>
            <OnBlurOnEnterInput
              inputValue={cameraComponent.data.zoomSpeed}
              min={0}
              onPress={handlChangeZoomSpeed}
              step={1}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
