import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from '../components/RouteWithLayout/RouteWithLayout';
import { AdminLayout } from '../super_admin/layouts';
import AdminDashboard from 'super_admin/views/AdminDashboard';
import AdminOrganizations from 'super_admin/views/AdminOrganizations';
import AdminOrganizationDetail from 'super_admin/views/AdminOrganizationDetail';


const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={AdminDashboard}
        exact
        layout={AdminLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={AdminOrganizations}
        exact
        layout={AdminLayout}
        path="/organizations"
      />
      <RouteWithLayout
        component={AdminOrganizationDetail}
        exact
        layout={AdminLayout}
        path="/organizationDetail"
      />
      <Redirect
        from="/"
        to="/dashboard"
      />
    </Switch>
  );
};

export default Routes;
