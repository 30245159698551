import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

export default function MaterialAssetType() {
  
  const selectedAsset = useSelector(
    state => state.scene.assetByIds[state.scene.selectedAssetId]
  );

  return (
    <Box
      alignItems="center"
      display="flex"
      flexGrow={1}
    >
      <Box
        display="flex"
        width="30%"
      >
        <Typography variant="body1">
          Type
        </Typography>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        my={2}
        width="70%"
      >
        <Typography variant="body1">
          {selectedAsset.data.kind}
        </Typography>
      </Box>
    </Box>
  );
}
