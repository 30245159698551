import React from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import MeshAssetPicker from '../../AssetPickers/MeshAssetPicker';
import { getAssetById, updateMeshComponentData } from 'ogmo_core';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  image: {
    width: 50,
    height: 50,
    backgroundColor: '#e6e6e6',
    cursor: 'pointer'
  },
  meshAssetTypography: {
    backgroundColor: '#eeeeee',
    height: 25,
    paddingTop: 3
  },
  typography: {
    color: '#575757'
  },
  deleteIcon: {
    width: '10%',
    marginLeft: 'auto'
  }
});

export default function MeshComponentMeshProperty({ meshProperty }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openMeshAssetPicker, setOpenMeshAssetPicker] = React.useState(false);

  const handleOpenMeshAssetPicker = () => {
    setOpenMeshAssetPicker(true);
  };
  const handleMeshCloseAssetPicker = () => {
    setOpenMeshAssetPicker(false);
  };
  const getAssetName = asset => {
    return asset ? asset.name : '';
  };

  const handleDeleteMeshProperty = () => {

    dispatch(updateMeshComponentData({
      componentId: meshProperty.id,
      updateField: 'meshAssetId',
      value: ''
    }))
  };
  const meshAssetProperty = getAssetById(meshProperty.data.meshAssetId);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexWrap="nowrap"
      mt={2}
    >
      <Typography
        className={classes.typography}
        variant="body1"
      >
        Mesh
      </Typography>

      <Box
        alignItems="flex-start"
        display="flex"
      >
        <Box width="25%">
          <img
            alt=""
            className={classes.image}
            onClick={handleOpenMeshAssetPicker}
          />
        </Box>
        <Box
          className={classes.meshAssetTypography}
          mt={1}
          pl={1}
          width="60%"
        >
          <Typography
            className={classes.typography}
            variant="body1"
          >
            {meshAssetProperty ? getAssetName(meshAssetProperty) : '[empty]'}
          </Typography>
        </Box>
        <IconButton
          className={classes.deleteIcon}
          onClick={handleDeleteMeshProperty}
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </IconButton>
      </Box>

      {openMeshAssetPicker && (
        <MeshAssetPicker
          handleCloseAssetPicker={handleMeshCloseAssetPicker}
          meshProperty={meshProperty}
          openAssetPicker={openMeshAssetPicker}
        />
      )}
    </Box>
  );
}
