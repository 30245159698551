import React, { useState, useRef, useEffect } from 'react';
import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  input: {
    fontSize:props => props.inputValueFontSize,
    color: '#575757'
  }
});


const OnBlurOnEnterInput =({inputValue = '', onPress, step = 1, min = null, max = null, type = 'number', placeholder, inputValueFontSize='10px'}) => {
  const props = { inputValueFontSize };
  const classes = useStyles(props);
  const ref = useRef(inputValue); // ref will hold the latest value of the input field at any given time
  const [value, setValue] = useState(inputValue);
  
  useEffect(() => {
    setValue(inputValue); // inorder to update the input value when just switch between different components
    ref.current = inputValue;
  },[inputValue])
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onPress(ref.current)
      setValue(ref.current)
    }
  }
  
  const handleOnBlur = value => {
    if(value == null){
      setValue(ref.current)
    }
  }

  const handleChange = (e,type) => {
    ref.current = type === 'number' ? Number(e.target.value) : e.target.value;
    setValue(e.target.value)
  }
  
  return (
    <Input
      className={classes.input}
      disableUnderline
      inputProps={{
        step: step,
        min: min,
        max: max,
        type: type
      }}
      onBlur={() => {
        onPress(ref.current);
        handleOnBlur();
      }}
      onChange={(e)=>handleChange(e,type)}
      onKeyPress={handleKeyPress}
      placeholder = {placeholder}

      value={value}
    />
  )
}

export default OnBlurOnEnterInput;