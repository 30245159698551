import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Paper,
  Input,
  Box,
  Card,
  Button,
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setEcommerceProducts, setAssignedEcommerceProducts, setSelectedEcommerce, setPageNumber } from '../../reducers/ecommerceProductsSlice';
import * as api from 'services/api';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AllProducts from './AllProducts';
import LinkedProducts from './LinkedProducts';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router-dom';
import { showErrorSnackbar} from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  paper: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 50,
    width: '200px',
    marginRight: '50px'
  },
  search: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    width: 200
  },
  searchButton: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    width: '135.6px',
    height: '45px',
    fontSize: '14px',
    borderRadius: '3px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  loader: {
    color: '#3183ae'
  },
  noProductLabel: {
    backgroundColor: '#ececec',
    height:80
  },
  icon: {
    width: '20.9px',
    height: '20.9px',
    color: '#3e8eb8'
  },
  linkTypography: {
    marginRight:5,
    marginTop:10,
    width: '330px',
    height: '18px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 550,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#636363',
  },
  link: {
    fontSize: '14px',
    textTransform: 'capitalize',
    color: '#3e8eb8'
  },
  platformLabel: {
    width: '62px',
    height: '18px',
    lineHeight: 1.29,
    color: '#576077',
    fontWeight: 'bold'
  },
  AppBar: {
    maxWidth: 400,
    display: 'flex',
    alignItems: 'space-between'
  },
  tab: { 
    textTransform: 'capitalize',
    width: 200,
    color: '#636363',
    '&:selected': { color: '#3e8eb8' }
  },
  tabPanel: {
    marginLeft: -24
  },
  magentoText:{
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4)
  },
  selectHost: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 250,
    border: 'solid 1px #C4C4C4',
    marginRight : theme.spacing(2)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      aria-labelledby={`action-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default function SelectProducts() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();

  const organizationId = useSelector(state => state.dashboard.userOrganizationId);
  const ecommerceHosts = useSelector(state => state.ecommerceProducts.ecommerceHosts); 
  const selectedEcommerce = useSelector(state => state.ecommerceProducts.selectedEcommerce);
  const [keyword, setKeyword] = useState('');
  const [SKU, setSKU] = useState('');
  const [isProductListLoading, setIsProductListLoading] = useState(false);
  const [isAllProductsTab, setIsAllProductsTab] = useState(true);  // To Check whether All products tab is selected
  const [value, setValue] = useState(0);
  const [productResponse, setProductResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [chooseHost,setChooseHost] = useState(selectedEcommerce == '' ? true : false);//showChooseHostDropDown
  const [hostName,setHostName]= useState();
  const [shopifyPrevCursor,setShopifyPrevCursor] = useState('')
  const [shopifyNextCursor,setShopifyNextCursor] = useState('')
  const [prevSkuKey,setPrevSkuKey] = useState('')
  const [skuKey,setSkuKey] = useState('')
  const [shopifyCursor,setShopifyCursor] = useState('')
  const [currentLinkedPage,setCurrentLinkedPage] = useState(1)


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { ecommerceProductsByIdsInTheStore,assignedProductsByIdsInTheStore, rows, pageNo } = useSelector(state => {
    const obj = {
      ecommerceProductsByIdsInTheStore: state.ecommerceProducts.products,
      rows: state.ecommerceProducts.rowsPerPage,
      pageNo: state.ecommerceProducts.page,
      assignedProductsByIdsInTheStore: state.ecommerceProducts.assignedProducts
    }
    return obj;
  });

  const ecommerceProductIds = Object.keys(ecommerceProductsByIdsInTheStore || []);
  const assignedProductIds = Object.keys(assignedProductsByIdsInTheStore || []);

  const ecommerceProductsInTheStore = ecommerceProductIds.map(productId => {
    return ecommerceProductsByIdsInTheStore[productId];
  });

  const assignedProductsInTheStore = assignedProductIds.map(productId => {
    return assignedProductsByIdsInTheStore[productId];
  });

  useEffect(() => {
    if(ecommerceHosts[selectedEcommerce]){
      searchByNameAndSKU();
    }
  }, [rows, pageNo, isAllProductsTab, rowsPerPage, selectedEcommerce, shopifyCursor, skuKey, ecommerceHosts]);

  const handleChangeName = event => {
    setKeyword(event.target.value);
  };

  const handleChangeSKU = event => {
    setSKU(event.target.value);
  };

  const searchByNameAndSKU = () => {
    const allProductsUrls = {
      'magento 1.x': `magento1x/products?page=${pageNo}&limit=${rows}&keyword=${keyword}&sku=${encodeURIComponent(SKU)}&organization_id=${organizationId}&magento_host=${ecommerceHosts[selectedEcommerce].host}`,
      'magento 2.x': `magento2x/products?page=${pageNo}&limit=${rows}&keyword=${keyword}&sku=${encodeURIComponent(SKU)}&organization_id=${organizationId}&magento_host=${ecommerceHosts[selectedEcommerce].host}`,
      'woocommerce': `woocommerce/allproducts?page=${pageNo}&limit=${rows}&keyword=${keyword}&sku=${encodeURIComponent(SKU)}&organization_id=${organizationId}&host=${ecommerceHosts[selectedEcommerce].host}`,
      'shopify': `shopify/products?keyword=${keyword}&sku=${encodeURIComponent(SKU)}&organization_id=${organizationId}&link=${encodeURIComponent(shopifyCursor)}`,
      'dokan': `https://${ecommerceHosts[selectedEcommerce].host}/wp-json/dokan/v1/stores/${ecommerceHosts[selectedEcommerce].version}/products?per_page=${rows}&page=${pageNo}`
    }

    setIsProductListLoading(true);

    const url = isAllProductsTab ? allProductsUrls[`${ecommerceHosts[selectedEcommerce].platform}${ecommerceHosts[selectedEcommerce].platform === 'magento'? ' '+ecommerceHosts[selectedEcommerce].version:''}`] : 
      `products/assign?&items=${rowsPerPage}&keyword=${keyword}&sku=${encodeURIComponent(SKU)}&organization_id=${organizationId}&host=${ecommerceHosts[selectedEcommerce].host}` 

    api
      .get(url)
      .then(res => {
        if (isAllProductsTab) {
          if(ecommerceHosts[selectedEcommerce].platform === 'shopify'){
            setShopifyPrevCursor(url)
            setShopifyNextCursor(url)
            if (rows * pageNo >= res.data.products.length) {
              setShopifyNextCursor('')
            }
            dispatch(setEcommerceProducts(res.data.products.slice(rows*(pageNo-1),rows*pageNo)))
          }
          else{
            if(res.data == undefined ){  //In the case of dokan we get the result res it self. Becaue we call it directly in front end.
              dispatch(setEcommerceProducts(res)) 
            }else{
              dispatch(setEcommerceProducts(res.data))
            }
          }
        } else {
          res.data.forEach(product => product.assigned = true);
          dispatch(setAssignedEcommerceProducts(res.data));
        }
        setIsProductListLoading(false);
        if(ecommerceHosts[selectedEcommerce].platform === 'shopify'){ 
          setProductResponse({
            ...res.data.products,
            currentPage: currentLinkedPage,
            total: res.data.total})  
        }
        setProductResponse(res);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.error(error);
      });
  };

  const handleEnterKeyPress = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      searchByNameAndSKU();
    }
  };

  const handleClickLinkedProductsTab = () => {
    setKeyword('');
    setSKU('');
    setIsAllProductsTab(false);
    setCurrentLinkedPage(1);
  };

  const handleClickAllProductTab = () => {
    setKeyword('');
    setSKU('');
    setIsAllProductsTab(true);
    dispatch(setPageNumber(1));
    handleShopifyPagination();
  };

  const handleRowsPerPageChange = event => {
    setSkuKey('')
    setCurrentLinkedPage(1)
    setRowsPerPage(event.target.value);
  };

  const setSelectedEcommercePlatform = () => { 
    dispatch(setSelectedEcommerce(hostName))
    localStorage.setItem('selectedHost', hostName)
  }

  const handleChangeHost = event => {
    let host = event.target.value;
    setHostName(host);
  };

  const handlePageChange = page => {
    setIsProductListLoading(true);
    const url =
      productResponse.currentPage > page + 1
        ? productResponse.prev
        : productResponse.next;

    api
      .get(url)
      .then(res => {
        setIsProductListLoading(false);
        res.data.forEach(product => product.assigned = true);
        dispatch(setAssignedEcommerceProducts(res.data));
        setProductResponse(res);
      })
      .catch(error => {
        setIsProductListLoading(false);
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message);
      });
  };

  const handleLinkClick = () => {
    history.push({ pathname: '/settings', state: { tab: 2 } });
  };

  const handleShopifyPagination = (link) => {
    setShopifyCursor(link)
  }

  const handleShopifyLinkedPageChange = (nextPage) => {
    setIsProductListLoading(true);
    const url =
      productResponse.currentPage > nextPage
        ? productResponse.prev
        : productResponse.next;
    api
      .get(url)
      .then(res => {
        setIsProductListLoading(false);
        res.data.forEach(product => product.assigned = true);
        dispatch(setAssignedEcommerceProducts(res.data));
        setProductResponse(res);
      })
      .catch(error => {
        setIsProductListLoading(false);
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message);
      });
    const prevSku = currentLinkedPage == 1 ? '' : ecommerceProductsInTheStore[0].sku
    setPrevSkuKey(prevSku)
    if(currentLinkedPage - 1 < nextPage){
      setCurrentLinkedPage(nextPage+1)
    }
    else{
      setSkuKey(prevSkuKey)
      setCurrentLinkedPage(nextPage+1)
    }
  }

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
          {isAllProductsTab ? 'Products' : 'Linked Products'}
        </Typography>
      </Breadcrumbs>
      <Box
        ml={3}
        mr={20}
      >
        {Object.keys(ecommerceHosts).length === 0 &&  (
          <Box
            alignItems="center"
            className={classes.noProductLabel}
            display="flex"
            flexDirection="column"
          >
            <Box display="flex">
              <Typography
                className={classes.linkTypography}
                variant="h6"
              >
                Link an Ecommerce platform to load products
              </Typography>
              <IconButton>
                <InfoOutlinedIcon
                  className={classes.icon}
                  color="primary"
                />
              </IconButton>
            </Box>
            <Typography variant="body2">
              <Button
                className={classes.link}
                onClick={handleLinkClick}
              >
                Link Ecommerce platform
              </Button>
            </Typography>
          </Box>
        )}
        {Object.keys(ecommerceHosts).length != 0 && !chooseHost &&(
          <Box>
            <Box
              mb={2}
              mt={2}
            >
              <Box
                alignItems="center"
                display="flex"
              >
                <Typography
                  className={classes.platformLabel}
                  variant="subtitle2"
                > Platform:</Typography>
                <Typography className={classes.magentoText}>{ecommerceHosts[selectedEcommerce].platform}</Typography>

                {ecommerceHosts[selectedEcommerce].version && (
                  <>
                    <Typography
                      className={classes.platformLabel}
                      variant="subtitle2"
                    > Version: </Typography>
                    <Typography className={classes.magentoText}>{ecommerceHosts[selectedEcommerce].version}</Typography>
                  </>
                )}

                <Typography
                  className={classes.platformLabel}
                  variant="subtitle2"
                > Domain: </Typography>
                <Typography className={classes.magentoText}>{ecommerceHosts[selectedEcommerce].host}</Typography>
                <Button
                  className={classes.link}
                  onClick={() => {
                    setChooseHost(true)
                  }}
                  variant="contained"
                >
                  Choose another platform
                </Button>
              </Box>
              <Box
                display="flex"
                mb={4}
                mt={4}
              >
                <Paper className={classes.paper}>
                  <SearchIcon
                    color="disabled"
                    fontSize="small"
                  />
                  <Input
                    autoFocus
                    className={classes.search}
                    disableUnderline
                    onChange={handleChangeName}
                    onKeyPress={handleEnterKeyPress}
                    placeholder="Search by name"
                  />
                </Paper>
                <Paper className={classes.paper}>
                  <SearchIcon
                    color="disabled"
                    fontSize="small"
                  />
                  <Input
                    className={classes.search}
                    disableUnderline
                    onChange={handleChangeSKU}
                    onKeyPress={handleEnterKeyPress}
                    placeholder="Search by SKU"
                  />
                </Paper>
                <Button
                  className={classes.searchButton}
                  disabled={SKU === '' && keyword === ''}
                  onClick={()=>{
                    dispatch(setPageNumber(1));
                    searchByNameAndSKU();
                  }}
                  variant="contained"
                >
                  Search
                </Button>
              </Box>
            </Box>
            <AppBar
              className={classes.AppBar}
              color="default"
              position="static"
            >
              <Tabs
                aria-label="action tabs example"
                indicatorColor="primary"
                onChange={handleTabChange}
                textColor="secondary"
                value={value}
                variant="standard"
              >
                <Tab
                  className={classes.tab}
                  label="All Products"
                  onClick={handleClickAllProductTab}
                />
                <Tab
                  className={classes.tab}
                  label="Linked Products"
                  onClick={handleClickLinkedProductsTab}
                />
              </Tabs>
            </AppBar>
            <TabPanel
              dir={theme.direction}
              index={0}
              value={value}
            >
              <Card className={classes.tabPanel}>
                <AllProducts
                  ecommerceProductsInTheStore={ecommerceProductsInTheStore}
                  handleShopifyPagination={handleShopifyPagination}
                  isProductListLoading={isProductListLoading}
                  organizationId={organizationId}
                  pageNo={pageNo}
                  rows={rows}
                  searchByNameAndSKU={searchByNameAndSKU}
                  shopifyNextCursor={shopifyNextCursor}
                  shopifyPrevCursor={shopifyPrevCursor}
                />
              </Card>
            </TabPanel>
            <TabPanel
              dir={theme.direction}
              index={1}
              value={value}
            >
              <Card className={classes.tabPanel}>
                <LinkedProducts
                  assignedProductsInTheStore={assignedProductsInTheStore}
                  handlePageChange={ecommerceHosts[selectedEcommerce].platform === 'shopify' ? handleShopifyLinkedPageChange : handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  isProductListLoading={isProductListLoading}
                  organizationId={organizationId}
                  productResponse={productResponse}
                  rowsPerPage={rowsPerPage}
                  searchByNameAndSKU={searchByNameAndSKU}
                  setIsProductListLoading={setIsProductListLoading}
                />
              </Card>
            </TabPanel>
          </Box>
        )}
        {Object.keys(ecommerceHosts).length > 0 && chooseHost && (
          <Box
            mb={2}
            mt={2}
          >
            <Typography style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(3) }}>Choose Host</Typography>
            <Box
              alignItems="center"
              display="flex"
            >
              <Box>
                <FormControl>
                  <Select
                    className={classes.selectHost}
                    data-test="choose-host"
                    disableUnderline
                    onChange={handleChangeHost}
                    value={hostName}
                  >
                    {Object.keys(ecommerceHosts).map((host, index) => (
                      <MenuItem
                        data-test="selected-host"
                        key={index}
                        value={host}
                      >{host}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Box>
              <Button
                className={classes.link}
                onClick={() => {
                  setSelectedEcommercePlatform()
                  setChooseHost(false)
                }}
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
