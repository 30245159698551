import React from 'react';
import AssetPicker from './AssetPicker';
import { useSelector, useDispatch } from 'react-redux';
import { OgmoConsts } from 'ogmo_core';
import { updateMaterialAssetData } from 'ogmo_core';

const TextureAssetPicker = props => {
  const { openAssetPicker, handleCloseAssetPicker, type } = props;
  const dispatch = useDispatch();

  const selectedAssetId = useSelector(
    state => state.scene.assetByIds[state.scene.selectedAssetId]
  );

  const handleChoosedTextureAssetId = choosedTextureAssetId => {
    dispatch(updateMaterialAssetData({
      assetId: selectedAssetId.id,
      value: choosedTextureAssetId,
      updateField: type
    }
    ));
    handleCloseAssetPicker();
  };

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedTextureAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.TEXTURE]}
    />
  );
};
export default TextureAssetPicker;
