import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextureAssetPicker from '../AssetPickers/TextureAssetPicker';
import ExpansionSection from '../ExpansionSection';
import InspectorPropertyPreviewHook from '../InspectorPropertyPreviewHook';
import InspectorPropertyPreview from '../InspectorPropertyPreview';

const useStyles = makeStyles({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '0 0 3px 10px',
    height: '25px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  },
  channel: {
    fontWeight: 600
  }
});

const mapType = 'aoMap';

export default function AmbientOcclusion() {
  const classes = useStyles();
  const [
    textureAssetPicker,
    handleOpenTextureAssetPicker,
    handleCloseTextureAssetPicker,
    assetMap
  ] = InspectorPropertyPreviewHook(mapType);

  return (
    <ExpansionSection sectionName="Ambient Occlusion">
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        pt={2}
        width="100%"
      >
        <InspectorPropertyPreview
          assetMap={assetMap}
          handleOpenTextureAssetPicker={handleOpenTextureAssetPicker}
          mapType={mapType}
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          mb={1}
        >
          <Typography
            className={classes.channel}
            variant="caption"
          >Channel: R</Typography>
        </Box>
      </Box>
      {textureAssetPicker.openTextureAssetPicker && (
        <TextureAssetPicker
          handleCloseAssetPicker={handleCloseTextureAssetPicker}
          openAssetPicker={textureAssetPicker.openTextureAssetPicker}
          type={textureAssetPicker.type}
        />
      )}
    </ExpansionSection>
  );
}
