import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TablePagination } from '@material-ui/core';
import ProductsTable from './ProductsTable';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  loader: {
    color: '#3183ae',
    margin: 'auto'
  }
}));

export default function LinkedProducts(props) {
  const {
    isProductListLoading,
    handleRowsPerPageChange,
    handlePageChange,
    assignedProductsInTheStore,
    productResponse,
    rowsPerPage,
    organizationId,
    searchByNameAndSKU
  } = props;
  const classes = useStyles();

  if (isProductListLoading) {
    return (
      <Box
        display="flex"
        height={200}
        justifyContent="center"
      >
        <CircularProgress className={classes.loader} />
      </Box>
    );
  } else {
    return (
      <Box>
        <ProductsTable
          ecommerceProductsInTheStore={assignedProductsInTheStore}
          organizationId={organizationId}
          searchByNameAndSKU={searchByNameAndSKU}
          showId={false}
          showLinkedDesign
          showModifiedDate
          showPreviewButton
          showStatus={false}
        />
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'previous page',
            disabled: productResponse.currentPage === 1
          }}
          className={classes.tablePagination}
          component="div"
          count={productResponse.total ? productResponse.total : 0}
          nextIconButtonProps={{
            'aria-label': 'next page',
            disabled: productResponse.currentPage >= productResponse.totalPages
          }}
          onChangePage={(event, page) => handlePageChange(page)}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={productResponse.currentPage - 1}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Box>
    );
  }
}
