import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import OnBlurOnEnterInput from '../OnBlurOnEnterInput.js';
import { updateCameraComponentData } from 'ogmo_core';

const useStyles = makeStyles({
  rotateSpeedInputField: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    paddingLeft: '3px',
    margin: '6px 0 3px 8px',
    height: '25px',
    width: '50px',
    '&:hover': { backgroundColor: '#d6d6d6' }
  }
});

export default function RotateSpeed({ cameraComponent }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handlChangeRotateSpeed = value => {
    if (value < 0) {
      value = 0;
    }

    dispatch(updateCameraComponentData({
      componentID: cameraComponent.id,
      value: value === '' ? '' : Number(value),
      updateField: 'rotateSpeed'
    }
    ));
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
    >
      <Box width="45%">
        <Typography variant="caption">Rotate Speed</Typography>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        width="55%"
      >
        <Box className={classes.rotateSpeedInputField}>
          <OnBlurOnEnterInput
            inputValue={cameraComponent.data.rotateSpeed}
            min={0}
            onPress={handlChangeRotateSpeed}
            step={1}
          />
        </Box>
      </Box>
    </Box>
  );
}
