import * as THREE from 'three';
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";

export const createARAssetFromScene = (scene, uploadGLBFile) => {

  let clonedScene = scene.clone(new THREE.Scene);
  let helperList = [...scene['children']];
  let isTextureMatched = false;
  let BrowseException = (material) => { return { "message": material } };

  helperList = helperList.filter(function (helper) {
    return helper.userData.hideInARView !== true || (helperList[helper] == "Mesh" && helper.children.userData.hideInARView == true);
  });

  clonedScene['children'] = helperList;

  const gltfExporter = new GLTFExporter();

  let options = {
    binary: true,
    trs: true,
    onlyVisible: true,
    truncateDrawRange: false,
    embedImages: true,
    maxTextureSize: Infinity,
    animations: [],
    forcePowerOfTwoTextures: true,
    includeCustomExtensions: true
  };

  try {
    clonedScene.traverse(function (child) {
      if (child.isMesh) {
        let material = child.material;

        child.position.x = child.position.x / 100;
        child.position.y = child.position.y / 100;
        child.position.z = child.position.z / 100;
        child.scale.x = child.scale.x / 100;
        child.scale.y = child.scale.y / 100;
        child.scale.z = child.scale.z / 100;
        child.geometry.scale.x = child.scale.x / 100;

        if (material.isMeshPhysicalMaterial) {
          isTextureMatched = false;
          if (material.roughnessMap == material.metalnessMap) {
            isTextureMatched = true;
          } else {
            throw BrowseException(material.name);
          }
        }
      }
    });
  } catch (err) {
    alert(" Material " + err.message + " needs same Roughness and Metallic texture maps to generate Glb file");
  }

  try {
    if (isTextureMatched) {
      gltfExporter.parse(clonedScene, (result) => {
        if (result instanceof ArrayBuffer) {
          uploadGLBFile('scene.glb', result);
        } else {
          var output = JSON.stringify(result, null, 2);
          console.log(output);
        }
      }, options);

    }
  } catch (err) {
    console.log(err.message);
  }

}