import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import AssetThumbnail from '../AssetThumbnail';
import AssetBrowserToolbar from './AssetBrowserToolbar';
import Box from '@material-ui/core/Box';
import { setSelectedAsset, closeAllHotspotOptionPanels } from 'ogmo_core';

const useStyles = makeStyles({
  thumbnail: {
    overflowY: 'scroll'
  }
});

export default function AssetsBrowser(props) {
  const { designId } = props || {};
  const dispatch = useDispatch()
  const [filteredAssetType, setfilteredAssetType] = React.useState('');
  const classes = useStyles();
  const assetByIds = useSelector(state => state.scene.assetByIds);

  const assetIds = Object.keys(assetByIds);
  const filteredAssetsIds = assetIds.filter(id => {
    const asset = assetByIds[id];

    if (filteredAssetType === '') {
      return id;
    }
    if (asset.type === filteredAssetType) {
      return id;
    }
    return null;
  });

  const chooseSelectedAssetId = assetKey => {
    setSelectedAsset(assetKey);
    dispatch(closeAllHotspotOptionPanels())
  };

  return (
    <Box
      flexDirection="coloumn"
      height="100%"
      pl={1}
      pt={1}
    >
      <AssetBrowserToolbar
        designId={designId}
        filteredAssetType={filteredAssetType}
        setfilteredAssetType={setfilteredAssetType}
      />
      <Box
        className={classes.thumbnail}
        display="flex"
        flexWrap="wrap"
        height="calc(100% - 50px)"
      >
        {filteredAssetsIds.map((assetKey, index) => (
          <AssetThumbnail
            asset={assetByIds[assetKey]}
            key={index}   
            setSelectedAssetId={() => chooseSelectedAssetId(assetKey)}       
          />
        ))}
      </Box>
    </Box>
  );
}
