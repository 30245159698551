import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { OgmoConsts } from 'ogmo_core';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import MeshIcon from '../images/mesh.png';
import USDZLogo from '../images/usdzLogo.png';
import Icon from '../images/icon.png';
import { showErrorSnackbar } from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  assetThumbnail: {
    height: '75px',
    width: '75px',
    margin: 'auto',
    display: 'block',
    opacity: props => props.assetId === props.assetBrowserUploadingId && props.uploading ? '0.2' : '1',
    borderRadius: props => props.AssetType === OgmoConsts.AssetType.MATERIAL ? '100%' : '8px',
  },
  textureThumbnail: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '8px',
    height: 'max-content'
  },
  materialItem: {
    maxWidth: 100,
    backgroundColor:props => props.assetId === props.selectedAssetId ? 'black':'transparent',
    '&:hover': {
      background: 'grey',
      color:'white'
    },
    cursor:'pointer',
    height:'110px',
    borderRadius:'10px',
    position:'relative'
  },
  assetTypography: {
    textOverflow: 'ellipsis',
    overflow:'hidden',
    marginTop:'5px',
    color:props => props.assetId === props.selectedAssetId ? 'white':'black',
    opacity: props => props.assetId === props.assetBrowserUploadingId && props.uploading ? '0.2' : '1',
  },
  assetProgress:{
    position:'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  assetIcon: {
    position: 'absolute',
    borderRadius: '5px 1px 1px 1px',
    height: 20,
    width: 20,
  }
});

const AssetThumbnail = ({asset, setSelectedAssetId}) => {  

  const selectedAssetId = useSelector(state => state.scene.selectedAssetId);
  const {assetId,isUploading} = useSelector(state => state.assetBrowser);
  const props = { assetId: asset.id, AssetType:asset.type, selectedAssetId: selectedAssetId, assetBrowserUploadingId:assetId, uploading:isUploading};
  const classes = useStyles(props);
  const dispatch = useDispatch();

  let assetIcon;

  if (asset.type === OgmoConsts.AssetType.MESH) {
    assetIcon = <img
      alt=""
      className={classes.assetIcon}
      src={MeshIcon}
    />;
  } else if (asset.type === OgmoConsts.AssetType.USDZ) {
    assetIcon = <img
      alt=""
      className={classes.assetIcon}
      src={USDZLogo}
    />;
  } else if (asset.type === OgmoConsts.AssetType.ICON) {
    assetIcon = <img
      alt=""
      className={classes.assetIcon}
      src={Icon}
    />;
  }

  return (
    <Box
      className={classes.materialItem}
      m={1}
      onClick={setSelectedAssetId}
      p={1}
    >
      {(asset.id === assetId && isUploading) && <CircularProgress
        className={classes.assetProgress}
        disableShrink
      />}
      <Paper className={classes.assetThumbnail}>
        { assetIcon }
        {asset.type === OgmoConsts.AssetType.TEXTURE && (
          <img
            alt=""
            className={classes.textureThumbnail}
            onError={(error) => dispatch(showErrorSnackbar(error))}
            src={asset.data.originalTextures[0]}
          />
        )}
        {asset.type === OgmoConsts.AssetType.ICON && (
          <img
            alt=""
            className={classes.textureThumbnail}
            onError={(error) => dispatch(showErrorSnackbar(error))}
            src={asset.data.iconImage}
          />
        )}
      </Paper>
      <Typography
        align="center"
        className={classes.assetTypography}
        variant="body2"
      >
        {asset.name}
      </Typography>
    </Box>
  );
};

export default AssetThumbnail;
