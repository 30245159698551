export function RGB2Hex(RGB2HexColor) {
  let R = RGB2HexColor[0] * 255;
  let G = RGB2HexColor[1] * 255;
  let B = RGB2HexColor[2] * 255;

  let r = Number(R).toString(16);
  let g = Number(G).toString(16);
  let b = Number(B).toString(16);

  if (r.length === 1) r = '0' + r;
  if (g.length === 1) g = '0' + g;
  if (b.length === 1) b = '0' + b;

  return '#' + r + g + b;
}