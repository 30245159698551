import React, {useEffect} from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Tab,
  Tabs
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OrganizationSettings from './OrganizationSettings';
import AccountInfo from './AccoutInfo';
import ECommercePlatform from './ECommercePlatform';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Auth } from 'aws-amplify';
import { showErrorSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  tabButton: {
    textTransform: 'capitalize',
    fontSize: 14,
    opacity: 0.4,
    fontWeight: 500
  },
  bigIndicator: {
    height: 0
  }
}));

function TabPanel({ children, value, index }) {
  return (
    <Box hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
export default function Settings(props) {
  const classes = useStyles();

  const activeTabIndex = props.location.state ? props.location.state.tab : 0;

  const [option, setOption] = React.useState(activeTabIndex);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const dispatch = useDispatch();

  const handleChangeOptions = (event, newValue) => {
    setOption(newValue);
  };

  useEffect(() => {
    Auth.currentSession()
      .then(data => {
        const payload = data.idToken.payload;
        const cognito_groups = payload['cognito:groups'];
        setIsAdmin(cognito_groups.includes('Admin'));
      })
      .catch(err => {
        dispatch(showErrorSnackbar(err));
        console.log(err)
      })
  },[isAdmin]);

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
          {option===0 && 'Account Info'}
          {option===1 && 'Organization Settings'}
          {option===2 && 'ECommerce Platform Settings'}
        </Typography>
      </Breadcrumbs>
      <Box p={4}>
        <Box
          display="flex"
          flexDirection="column"
        >
          <Card>
            <CardContent className={classes.margin}>
              <Tabs
                classes={{ indicator: classes.bigIndicator }}
                onChange={handleChangeOptions}
                value={option}
                variant="fullWidth"
              >
                <Tab
                  className={classes.tabButton}
                  label="Account Info"
                />
                {isAdmin && (<Tab
                  className={classes.tabButton}
                  label="Organization Settings"
                />)}
                {isAdmin && (<Tab
                  className={classes.tabButton}
                  label="ECommerce Platform Settings"
                />)}
              </Tabs>
            </CardContent>
          </Card>
          <TabPanel
            index={0}
            value={option}
          >
            <AccountInfo />
          </TabPanel>
          {isAdmin && (<TabPanel
            index={1}
            value={option}
          >
            <OrganizationSettings />
          </TabPanel>)}
          {isAdmin && (<TabPanel
            index={2}
            value={option}
          >
            <ECommercePlatform />
          </TabPanel>)}
        </Box>
      </Box>
    </Box>
  );
}
