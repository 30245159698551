import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
  MenuItem,
  Select,
  IconButton,
  Input
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  rowsPerPageText: {
    marginRight: 20
  },
  rowsPerPageOptions: {
    fontSize: 13,
    marginRight: 20,
    backgroundColor: 'white'
  },
  pageNumbers: {
    marginRight: 20
  }
});
export default function TablePagination({
  handleChangeRowsPerPage,
  handleChangePageNumber,
  rows,
  pageNo,
  shopifyPrevCursor,
  shopifyNextCursor,
  ecommerceProductsInTheStore
}) {
  const classes = useStyles();
  const ecommerceHosts = useSelector(state => state.ecommerceProducts.ecommerceHosts);
  const selectedEcommerce = useSelector(state => state.ecommerceProducts.selectedEcommerce);
  
  return (
    <Box
      alignItems="center"
      display="flex"
      flexGrow={1}
      justifyContent="flex-end"
    >
      <Typography className={classes.rowsPerPageText}>
        Rows per page:
      </Typography>
      <Select
        className={classes.rowsPerPageOptions}
        input={<Input disableUnderline />}
        onChange={handleChangeRowsPerPage}
        value={rows}
      >
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
      </Select>
      <Typography className={classes.pageNumbers}>Page: {pageNo}</Typography>
      <IconButton
        disabled={pageNo < 2 || (ecommerceHosts[selectedEcommerce].platform === 'shopify' && !shopifyPrevCursor)}
        onClick={event => handleChangePageNumber('prev')} // eslint-disable-line no-unused-vars
      >
        <KeyboardArrowLeftIcon />
      </IconButton>

      <IconButton 
        disabled={ecommerceProductsInTheStore.length < rows || (ecommerceHosts[selectedEcommerce].platform === 'shopify' && !shopifyNextCursor)}
        onClick={event => handleChangePageNumber('next')} // eslint-disable-line no-unused-vars
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
  );
}
