import React from 'react';
import AssetPicker from '../AssetPicker';
import { useDispatch } from 'react-redux';
import { OgmoConsts } from 'ogmo_core';
import { setIconToGlobalHotspotGroupComponent } from 'ogmo_core';

const GlobalHotspotGroupIconPicker = props => {
  const { openAssetPicker, handleCloseAssetPicker, globalHotspotGroupComponentId } = props;
  const dispatch = useDispatch();

  const handleChoosedIconAssetId = choosedIconAssetId => {
    dispatch(setIconToGlobalHotspotGroupComponent({
      globalHotspotGroupComponentId: globalHotspotGroupComponentId,
      iconAssetId: choosedIconAssetId
    }
    ));
    handleCloseAssetPicker();
  };

  return (
    <AssetPicker
      handleChoosedAssetId={handleChoosedIconAssetId}
      handleCloseAssetPicker={handleCloseAssetPicker}
      openAssetPicker={openAssetPicker}
      types={[OgmoConsts.AssetType.ICON]}
    />
  );
};
export default GlobalHotspotGroupIconPicker;
