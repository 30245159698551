import React, { useEffect,useRef } from 'react';
import { useSelector } from 'react-redux'
import { useAssetRenderer } from 'ogmo_core';

export default function AssetRenderer ({hotSpotEditorAssetPreview})  {
  const ref = useRef();
  const renderAsset = useAssetRenderer();
  const selectedAssetId = useSelector(state => state.scene.selectedAssetId);

  useEffect(()=>{//Only for rendering hotspot previews
    const _canvas = ref.current;
    if(!hotSpotEditorAssetPreview){
      return;
    }
    renderAsset(_canvas, hotSpotEditorAssetPreview.id);
  }, []);

  useEffect(()=>{
    const _canvas = ref.current;
    if(!selectedAssetId){
      return;
    }
    renderAsset(_canvas, selectedAssetId);
  }, [selectedAssetId]);

  return <canvas
    height="415px"
    ref={ref}
    width="415px"
  />
} 