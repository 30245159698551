import { useState, useEffect } from 'react';
import { setInitialDesigns ,setDesignResponseData}  from '../../reducers/dashboardSlice';
import { useDispatch } from 'react-redux';
import * as api from '../../services/api';
import {useSelector} from 'react-redux' ;
import { showErrorSnackbar} from 'reducers/snackbarSlice';

const UseFetchDesigns = () => {

  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDesignsAreLoading, setIsDesignsAreLoading] = useState(false);
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);
  const prev = useSelector(state => state.dashboard.designResponsePrev);
  const next = useSelector(state => state.dashboard.designResponseNext);
  const currentPage = useSelector(state => state.dashboard.designResponseCurrentPage);

  useEffect(() => {
    loadDesigns();
  }, [rowsPerPage]);

  const loadDesigns = () => {
    setIsDesignsAreLoading(true);
    api
      .get(`designs?rowsPerPage=${rowsPerPage}&organization_id=${organizationId}`)
      .then(data => {
        setIsDesignsAreLoading(false);
        dispatch(setInitialDesigns(data.data));
        dispatch(setDesignResponseData(
          {
            total: data.total,
            prev: data.prev,
            next: data.next,
            currentPage: data.currentPage
          }));
      })
      .catch(error => {
        setIsDesignsAreLoading(false);
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    setIsDesignsAreLoading(true);

    const url =
      currentPage > page + 1
        ? prev
        : next;
    api.get(url)
      .then(data => {
        setIsDesignsAreLoading(false);
        dispatch(setInitialDesigns(data.data));
        dispatch(setDesignResponseData(
          { 
            total:data.total,
            prev:data.prev,
            next:data.next,
            currentPage:data.currentPage
          }));
      })
      .catch(error => {
        setIsDesignsAreLoading(false);
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message)
      });
  };

  return [
    rowsPerPage,
    setRowsPerPage,
    handlePageChange,
    isDesignsAreLoading,
    loadDesigns,
    setIsDesignsAreLoading
  ];
};

export default UseFetchDesigns;
