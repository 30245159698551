import React, { useState } from 'react';
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import GlbAssetPicker from '../AssetPickers/GlbAssetPicker';
import UsdzAssetPicker from '../AssetPickers/UsdzAssetPicker';
import { getAssetById,updateARComponentData} from 'ogmo_core';
import { useDispatch , useSelector } from 'react-redux';
import { showInfoSnackbar} from 'reducers/snackbarSlice';

const useStyles = makeStyles({
  image: {
    width: 50,
    height: 50,
    border: '1px solid #d6d6d6',
    backgroundColor: 'white',
    cursor: 'pointer'
  },
  deleteIcon: {
    marginLeft: 'auto'
  },
  componentTypography: {
    marginTop: 20,
    minHeight: 30,
    background: '#e0e0e0',
    display: 'flex',
    paddingLeft: '10px',
    alignItems: 'center'
  },
  componentContent: {
    height: '100px',
    background: '#f7f4f4',
    padding: '15px'
  },
  assetNameTypography: {
    marginLeft: '10px'
  }
});

export default function ARComponent({ componentId }) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [openGlbAssetPicker, setOpenGlbAssetPicker] = useState(false);
  const [openUsdzAssetPicker, setOpenUsdzAssetPicker] = useState(false);
  const arComponent = useSelector(state => state.scene.componentByIds[componentId]);
  
  const deleteArComponent = () => {
    dispatch(showInfoSnackbar('Clicked Delete AR Component'));
  };

  const handleOpenGlbAssetPicker = () => {
    setOpenGlbAssetPicker(true);
  };

  const handleCloseGlbAssetPicker = () => {
    setOpenGlbAssetPicker(false);
  };

  const handleOpenUsdzAssetPicker = () => {
    setOpenUsdzAssetPicker(true);
  };

  const handleCloseUsdzAssetPicker = () => {
    setOpenUsdzAssetPicker(false);
  };

  const getGlbAssetName = asset => {
    return asset ? asset.name : '';
  };

  const getUsdzAssetName = asset => {
    return asset ? asset.name : '';
  };

  const getGlbAssetURL = asset => {
    return asset ? asset.data.glbPath : '';
  };

  const getUsdzAssetURL = asset => {
    return asset ? asset.data.usdzPath : '';
  };

  const handleDeleteGlbAsset = () => {
    dispatch(updateARComponentData({
      componentId: arComponent.id,
      updateField: 'glbAssetId',
      value: ''
    })
    );
  };

  const handleDeleteUsdzAsset = () => {
    dispatch(updateARComponentData({
      componentId: arComponent.id,
      updateField: 'usdzAssetId',
      value: ''
    })
    );
  };

  const glbAsset = getAssetById(arComponent.data.glbAssetId);

  const usdzAsset = getAssetById(arComponent.data.usdzAssetId);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexWrap="nowrap"
      mt={1}
    >
      <Box className={classes.componentTypography}>
        <Typography variant="body1">AR Component</Typography>
        <Button
          className={classes.deleteIcon}
          onClick={deleteArComponent}
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </Button>
      </Box>

      <Box className={classes.componentContent}>
        <Typography>GLB Asset</Typography>

        <Box display="flex">
          <img
            alt=""
            className={classes.image}
            onClick={handleOpenGlbAssetPicker}
            src={getGlbAssetURL(glbAsset)}
          />
          <Typography className={classes.assetNameTypography}>
            {getGlbAssetName(glbAsset)}
          </Typography>
          <IconButton
            aria-label="delete"
            className={classes.deleteIcon}
            onClick={handleDeleteGlbAsset}
          >
            <DeleteIcon
              color="action"
              fontSize="small"
            />
          </IconButton>
        </Box>
      </Box>

      <Box className={classes.componentContent}>
        <Typography>USDZ Asset</Typography>

        <Box display="flex">
          <img
            alt=""
            className={classes.image}
            onClick={handleOpenUsdzAssetPicker}
            src={getUsdzAssetURL(usdzAsset)}
          />
          <Typography className={classes.assetNameTypography}>
            {getUsdzAssetName(usdzAsset)}
          </Typography>
          <IconButton
            aria-label="delete"
            className={classes.deleteIcon}
            onClick={handleDeleteUsdzAsset}
          >
            <DeleteIcon
              color="action"
              fontSize="small"
            />
          </IconButton>
        </Box>
      </Box>

      {openGlbAssetPicker && (
        <GlbAssetPicker
          glbComponent={arComponent}
          handleCloseAssetPicker={handleCloseGlbAssetPicker}
          openAssetPicker={openGlbAssetPicker}
        />
      )}
      {openUsdzAssetPicker && (
        <UsdzAssetPicker
          handleCloseAssetPicker={handleCloseUsdzAssetPicker}
          openAssetPicker={openUsdzAssetPicker}
          usdzComponent={arComponent}
        />
      )}
    </Box>
  );
}
