import React from 'react';
import { Box } from '@material-ui/core';
import TablePagination from 'views/Products/TablePagination';
import { useDispatch } from 'react-redux';
import {
  setRowsPerPage,
  setPageNumber,
} from '../../reducers/ecommerceProductsSlice';
import ProductsTable from './ProductsTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({ // eslint-disable-line no-unused-vars
  loader: {
    color: '#3183ae',
    margin: 'auto',
  },
}));

export default function AllProducts(props) {
  const {
    isProductListLoading,
    ecommerceProductsInTheStore,
    handleShopifyPagination,
    shopifyPrevCursor,
    shopifyNextCursor,
    searchByNameAndSKU,
    rows,
    pageNo,
    organizationId
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChangePageNumber = (newPage) => {
    if (newPage === 'prev') {
      dispatch(setPageNumber(pageNo - 1));
      handleShopifyPagination(shopifyPrevCursor);
    }
    if (newPage === 'next') {
      dispatch(setPageNumber(pageNo + 1));
      handleShopifyPagination(shopifyNextCursor);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(event.target.value));
    dispatch(setPageNumber(1));
  };

  if (isProductListLoading) {
    return (
      <Box
        display="flex"
        height={200}
        justifyContent="center"
      >
        <CircularProgress className={classes.loader} />
      </Box>
    );
  } else {
    return (
      <Box>
        <ProductsTable
          ecommerceProductsInTheStore={ecommerceProductsInTheStore}
          organizationId={organizationId}
          searchByNameAndSKU={searchByNameAndSKU}
          showId
          showLinkedDesign={false}
          showModifiedDate={false}
          showPreviewButton={false}
        />
        <TablePagination
          ecommerceProductsInTheStore={ecommerceProductsInTheStore}
          handleChangePageNumber={handleChangePageNumber}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          pageNo={pageNo}
          rows={rows}
          shopifyNextCursor={shopifyNextCursor}
          shopifyPrevCursor={shopifyPrevCursor}
        />
      </Box>
    );
  }
}
