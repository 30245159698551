import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { RGB2Hex } from '../../utils/utils';
import { Box,Typography } from '@material-ui/core';

const ColorPickerInput = withStyles({
  input: {
    borderRadius: 2,
    position: 'relative',
    width: '50px',
    height: '30px',
    cursor: 'pointer'
  }
})(InputBase);

export default function ColorPickerInputBase({
  handleChangeColorPicker,
  colorValue
}) {

  return (
    <Box
      alignItems="center"
      display="flex"
      width="100%"
    >
      <Box width="30%">
        <Typography variant="caption" >Color </Typography>
      </Box>
      <Box width="40%">
        <ColorPickerInput
          onChange={handleChangeColorPicker}
          type="color"
          value={RGB2Hex(colorValue)}
        />
      </Box>
    </Box>
  );
}
