import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAssetById } from 'ogmo_core';

export default function InspectorPropertyPreviewHook(mapType) {
  const selectedAsset = useSelector(
    state => state.scene.assetByIds[state.scene.selectedAssetId]
  );

  const [textureAssetPicker, setTextureAssetPicker] = useState({
    openTextureAssetPicker : false,
    type: ''
  });

  const handleOpenTextureAssetPicker = type => () => {
    setTextureAssetPicker(oldValue => {
      oldValue.openTextureAssetPicker  = true;
      oldValue.type = type;
      return { ...oldValue };
    });
  };

  const handleCloseTextureAssetPicker = () => {
    setTextureAssetPicker(oldValue => {
      oldValue.openTextureAssetPicker  = false;
      oldValue.type = '';
      return { ...oldValue };
    });
  };

  const assetMap = getAssetById(selectedAsset.data[mapType]);

  return [
    textureAssetPicker,
    handleOpenTextureAssetPicker,
    handleCloseTextureAssetPicker,
    assetMap
  ];
}
