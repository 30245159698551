const initialState = {
  assetId: '',
  isUploading: false
};

export const assetBrowserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_UPLOAD_PROGRESS':
      return {
        ...state,
        isUploading: action.payload.uploading,
        assetId: action.payload.assetId
      };
    default:
      return state;
  }
};
