import {
  getEntityById, 
  setChild,
  getEntity,
  setComponent,
  CameraComponent,
  LightComponent, 
  SkyBoxComponent
} from 'ogmo_core';
import React from 'react';
import {useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';

export default function AddPrefab() {

  const selectedEntityId = useSelector(state => state.scene.selectedEntitiyId);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const root = useSelector(state => state.scene.entityByIds[state.scene.rootId]);

  const handleAddEmptyEntity = () => {
    handleShortcutMenuClose();
    const emptyEntity = getEntity();
    let parentOfTheNewEntity = getEntityById(selectedEntityId);
    setChild(emptyEntity, parentOfTheNewEntity);
  }

  const handleCreateCameraEntity = () => {
    handleShortcutMenuClose();
    const camera = getEntity();
    camera.name = 'Camera';
    camera.position = [0, 15, 15];
    const camera_comp = CameraComponent();
    camera.parentId = root.id;
    setChild(camera, null);
    setComponent(camera_comp, camera);
  };

  const handleAddDirectionalLight = () => {
    handleShortcutMenuClose();
    const light = getEntity();
    light.name = 'Directional Light';
    light.position = [3, 3, 3];
    const light_comp = LightComponent();

    setChild(light, null);
    setComponent(light_comp, light);
  };

  const handleAddSky = () => {
    handleShortcutMenuClose();
    const sky = getEntity();
    sky.name = 'Sky';
    const sky_comp = SkyBoxComponent();

    setChild(sky, null);
    setComponent(sky_comp, sky);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleShortcutMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
      >
        +
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted
        onClose={handleShortcutMenuClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={handleAddEmptyEntity}>Entity</MenuItem>
        <MenuItem onClick={handleCreateCameraEntity}>Camera</MenuItem>
        <MenuItem onClick={handleAddSky}>Sky</MenuItem>
        <MenuItem onClick={handleAddDirectionalLight}>Directional Light</MenuItem>

      </Menu>
    </>
  );
}
