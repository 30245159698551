import React, { useState, useEffect } from 'react';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const PrettoSlider = withStyles({
  root: {
    color: '#2b2b2b',
    height: 3
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -6,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 3
  },
  rail: {
    height: 3
  }
})(Slider);

export default function OnCommitedSliderInput({
  sliderInputValue = '',
  handleDragStop,
  min = null,
  max = null,
  step = 1
}) {
  const [sliderValue, setSliderValue] = useState(sliderInputValue);

  useEffect(() => {
    setSliderValue(sliderInputValue);
  }, [sliderInputValue]);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <PrettoSlider
      max={max}
      min={min}
      onChange={handleSliderChange}
      onChangeCommitted={handleDragStop}
      step={step}
      value={sliderValue}
    />
  );
}
