import { OgmoConsts } from '../constants/consts';
import { createUUID } from '../core/Utils.js';

export const CameraComponent = () => ({
    type: OgmoConsts.ComponentType.CAMERA,
    needsUpdate: true,
    id: createUUID(),
    name: "Camera Component",
    data: {
        center: [0, 0, 0],
        up: [0, 1, 0],
        near: 0.01,
        far: 100,
        clearColor: [0.9, 0.9, 0.9, 1],
        fov: 45,
        minZoomDistance: 1,
        maxZoomDistance: 10,
        minPolarAngle: Math.PI * 0.05,
        maxPolarAngle: Math.PI * 0.95,
        zoomSpeed: 1.0,
        rotateSpeed: 1.0,
        dampingFactor: 0.05,
        enableInteraction: true,
        enableZoom: true,
        enableDamping: true
    }
});