import React from 'react';
import { Card, CardContent, Typography, Input, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 550
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 300,
    border: 'solid 1px #C4C4C4',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

const DesignDetails = ({ handleNameChange }) => {
  let location = useLocation();

  const titleLabel =
    location.pathname === '/createSingleDesign'
      ? 'Design Details'
      : 'Group Design Details';
  const nameLabel =
    location.pathname === '/createSingleDesign' ? 'Design name' : 'Group name';
  const placeholderLabel =
    location.pathname === '/createSingleDesign'
      ? 'Add Design Name'
      : 'Add Group Name';

  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Typography
          className={classes.title}
          variant="h5"
        >
          {titleLabel}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          mb={2}
          ml={6}
        >
          <FormControl>
            <Typography>{nameLabel}</Typography>
            <Input
              autoFocus
              className={classes.input}
              disableUnderline
              onChange={handleNameChange}
              placeholder={placeholderLabel}
              required
            />
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DesignDetails;
