import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table, TableBody, TableContainer, TableCell, TableRow,
  TableHead, Paper, TablePagination, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoader from 'super_admin/components/CircularLoader';
import * as api from '../../../src/services/api';
import { showErrorSnackbar, showSuccessSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 600,
    color: '#1F3974'
  },
  blueTypography: {
    fontWeight: 600,
    color: '#1F3974'
  },
  button: {
    width: '80px'
  }
}));

const Designs = ({ organization_id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [designs, setDesigns] = useState([]);
  const [designCount, setDesignCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [blockDialogOpen, setBlockDialogOpen] = React.useState(false);
  const [blockDesign, setBlockDesign] = useState({});
  const [blockConfirm, setBlockConfirm] = useState('');
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, designs.length - page * rowsPerPage);

  useEffect(() => {
    setIsLoading(true);
    api.post(`analytics/report/${organization_id}`,{})
      .then(count_data => {
        api.get(`superadmin/designs/${organization_id}`)
          .then(res => {
            setIsLoading(false);
            const design_data = res.data.map(design => {
              const design_count_short_index = (count_data.findIndex(iter => iter.design_id == design.short_url));
              const design_count_long_index = (count_data.findIndex(iter => iter.design_id == design.id.split('DES#')[1]));
              if (design_count_short_index >= 0) design.d_views = parseInt(count_data[design_count_short_index].count);
              if (design_count_long_index >= 0) design.d_views += parseInt(count_data[design_count_long_index].count);
              return { ...design, designBlockStatus: (design.design_blocked_by_superadmin || design.org_blocked_by_superadmin) };
            });
            setDesigns(design_data);
            setDesignCount(design_data.length);
          })
          .catch(error => {
            dispatch(showErrorSnackbar('Designs were failed to load'));
            console.log(error);
          });
      });
  }, [])

  const handleBlock = () => {
    const clicked_design = blockDesign;
    if (clicked_design.name == blockConfirm) {
      setIsLoading(true);
      api.put(`superadmin/block/design?design_id=${clicked_design.id.split('DES#')[1]}&isToBlock=${!(clicked_design.designBlockStatus)}`)
        .then(() => {
          const index = designs.findIndex(design => design.id == clicked_design.id);
          designs[index].designBlockStatus = !(clicked_design.designBlockStatus);
          setDesigns(designs);
          setIsLoading(false);
          dispatch(showSuccessSnackbar(clicked_design.designBlockStatus ? 'Successfully Blocked the Design' : 'Successfully UnBlocked the Design'));
        })
        .catch(error => {
          dispatch(showErrorSnackbar('Organization is blocked'));
          console.log(error);
        });
      setBlockDesign({});
      setBlockDialogOpen(false);
    }
    else {
      setBlockDialogOpen(false);
      dispatch(showErrorSnackbar('Invalid Design Name'));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (design) => {
    setBlockDesign(design);
    setBlockDialogOpen(true);
  };

  const handleClose = () => {
    setBlockDesign({});
    setBlockDialogOpen(false);
  };

  return (
    <>
      {!isLoading && <Box
        display="flex"
        flexDirection="column"
        p={4}
      >
        <Typography
          className={classes.title}
          component={'span'}
        > {designCount} Designs are available </Typography>
      </Box>
      }
      {isLoading && <CircularLoader />}
      {!isLoading && <Box p={4} >
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell className={classes.blueTypography}>Design name</TableCell>
                  <TableCell
                    align="center"
                    className={classes.blueTypography}
                  >Blocked By SuperAdmin</TableCell>
                  <TableCell
                    align="center"
                    className={classes.blueTypography}
                  >3D views</TableCell>
                  <TableCell
                    align="center"
                    className={classes.blueTypography}
                  >AR views</TableCell>
                  <TableCell
                    align="center"
                    className={classes.blueTypography}
                  >Block/Unblock</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {designs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((design, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.blueTypography}>{design.name}</TableCell>
                    <TableCell
                      align={design.align}
                      className={classes.blueTypography}
                    >{(design.designBlockStatus) ? 'YES' : 'NO'}</TableCell>
                    <TableCell
                      align="center"
                      className={classes.blueTypography}
                    >{design.d_views}</TableCell>
                    <TableCell
                      align="center"
                      className={classes.blueTypography}
                    >{design.ar_views}</TableCell>
                    <TableCell
                      align="center"
                      className={classes.blueTypography}
                    >
                      <Button
                        className={classes.button}
                        onClick={() => handleClickOpen(design)}
                        variant="contained"
                      >
                        {design.designBlockStatus ? 'UnBlock' : 'Block'}
                      </Button>
                      <Dialog
                        aria-labelledby="form-dialog-title"
                        onClose={handleClose}
                        open={blockDialogOpen}
                      >
                        <DialogTitle id="form-dialog-title">{(blockDesign.designBlockStatus) ? 'UnBlock' : 'Block'}</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            {`To ${(blockDesign.designBlockStatus) ? 'UnBlock' : 'Block'} the design, please enter the design name`}
                          </DialogContentText>
                          <TextField
                            autoFocus
                            fullWidth
                            id="name"
                            label="Design Name"
                            margin="dense"
                            onChange={(e) => setBlockConfirm(e.target.value)}
                            type="text"
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color="primary"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            onClick={handleBlock}
                          >
                            {(blockDesign.designBlockStatus) ? 'UnBlock' : 'Block'}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={designs.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
          /></>
      </Box>
      }
    </>);
};
export default Designs;
