import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input, Button, Box } from '@material-ui/core';
import {setInitialDesigns,setDesignResponseData} from '../../reducers/dashboardSlice';
import { useDispatch } from 'react-redux';
import * as api from '../../services/api';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux'
import { showErrorSnackbar} from 'reducers/snackbarSlice';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    display: 'flex',
    width: 200,
    marginRight: 8
  },
  input: {
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    fontSize: 12
  },
  button: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '6px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  loader: {
    color: '#3183ae',
    marginLeft: theme.spacing(2)
  }
}));

export default function SearchInput({
  rowsPerPage,
  ...rest
}) {
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);
  const [searchingLoader, setSearchingLoader] = useState(false);

  const handleChange = event => {
    setSearchText(event.target.value);
  };

  const handleSearchSubmit = e => { // eslint-disable-line no-unused-vars
    setSearchingLoader(true);
    api.get(`designs?rowsPerPage=${rowsPerPage}&keyword=${searchText}&organization_id=${organizationId}`)
      .then(data => {
        dispatch(setInitialDesigns(data.data));
        dispatch(setDesignResponseData(
          {
            total:data.total,
            prev:data.prev,
            next:data.next,
            currentPage:data.currentPage
          }));
        setSearchingLoader(false);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message)
        setSearchingLoader(false);
      });
  };

  const handleEnterKeyPress = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      handleSearchSubmit();
    }
  };

  return (
    <Box display="flex">
      <Paper
        {...rest}
        className={classes.root}
      >
        <SearchIcon
          color="disabled"
          fontSize="small"
        />
        <Input
          {...rest}
          autoFocus
          className={classes.input}
          disableUnderline
          onChange={handleChange}
          onKeyPress={handleEnterKeyPress}
        />
      </Paper>
      <Button
        className={classes.button}
        onClick={event => handleSearchSubmit(event)}
        variant="contained"
      >
        Search
      </Button>
      {searchingLoader &&(
        <CircularProgress
          className={classes.loader}
          disableShrink
        />
      )}
    </Box>
  );
}
