import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Paper,
  Input,
  Box,
  Card,
  Avatar,
  Button,
  TableRow,
  Table,
  TableBody,
  Typography,
  TableHead,
  TableCell,
  Checkbox,
  TablePagination
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setOGMODesigns } from '../../reducers/ogmoDesignsSlice';
import { setDesignRowsPerPage } from '../../reducers/ogmoDesignsSlice'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as api from 'services/api';
import SearchIcon from '@material-ui/icons/Search';
import Preview from '@material-ui/icons/RemoveRedEye';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { showErrorSnackbar} from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  paper: {
    borderRadius: 5,
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 50,
    width: 300,
    marginRight: theme.spacing(2),
  },
  input: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    width: 200,
  },
  searchBar: {
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },
  nameTypography: {
    width: 100
  },
  linkButton: {
    marginTop: 20,
    width: '140px',
    height: '45px',
    color: 'white',
    fontSize: 14,
    borderRadius: 3,
    backgroundColor: '#3e8eb8',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  loader: {
    color: '#3183ae',
    margin: theme.spacing(8)
  },
  productAvatar: {
    marginRight: theme.spacing(3),
    borderRadius: 5,
    border: 'solid 1px #c4c4c4',
    width: 90,
    height: 90
  },
  productName: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1.19,
    textAlign: 'left',
    color: '#27385d',
  },
  productDetails: {
    marginBottom: 5,
    fontSize: '14px',
    lineHeight: 1.29,
    color: '#27385d',
  },
  title: {
    marginTop: theme.spacing(5),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterWSpacing: 'normal',
    color: '#27385d'
  },
  searchButton: {
    color: 'white',
    fontSize: 14,
    width: 120,
    height: 45,
    borderRadius: 3,
    backgroundColor: '#3e8eb8',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  card: {
    maxWidth: 1000
  },
  designAvatar: {
    marginRight: theme.spacing(2),
    borderRadius: 5
  },
  tableHead: {
    color: '#a8a8a8'
  },
  tableData: {
    fontSize: '14px',
    color: '#585858'
  }
}));

export default function SelectOGMODesign(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);

  const [keyword, setKeyword] = useState('');
  const [isOGMODesignLoading, setIsOGMODesignLoading] = useState(false);
  const [selectedDesign, setSelectedDesign] = React.useState({});
  const [initialized, setInitialized] = useState(false);

  const {productName, productUrlKey, productSKU, productOriginalId } = props.location.state;

  const { productsByIdsInTheStore,total,next,prev,currentPage } = useSelector(state => {
    const obj = {
      productsByIdsInTheStore : state.ogmoDesigns.designs.data,
      total : state.ogmoDesigns.designs.total,
      next:state.ogmoDesigns.designs.next,
      prev:state.ogmoDesigns.designs.prev,
      currentPage:state.ogmoDesigns.designs.currentPage
    }
    return obj;
  });

  const rowsPerPage = useSelector(
    state => state.ogmoDesigns.designRowsPerPage
  );
  const productIds = Object.keys(productsByIdsInTheStore || []);

  const productsInTheStore = productIds.map(productId => {
    return productsByIdsInTheStore[productId];
  });

  const getOGMODesign = url => {
    setIsOGMODesignLoading(true);
    api
      .get(url)
      .then(data => {
        dispatch(
          setOGMODesigns({
            data: data.data,
            total: data.total,
            next: data.next,
            prev: data.prev,
            currentPage: data.currentPage
          })
        );
        setIsOGMODesignLoading(false);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
      });
  };

  useEffect(() => {
    const url = `designs?rowsPerPage=${rowsPerPage}&organization_id=${organizationId}`;
    getOGMODesign(url);
  }, [])

  const SearchByName = () => {
    const url = `designs?rowsPerPage=${rowsPerPage}&keyword=${keyword}&organization_id=${organizationId}`;
    getOGMODesign(url);
  };

  const handleChangeName = event => {
    setKeyword(event.target.value);
  };

  const handleChangePageNumber = page => {
    const url =
      currentPage > page + 1
        ? prev
        : next;

    getOGMODesign(url);
    setSelectedDesign({});
  };

  const handleChangeRowsPerPage = event => {
    dispatch(setDesignRowsPerPage(event.target.value));
    setSelectedDesign({});
  };

  useEffect(() => {
    if(initialized){ SearchByName();}
    if(!initialized){setInitialized(true)} 
  }, [rowsPerPage]);

  const handleClickCheckBox = (id, name) => {
    if (id !== selectedDesign.id) {
      setSelectedDesign({ id, name });
    } else {
      setSelectedDesign({});
    }
  };
  const handleClickOGMODesign = (id, name) => {
    history.push({
      pathname: 'complete',
      state: {
        OGMODesignId: id,
        productSKU: productSKU,
        productUrlKey: productUrlKey,
        productName: productName,
        productOriginalId: productOriginalId,
        OGMODesignName: name
      }
    });
  };

  const handleProductsBreadcrumbClick = event => {
    event.preventDefault();
    history.push('/assign/chooseProducts')
  };

  const handlePreview = (design) => {
    var url = `${api.ViewerHost}/?id=${design.short_url}`;
    var win = window.open(url, '_blank');
    win.focus();
  };

  const isSelected = id => selectedDesign.id === id;

  const handleEnterKeyPress = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      SearchByName();
    }
  };

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleProductsBreadcrumbClick}
        >
          Products
        </Link>
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >Link Product</Typography>
      </Breadcrumbs>
      <Box
        ml={10}
        mt={10}
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            my={2}
          >
            <Box>
              <Avatar className={classes.productAvatar} />
            </Box>
            <Box>
              <Typography className={classes.productName}>
                {productName}
              </Typography>
              <Typography className={classes.productDetails}>
                SKU : {productSKU}
              </Typography>
            </Box>
          </Box>
          <Typography
            className={classes.title}
            variant="subtitle2"
          >
            Select a Design To Link
          </Typography>
          <Box
            className={classes.searchBar}
            display="flex"
          >
            <Paper className={classes.paper}>
              <SearchIcon
                color="disabled"
                fontSize="small"
              />
              <Input
                autoFocus
                className={classes.input}
                disableUnderline
                onChange={handleChangeName}
                onKeyPress={handleEnterKeyPress}
                placeholder="Search by Name"
              />
            </Paper>
            <Button
              className={classes.searchButton}
              onClick={SearchByName}
              variant="contained"
            >
              Search
            </Button>
          </Box>
        </Box>
        <Card className={classes.card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHead}>Design Name</TableCell>
                <TableCell className={classes.tableHead}>Modified On</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            {(!isOGMODesignLoading || productsInTheStore.length > 0) && (
              <>
                {productsInTheStore.map((element, index) => {
                  const isItemSelected = isSelected(element.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  const date = new Date(element.updated_at);
                  const updatedDate = date.toLocaleDateString();
                  const updatedTime = date.toLocaleTimeString();
                  return (
                    <TableBody key={index}>
                      <TableRow
                        hover
                        onChange={() =>
                          handleClickCheckBox(element.id, element.name)
                        }
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell width="35%">
                          <Box
                            alignItems="center"
                            display="flex"
                          >
                            <Typography className={classes.tableData}>
                              {element.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          className={classes.tableData}
                          display="flex"
                          width="35%"
                        >
                          {updatedDate !== 'Invalid Date' && <Typography>{updatedDate}</Typography>}
                          {updatedTime !== 'Invalid Date' && <Typography>{updatedTime}</Typography>}
                        </TableCell>
                        <TableCell align="center">
                          <Preview onClick={() => handlePreview(element)} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </>
            )}
          </Table>
          {isOGMODesignLoading && (
            <Box
              display="flex"
              justifyContent="center"
              pt={2}
            >
              <CircularProgress className={classes.loader} />
            </Box>
          )}
          {productsInTheStore.length > 0 && (
            <TablePagination
              backIconButtonProps={{
                'aria-label': 'previous page'
              }}
              component="div"
              count={total ? total : 0}
              nextIconButtonProps={{
                'aria-label': 'next page'
              }}
              onChangePage={(event, page) => handleChangePageNumber(page)}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={currentPage - 1}
              rowsPerPage={Number(rowsPerPage)}
              rowsPerPageOptions={[5, 10, 25]}
            />
          )}
        </Card>
        <Button
          className={classes.linkButton}
          color="primary"
          disabled={!selectedDesign.id}
          onClick={() =>
            handleClickOGMODesign(selectedDesign.id, selectedDesign.name)
          }
          variant="contained"
        >
          Link Design
        </Button>
      </Box>
    </Box>
  );
}
