import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table, TableBody, TableContainer, TableCell, TableRow,
  TableHead, Paper, Typography, TablePagination, Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoader from 'super_admin/components/CircularLoader';
import { useHistory } from 'react-router-dom';
import * as api from '../../../src/services/api';
import { showErrorSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 600,
    color: '#1F3974'
  },
  blueTypography: {
    fontWeight: 600,
    color: '#1F3974'
  }
}));

const AdminOrganizations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [organizations, setOrganizations] = useState([]);
  const [organizationCount, setOrganizationCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, organizations.length - page * rowsPerPage);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true)
    api.post('analytics/report',{})
      .then(res => res.map(organization => {
        return { ...organization, organization_id: organization.organization_id.replace(/ORG#|ORG_|ORG/gi, '', '').replace(/_/gi, '-') };
      }))
      .then(count_data => {
        api.get('superadmin/organizations/all')
          .then(res => {
            const org_data = res.data.map((org) => {
              const org_count_index = (count_data.findIndex(iter => iter.organization_id == org.organization_id.split('ORG#')[1]));
              return { 'id': org.organization_id, 'name': org.name, 'profile': '', 'ar_views': 0, 'd_views': (org_count_index >= 0) ? count_data[org_count_index].count : 0, 'org_blocked_by_admin': org.org_blocked_by_admin ? org.org_blocked_by_admin : false, 'org_blocked_by_superadmin': org.org_blocked_by_superadmin ? org.org_blocked_by_superadmin : false };
            })
            setOrganizations(org_data);
            setOrganizationCount(org_data.length);
            setIsLoading(false);
          })
          .catch(error => {
            dispatch(showErrorSnackbar('Organizations were failed to load'));
            console.log(error);
          });
      });
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRedirect = (selectedOrganization) => {
    history.push({
      pathname: '/organizationDetail',
      state: { organization: selectedOrganization }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Box p={4}>
        <Typography className={classes.title} > Organizations </Typography>
      </Box>
      {!isLoading && <Box
        display="flex"
        flexDirection="column"
        p={4}
      >
        <Typography
          className={classes.title}
          component={'span'}
        >{organizationCount} Organizations are available</Typography>
      </Box>
      }
      {isLoading && <CircularLoader />}
      {!isLoading && <Box p={4} >
        <>
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              className={classes.table}
            >
              <TableHead>
                <TableRow >
                  <TableCell
                    align="left"
                    className={classes.blueTypography}
                  >Organization Name</TableCell>
                  <TableCell
                    align="center"
                    className={classes.blueTypography}
                  >AR Views(Not Implemented Yet)</TableCell>
                  <TableCell
                    align="center"
                    className={classes.blueTypography}
                  >3D Views</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((organization, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRedirect(organization)}
                  >
                    <TableCell
                      align="left"
                      className={classes.blueTypography}
                    >{organization.name}</TableCell>
                    <TableCell
                      align="center"
                      className={classes.blueTypography}
                    >{organization.ar_views}</TableCell>
                    <TableCell
                      align="center"
                      className={classes.blueTypography}
                    >{organization.d_views}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={organizations.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
          /></>
      </Box>
      }
    </>);
};
export default AdminOrganizations;
