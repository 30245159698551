import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Typography, Box, Tab, Tabs, Breadcrumbs, Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LinkedSites from 'super_admin/components/LinkedSites';
import Designs from 'super_admin/components/Designs';
import Products from 'super_admin/components/Products';
import BlockUnBlock from 'super_admin/components/BlockUnBlock'
import Collaborators from 'super_admin/components/Collaborators';
import Statistics from 'super_admin/components/Statistics';
import * as api from '../../../src/services/api';
import { showErrorSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 600,
    color: '#1F3974'
  },
  blueTypography: {
    fontWeight: 600,
    color: '#1F3974'
  },
  superadmin_block_button: {
    backgroundColor: 'red',
    color: 'white',
    fontSize: '12px',
  },
  admin_block_button: {
    backgroundColor: 'orange',
    color: 'white',
    fontSize: '12px',
  }
}));

const AdminOrganizations = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { organization } = props.location.state || {};
  const [isBlockedBySuperAdmin, setIsBlockedBySuperAdmin] = React.useState(false);
  const [isBlockedByAdmin, setIsBlockedByAdmin] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    api.get(`superadmin/organization/${organization.id.split('ORG#')[1]}`)
      .then(res => {
        setIsBlockedBySuperAdmin(res.data.org_blocked_by_superadmin);
        setIsBlockedByAdmin(res.data.org_blocked_by_admin);
        setIsLoading(false);
      })
      .catch(error => {
        dispatch(showErrorSnackbar('Organization was failed to load'));
        console.log(error);
      });
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditBreadcrumbClick = event => {
    event.preventDefault();
    history.push('/organizations');
  };

  return (
    <Box p={5}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon />}
      >
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleEditBreadcrumbClick}
        >
          <Typography className={classes.title} > Organizations </Typography>
        </Link>
        <Typography className={classes.title} >
          {organization.name}
        </Typography>
        {(!isLoading) && <Typography
          className={classes.title}
          component={'span'}
        >
          {(isBlockedBySuperAdmin ? <div className={classes.superadmin_block_button}>Account Blocked By SuperAdmin</div> : isBlockedByAdmin ? <div className={classes.admin_block_button}> Account Blocked By Admin</div> : null)}
        </Typography>}
      </Breadcrumbs>
      <Tabs
        aria-label="disabled tabs example"
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        value={value}
      >
        <Tab label="Sites" />
        <Tab label="Designs" />
        <Tab label="Products" />
        <Tab label="Collaborators" />
        <Tab label="Statistics / Limit" />
        <Tab label="Block/Unblock" />
      </Tabs>
      {value == 0 && <LinkedSites organization_id={organization.id.split('ORG#')[1]} />}
      {value == 1 && <Designs organization_id={organization.id.split('ORG#')[1]} />}
      {value == 2 && <Products organization_id={organization.id.split('ORG#')[1]} />}
      {value == 3 && <Collaborators organization_id={organization.id.split('ORG#')[1]} />}
      {value == 4 && <Statistics organization_id={organization.id.split('ORG#')[1]} />}
      {value == 5 && <BlockUnBlock
        organization_id={organization.id.split('ORG#')[1]}
        setIsBlocked={(isBlockBySuperAdmin, isBlockByAdmin) => { setIsBlockedBySuperAdmin(isBlockBySuperAdmin); setIsBlockedByAdmin(isBlockByAdmin) }}
      />}
    </Box>);
};
export default AdminOrganizations;
