import { OgmoConsts } from '../constants/consts';
import { createUUID } from "../core/Utils";

export const TextureAsset = () => ({
	type: OgmoConsts.AssetType.TEXTURE,
	name: "Default Texture",
	id: createUUID(),
	data: {
		isCompressed: false,
		compressionType: "Uncompressed",
		levels: 2,
		originalTextures: [],
		compressedTextures: []
	}
});
