import React, {useEffect, useState} from 'react';
import './Editor.css';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TreeViewStructure from '../../components/treeViewStructure/treeViewStructure';
import ComponentInspector from '../../components/ComponetInspector/ComponetInspector';
import EditorCanvas from '../../components/EditorCanvas';
import AddPrefab from 'components/AddPrefab';
import AssetInspector from '../../components/AssetInspector/AssetInspector';
import AssetsBrowser from '../../components/AssetsBrowser/AssetsBrowser';
import ToolBar from '../../components/ToolBar';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadScene, resetScene, setSelectedEntity } from 'ogmo_core';
import EditorLoader from '../../components/EditorLoader';
import * as api from '../../services/api';
import Button from '@material-ui/core/Button';
import RootEntitySelectedMessage from 'components/ComponetInspector/RootEntitySelectedMessage';
import EditGlobalHotspot from 'components/ComponetInspector/GlobalHotspotComponent/EditGlobalHotspot';
import EditGlobalHotspotGroupComponent from 'components/ComponetInspector/GlobalHotspotGroupComponent/EditGlobalHotspotGroupComponent';
// Then, when you start to have logic in your mapDispatchToProps function, it's time to dispatch action in your saga (3rd way).
import { showErrorSnackbar} from 'reducers/snackbarSlice';

const Inspectors = ({designId}) =>{
  const dispatch = useDispatch()
  const [linkedProductIds,setLinkedProductIds] = useState([])
  const selectedAssetId = useSelector(state => state.scene.selectedAssetId);
  const selectedEntitiyId = useSelector(state => state.scene.selectedEntitiyId);
  const rootId = useSelector(state => state.scene.rootId);
  const selectedGlobalHotspotComponentId = useSelector(state => state.hotSpot.globalHotspotComponentId)
  const selectedGlobalHotspotGroupComponentId = useSelector(state => state.hotSpot.globalHotspotGroupComponentId)
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);

  useEffect(() => {
    api.get(`products/assign/find_by/${designId}?organization_id=${organizationId}`).then(
      res => setLinkedProductIds(res)
    ).catch(err => {
      console.error(err)
      dispatch(showErrorSnackbar('Getting Linked Products for this design failed'))
    })
  },[])

  if(selectedAssetId){
    return <AssetInspector />
  }

  if(selectedGlobalHotspotComponentId){
    return <EditGlobalHotspot  
      componentId={selectedGlobalHotspotComponentId} 
      linkedProductIds={linkedProductIds}
    />
  }

  if(selectedGlobalHotspotGroupComponentId){
    return <EditGlobalHotspotGroupComponent  componentId={selectedGlobalHotspotGroupComponentId} />
  }

  if(selectedEntitiyId == rootId ){
    return <RootEntitySelectedMessage />
  }

  if(!selectedAssetId ){
    return <ComponentInspector />
  }
}

function Editor(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  //const loadScene = useSceneLoader();
  const { designId,designName,IsdesignPublished } = props.location.state || {}; ///////////////
  const [isModalOpen,setIsModalOpen] = useState(true);
  const rootId = useSelector(state => state.scene.rootId)
  if (!designId) history.push('/designs');

  useEffect(() => {
    resetScene();
    handleLoad(false);
  }, []);

  const handleLoadScene = (sceneURL) => {
    loadScene(sceneURL, () => {
      setIsModalOpen(false);
    })
  }

  const handleLoad = isPublic => {
    api.get(`editor/scene/${designId}`)
      .then(response => {
        const sceneURL = isPublic ? response.scene_public : response.scene_private;
        if (sceneURL) {
          handleLoadScene(sceneURL)
        } else {
          setIsModalOpen(false);
        }
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
      });
  }

  const seletRootEntity = () =>{
    setSelectedEntity(rootId);
  }

  return (
    <>
      <EditorLoader
        open = {isModalOpen}
      />
      <Grid
        className="editorContainer"
        container
        wrap="nowrap"
      >
        <ToolBar 
          IsdesignPublished={IsdesignPublished} 
          designId={designId}
        />
        <Grid
          className="assetView"
          item
          xs={2}
        >
          <h5 style={{ padding: '2%' }}>Entity Hierarchy V 1.3</h5>
          <Button
            onClick={seletRootEntity}
            variant="contained"
          >
            {designName}
          </Button>
          <AddPrefab />
          <TreeViewStructure />
        </Grid>
        <Grid
          className="editorAndAssetBrowserContainer"
          item
          xs={7}
        >
          <EditorCanvas designId={designId}/>
          <Box className="assetBrowser">
            <AssetsBrowser designId={designId}/>
          </Box>
        </Grid>
        <Grid
          className="settingsPanel"
          item
          xs={3}
        >
          <Inspectors designId={designId}/>
        </Grid>
      </Grid>
    </>
  );
}

export default Editor;
