import React, {useEffect}  from 'react';
import { Switch,Redirect} from 'react-router-dom';
import RouteWithLayout from '../components/RouteWithLayout/RouteWithLayout';
import { Main as MainLayout } from '../layouts';
import SelectProducts from './Products/SelectProducts'
import SelectOGMODesign from './Products/SelectOGMODesign';
import AssignDesignToProduct from './Products/AssignDesignToProduct';
import {useRouteMatch} from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { setEcommerceProducts} from '../reducers/ecommerceProductsSlice';

const Assign = () => {
  const dispatch = useDispatch();
  const { path} = useRouteMatch();

  useEffect(() => {
    return () => {
      dispatch(setEcommerceProducts({}));
    }
  }, [])

  return (
    <Switch>
      <RouteWithLayout 
        component={SelectProducts} 
        layout={MainLayout}
        path={`${path}/chooseProducts`}
      />
      <RouteWithLayout 
        component={SelectOGMODesign}
        exact
        layout={MainLayout}
        path={`${path}/chooseDesigns`}
      />
      <RouteWithLayout 
        component={AssignDesignToProduct}
        exact
        layout={MainLayout}
        path={`${path}/complete`}
      />
      <Redirect
        from="/"
        to={`${path}/chooseProducts`}
      />
    </Switch>
  );
};
export default Assign;
