import React from 'react';
import { ExpansionPanelDetails } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';

const useStyles = makeStyles({
  expansionPanelDetails: {
    backgroundColor: '#f7f4f4',
    marginBottom: -16
  },
  input: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'left',
    color: '#575757'
  }
});

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      marginTop: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      marginTop: 0
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#d6d6d6',
    marginTop: -1,
    height: 30,
    minHeight: 30,
    '&$expanded': {
      minHeight: 30
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

export default function ExpansionSection({ sectionName, children }) {
  const classes = useStyles();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.input}>{sectionName}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
