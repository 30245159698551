import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Input,
  IconButton,
  Divider,
  Avatar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChangePassword from './ChangePassword';
import DeleteIcon from '@material-ui/icons/Delete';
import * as api from '../../services/api';
import * as img from '../../services/image-resize';
import tempImg from '../../images/user-icon.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonalInfoNameEmail from './PersonalInforNameEmail';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setUserImage, setUserPhoneNumber } from '../../reducers/dashboardSlice';
import { showErrorSnackbar, showSuccessSnackbar} from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold'
  },
  margin: {
    marginBottom: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '12px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 250,
    border: 'solid 1px #C4C4C4',
    marginTop: theme.spacing(2)
  },
  image: {
    height: 90,
    width: 90,
    border: 'solid 1px #C4C4C4',
    borderRadius: '4px'
  },
  uploadButton: {
    backgroundColor: '#ececec',
    textTransform: 'capitalize',
    marginTop: 15
  },
  imageUploadFile: {
    display: 'none'
  },
  deleteIcon: {
    marginTop: 10
  },
  loader: {
    color: '#3183ae'
  },
  avatar: {
    borderRadius: 0,
    height: 88,
    width: 88
  }
}));

export default function AccountInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userPhoneNumber = useSelector(state => state.dashboard.userPhoneNumber);
  const userImageURL = useSelector(state => state.dashboard.userImageURL);

  const formik = useFormik({
    initialValues: { userPhoneNo: userPhoneNumber, userProfileImg: userImageURL },
    onSubmit: (values, { setSubmitting }) => {
      const { userPhoneNo, userProfileImg } = values
      return api
        .put('users', {
          img: (userProfileImg) ? userProfileImg : '',
          telephone: userPhoneNo
        })
        .then(res => {
          dispatch(setUserImage(res.data.img));
          dispatch(setUserPhoneNumber(res.data.telephone));
          setSubmitting(false);
          dispatch(showSuccessSnackbar(res.message));
        })
        .catch(error => {
          setSubmitting(false);
          dispatch(showErrorSnackbar(error.message));
          console.log(error.message);
        })
    }
  });

  const handleImageUpload = event => {
    const file = event.target.files[0];
    uploadImage(file);
  };

  const uploadImage = file => {
    img
      .imgResize(file, 'base64')
      .then(resizedImgResponse => {
        formik.setFieldValue('userProfileImg', resizedImgResponse)
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
      });
  };

  const deleteUploadedImg = () => {
    formik.setFieldValue('userProfileImg', null)
  };

  return (
    <Box>
      <Card className={classes.margin}>
        <CardContent>
          <Box mx={4}>
            <Typography
              className={classes.title}
              variant="subtitle2">
              Personal Info
            </Typography>
            <PersonalInfoNameEmail />
            <Divider />
          </Box>
          <Box mx={4}>
            {(formik.isSubmitting) && (
              <Box
                display="flex"
                justifyContent="center"
                pt={2}>
                <CircularProgress className={classes.loader} />
              </Box>
            )}
            <>
              {!formik.isSubmitting && (
                <>
                  <Box
                    display="flex"
                    my={4}>
                    <Box
                      alignItems="center"
                      className={classes.image}
                      display="flex"
                      justifyContent="center"
                      mr={3}
                    >
                      <Avatar
                        className={classes.avatar}
                        src={formik.values.userProfileImg ? formik.values.userProfileImg : tempImg}
                      />
                    </Box>
                    <Box
                      mr={11}
                      mt={4}>
                      <Input
                        accept="image/*"
                        className={classes.imageUploadFile}
                        id="contained-button-file"
                        inputProps={{'data-test': 'user-profile-picture'}}
                        onChange={handleImageUpload}
                        type="file"
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          className={classes.uploadButton}
                          component="span"
                        >
                                  Upload Picture
                        </Button>
                      </label>
                      <IconButton
                        className={classes.deleteIcon}
                        onClick={deleteUploadedImg}
                      >
                        <DeleteIcon
                          color="action"
                          fontSize="small" />
                      </IconButton>
                    </Box>
                    <Box>
                      <Typography>Tel No</Typography>
                      <Input
                        className={classes.input}
                        disableUnderline
                        inputProps={{'data-test': 'personal-info-telephone'}}
                        onChange={formik.handleChange('userPhoneNo')}
                        value={formik.values.userPhoneNo}
                      />
                    </Box>
                  </Box>
                </>
              )}
              <Button
                className={classes.button}
                data-test="save-personal-info-telephone"
                disabled={formik.isSubmitting}
                onClick={formik.handleSubmit}
                type="submit"
                variant="contained"
              >
                        Save Changes
              </Button>
            </>
          </Box>
        </CardContent>
      </Card>
      <ChangePassword />
    </Box>
  );
}
