import React from 'react';
import { Grid,Button } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteEntity as _deleteEntity } from 'ogmo_core';

const useStyles = makeStyles({
  iconEntity: {
    minHeight: '30px',
    background: '#e0e0e0',
    alignItems: ' center'
  },
  icon: {
    margin: '0 5px 0 10px'
  },
  entity: {
    color: '#575757'
  },
  deleteIcon:{
    marginLeft:'auto'
  }
});

export default function InspectorHeader({selectedEntitiyId}) {
  const classes = useStyles();
  
  const deleteEntity=()=>{
    _deleteEntity(selectedEntitiyId);
  }

  return (
    <Grid
      className={classes.iconEntity}
      container
    >
      <PlayCircleOutlineIcon
        className={classes.icon}
        color="action"
        fontSize="small"
      />
      <Typography
        className={classes.entity}
        variant="subtitle2"
      >
        Entity
      </Typography>
      <Button
        className={classes.deleteIcon}
        onClick={deleteEntity}
      >
        <DeleteIcon
          color="action"
          fontSize="small"
        />
      </Button>
    </Grid>
  );
}
