import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenGlobalHotspotDetails, deleteComponent } from 'ogmo_core';
import GlobalHotspotOptionList from './GlobalHotspotOptionList';
import AddOptionButton from './AddOptionButton';
import GlobalHotspotIconOrColor from './GlobalHotspotIconOrColor'

const useStyles = makeStyles({
  componentBody: {
    Height: 'auto',
    width:326
  },
  globalHotspotTypography: {
    marginTop: 20,
    minHeight: 35,
    background: '#e0e0e0',
    paddingLeft: '10px',
  },
  globalHotspotContent: {
    height: 'auto',
    background: '#f7f4f4',
    marginLeft: '15px'
  },
  Typography: {
    color: '#575757'
  },
  deleteIcon: {
    marginLeft: 'auto',
    marginRight: '20px',
  },
  createIcon: {
    marginRight: 1,
    marginLeft: '120px',
  },
  nameInput: {
    backgroundColor: '#eeeeee',
    height: 25,
    paddingTop: 3
  }
});

const  GlobalHotspotComponent = ({ componentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalHotspotComponent = useSelector(state => state.scene.componentByIds[componentId]);
  const globalHotspotComponentData = globalHotspotComponent.data

  const deleteGlobalHotspot = () => {
    deleteComponent(globalHotspotComponent)
  };

  const handleOpenGlobalHotspotDetails = (globalHotspotComponentId) => {
    dispatch(setOpenGlobalHotspotDetails(globalHotspotComponentId));
  };

  return (
    <Box
      className={classes.componentBody}
      display="flex"
      flex="1"
      flexDirection="column"
      position="relative"
    >
      <Box
        alignItems="center"
        className={classes.globalHotspotTypography}
        display="flex"
      >
        <Typography variant="subtitle2" >
          Global Hotspot
        </Typography>
        <IconButton
          className={classes.createIcon}
          onClick={()=>handleOpenGlobalHotspotDetails(globalHotspotComponent.id)}
          size="small"
        >
          <CreateIcon
            color="action"
            fontSize="small"
          />
        </IconButton>
        <IconButton
          className={classes.deleteIcon}
          onClick={deleteGlobalHotspot}
          size="small"
        >
          <DeleteIcon
            color="action"
            fontSize="small"
          />
        </IconButton>
      </Box>
      <Box className={classes.globalHotspotContent}>
        <Box
          display="flex"
          mb={2}
          mt={2}
        >
          <Box width="20%">
            <Typography
              className={classes.Typography}
              variant="body1"
            >
              Name
            </Typography>
          </Box>
          <Box
            className={classes.nameInput}
            pl={1}
            width="80%"
          >
            <Typography
              className={classes.Typography}
              variant="body1"
            >
              {globalHotspotComponent.data.displayName}
            </Typography>
          </Box>
        </Box>
        <GlobalHotspotIconOrColor globalHotspot={globalHotspotComponentData} />
        <AddOptionButton componentId={globalHotspotComponent.id} />
        <GlobalHotspotOptionList componentId={globalHotspotComponent.id} />
      </Box>
    </Box>
  );
}
export default GlobalHotspotComponent;