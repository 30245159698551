import React from 'react';
import {
  Typography,
  Button,
  Box,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { useSelector, useDispatch } from 'react-redux'
import {
  editGlobalHotspotGroupName,
  setGlobalHotspotGroupColor,
  setIconToGlobalHotspotGroupComponent,
  closeGlobalHotspotGroupDetails,
  setComponentIdentifierType
} from 'ogmo_core';
import OnBlurOnEnterInput from './../../ComponetInspector/OnBlurOnEnterInput';
import MenuItem from '@material-ui/core/MenuItem';
import ColorPickerInputBase from '../.././ColorPickers/ColorPickerInputBase';
import GlobalHotspotGroupComponentIconPicker from './GlobalHotspotGroupComponentIconPicker';

const useStyles = makeStyles({
  body: {
    height: '100%',
  },
  titleBox: {
    minHeight: '30px',
    background: '#e0e0e0',
    alignItems: 'center'
  },
  title: {
    marginLeft: '10px'
  },
  icon: {
    margin: '0 5px 0 10px'
  },
  deleteIcon: {
    marginLeft: 'auto'
  },
  nameField: {
    backgroundColor: 'white',
    height: 25,
    paddingTop: 2,
    paddingLeft: 2
  },
  menu: {
    width: 180,
    backgroundColor: '#e6e6e6',
    height: 25,
    paddingTop: 2,
    paddingLeft: 2
  },
});

const EditGlobalHotspotGroupComponent = ({componentId}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalHotspotGroupComponent = useSelector(state => state.scene.componentByIds[componentId]);
  const globalHotspotGroupData = globalHotspotGroupComponent.data;
  const globalHotspotGroupColor = globalHotspotGroupData.color;
  const globalHotspotGroupIcon = globalHotspotGroupData.iconAssetId;
  const selectedIdentifierType = globalHotspotGroupData.identifierType

  const handleGlobalHotspotGroupDisplayNameChange = (value) => {
    dispatch(editGlobalHotspotGroupName({
      componentId: componentId,
      globalHotspotGroupDisplayName: value
    }));
  }

  const closeEditGlobalHotspotGroup = () => {
    dispatch(closeGlobalHotspotGroupDetails());
  }

  const handleChange = (event) => {
    let selectedIdentifierType= event.target.value;
    if (selectedIdentifierType == 'No Identifier') {
      dispatch(setIconToGlobalHotspotGroupComponent({
        globalHotspotGroupComponentId: componentId,
        iconAssetId: ''
      }));
      dispatch(setGlobalHotspotGroupColor({
        componentId: componentId,
        updateField: 'color',
        value: []
      }));
      dispatch(setComponentIdentifierType({
        componentId: componentId,
        updateField: 'identifierType',
        value: selectedIdentifierType
      }));
    }
    if (selectedIdentifierType == 'Icon') {
      dispatch(setGlobalHotspotGroupColor({
        componentId: componentId,
        updateField: 'color',
        value: []
      }));
      dispatch(setComponentIdentifierType({
        componentId: componentId,
        updateField: 'identifierType',
        value: selectedIdentifierType
      }));
    }
    if (selectedIdentifierType == 'Color') {
      dispatch(setIconToGlobalHotspotGroupComponent({
        globalHotspotGroupComponentId: componentId,
        iconAssetId: ''
      }))
      dispatch(setComponentIdentifierType({
        componentId: componentId,
        updateField: 'identifierType',
        value: selectedIdentifierType
      }));
    }
  };

  const handleChangeGlobalHotspotGroupColor = event => {
    const Hex2RGB = hex => {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      const R = r / 255;
      const G = g / 255;
      const B = b / 255;
      return [R, G, B];
    };

    dispatch(setGlobalHotspotGroupColor({
      componentId: componentId,
      updateField: 'color',
      value: Hex2RGB(event.target.value)
    }));
  };

  return (
    <Box
      className={classes.body}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Box
        alignItems="center"
        className={classes.titleBox}
        display="flex"
      >
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          Edit Global Hotspot Group Details
        </Typography>
        <Button className={classes.deleteIcon}>
          <ArrowLeftIcon
            className={classes.icon}
            color="action"
            fontSize="small"
            onClick = {closeEditGlobalHotspotGroup}
          />
        </Button>
      </Box>
      <Box ml={3}>
        <Box
          alignItems="center"
          display="flex"
          mb={2}
          mt={3}
        >
          <Box width="30%">
            <Typography variant="body2">
              Group Name
            </Typography>
          </Box>
          <Box
            className={classes.nameField}
            width="60%"
          >
            <OnBlurOnEnterInput
              inputValue={globalHotspotGroupData.displayName}
              inputValueFontSize="12px"
              onPress={handleGlobalHotspotGroupDisplayNameChange}
              type="text"
            />
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          mb={3}
          mt={3}
        >
          <Box width="30%">
            <Typography variant="body2">
              Set Identifier
            </Typography>
          </Box>
          <Box width="60%" >
            <Select
              className={classes.menu}
              onChange={handleChange}
              value={selectedIdentifierType}
            >
              <MenuItem value="No Identifier">No identifier</MenuItem>
              <MenuItem value="Icon">Icon</MenuItem>
              <MenuItem value="Color">Color</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box>
          {(globalHotspotGroupColor.length || selectedIdentifierType == 'Color') &&
            (<ColorPickerInputBase
              colorValue={globalHotspotGroupData.color}
              handleChangeColorPicker={handleChangeGlobalHotspotGroupColor}
            />)
          }
        </Box>
        <Box>
          {(globalHotspotGroupIcon || selectedIdentifierType == 'Icon') &&
            <GlobalHotspotGroupComponentIconPicker globalHotspotGroupComponent={globalHotspotGroupComponent}/>
          }
        </Box>
      </Box>
    </Box>
  );
}

export default EditGlobalHotspotGroupComponent;

