import React from 'react';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularLoader() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      p={4}
    >
      <CircularProgress disableShrink />
    </Box>
  );
}
