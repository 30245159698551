import React from 'react';
import {
  Box,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import {
  createMeshMaterialOption,
  addTargetOptionForGlobalHotspot
} from 'ogmo_core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
  addTargetOptionButton: {
    marginTop: 20,
    display: 'flex',
    paddingLeft: '25px',
    alignItems: 'center'
  },
  button: {
    width: 250,
    alignSelf: 'center',
    borderRadius: 0,
    boxShadow: 'none',
    fontSize: 12,
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
    backgroundColor: '#e0e0e0',
    '&:hover': { boxShadow: 'none', backgroundColor: '#b5b5b5' }
  },
  buttonIcon: {
    backgroundColor: '#576077',
    color: 'white',
    borderRadius: 8
  }
});

const AddOptionButton = ({ componentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClickAddTargetOption = () => {
    dispatch(
      addTargetOptionForGlobalHotspot({
        option: createMeshMaterialOption(),
        componentId: componentId
      })
    );
  }

  return (
    <Box className={classes.addTargetOptionButton}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classes.button}
        onClick={handleClickAddTargetOption}
        size="small"
        startIcon={<AddIcon className={classes.buttonIcon} />}
        type="button"
        variant="contained"
      >
        Add Target Option
      </Button>
    </Box>
  );
}
export default AddOptionButton;





