import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  designs: {},
  userOrganizationId: null,
  isCreateGroupDesignDialogOpen: false,
  designResponseTotal:null,
  designResponsePrev:null,
  designResponseNext:null,
  designResponseCurrentPage:null,
  userImageURL: null,
  cognitoUsername: null,
  userPhoneNumber: ''
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setInitialDesigns(state, action) {
      state.designs = {};
      action.payload.forEach(design => {
        state.designs[design.id] = design;
      });
    },
    setUserOrganizationId(state, action) {
      state.userOrganizationId = action.payload;
    },
    deleteDesign(state, action) {
      delete state.designs[action.payload];
    },
    setOpenCreateGroupDesignDialog(state, action){
      state.isCreateGroupDesignDialogOpen = action.payload;
    },
    setDesignResponseData(state,action) {
      state.designResponseTotal = action.payload.total;
      state.designResponsePrev = action.payload.prev;
      state.designResponseNext = action.payload.next;
      state.designResponseCurrentPage = action.payload.currentPage;
    },
    setUserImage(state, action) {
      state.userImageURL = action.payload;
    },
    setCognitoUsername(state, action) {
      state.cognitoUsername = action.payload;
    },
    setDesignPublishedState(state,action){
      state.designs[action.payload.id].published=action.payload.publish;
    },
    ResetDashboardSlice(state, action){ // eslint-disable-line no-unused-vars
      Object.keys(state).forEach(key => {
        state[key] = initialState[key]
      })
    },
    setUserPhoneNumber(state, action) {
      state.userPhoneNumber= action.payload;
    }
  }
});

export const { 
  setInitialDesigns, 
  setUserOrganizationId, 
  deleteDesign, 
  setOpenCreateGroupDesignDialog,
  setDesignResponseData,
  setUserImage,
  setCognitoUsername,
  setDesignPublishedState,
  ResetDashboardSlice,
  setUserPhoneNumber
} = dashboardSlice.actions

export default dashboardSlice.reducer
