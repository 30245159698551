import { OgmoConsts } from '../constants/consts';
import { createUUID } from '../core/Utils';

export const MeshComponent = () => ({
    type: OgmoConsts.ComponentType.MESH,
    needsUpdate: true,
    id: createUUID(),
    name: "Mesh Component",
    data: {
        displayName: "Mesh Component",
        meshAssetId: "",
        materialAssetId: "",
        castShadows: true,
        receiveShadows: true,
    }
})