import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  tableHead: {
    color: '#9A9A9A',
    fontSize: 11
  },
  designName: {
    marginLeft: theme.spacing(4),
    color: '#9A9A9A',
    fontSize: 11
  }
}));

function EnhancedTableHead({
  showactions,
  published,
  blockedStatus,
  showCheckBox,
  showPreviewIcon
}) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {showCheckBox && <TableCell />}
        <TableCell>
          <Box>
            <Typography className={classes.designName}>Design Name</Typography>
          </Box>
        </TableCell>
        {showactions && (
          <TableCell className={classes.tableHead}>{published}</TableCell>
        )}
        {showactions && (
          <TableCell className={classes.tableHead}>{blockedStatus}</TableCell>
        )}
        <TableCell className={classes.tableHead}>Type</TableCell>
        <TableCell className={classes.tableHead}>Modified On</TableCell>
        {showactions && <TableCell />}
        {showPreviewIcon && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
