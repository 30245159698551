import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  products: {},
  page:1,
  rowsPerPage: '10',
  assignedProducts: {},
  ecommerceHosts: {},
  selectedEcommerce: ''
};

const ecommerceProductsSlice = createSlice({
  name: 'ecommerceProducts',
  initialState,
  reducers: {
    setEcommerceProducts(state, action) {
      state.products = action.payload;
    },
    setAssignedEcommerceProducts(state, action) {
      state.assignedProducts = action.payload;
    },
    setPageNumber(state, action) {
      state.page = action.payload;

    },
    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    setEcommerceHosts(state, action){
      state.ecommerceHosts = action.payload;
    },
    setSelectedEcommerce(state, action){
      state.selectedEcommerce = action.payload;
    },
    ResetEcommerceProducts(state, action){ // eslint-disable-line no-unused-vars
      Object.keys(state).forEach(key => {
        state[key] = initialState [key]
      })
    }
  }
});

export const {
  setEcommerceProducts,
  setAssignedEcommerceProducts,
  setPageNumber,
  setRowsPerPage,
  setEcommerceHosts,
  ResetEcommerceProducts,
  setSelectedEcommerce,
} = ecommerceProductsSlice.actions

export default ecommerceProductsSlice.reducer
