import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardContent,
  Button,
  Divider,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Box,
  Input
} from '@material-ui/core';
import DesignDialog from '../components/CreateDesign/DesignDialog';
import UseFetchDesigns from '../components/Design/UseFetchDesigns';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as api from '../services/api';
import { useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { showErrorSnackbar } from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 600
  },
  count: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  tableContent: {
    marginTop: theme.spacing(4),
    width: '75%'
  },
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: 5
  },
  button: {
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '8px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 300,
    border: 'solid 1px #C4C4C4',
  },
  noProductLabel: {
    backgroundColor: '#f8f8f8'
  },
  loader: {
    color: '#3183ae',
    height:'100%',
    width:'100%',
    marginTop:14,
    marginLeft:10

  }
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getDesignStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)'
  })
});

const getTableStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white'
});

const CreateGroupDesign = (props) => {

  const [
    rowsPerPage,
    setRowsPerPage,
    handlePageChange,
    isDesignsAreLoading,
    setIsDesignsAreLoading
  ] = UseFetchDesigns();

  const { designId } = props.location.state || {};
  const [openDesignsDialog, setOpenDesignsDialog] = useState(false);
  const [groupItemName, setGroupItemName] = useState('');
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [clickedDesignId, setClickedDesignId] = useState('');
  const [isSubmittingGroupDesignName, setIsSubmittingGroupDesignName] = useState(false);
  const [design,setDesign] = useState({list:[]});
  const [groupName, setGroupName] = useState(design.name);
  const [isPageLoading,setIsPageLoading] = useState(false);
  const [isSubmittingGroupItemName,setIsSubmittingGroupItemName] = useState(false);
  const [isGroupDesignNameEditClicked,setIsGroupDesignNameEditClicked] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const organizationId = useSelector(state => state.dashboard.userOrganizationId);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsPageLoading(true);
    api
      .get(`designs/${designId}`)
      .then(data => {
        setDesign(prevState => {
          return ((data.list && data.list.length) ? ({ ...prevState, ...data, list: data.list.sort((a, b) => a.position - b.position)}) : { ...prevState, ...data});
        }) 
        setGroupName(data.name);
        setIsPageLoading(false);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
        setIsPageLoading(false);
      });
  }, [designId]);

  const handleEditBreadcrumbClick = event => {
    event.preventDefault();
    history.push('/designs')
  };

  const handleOpenEdit = (item) => {
    setIsEditClicked(true);
    setClickedDesignId(item.id)
    setGroupItemName(item.name)
  };

  const handleCloseEdit = () => {
    setIsEditClicked(false);
  }

  const handleOpenGroupNameEdit = () => {
    setIsGroupDesignNameEditClicked(true);
  }

  const discardGroupNameChange = () => {
    setIsGroupDesignNameEditClicked(false);
    setGroupName(design.name)
  }

  const saveGroupNameChange = () => {
    setIsSubmittingGroupDesignName(true);
    handleUpdateGroupDesign(); 
    setIsGroupDesignNameEditClicked(false);
  }

  const handleClickOpen = () => {
    setOpenDesignsDialog(true);
  };

  const handleClose = () => {
    setOpenDesignsDialog(false);
  };

  const handleGroupNameChange = event => {
    setGroupName(event.target.value);
  };

  const handleSaveGroupItemNameChanges = (indexOfSingleDesign) => {
    handleCloseEdit();
    setIsSubmittingGroupItemName(true);
    design.list[indexOfSingleDesign].name = groupItemName;
    handleUpdateGroupDesign();
  }
  
  const handleUpdateGroupDesign = () => {
    const data = {
      name: groupName,
      type: 'Group',
      list: design.list,
      organization_id: organizationId
    };
    api
      .put(`designs/${design.id}`, data)
      .then(res => {
        setDesign(res.data)
        setIsSubmittingGroupDesignName(false);
        setIsSubmittingGroupItemName(false);
        setIsPageLoading(false);
        history.push('/designs');
      })
      .catch(error => {
        setIsSubmittingGroupDesignName(false);
        setIsSubmittingGroupItemName(false);
        setIsPageLoading(false);
        dispatch(showErrorSnackbar('Failed to edit design', error.message))
        console.log('failed to edit design', error.message)
        history.push('/designs');
      });
  };

  const handleDeleteDesignAddedToTheGroup = selectedDesignId => {
    const filteredDesigns = design.list.filter(
      eachDesign => eachDesign.id !== selectedDesignId
    );
    setDesign(prevState => {
      prevState.list = filteredDesigns;
      return { ...prevState };
    })
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const designs = reorder(
      design.list,
      result.source.index,
      result.destination.index
    );
    setDesign(prevState => {
      prevState.list = designs;
      return { ...prevState };
    })
  };

  const handlePreview = (designId) => {
    var url = `${api.ViewerHost}/?design_id=${designId}`;
    var win = window.open(url, '_blank');
    win.focus();
  }

  const handleGroupItemNameChange = (e) => {
    setGroupItemName(e.target.value)
  }


  const setSelectedDesignIdsToDesign = (newList) => {
    setDesign(prevState => {
      prevState.list = newList;
      return { ...prevState };
    })
  }

  const goBackToDesignsPage = () => {
    handleUpdateGroupDesign();
  }

  if(isPageLoading){
    return (
      <Box
        display="flex"
        justifyContent="center"
      >
        <CircularProgress
          className={classes.loader}
          disableShrink
        />
      </Box>
    )
  }
 
  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          className={classes.breadcrumb}
          color="inherit"
          onClick={handleEditBreadcrumbClick}
        >
          Designs
        </Link>
        <Typography
          className={classes.breadcrumb}
          color="textPrimary"
        >
          {design.id ? 'Edit Design' : 'Create Group Design'}
        </Typography>
      </Breadcrumbs>
      <Box p={8}>
        <Box
          display="flex"
          justifyContent="flex-end"
          mb={4}
          mt={0}
        >
          <Button
            className={classes.button}
            color="primary"
            onClick={goBackToDesignsPage}
            type="submit"
            variant="contained"
          >
            Save changes
          </Button>
        </Box>
        <Card>
          <CardContent>
            <Typography
              className={classes.title}
              variant="h5"
            >
             Group Design Name
            </Typography>
            <Box
              display="flex"
              ml={6}
            >
              <Box
                alignItems="center"
                display="flex"
              >
                {!isGroupDesignNameEditClicked && (
                  <IconButton onClick={handleOpenGroupNameEdit}>
                    <CreateIcon
                      fontSize="small"
                    />
                  </IconButton>
                )}

                {!isGroupDesignNameEditClicked && (
                  <Typography variant="h5">{groupName}</Typography>)}

                {isGroupDesignNameEditClicked && (
                  <Input
                    autoFocus
                    className={classes.input}
                    disableUnderline
                    onChange={handleGroupNameChange}
                    required
                    value={groupName}
                  />
                )}
                {isGroupDesignNameEditClicked && (
                  <IconButton 
                    disabled={!groupName}
                    onClick={saveGroupNameChange}
                  >
                    <CheckIcon />
                  </IconButton>
                )}
                {isGroupDesignNameEditClicked && (
                  <IconButton onClick={discardGroupNameChange}>
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
              {isSubmittingGroupDesignName && (<CircularProgress
                className={classes.loader}
                disableShrink
              />)}
            </Box>
          </CardContent>
        </Card>
        <Card className={classes.tableContent}>
          <CardContent>
            <Typography className={classes.count}>
              Designs in the Group : {design.list.length}
            </Typography>
            <Divider />
            {(design.list.length === 0) && (
              <Box
                className={classes.noProductLabel}
                display="flex"
                justifyContent="center"
                p={2}
              >
                <Typography>No products added to the group</Typography>
              </Box>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Table style={getTableStyle(snapshot.isDraggingOver)}>
                    <TableBody ref={provided.innerRef}>
                      {design.list.map((singleDesign, indexOfSingleDesign) => (
                        <Draggable
                          draggableId={singleDesign.id}
                          index={indexOfSingleDesign}
                          key={singleDesign.id}
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              key={singleDesign.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getDesignStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <TableCell>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                >
                                  <Box
                                    display="flex"
                                    flexGrow={1}
                                  >
                                    <IconButton>
                                      <DragHandleIcon fontSize="small" />
                                    </IconButton>
                                    <Box
                                      alignItems="center"
                                      display="flex"
                                    >
                                      <Avatar
                                        className={classes.avatar}
                                        src="/images/avatars/avatar_3.png"
                                      />
                                      {!isSubmittingGroupItemName && !isEditClicked && (
                                        <IconButton
                                          onClick={() =>
                                            handleOpenEdit(singleDesign)
                                          }
                                        >
                                          <CreateIcon
                                            fontSize="small"
                                          />
                                        </IconButton>
                                      )}
                                      {(clickedDesignId === singleDesign.id) && isSubmittingGroupItemName && (
                                        <CircularProgress
                                          className={classes.loader}
                                          disableShrink
                                        />
                                      )}

                                      {(!isEditClicked ||
                                          clickedDesignId !== singleDesign.id) && (
                                        <Typography>
                                          {singleDesign.name}
                                        </Typography>
                                      )}
                                      {isEditClicked &&
                                          clickedDesignId === singleDesign.id && (
                                        <Input
                                          autoFocus
                                          className={classes.input}
                                          disableUnderline
                                          onChange={
                                            handleGroupItemNameChange
                                          }
                                          required
                                          value={groupItemName}
                                        />
                                      )}
                                      {isEditClicked &&
                                          clickedDesignId === singleDesign.id && (
                                        <IconButton
                                          disabled={!groupItemName}
                                          onClick={() =>
                                            handleSaveGroupItemNameChanges(
                                              indexOfSingleDesign
                                            )
                                          }
                                        >
                                          <CheckIcon />
                                        </IconButton>
                                      )}
                                      {isEditClicked &&
                                          clickedDesignId === singleDesign.id && (
                                        <IconButton onClick={handleCloseEdit}>
                                          <CloseIcon />
                                        </IconButton>
                                      )}

                                    </Box>
                                  </Box>
                                  <Box display="flex">
                                    <IconButton
                                      onClick={() =>
                                        handlePreview(singleDesign.id)
                                      }
                                    >
                                      <VisibilityIcon
                                        fontSize="small"
                                      />
                                    </IconButton>
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteDesignAddedToTheGroup(
                                          singleDesign.id
                                        )
                                      }
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                      />
                                    </IconButton>

                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                )}
              </Droppable>
            </DragDropContext>
            <Divider />
            <Box
              ml={3}
              mt={3}
              p={2}
            >
              <Button
                className={classes.button}
                onClick={handleClickOpen}
                variant="contained"
              >
                Add designs to group
              </Button>
            </Box>
          </CardContent>
        </Card>
        {openDesignsDialog && (
          <DesignDialog
            designsAddedToTheGroup={design.list}
            handleClose={handleClose}
            handlePageChange={handlePageChange}
            isDesignsAreLoading={isDesignsAreLoading}
            open={openDesignsDialog}
            passSelectedDesignIdsToParent={setSelectedDesignIdsToDesign}
            rowsPerPage={rowsPerPage}
            setIsDesignsAreLoading={setIsDesignsAreLoading}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Box>
    </Box>
  );
};
export default CreateGroupDesign;
