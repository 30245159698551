import { OgmoConsts } from "../constants/consts";
import { createUUID } from "../core/Utils";

export const GLBAsset = () => ({
	type: OgmoConsts.AssetType.GLB,
	name: "",
	id: createUUID(),
	data: {
		glbPath: ""		
	}
});