import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Box, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useDispatch } from 'react-redux';
import { setUserOrganizationId } from '../reducers/dashboardSlice';
import * as api from 'services/api';
import { showErrorSnackbar} from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 550
  },
  button: {
    display: 'block',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
}));

const ChooseOrganization = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [availableOrganizationsForUser, setAvailableOrganizationsForUser] = useState([]);

  useEffect(() => {
    let cancelled = false
    api
      .get('organizations')
      .then((res) => {
        if (!cancelled) {
          setAvailableOrganizationsForUser(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(showErrorSnackbar(error.message));
      });
      return () => {
        cancelled = true;
      }
  }, []);

  const handleChange = event => {
    setSelectedOrgId(event.target.value);
  };

  const handleSetOrganization = event => { // eslint-disable-line no-unused-vars
    dispatch(setUserOrganizationId(selectedOrgId));
  }
  
  return (
    <Box>
      <Card>
        <CardContent>
          <Typography
            className={classes.title}
            variant="h5"
          >
            Choose an Organization
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel id="user-organization-selector">Select organization</InputLabel>
            <Select
              labelId="user-organization-selector"
              onChange={handleChange}
              value={selectedOrgId}
            >
              {
                availableOrganizationsForUser.map((organization) => {
                  return (
                    <MenuItem key={organization.organization_id} value={organization.organization_id}>{organization.name}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <Button
            className={classes.button}
            color="primary"
            onClick={handleSetOrganization}
            variant="contained"
          >
            Go to the Organization
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};
export default ChooseOrganization;
