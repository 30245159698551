import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table, Input, Button, TableBody, TableContainer, TableCell, TableRow,
  TableHead, Paper, TablePagination, Box, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoader from 'super_admin/components/CircularLoader';
import * as api from '../../../src/services/api';
import { showErrorSnackbar, showSuccessSnackbar } from 'reducers/snackbarSlice';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 600,
    color: '#1F3974'
  },
  input: {
    fontSize: 12,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    width: 100,
    border: 'solid 1px #C4C4C4'
  },
  button: {
    marginLeft: theme.spacing(2),
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '12px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  blueTypography: {
    fontWeight: 600,
    color: '#1F3974'
  }
}));

const Statistics = ({ organization_id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [statistics, setStatistics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limitLoading, setLimitLoading] = useState(true);

  useEffect(() => {
    setLimitLoading(true);
    api
      .get(`superadmin/organization/${organization_id}`)
      .then(data => {
        setDesignViewLimit(data.data.designLimit);
        setLimitLoading(false);
      })
      .catch(error => {
        dispatch(showErrorSnackbar(error.message));
        console.log(error);
        setLimitLoading(false);
      });
  }, []);

  const [designViews, setDesignViews] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, statistics.length - page * rowsPerPage);
  const [designViewLimit, setDesignViewLimit] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleChangeDesignLimit = event => {
    setDesignViewLimit(event.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    api.get(`superadmin/designs/${organization_id}`)
      .then(res => {
        const design_list = (res.data);
        api.post(`analytics/report/${organization_id}`,{})
          .then(res => {
            const des_data = [];
            res.map(design => {
              const short_index = design_list.findIndex(des => des.short_url == design.design_id);
              const id_index = design_list.findIndex(des => des.id.split('DES#')[1] == design.design_id);
              let des_stat = {};
              if (id_index != '-1') {
                const design_short_url = design_list.find(item => item.id.split('#DES'[1])).short_url;
                des_stat = { ...design, design_id: design_short_url, name: design_list[id_index].name, count: parseInt(design.count) };
              } else if (short_index != '-1') {
                des_stat = { ...design, name: design_list[short_index].name, count: parseInt(design.count) };
              }
              else {
                des_stat = { ...design, name: 'Deleted Design', count: parseInt(design.count) };
              }
              if (des_data.find(item => item.design_id == des_stat.design_id)) des_data[des_data.findIndex(item => item.design_id == des_stat.design_id)].count += des_stat.count;
              else des_data.push(des_stat);
            });
            setStatistics(des_data);
            setDesignViews(des_data.map(design => parseInt(design.count)).reduce((a, b) => a + b, 0));
            setIsLoading(false);
          })
          .catch(error => {
            dispatch(showErrorSnackbar('Statistics were failed to load'));
            console.log(error);
          });
      })
      .catch(error => {
        dispatch(showErrorSnackbar('Designs were failed to load'));
        console.log(error);
      });
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSubmit = () => {
    setIsSubmitting(true)
    return api
      .put(`superadmin/organization/${organization_id}`, {
        designLimit: designViewLimit
      })
      .then(res => {
        setIsSubmitting(false);
        dispatch(showSuccessSnackbar(res.message));
      })
      .catch(error => {
        setIsSubmitting(false);
        dispatch(showErrorSnackbar(error.message));
        console.log(error.message);
      });
  }

  return (
    <>
      {!isLoading && <Box
        display="flex"
        flexDirection="column"
        p={1}
      >
        <Typography
          className={classes.title}
          component={'span'}
        >Total Design Views : {designViews}</Typography>
      </Box>
      }
      {!limitLoading && (
        <Box
          alignItems="center"
          display="flex"
          mt = {2}
        >
          <Typography
            className={classes.title}
            component={'span'}
            required
          >
            Set design views limit:
          </Typography>
          <Input
            className={classes.input}
            disableUnderline
            inputProps={{ 'data-test': 'design-limit' }}
            onChange={handleChangeDesignLimit}
            required
            value={designViewLimit}
          />
          <Button
            className={classes.button}
            data-test="save-design-limit"
            disabled={isSubmitting}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Save / Update Limit
          </Button>
        </Box>
      )
      }
      { isLoading && <CircularLoader />}
      {
        !isLoading && <Box p={4} >
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow >
                    <TableCell className={classes.blueTypography}>Design Name</TableCell>
                    <TableCell className={classes.blueTypography}>Design id</TableCell>
                    <TableCell
                      align="right"
                      className={classes.blueTypography}
                    >Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statistics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.blueTypography}>{data.name}</TableCell>
                      <TableCell className={classes.blueTypography}>{data.design_id}</TableCell>
                      <TableCell
                        align="right"
                        className={classes.blueTypography}
                      >{data.count}</TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={statistics.length}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 100]}
            /></>
        </Box>
      }
    </>);
};
export default Statistics;
