import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Input,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { Auth } from 'aws-amplify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { showErrorSnackbar, showSuccessSnackbar} from 'reducers/snackbarSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  cardContent: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: 550
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold'
  },
  button: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    backgroundColor: '#3E8EB8',
    color: 'white',
    padding: '12px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: '#24739C' }
  },
  feedback: {
    color: 'red',
    fontSize: '13px',
    marginBottom: theme.spacing(3)
  }
}));

export default function ChangePassword() {
  const classes = useStyles();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickShowCurrentPassword = event => { // eslint-disable-line no-unused-vars
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = event => { // eslint-disable-line no-unused-vars
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = event => { // eslint-disable-line no-unused-vars
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          Change Password
        </Typography>
        <Formik
          initialValues={{
            oldpassword: '',
            newpassword: '',
            confirmpassword: ''
          }}
          onSubmit={async (values, { setSubmitting }) => { // eslint-disable-line no-unused-vars
            try {
              const currentUser = await Auth.currentAuthenticatedUser();
              await Auth.changePassword(
                currentUser,
                values.oldpassword,
                values.newpassword
              );
              dispatch(showSuccessSnackbar('Successfully changed!'));
            } catch (e) {
              dispatch(showErrorSnackbar(e.message));
              console.log(e);
            }
          }}
          validationSchema={Yup.object().shape({
            oldpassword: Yup.string().required('Old password cannot be empty'),
            newpassword: Yup.string()
              .required('No password provided.')
              .min(8, 'Password should be 8 characters minimum.')
              .matches(
                /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/,
                'Password should be strong.'
              ),
            confirmpassword: Yup.string()
              .oneOf([Yup.ref('newpassword'), null], 'Passwords must match')
              .required('Password confirm is required')
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box>
                <Box mb={5}>
                  <FormControl>
                    <InputLabel>Current Password</InputLabel>
                    <Input
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showCurrentPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      id="oldpassword"
                      onChange={handleChange}
                      type={showCurrentPassword ? 'text' : 'password'}
                      value={values.oldpassword}
                    />
                    {errors.oldpassword && touched.oldpassword && (
                      <Box className={classes.feedback}>
                        {errors.oldpassword}
                      </Box>
                    )}
                  </FormControl>
                </Box>
                <Box display="flex">
                  <Box mr={15}>
                    <FormControl>
                      <InputLabel>New Password</InputLabel>
                      <Input
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        id="newpassword"
                        onChange={handleChange}
                        type={showNewPassword ? 'text' : 'password'}
                        value={values.newpassword}
                      />
                      {errors.newpassword && touched.newpassword && (
                        <Box className={classes.feedback}>
                          {errors.newpassword}
                        </Box>
                      )}
                      <FormHelperText>Password should be strong</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl>
                      <InputLabel>Confirm Password</InputLabel>
                      <Input
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        id="confirmpassword"
                        onChange={handleChange}
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={values.confirmpassword}
                      />
                      {errors.confirmpassword && touched.confirmpassword && (
                        <Box className={classes.feedback}>
                          {errors.confirmpassword}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Button
                className={classes.button}
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}
